import { isEmpty } from 'react-form-validator-core/lib/ValidationRules';

export const caseInsensitiveSort = field => (a, b) => {
  const aLower = a[field].toString().toLowerCase();
  const bLower = b[field].toString().toLowerCase();
  return aLower < bLower ? -1 : 1;
};

export const sortByName = (a, b) => {
  const nameA = `${a.firstName} ${a.lastName}`;
  const nameB = `${b.firstName} ${b.lastName}`;

  return nameA < nameB ? -1 : 1;
};

export const sortByTeamSeasonName = (a, b) => {
  if (isEmpty(a.activeRosters)) return 1;

  if (isEmpty(b.activeRosters)) return -1;

  return a.activeRosters[0].name < b.activeRosters[0].name ? -1 : 1;
};

export const sortByTeamSeasonEndDate = (a, b) => {
  if (isEmpty(a.activeRosters)) return 1;

  if (isEmpty(b.activeRosters)) return -1;

  return a.activeRosters[0].endDate < b.activeRosters[0].endDate ? -1 : 1;
};

export const sortByTeamHasSeason = (a, b) => {
  if (isEmpty(a.activeRosters)) return 1;

  if (isEmpty(b.activeRosters)) return -1;

  return 1;
};

export const sortByBoolean = key => (a, b) => {
  if (a[key] === b[key]) return 0;

  return a[key] ? 1 : -1;
};
