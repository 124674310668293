import produce from 'immer';

import {
  GET_ORGANIZATION_WATCHLIST_FAILURE,
  GET_ORGANIZATION_WATCHLIST_REQUEST,
  GET_ORGANIZATION_WATCHLIST_SUCCESS,
} from 'modules/watchlist/watchlist.actions';

const initialState = {
  isLoading: false,
  watchlist: [],
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_WATCHLIST_SUCCESS:
      draft.watchlist = action.response;
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_WATCHLIST_FAILURE:
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_WATCHLIST_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
