import React from 'react';
import SportScaleMenuButton from 'common/buttons/sportScaleMenuButton.component';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const SportScaleTableActionMenu = (className, getRowActions) => ({ data }) => {
  return (
    <SportScaleMenuButton
      buttonComponent={
        <IconButton className={className} label="">
          <MoreVert />
        </IconButton>
      }
      menuItems={getRowActions(data)}
    />
  );
};

export default SportScaleTableActionMenu;
