import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, DialogContentText, Paper, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleBreadCrumbs from 'modules/layout/breadCrumbs/breadCrumbs.component';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import SubscriptionForm from 'modules/organizations/subscriptions/subscriptionForm.component';
import {
  CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  createOrganizationSubscription,
  getOrganizationSubscription,
  removeOrganizationSubscription,
  resetSelectedSubscription,
  updateOrganizationSubscription,
} from 'modules/organizations/subscriptions/subscriptions.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { ROUTES, SUBSCRIPTION_ID_MAP, generateBreadCrumbMap, generateRouteParamsMap } from 'utilities/breadCrumbs';
import { SUBSCRIPTION_NONE_VALUE } from 'utilities/subscriptions';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { getSubmitText } from 'utilities/form';
import { selectSelectedOrganization } from 'modules/organizations/organizations.selectors';
import {
  selectSelectedSubscription,
  selectSubscriptionsIsLoadingSubscription,
} from 'modules/organizations/subscriptions/subscriptions.selectors';

const INITIAL_SUBSCRIPTION = {
  endDate: null,
  startDate: null,
};

const Subscription = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { organizationId, subscriptionId } = useParams();

  const isLoadingSubscription = useSelector(selectSubscriptionsIsLoadingSubscription);
  const selectedSubscription = useSelector(selectSelectedSubscription);

  const selectedOrganization = useSelector(selectSelectedOrganization);

  const [subscription, setSubscription] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const isNewSubscription = subscriptionId === NEW_ITEM_ROUTE;

  useEffect(() => {
    return () => {
      dispatch(resetSelectedSubscription());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(selectedSubscription)) setSubscription(selectedSubscription);
  }, [dispatch, selectedSubscription]);

  useEffect(() => {
    if (!isNil(selectedOrganization)) {
      dispatch(setPageTitle(`${selectedOrganization.name} - Subscription`));
    }

    if (isNewSubscription) {
      setSubscription(INITIAL_SUBSCRIPTION);
    }

    if (!isEmpty(location.state)) {
      const { subscription: locationSubscription } = location.state;

      setSubscription(locationSubscription);
      return;
    }

    if (!isNil(selectedOrganization) && !isNewSubscription) {
      dispatch(getOrganizationSubscription(organizationId, subscriptionId));
    }
  }, [dispatch, location, isNewSubscription, selectedOrganization, organizationId, subscriptionId]);

  const handleInputChange = useCallback(
    ({ target: { name, value } }) => setSubscription({ ...subscription, [name]: value }),
    [subscription, setSubscription]
  );

  const handleSubmitSubscription = useCallback(() => {
    (async () => {
      let response;
      const updatedSubscription = {
        ...subscription,
        endDate: moment(subscription.endDate),
        startDate: moment(subscription.startDate),
        type: SUBSCRIPTION_NONE_VALUE,
        athleteLimit: 1,
        teamLimit: 1,
      };

      if (isNewSubscription) {
        response = await dispatch(createOrganizationSubscription(organizationId, updatedSubscription));
      } else {
        delete updatedSubscription.tableData;
        response = await dispatch(updateOrganizationSubscription(organizationId, updatedSubscription));
      }

      if (
        response.type !== CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS &&
        response.type !== UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS
      ) {
        const errorMessage = isNewSubscription
          ? ERROR_MESSAGES.CREATE_SUBSCRIPTION_FAILURE
          : ERROR_MESSAGES.UPDATE_SUBSCRIPTION_FAILURE;

        dispatch(handleToastMessage(errorMessage, TOAST_TYPES.ERROR));
        return;
      }

      const successMessage = isNewSubscription
        ? SUCCESS_MESSAGES.CREATE_SUBSCRIPTION_SUCCESS
        : SUCCESS_MESSAGES.UPDATE_SUBSCRIPTION_SUCCESS;

      dispatch(handleToastMessage(successMessage));

      history.push(`/organizations/${organizationId}`);
    })();
  }, [dispatch, history, isNewSubscription, organizationId, subscription]);

  const submitText = useMemo(() => getSubmitText(isLoadingSubscription, isNewSubscription), [
    isLoadingSubscription,
    isNewSubscription,
  ]);

  const breadCrumbs = useMemo(() => {
    if (!isNil(subscription)) {
      const subscriptionCrumb = isNewSubscription ? 'create' : 'edit';

      return generateBreadCrumbMap(generateRouteParamsMap(SUBSCRIPTION_ID_MAP, [organizationId, subscriptionId]), {
        [ROUTES.ORGANIZATION_ROUTE]: 'organizations',
        [isNewSubscription ? ROUTES.NEW_SUBSCRIPTION_ROUTE : ROUTES.SUBSCRIPTION_ROUTE]: subscriptionCrumb,
      });
    }

    return {};
  }, [isNewSubscription, organizationId, subscription, subscriptionId]);

  const handleToggleRemoveDialog = useCallback(() => setRemoveDialogOpen(!removeDialogOpen), [
    removeDialogOpen,
    setRemoveDialogOpen,
  ]);

  const handleDeleteSubscription = useCallback(() => {
    (async () => {
      const response = await dispatch(removeOrganizationSubscription(organizationId, subscriptionId));

      if (response.type !== DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_SUBSCRIPTION_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_SUBSCRIPTION_SUCCESS));
      history.push(`/organizations/${organizationId}`);
    })();
  }, [dispatch, history, organizationId, subscriptionId]);

  if (isLoadingSubscription || isNil(subscription))
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  return (
    <>
      <PageWrapper className={classes.pageWrapper}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <SportScaleBreadCrumbs breadCrumbs={breadCrumbs} />
          {!isNewSubscription && (
            <SportScalePrimaryButton onClick={handleToggleRemoveDialog}>remove subscription</SportScalePrimaryButton>
          )}
        </Box>
        <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitSubscription}>
          <Paper className={classes.paper}>
            <SubscriptionForm
              isLoading={isLoadingSubscription}
              subscription={subscription}
              onChange={handleInputChange}
            />
          </Paper>
          <Box alignItems="flex-end" display="flex" flex={1} justifyContent="space-between" width="100%">
            <SportScaleSecondaryButton
              disabled={isLoadingSubscription}
              onClick={() => history.push(`/organizations/${organizationId}`)}
            >
              cancel
            </SportScaleSecondaryButton>
            <SportScalePrimaryButton disabled={isLoadingSubscription} type="submit">
              {submitText}
            </SportScalePrimaryButton>
          </Box>
        </ValidatorForm>
      </PageWrapper>
      {!isNil(subscription) && (
        <SportScaleDialog
          actions={[
            {
              action: handleToggleRemoveDialog,
              disabled: isLoadingSubscription,
              text: 'cancel',
            },
            {
              action: handleDeleteSubscription,
              disabled: isLoadingSubscription,
              isPrimary: true,
              text: isLoadingSubscription ? 'removing...' : 'remove',
            },
          ]}
          content={
            <DialogContentText>
              Are you sure you want to remove this subscription from this organization?
            </DialogContentText>
          }
          open={removeDialogOpen}
          title="Remove Sport?"
          onClose={handleToggleRemoveDialog}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  actions: {
    marginLeft: theme.spacing(2),
  },
}));

Subscription.propTypes = {};

export default Subscription;
