import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "subscriptions" gets changed
const selectSubscriptionsState = createSelector(
  state => state.subscriptions,
  subscriptionsState => subscriptionsState
);

export const selectSubscriptions = createSelector(
  selectSubscriptionsState,
  subscriptionsState => subscriptionsState.subscriptions
);

export const selectSubscriptionsIsLoading = createSelector(
  selectSubscriptionsState,
  subscriptionsState => subscriptionsState.isLoadingSubscription || subscriptionsState.isLoadingSubscriptions
);

export const selectSubscriptionsIsLoadingSubscriptions = createSelector(
  selectSubscriptionsState,
  subscriptionsState => subscriptionsState.isLoadingSubscriptions
);

export const selectSubscriptionsIsLoadingSubscription = createSelector(
  selectSubscriptionsState,
  subscriptionsState => subscriptionsState.isLoadingSubscription
);

export const selectSelectedSubscription = createSelector(
  selectSubscriptionsState,
  subscriptionsState => subscriptionsState.subscription
);
