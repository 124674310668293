import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { RadioGroup, Typography, withStyles } from '@material-ui/core';

const SportScaleRadioGroup = memo(({ classes, children, error, hasValidation, label, row, variant, ...rest }) => (
  <>
    <Typography variant={variant}>{label}</Typography>
    <RadioGroup classes={{ root: classes.radioGroup }} name="answer" row={row} {...rest}>
      {children}
    </RadioGroup>
  </>
));

SportScaleRadioGroup.propTypes = {
  classes: PropTypes.any.isRequired,

  children: PropTypes.any.isRequired,
  error: PropTypes.bool,
  hasValidation: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  row: PropTypes.bool,
  value: PropTypes.any,
  variant: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

SportScaleRadioGroup.defaultProps = {
  error: false,
  hasValidation: false,
  row: true,
  value: null,
  variant: 'body2',
};

const styles = () => ({
  radioGroup: {
    alignItems: 'flex-start',
    marginTop: '.5rem',
  },
});

export default withStyles(styles)(SportScaleRadioGroup);
