import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';

const SportScaleDarkBGPrimaryButton = ({ children, className, onClick, ...restProps }) => {
  const classes = useStyles();

  return (
    <SportScalePrimaryButton className={classNames(className, classes.button)} onClick={onClick} {...restProps}>
      {children}
    </SportScalePrimaryButton>
  );
};

SportScaleDarkBGPrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,

  onClick: PropTypes.func,
};

SportScaleDarkBGPrimaryButton.defaultProps = {
  className: '',

  onClick: () => {},
};

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export default SportScaleDarkBGPrimaryButton;
