/*
  I need the following config to request the token outside of a react component properly
*/
export const auth0Config = {
  audience: process.env.REACT_APP_AUTH_0_AUDIENCE,
  scope: process.env.REACT_APP_AUTH_0_SCOPES,
};

let getAccessTokenSilently = null;
let logout = null;

const auth0Service = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: func => {
    getAccessTokenSilently = func;
  },
  // This is written this way in order to have the ability to call logut with an options object
  logout: () => logout,
  setLogout: func => {
    logout = func;
  },
};

export default auth0Service;
