import React, { memo, useCallback, useEffect, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SportScaleDateColumn from 'common/table/sportScaleDateColumn.component';
import SportScaleDateFilter from 'common/table/sportScaleDateFilter.component';
import SportScaleTable from 'common/table/sportScaleTable.component';
import SportScaleTableActionButton from 'common/table/sportScaleTableActionButton.component';
import SportScaleTableToolbar from 'common/table/sportScaleTableToolbar.component';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PERMISSIONS, can } from 'common/permissions';
import { filterByDateField } from 'utilities/table.utils';
import { selectSeasons, selectSeasonsIsLoadingSeasons } from 'modules/teams/seasons/seasons.selectors';

const COLUMNS = [
  { title: 'Season', field: 'name' },
  {
    title: 'Start Date',
    field: 'startDate',
    render: rowData => SportScaleDateColumn(rowData.startDate),
    filterComponent: SportScaleDateFilter,
    customFilterAndSearch: filterByDateField('startDate', 'isSameOrAfter'),
  },
  {
    title: 'End Date',
    field: 'endDate',
    render: rowData => SportScaleDateColumn(rowData.endDate),
    filterComponent: SportScaleDateFilter,
    customFilterAndSearch: filterByDateField('endDate', 'isSameOrBefore'),
  },
];

/* memo is the equivalent to PureComponent and stops this from re-rendering when the parents team state is changing */
const Seasons = memo(() => {
  const tableRef = useRef();

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const isLoadingSeasons = useSelector(selectSeasonsIsLoadingSeasons);
  const seasons = useSelector(selectSeasons);

  useEffect(() => {
    const localTableRef = tableRef;

    return () => {
      /*
        When unmounting the table doesn't clear it's tabledata, if the columns and stores the reference,
        so if the columns stay the same the filter values stick around. Here we're
        using a tableRef in order to clear all column filters unless
        the table isn't currently filtered
      */
      const { state, dataManager } = localTableRef.current;

      if (dataManager.filtered) {
        state.columns.forEach(column => {
          column.tableData.filterValue = undefined;
        });
      }
    };
  }, [tableRef]);

  const goToCreateSeason = useCallback(() => history.push(`${location.pathname}/seasons/${NEW_ITEM_ROUTE}`), [
    history,
    location,
  ]);

  return (
    <Box mb={2} width="100%">
      <SportScaleTable
        ref={tableRef}
        actions={[
          {
            icon: '',
            text: can(PERMISSIONS.EDIT_TEAMS) ? 'Details' : 'View',
            onClick: rowData => history.push(`${location.pathname}/seasons/${rowData.id}`, { season: rowData }),
          },
        ]}
        columns={COLUMNS}
        components={{
          Action: SportScaleTableActionButton(classes.actionButton, false),
          Toolbar: SportScaleTableToolbar(
            can(PERMISSIONS.EDIT_TEAMS) ? [{ text: 'add season', onClick: goToCreateSeason }] : []
          ),
        }}
        data={seasons || []}
        isLoading={isLoadingSeasons}
        options={{
          paging: true,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20],
          toolbar: true,
        }}
      />
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
}));

export default Seasons;
