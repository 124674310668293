import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, DialogContentText, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import AthleteDialog from 'modules/teams/roster/athleteDialog.component';
import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import Roster from 'modules/teams/roster/roster.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SeasonForm from 'modules/teams/seasons/seasonForm.component';
import SportScaleBreadCrumbs from 'modules/layout/breadCrumbs/breadCrumbs.component';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import useApiRequest from 'hooks/apiRequest';
import {
  COPY_SEASON_ROSTER_SUCCESS,
  CREATE_TEAM_SEASON_SUCCESS,
  DELETE_TEAM_SEASON_SUCCESS,
  GET_SEASON_ROSTER_SUCCESS,
  GET_TEAM_SEASONS_SUCCESS,
  REMOVE_ATHLETE_FROM_ROSTER_SUCCESS,
  UPDATE_TEAM_SEASON_SUCCESS,
  copySeasonRoster,
  createTeamSeason,
  deleteTeamSeason,
  getSeasonRoster,
  getTeamSeason,
  getTeamSeasons,
  removeAthleteFromRoster,
  resetSeasonRoster,
  resetSelectedSeason,
  updateTeamSeason,
} from 'modules/teams/seasons/seasons.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { ROUTES, SEASON_ID_MAP, generateBreadCrumbMap, generateRouteParamsMap } from 'utilities/breadCrumbs';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { getOrganizationTeam } from 'modules/teams/teams.actions';
import { getSubmitText } from 'utilities/form';
import { selectAthletesIsRemovingAthlete } from 'modules/athletes/athletes.selectors';
import {
  selectSeasons,
  selectSeasonsIsLoadingRoster,
  selectSeasonsIsLoadingSeason,
  selectSeasonsIsLoadingSeasons,
  selectSelectedSeason,
} from 'modules/teams/seasons/seasons.selectors';
import { selectSelectedOrganizationName } from 'modules/organizations/organizations.selectors';
import { selectSelectedTeam } from 'modules/teams/teams.selectors';

const INITIAL_SEASON = {
  daysTracked: [],
  enableHydrationTracking: false,
  endData: null,
  name: '',
  startDate: null,
  showWeight: false,
};

const SeasonContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { organizationId, seasonId, teamId } = useParams();
  const makeRequest = useApiRequest();

  const isLoadingSeasons = useSelector(selectSeasonsIsLoadingSeasons);
  const isLoadingSeason = useSelector(selectSeasonsIsLoadingSeason);
  const isLoadingRoster = useSelector(selectSeasonsIsLoadingRoster);
  const seasons = useSelector(selectSeasons);
  const selectedSeason = useSelector(selectSelectedSeason);

  const selectedTeam = useSelector(selectSelectedTeam);

  const isRemovingAthlete = useSelector(selectAthletesIsRemovingAthlete);

  const organizationName = useSelector(selectSelectedOrganizationName);

  const [season, setSeason] = useState(INITIAL_SEASON);
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [isAddAthlete, setIsAddAthlete] = useState(false);
  const [selectedRoster, setSelectedRoster] = useState(null);

  const [athleteDialogOpen, setAthleteDialogOpen] = useState(false);
  const [removeSeasonDialogOpen, setRemoveSeasonDialogOpen] = useState(false);
  const [removeAthleteDialogOpen, setRemoveAthleteDialogOpen] = useState(false);
  const [copyRosterDialogOpen, setCopyRosterDialogOpen] = useState(false);

  const isNewSeason = seasonId === NEW_ITEM_ROUTE;

  useEffect(() => {
    if (isNil(organizationName)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.SPORTS_TEAMS}`));
  }, [dispatch, organizationName]);

  useEffect(() => {
    if (isNil(selectedTeam)) dispatch(getOrganizationTeam(organizationId, teamId));
    if (isNil(seasons))
      makeRequest(
        () => getTeamSeasons(organizationId, teamId),
        { type: GET_TEAM_SEASONS_SUCCESS },
        ERROR_MESSAGES.GET_TEAM_SEASONS_FAILURE
      );
  }, [dispatch, makeRequest, organizationId, seasons, selectedTeam, teamId]);

  /* SPOR-280: Reset the selected season and season roster when leaving the page. */
  useEffect(() => {
    return () => {
      dispatch(resetSelectedSeason());
      dispatch(resetSeasonRoster());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isNil(organizationName) || isNewSeason) return;

    makeRequest(
      () => getSeasonRoster(organizationId, teamId, seasonId),
      { type: GET_SEASON_ROSTER_SUCCESS },
      ERROR_MESSAGES.GET_SEASON_ROSTER_FAILURE
    );

    if (!isEmpty(location.state)) {
      setSeason(location.state.season);
      return;
    }

    (async () => {
      const { response } = await dispatch(getTeamSeason(organizationId, teamId, seasonId));

      setSeason(response);
    })();
  }, [dispatch, location, makeRequest, isNewSeason, organizationId, organizationName, seasonId, teamId]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setSeason({ ...season, [name]: value }), [
    season,
    setSeason,
  ]);

  const handleSubmitSeason = useCallback(() => {
    (async () => {
      let type;
      let newSeasonId;

      const updatedSeason = {
        ...season,
        daysTracked: season.enableHydrationTracking ? season.daysTracked : [],
        endDate: moment(season.endDate),
        startDate: moment(season.startDate),
      };

      if (isNewSeason) {
        const response = await dispatch(createTeamSeason(organizationId, teamId, updatedSeason));
        type = response.type;
        newSeasonId = response.response.id;
      } else {
        delete updatedSeason.tableData;
        const response = await dispatch(updateTeamSeason(organizationId, teamId, updatedSeason));
        type = response.type;
      }

      if (type !== CREATE_TEAM_SEASON_SUCCESS && type !== UPDATE_TEAM_SEASON_SUCCESS) {
        const errorMessage = isNewSeason ? ERROR_MESSAGES.CREATE_SEASON_FAILURE : ERROR_MESSAGES.UPDATE_SEASON_FAILURE;

        dispatch(handleToastMessage(errorMessage, TOAST_TYPES.ERROR));
        return;
      }

      const successMessage = isNewSeason
        ? SUCCESS_MESSAGES.CREATE_SEASON_SUCCESS
        : SUCCESS_MESSAGES.UPDATE_SEASON_SUCCESS;

      dispatch(handleToastMessage(successMessage));

      if (isNewSeason) {
        history.push(`/organizations/${organizationId}/teams/${teamId}/seasons/${newSeasonId}`);
        return;
      }

      setSeason(updatedSeason);
    })();
  }, [dispatch, history, isNewSeason, organizationId, season, teamId]);

  useEffect(() => {
    if (!isEmpty(selectedSeason)) setSeason(selectedSeason);
  }, [selectedSeason]);

  const handleToggleSeasonDialog = useCallback(() => {
    setRemoveSeasonDialogOpen(!removeSeasonDialogOpen);
  }, [removeSeasonDialogOpen, setRemoveSeasonDialogOpen]);

  const handleDeleteSeason = useCallback(() => {
    (async () => {
      const response = await dispatch(deleteTeamSeason(organizationId, teamId, seasonId));

      setRemoveSeasonDialogOpen(false);

      if (response.type !== DELETE_TEAM_SEASON_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_SEASON_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_SEASON_SUCCESS));
      history.replace(`/organizations/${organizationId}/teams/${teamId}`);
    })();
  }, [dispatch, history, organizationId, seasonId, teamId]);

  const submitText = useMemo(() => getSubmitText(isLoadingSeason, isNewSeason), [isLoadingSeason, isNewSeason]);

  const handleToggleAthleteDialog = useCallback(
    // eslint-disable-next-line no-shadow
    (editAthlete, isAddAthlete) => {
      if (!athleteDialogOpen) setSelectedAthlete(editAthlete);
      if (!isNil(isAddAthlete)) setIsAddAthlete(isAddAthlete);

      setAthleteDialogOpen(!athleteDialogOpen);
    },
    [athleteDialogOpen, setAthleteDialogOpen, setIsAddAthlete, setSelectedAthlete]
  );

  const handleToggleRemoveAthleteDialog = useCallback(
    deleteAthlete => {
      setRemoveAthleteDialogOpen(!removeAthleteDialogOpen);
      setSelectedAthlete(deleteAthlete);
    },
    [removeAthleteDialogOpen, setRemoveAthleteDialogOpen, setSelectedAthlete]
  );

  const handleRemoveAthlete = useCallback(() => {
    (async () => {
      const response = await dispatch(removeAthleteFromRoster(organizationId, teamId, seasonId, selectedAthlete.id));

      setRemoveAthleteDialogOpen(false);

      if (response.type !== REMOVE_ATHLETE_FROM_ROSTER_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.REMOVE_ATHLETE_FROM_ROSTER_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.REMOVE_ATHLETE_FROM_ROSTER_SUCCESS));
      dispatch(getSeasonRoster(organizationId, teamId, seasonId));
    })();
  }, [dispatch, organizationId, seasonId, selectedAthlete, teamId]);

  const breadCrumbs = useMemo(() => {
    if (!isEmpty(season) && !isNil(selectedTeam)) {
      let seasonName = isNewSeason && 'create';

      if (!isNewSeason && !isEmpty(location.state)) seasonName = location.state.season.name;
      if (!isNewSeason && !isEmpty(selectedSeason)) seasonName = selectedSeason.name;

      return generateBreadCrumbMap(generateRouteParamsMap(SEASON_ID_MAP, [organizationId, teamId, seasonId]), {
        [ROUTES.TEAMS_ROUTE]: PAGE_TITLES.SPORTS_TEAMS,
        [ROUTES.TEAM_ROUTE]: selectedTeam.name,
        [isNewSeason ? ROUTES.NEW_SEASON_ROUTE : ROUTES.SEASON_ROUTE]: seasonName,
      });
    }

    return {};
  }, [location, isNewSeason, organizationId, season, seasonId, teamId, selectedSeason, selectedTeam]);

  const handleToggleCopyRosterDialog = useCallback(
    // eslint-disable-next-line no-shadow
    (season = null) => {
      setSelectedRoster(season);
      setCopyRosterDialogOpen(!copyRosterDialogOpen);
    },
    [copyRosterDialogOpen, setCopyRosterDialogOpen, setSelectedRoster]
  );

  const handleCopyRoster = useCallback(() => {
    (async () => {
      const response = await dispatch(copySeasonRoster(organizationId, teamId, seasonId, selectedRoster.id));

      setCopyRosterDialogOpen(false);

      if (response.type !== COPY_SEASON_ROSTER_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.COPY_SEASON_ROSTER_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.COPY_SEASON_ROSTER_SUCCESS));
      dispatch(getSeasonRoster(organizationId, teamId, seasonId));
    })();
  }, [dispatch, organizationId, seasonId, selectedRoster, teamId]);

  const dialogProps = useMemo(() => {
    if (removeSeasonDialogOpen)
      return {
        actions: [
          {
            action: handleToggleSeasonDialog,
            disabled: isLoadingSeason,
            text: 'cancel',
          },
          {
            action: handleDeleteSeason,
            disabled: isLoadingSeason,
            isPrimary: true,
            text: isLoadingSeason ? 'removing...' : 'remove',
          },
        ],
        content: `Are you sure you want to remove ${season.name} from this sport?`,
        title: 'Remove Season?',
        open: removeSeasonDialogOpen,
        onClose: handleToggleSeasonDialog,
      };

    if (removeAthleteDialogOpen)
      return {
        actions: [
          {
            action: handleToggleRemoveAthleteDialog,
            disabled: isRemovingAthlete,
            text: 'cancel',
          },
          {
            action: handleRemoveAthlete,
            disabled: isRemovingAthlete,
            isPrimary: true,
            text: isRemovingAthlete ? 'removing...' : 'remove',
          },
        ],
        content: `Are you sure you want to remove ${selectedAthlete.firstName} ${selectedAthlete.lastName} from this roster?`,
        title: 'Remove Athlete?',
        open: removeAthleteDialogOpen,
        onClose: handleToggleRemoveAthleteDialog,
      };

    if (copyRosterDialogOpen)
      return {
        actions: [
          {
            action: handleToggleCopyRosterDialog,
            disabled: isLoadingRoster,
            text: 'cancel',
          },
          {
            action: handleCopyRoster,
            disabled: isLoadingRoster,
            isPrimary: true,
            text: isLoadingRoster ? 'copying...' : 'copy',
          },
        ],
        content: `Are you sure you want to copy ${selectedRoster.name}'s roster to this roster?`,
        title: 'Copy Roster?',
        open: copyRosterDialogOpen,
        onClose: handleToggleCopyRosterDialog,
      };

    return {
      open: false,
      onClose: () => {},
    };
  }, [
    copyRosterDialogOpen,
    isLoadingRoster,
    isLoadingSeason,
    isRemovingAthlete,
    removeAthleteDialogOpen,
    removeSeasonDialogOpen,
    season,
    selectedAthlete,
    selectedRoster,
    handleCopyRoster,
    handleDeleteSeason,
    handleRemoveAthlete,
    handleToggleCopyRosterDialog,
    handleToggleRemoveAthleteDialog,
    handleToggleSeasonDialog,
  ]);

  if (isLoadingSeasons)
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  const DISABLE_CHANGE = isLoadingSeason || !can(PERMISSIONS.EDIT_TEAMS);

  return (
    <>
      <PageWrapper className={classes.pageWrapper}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <SportScaleBreadCrumbs breadCrumbs={breadCrumbs} />
          {!isNewSeason && can(PERMISSIONS.EDIT_TEAMS) && (
            <SportScalePrimaryButton disabled={isLoadingSeason} onClick={handleToggleSeasonDialog}>
              remove season
            </SportScalePrimaryButton>
          )}
        </Box>
        <ValidatorForm className={classes.form} onSubmit={handleSubmitSeason}>
          <SeasonForm disabled={DISABLE_CHANGE} season={season} onChange={handleInputChange} />
          {!isNewSeason && (
            <Box width="100%">
              <Roster
                isLoadingSeason={isLoadingSeason}
                onAddEditAthlete={handleToggleAthleteDialog}
                onCopyRoster={handleToggleCopyRosterDialog}
                onRemoveAthlete={handleToggleRemoveAthleteDialog}
              />
            </Box>
          )}
          <Box alignItems="flex-end" display="flex" flex={1} justifyContent="space-between" width="100%">
            <SportScaleSecondaryButton
              disabled={isLoadingSeason}
              onClick={() => history.push(`/organizations/${organizationId}/teams/${teamId}`)}
            >
              cancel
            </SportScaleSecondaryButton>
            <SportScalePrimaryButton disabled={DISABLE_CHANGE} type="submit">
              {submitText}
            </SportScalePrimaryButton>
          </Box>
        </ValidatorForm>
      </PageWrapper>
      <SportScaleDialog
        actions={dialogProps.actions}
        content={<DialogContentText>{dialogProps.content}</DialogContentText>}
        open={dialogProps.open}
        title={dialogProps.title}
        onClose={dialogProps.onClose}
      />
      <AthleteDialog
        isAddAthlete={isAddAthlete}
        isOpen={athleteDialogOpen}
        selectedAthlete={selectedAthlete}
        onClose={handleToggleAthleteDialog}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  title: {
    alignSelf: 'flex-start',
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
}));

SeasonContainer.propTypes = {};

export default SeasonContainer;
