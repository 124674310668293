import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthLoading from 'modules/auth/authLoading.container';
import { DEFAULT_ORG_ROUTE, isNoRedirectRoute } from 'utilities/routes';
import { LOGIN_STATE } from 'utilities/login';

import {
  setInitialLoad as setInitialLoadAction,
  toggleOrgSelectionDialog as toggleOrgSelectionDialogAction,
} from 'modules/layout/layout.actions';
import { setSelectedOrganizationId as setSelectedOrganizationIdAction } from 'modules/organizations/organizations.actions';
import { setUser as setUserAction } from 'modules/login/login.actions';

const AuthWrapper = ({
  children,
  hasInitialLoad,
  hasInitialLoadFailure,
  loginState,
  organizations,
  selectedOrganizationId,
  setInitialLoad,
  setSelectedOrganizationId,
  toggleOrgSelectionDialog,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { error: auth0Error, isAuthenticated, isLoading: auth0Loading, logout: auth0Logout } = useAuth0();

  const urlSegments = useMemo(() => location.pathname.split('/'), [location]);

  // On Auth0 error, logout to be safe
  useEffect(() => {
    if (auth0Error) auth0Logout({ returnTo: process.env.REACT_APP_AUTH_0_LOGOUT_REDIRECT_URL });
  }, [auth0Error, auth0Logout]);

  // Log user out of Auth0 when logged out of redux
  useEffect(() => {
    if (auth0Loading) return;

    if (isAuthenticated && loginState === LOGIN_STATE.NOT_LOGGED_IN) {
      auth0Logout({ returnTo: process.env.REACT_APP_AUTH_0_LOGOUT_REDIRECT_URL });
    }
  }, [auth0Loading, isAuthenticated, loginState, auth0Logout]);

  useEffect(() => {
    // Params don't exist here to look at as we aren't within a route
    const index = urlSegments.indexOf('organizations');
    const urlOrganizationId = index === -1 ? null : urlSegments[index + 1];

    /*
      Check url, local storage, and orgs list when:
      - Page load or reset of inital load on "not found" (initial load false)
      - Orgs list is not empty
    */
    if (hasInitialLoad || isEmpty(organizations)) return;

    setInitialLoad(true);

    if (isNoRedirectRoute(location.pathname) && !isEmpty(selectedOrganizationId)) return;

    // The user is routing directly to an org page
    if (!isNil(urlOrganizationId)) {
      setSelectedOrganizationId(urlOrganizationId);
      return;
    }

    // The user has previously selected an org and we want to take them straight there
    if (!isEmpty(selectedOrganizationId)) {
      history.push(`/organizations/${selectedOrganizationId}/${DEFAULT_ORG_ROUTE}`);
      return;
    }

    // The user is logging in to a non org page and has one or multiple organizational access
    if (organizations.length === 1) {
      setSelectedOrganizationId(organizations[0].id);
      history.push(`/organizations/${organizations[0].id}/${DEFAULT_ORG_ROUTE}`);
    } else {
      toggleOrgSelectionDialog();
    }
  }, [
    history,
    hasInitialLoad,
    location,
    organizations,
    selectedOrganizationId,
    urlSegments,
    setInitialLoad,
    setSelectedOrganizationId,
    toggleOrgSelectionDialog,
  ]);

  /* 
    APP IS LOADING WHEN
    - Initial load of data is successful
    - Auth0 is loading (auth0 does not load when logged out or has logged out)
    - Auth0 has loaded and organizations are loading
    - User is NOT_LOGGED_IN (this only occurs when user logs themselves out, this is then reset by Auth0 logout redirect)
  */
  if (
    !hasInitialLoadFailure &&
    (auth0Loading || (!hasInitialLoad && isAuthenticated) || loginState === LOGIN_STATE.NOT_LOGGED_IN)
  ) {
    return <AuthLoading />;
  }

  /*
    If this is the case, we don't want anything on the screen until the user has selected an org and there isn't an 
    initial load failure. A dialog box will be shown for the user to select an org
  */
  if (isAuthenticated && isNil(selectedOrganizationId) && !hasInitialLoadFailure) return null;

  return children;
};

AuthWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  hasInitialLoad: PropTypes.bool.isRequired,
  loginState: PropTypes.number.isRequired,
  organizations: PropTypes.array.isRequired,
  selectedOrganizationId: PropTypes.any,
};

AuthWrapper.defaultProps = {
  selectedOrganizationId: null,
};

const mapStateToProps = state => ({
  hasInitialLoad: state.layout.hasInitialLoad,
  hasInitialLoadFailure: state.layout.hasInitialLoadFailure,
  loginState: state.login.loginState,
  organizations: state.organizations.organizations,
  selectedOrganizationId: state.organizations.selectedOrganizationId,
});

export default connect(mapStateToProps, {
  setInitialLoad: setInitialLoadAction,
  setSelectedOrganizationId: setSelectedOrganizationIdAction,
  setUser: setUserAction,
  toggleOrgSelectionDialog: toggleOrgSelectionDialogAction,
})(AuthWrapper);
