import React from 'react';
import { Box, Card, CardContent, Typography, makeStyles } from '@material-ui/core';

import LoginForm from 'modules/login/loginForm.component';
import Logo from 'assets/images/brandLogo.png';
import PageWrapper from 'modules/layout/pageWrapper.component';
import { Container, Item } from 'common/components/grid.component';

// const MAX_WIDTH = '30rem';

const LoginContainer = () => {
  const classes = useStyles();

  return (
    <PageWrapper className={classes.pageWrapper}>
      <Container justify="center">
        <Item item xs={12} sm={6} style={{ textAlign: 'center' }}>
          <Card className={classes.card}>
            <CardContent className={classes.formContent}>
              <Box mb="2rem">
                <img src={Logo} alt="Sportscale" className={classes.logo} />
              </Box>
              <Box mb="2rem">
                <Typography variant="h6">Administration Portal</Typography>
              </Box>
              <LoginForm />
            </CardContent>
          </Card>
        </Item>
      </Container>
    </PageWrapper>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    alignSelf: 'center',
    justifyContent: 'center',
    margin: 'auto',
    // width: '50vw',
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // maxHeight: '50%',
    padding: theme.spacing() * 2,
    // width: '50vw',
    // maxWidth: MAX_WIDTH,
  },
  formContent: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  logo: {
    // maxWidth: '90%',
    // minWidth: '80%',
    width: '70%',
  },
  button: {
    marginBottom: '1.5rem',
  },
}));

LoginContainer.propTypes = {};

export default LoginContainer;
