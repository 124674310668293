import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ArrowDropDown } from '@material-ui/icons';
import { Box, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';

import SportScaleDarkBGPrimary from 'common/buttons/sportScaleDarkBGPrimaryButton.component';
import SportScaleMenuButton from 'common/buttons/sportScaleMenuButton.component';

/*
  The material-table library requires a component/function to be provided when overriding components,
  withStyles results in an object, so when material-table tries to run it things fail. We're creating
  a styled component that we can then return within the function that material-table requires
*/
const StyledSportScaleTableToolbar = ({ toolbarActions, toolbarText }) => {
  const classes = useStyles();

  const renderActions = () =>
    toolbarActions.map(action => {
      if (action.isMenu)
        return (
          <Box key={action.text}>
            <SportScaleMenuButton
              buttonComponent={
                <SportScaleDarkBGPrimary
                  className={classNames(classes.button, classes.toolbarButton)}
                  disabled={action.disabled}
                  endIcon={<ArrowDropDown />}
                >
                  {action.text}
                </SportScaleDarkBGPrimary>
              }
              menuItems={action.menuItems}
            />
          </Box>
        );

      return (
        <SportScaleDarkBGPrimary
          className={classNames(classes.button, classes.toolbarButton)}
          disabled={action.disabled}
          key={action.text}
          onClick={action.onClick}
        >
          {action.text}
        </SportScaleDarkBGPrimary>
      );
    });

  return (
    <Toolbar classes={{ root: classes.toolbarRoot }}>
      <Box flex={1}>
        {toolbarText && (
          <Typography className={classes.toolbarText} variant="h6">
            {toolbarText}
          </Typography>
        )}
      </Box>
      {!isEmpty(toolbarActions) && <Box display="flex">{renderActions()}</Box>}
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    margin: '0 1rem',
    minWidth: '9rem',
  },
  toolbarText: {
    color: theme.palette.primary.main,
  },
  toolbarButton: {
    // This is required to align the left edge with the select column
    margin: 0,
    marginLeft: theme.spacing(2),
  },
  toolbarRoot: {
    display: 'flex',
    padding: '0 1rem',
  },
}));

StyledSportScaleTableToolbar.propTypes = {
  toolbarActions: PropTypes.arrayOf(
    PropTypes.exact({
      disabled: PropTypes.bool,
      isMenu: PropTypes.bool,
      menuItems: PropTypes.arrayOf(
        PropTypes.exact({
          disabled: PropTypes.bool,
          text: PropTypes.string,
          onClick: PropTypes.func,
        })
      ),
      text: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  toolbarText: PropTypes.string,
};

StyledSportScaleTableToolbar.defaultProps = {
  toolbarActions: [],
  toolbarText: '',
};

const SportScaleTableToolbar = (toolbarActions, toolbarText) => props => (
  <StyledSportScaleTableToolbar toolbarActions={toolbarActions} toolbarText={toolbarText} {...props} />
);

export default SportScaleTableToolbar;
