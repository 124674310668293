import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATION_LOCATIONS_REQUEST = 'GET_ORGANIZATION_LOCATIONS_REQUEST';
export const GET_ORGANIZATION_LOCATIONS_SUCCESS = 'GET_ORGANIZATION_LOCATIONS_SUCCESS';
export const GET_ORGANIZATION_LOCATIONS_FAILURE = 'GET_ORGANIZATION_LOCATIONS_FAILURE';

export const UPDATE_ORGANIZATION_LOCATION_REQUEST = 'UPDATE_ORGANIZATION_LOCATION_REQUEST';
export const UPDATE_ORGANIZATION_LOCATION_SUCCESS = 'UPDATE_ORGANIZATION_LOCATION_SUCCESS';
export const UPDATE_ORGANIZATION_LOCATION_FAILURE = 'UPDATE_ORGANIZATION_LOCATION_FAILURE';

export const GET_ORGANIZATION_LOCATION_REQUEST = 'GET_ORGANIZATION_LOCATION_REQUEST';
export const GET_ORGANIZATION_LOCATION_SUCCESS = 'GET_ORGANIZATION_LOCATION_SUCCESS';
export const GET_ORGANIZATION_LOCATION_FAILURE = 'GET_ORGANIZATION_LOCATION_FAILURE';

export const CREATE_ORGANIZATION_LOCATION_REQUEST = 'CREATE_ORGANIZATION_LOCATION_REQUEST';
export const CREATE_ORGANIZATION_LOCATION_SUCCESS = 'CREATE_ORGANIZATION_LOCATION_SUCCESS';
export const CREATE_ORGANIZATION_LOCATION_FAILURE = 'CREATE_ORGANIZATION_LOCATION_FAILURE';

export const DELETE_ORGANIZATION_LOCATION_REQUEST = 'DELETE_ORGANIZATION_LOCATION_REQUEST';
export const DELETE_ORGANIZATION_LOCATION_SUCCESS = 'DELETE_ORGANIZATION_LOCATION_SUCCESS';
export const DELETE_ORGANIZATION_LOCATION_FAILURE = 'DELETE_ORGANIZATION_LOCATION_FAILURE';

export const RESET_ORGANIZATION_LOCATIONS = 'RESET_ORGANIZATION_LOCATIONS';

export const RESET_ORGANIZATION_SELECTED_LOCATION = 'RESET_ORGANIZATION_SELECTED_LOCATION';

export const getOrganizationLocations = organizationId => {
  return {
    [CALL_API]: {
      types: [
        GET_ORGANIZATION_LOCATIONS_REQUEST,
        GET_ORGANIZATION_LOCATIONS_SUCCESS,
        GET_ORGANIZATION_LOCATIONS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/locations`,
      method: 'GET',
    },
  };
};

export const updateOrganizationLocation = (organizationId, user) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_ORGANIZATION_LOCATION_REQUEST,
        UPDATE_ORGANIZATION_LOCATION_SUCCESS,
        UPDATE_ORGANIZATION_LOCATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/locations/${user.id}`,
      method: 'PUT',
      payload: user,
    },
  };
};

export const getOrganizationLocation = (organizationId, userId) => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_LOCATION_REQUEST, GET_ORGANIZATION_LOCATION_SUCCESS, GET_ORGANIZATION_LOCATION_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/locations/${userId}`,
      method: 'GET',
    },
  };
};

export const createOrganizationLocation = (organizationId, user) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_ORGANIZATION_LOCATION_REQUEST,
        CREATE_ORGANIZATION_LOCATION_SUCCESS,
        CREATE_ORGANIZATION_LOCATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/locations`,
      method: 'POST',
      payload: user,
    },
  };
};

export const deleteOrganizationLocation = (organizationId, userId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_ORGANIZATION_LOCATION_REQUEST,
        DELETE_ORGANIZATION_LOCATION_SUCCESS,
        DELETE_ORGANIZATION_LOCATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/locations/${userId}`,
      method: 'DELETE',
    },
  };
};

export const resetOrganizationLocations = () => {
  return {
    type: RESET_ORGANIZATION_LOCATIONS,
  };
};

export const resetOrganizationSelectedLocation = () => {
  return {
    type: RESET_ORGANIZATION_SELECTED_LOCATION,
  };
};
