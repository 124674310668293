export default Object.freeze({
  WEIGH_IN: {
    value: 'WEIGH_IN',
    label: 'Weigh In',
  },
  WEIGH_OUT: {
    value: 'WEIGH_OUT',
    label: 'Weigh Out',
  },
  SIMPLE: {
    value: 'SIMPLE',
    label: 'Weight',
  },
});
