import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "seasons" gets changed
const selectSeasonsState = createSelector(
  state => state.seasons,
  seasonsState => seasonsState
);

export const selectSeasons = createSelector(selectSeasonsState, seasonsState => seasonsState.seasons);

export const selectSeasonsIsLoading = createSelector(
  selectSeasonsState,
  seasonsState => seasonsState.isLoading || seasonsState.isLoadingSeason
);

export const selectSeasonsIsLoadingSeasons = createSelector(selectSeasonsState, seasonsState => seasonsState.isLoading);

export const selectSeasonsIsLoadingSeason = createSelector(
  selectSeasonsState,
  seasonsState => seasonsState.isLoadingSeason
);

export const selectSelectedSeason = createSelector(selectSeasonsState, seasonsState => seasonsState.selectedSeason);

export const selectSeasonsIsLoadingRoster = createSelector(
  selectSeasonsState,
  seasonsState => seasonsState.isLoadingRoster
);

export const selectSelectedSeasonRoster = createSelector(selectSeasonsState, seasonsState => seasonsState.roster);
