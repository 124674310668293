import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "profile" gets changed
const selectProfileState = createSelector(
  state => state.profile,
  profileState => profileState
);

export const selectProfile = createSelector(selectProfileState, profileState => profileState.profile);

export const selectProfileIsLoading = createSelector(selectProfileState, profileState => profileState.isLoading);

export const selectProfileIsSuperAdmin = createSelector(selectProfileState, profileState => profileState.isSuperAdmin);
