import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Paper, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import OrganizationForm from 'modules/organizations/organizationForm.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleFormDialog from 'common/components/sportScaleFormDialog.component';
import SubscriptionForm from 'modules/organizations/subscriptions/subscriptionForm.component';
import {
  CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  createOrganizationSubscription,
} from 'modules/organizations/subscriptions/subscriptions.actions';
import {
  CREATE_ORGANIZATION_SUCCESS,
  createOrganization,
  getOrganizations,
  setSelectedOrganizationId,
} from 'modules/organizations/organizations.actions';
import { SUBSCRIPTION_NONE_VALUE } from 'utilities/subscriptions';
import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import { getSubmitText } from 'utilities/form';
import { selectOrganizationsIsLoadingOrganization } from 'modules/organizations/organizations.selectors';
import { selectSubscriptionsIsLoadingSubscription } from 'modules/organizations/subscriptions/subscriptions.selectors';

const INITIAL_ORGANIZATION = {
  name: '',
};

const INITIAL_SUBSCRIPTION = {
  endDate: null,
  startDate: null,
};

const CreateOrganizationDialog = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoadingOrganization = useSelector(selectOrganizationsIsLoadingOrganization);
  const isLoadingSubscription = useSelector(selectSubscriptionsIsLoadingSubscription);

  const [organization, setOrganization] = useState(INITIAL_ORGANIZATION);
  const [subscription, setSubscription] = useState(INITIAL_SUBSCRIPTION);

  const handleCreateOrganization = useCallback(() => {
    (async () => {
      /* type, athleteLimit, and teamLimit aren't correctly used, but are required on the API */
      const subscriptionPayload = {
        endDate: moment(subscription.endDate),
        startDate: moment(subscription.startDate),
        type: SUBSCRIPTION_NONE_VALUE,
        athleteLimit: 1,
        teamLimit: 1,
      };

      const organizationResponse = await dispatch(createOrganization(organization));

      if (organizationResponse.type !== CREATE_ORGANIZATION_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.CREATE_ORGANIZATION_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      const subscriptionResponse = await dispatch(
        createOrganizationSubscription(organizationResponse.response.id, subscriptionPayload)
      );

      if (subscriptionResponse.type !== CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.CREATE_SUBSCRIPTION_FAILURE));
      } else {
        dispatch(handleToastMessage(SUCCESS_MESSAGES.CREATE_ORGANIZATION_SUCCESS));
      }

      onClose();

      dispatch(getOrganizations());
      dispatch(setSelectedOrganizationId(organizationResponse.response.id));

      history.push(`/organizations/${organizationResponse.response.id}`);
    })();
  }, [dispatch, history, organization, subscription, onClose]);

  const handleOrganizationInputChange = useCallback(
    ({ target: { name, value } }) => setOrganization({ ...organization, [name]: value }),
    [organization, setOrganization]
  );

  const handleSubscriptionInputChange = useCallback(
    ({ target: { name, value } }) => setSubscription({ ...subscription, [name]: value }),
    [subscription, setSubscription]
  );

  const submitText = useMemo(() => getSubmitText(isLoadingOrganization || isLoadingSubscription, true), [
    isLoadingOrganization,
    isLoadingSubscription,
  ]);

  return (
    <SportScaleFormDialog
      submitButtonText={submitText}
      content={
        <Paper className={classes.paper}>
          <OrganizationForm
            isLoading={isLoadingOrganization || isLoadingSubscription}
            organization={organization}
            onChange={handleOrganizationInputChange}
          />
          <SubscriptionForm
            containerClass={classes.gridSpacing}
            isLoading={isLoadingOrganization || isLoadingSubscription}
            subscription={subscription}
            onChange={handleSubscriptionInputChange}
          />
        </Paper>
      }
      fullWidth
      isLoading={isLoadingOrganization || isLoadingSubscription}
      maxWidth="md"
      open={isOpen}
      title="Create Organization"
      onClose={onClose}
      onSubmit={handleCreateOrganization}
    />
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  gridSpacing: {
    marginTop: theme.spacing(1),
  },
}));

CreateOrganizationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
};

export default CreateOrganizationDialog;
