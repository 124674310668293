import PropTypes from 'prop-types';
import React, { memo, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormControl, Icon, InputLabel, OutlinedInput, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SportScaleTextField = memo(
  ({
    disabled,
    endAdornment,
    hasInfoTooltip,
    hasValidation,
    inputProps,
    label,
    name,
    placeholder,
    readOnly,
    tooltipText,
    startAdornment,
    type,
    validatorRef,
    value,
    ...restProps
  }) => {
    const classes = useStyles();

    const [inputLabelRef, setInputLabelRef] = useState(undefined);

    const renderedLabel = useMemo(
      () =>
        hasInfoTooltip ? (
          <InputLabel
            ref={ref => {
              setInputLabelRef(ref);
            }}
            classes={{ root: classes.inputLabelRoot }}
          >
            {label}
            <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipText}>
              <Icon className={classNames(classes.infoIcon, 'fal fa-info-circle')} />
            </Tooltip>
          </InputLabel>
        ) : (
          <InputLabel
            ref={ref => {
              setInputLabelRef(ref);
            }}
          >
            {label}
          </InputLabel>
        ),
      [classes, hasInfoTooltip, label, tooltipText, setInputLabelRef]
    );

    return (
      <FormControl
        ref={validatorRef}
        classes={{
          // if this component is wrapped in a validation component we dont want
          // a bottom margin added because the validation will add it so the
          // margin includes any error messages
          root: hasValidation ? classes.formControlNoMargin : classes.formControl,
        }}
        disabled={disabled}
        variant="outlined"
        {...restProps}
      >
        {label && renderedLabel}
        <OutlinedInput
          className={classes.input}
          classes={{
            adornedEnd: endAdornment ? classes.endAdorned : '',
            adornedStart: startAdornment ? classes.startAdornment : '',
          }}
          endAdornment={endAdornment}
          inputProps={inputProps}
          labelWidth={inputLabelRef ? inputLabelRef.offsetWidth : 0}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          startAdornment={startAdornment}
          type={type}
          value={value}
        />
      </FormControl>
    );
  }
);

const useStyles = makeStyles(theme => ({
  input: {
    paddingRight: 0,
  },
  formControl: {
    borderRadius: '5%',
    flex: 1,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: '10rem',
  },
  formControlNoMargin: {
    marginBottom: 0,
  },
  endAdorned: {
    paddingRight: theme.spacing(1.5),
  },
  startAdornment: {
    paddingLeft: theme.spacing(1.5),
  },
  tooltip: {
    fontSize: '.75rem',
  },
  infoIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inputLabelRoot: {
    alignItems: 'center',
    display: 'flex',
    pointerEvents: 'auto',
  },
}));

SportScaleTextField.propTypes = {
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  hasInfoTooltip: PropTypes.bool,
  hasValidation: PropTypes.bool,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  startAdornment: PropTypes.node,
  tooltipText: PropTypes.string,
  type: PropTypes.string,
  validatorRef: PropTypes.object,
  value: PropTypes.any,

  onChange: PropTypes.func,
};

SportScaleTextField.defaultProps = {
  disabled: false,
  endAdornment: null,
  hasInfoTooltip: false,
  hasValidation: false,
  readOnly: false,
  inputProps: {},
  label: '',
  name: '',
  placeholder: '',
  startAdornment: null,
  tooltipText: '',
  type: 'input',
  validatorRef: React.createRef(),
  value: '',
  onChange: () => {},
};

export default SportScaleTextField;
