import React, { useCallback, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import FIELD_LABELS from 'types/fieldLabels';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SportScaleDateColumn from 'common/table/sportScaleDateColumn.component';
import SportScaleDateFilter from 'common/table/sportScaleDateFilter.component';
import SportScaleOverflowTooltip from 'common/components/sportScaleOverflowTooltip.component';
import SportScaleTable from 'common/table/sportScaleTable.component';
import SportScaleTableActionButton from 'common/table/sportScaleTableActionButton.component';
import SportScaleTableToolbar from 'common/table/sportScaleTableToolbar.component';
import useApiRequest from 'hooks/apiRequest';
import {
  GET_ORGANIZATION_TEAMS_SUCCESS,
  getOrganizationTeams,
  resetOrganizationSelectedTeam,
  resetOrganizationTeams,
} from 'modules/teams/teams.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { filterByDateField } from 'utilities/table.utils';
import { resetTeamSeasons } from 'modules/teams/seasons/seasons.actions';
import {
  selectSelectedOrganizationId,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';
import { selectTeams, selectTeamsIsLoading } from 'modules/teams/teams.selectors';
import { setPageTitle } from 'modules/layout/layout.actions';
import { sortByTeamHasSeason, sortByTeamSeasonEndDate, sortByTeamSeasonName } from 'utilities/sorting';

const TABLE_ACTIVE_ROSTER_FILTERS = {
  [true]: 'Yes',
  [false]: 'No',
};

const EMPTY_COLUMN_PLACEHOLDER = '- -';

const COLUMNS = [
  {
    cellStyle: { maxWidth: 150 },
    customFilterAndSearch: (term, rowData) => isEmpty(term) || rowData.name.toLowerCase().includes(term.toLowerCase()),
    render: rowData => <SportScaleOverflowTooltip text={rowData.name} />,
    field: 'name',
    title: FIELD_LABELS.SPORT,
  },
  {
    customFilterAndSearch: (term, rowData) =>
      isEmpty(term) || term.includes((!isEmpty(rowData.activeRosters)).toString()),
    customSort: sortByTeamHasSeason,
    lookup: TABLE_ACTIVE_ROSTER_FILTERS,
    render: rowData => (!isEmpty(rowData.activeRosters) ? 'Yes' : 'No'),
    title: FIELD_LABELS.ACTIVE_SEASON,
  },
  {
    customFilterAndSearch: (term, rowData) =>
      isEmpty(term) ||
      (!isEmpty(rowData.activeRosters) && rowData.activeRosters[0].name.toLowerCase().includes(term.toLowerCase())),
    customSort: sortByTeamSeasonName,
    render: rowData => (
      <SportScaleOverflowTooltip
        text={!isEmpty(rowData.activeRosters) ? rowData.activeRosters[0].name : EMPTY_COLUMN_PLACEHOLDER}
      />
    ),
    title: FIELD_LABELS.ACTIVE_SEASON_NAME,
  },
  {
    customFilterAndSearch: filterByDateField('endDate', 'isSameOrBefore'),
    customSort: sortByTeamSeasonEndDate,
    filterComponent: SportScaleDateFilter,
    render: rowData =>
      !isEmpty(rowData.activeRosters)
        ? SportScaleDateColumn(rowData.activeRosters[0].endDate)
        : EMPTY_COLUMN_PLACEHOLDER,
    title: FIELD_LABELS.ACTIVE_SEASON_END_DATE,
  },
];

const TeamsContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const makeRequest = useApiRequest();

  const isLoadingTeams = useSelector(selectTeamsIsLoading);
  const organizationId = useSelector(selectSelectedOrganizationId);
  const organizationName = useSelector(selectSelectedOrganizationName);
  const teams = useSelector(selectTeams);

  useEffect(() => {
    if (isNil(organizationId)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.SPORTS_TEAMS}`));

    /* SPOR-448: Clear any selected team and team seasons. They could still be
     set if the user navigates from a selected season to the teams list.  */
    dispatch(resetTeamSeasons());
    dispatch(resetOrganizationSelectedTeam());

    makeRequest(
      () => getOrganizationTeams(organizationId),
      { type: GET_ORGANIZATION_TEAMS_SUCCESS },
      ERROR_MESSAGES.GET_ORGANIZATION_TEAMS_FAILURE
    );
  }, [dispatch, makeRequest, organizationId, organizationName]);

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationTeams());
    };
  }, [dispatch]);

  const goToCreateTeam = useCallback(() => history.push(`${location.pathname}/${NEW_ITEM_ROUTE}`), [history, location]);

  return (
    <PageWrapper>
      <Box mb={2} width="100%">
        <SportScaleTable
          actions={[
            {
              icon: '',
              text: can(PERMISSIONS.EDIT_TEAMS) ? 'Details' : 'View',
              onClick: rowData => history.push(`${location.pathname}/${rowData.id}`, { team: rowData }),
            },
          ]}
          columns={COLUMNS}
          components={{
            Action: SportScaleTableActionButton(classes.actionButton, false),
            Toolbar: SportScaleTableToolbar(
              can(PERMISSIONS.CREATE_TEAMS) ? [{ text: 'create sport', onClick: goToCreateTeam }] : []
            ),
          }}
          data={teams || []}
          isLoading={isLoadingTeams}
          options={{ toolbar: true }}
        />
      </Box>
    </PageWrapper>
  );
};

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
}));

TeamsContainer.propTypes = {};

TeamsContainer.defaultProps = {
  organization: null,
};

export default TeamsContainer;
