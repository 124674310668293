import React from 'react';
import moment from 'moment';
import { ValidationRules } from 'react-form-validator-core';
import { isEmpty, isNil } from 'lodash';
import { isValidNumber } from 'libphonenumber-js';

import { MAX_HEIGHT_CM, MIN_HEIGHT_CM } from 'types/height';

export const US_COUNTRY_CODE = 'US';

export const VALIDATION_TYPES = {
  // Built-in validators to react-form-validator-core
  IS_EMAIL: 'isEmail',
  REQUIRED: 'required',
  TRIM: 'trim',
  IS_NUMBER: 'isNumber',
  IS_POSITIVE: 'isPositive',
  MIN_NUMBER: 'minNumber',
  MAX_NUMBER: 'maxNumber',
  MIN_STRING_LENGTH: 'minStringLength',
  MAX_STRING_LENGTH: 'maxStringLength',

  // Our custom validators,
  IS_NOT_EMPTY: 'isNotEmpty',
  IS_PHONE: 'isPhone',
  IS_DATE: 'isDate',
  IS_DATE_AFTER_DATE: 'isDateAfterDate',
  IS_DATE_IN_FUTURE: 'isDateInFuture',
  IS_DATE_IN_PAST: 'isDateInPast',
  IS_NUMBER_OR_FLOAT: 'isNumberOrFloat',
  VALID_HEIGHT: 'validHeight',
  VALID_WEIGHT: 'validWeight',
};

export const MIN_WEIGHT = 1;
export const MAX_WEIGHT = 500;

// validation functions for text input fields
// return error text if validator function fails
// return false if no error

export const getValidationErrorMessages = (value, fieldLabel, validators, compareValue = null, compareLabel = null) => {
  const toReturn = [];

  validators.forEach(validator => {
    /* Validator could be
        - function name
        - a message provided
        - combination of a function name and condition (.ie maxStringLength:256) https://www.npmjs.com/package/react-form-validator-core
    */
    let validatorName = validator;
    let validatorCondition;

    if (validator.indexOf(':') !== -1) {
      const splitValidator = validator.split(':');
      [validatorName, validatorCondition] = splitValidator;
    }

    if (fieldValidators[validatorName] && !isNil(compareValue)) {
      toReturn.push(fieldValidators[validatorName](value, fieldLabel, compareValue, compareLabel));
      return;
    }

    if (fieldValidators[validatorName]) {
      toReturn.push(fieldValidators[validatorName](value, fieldLabel, validatorCondition));
      return;
    }

    toReturn.push(validatorName);
  });

  return toReturn;
};

export const isPhone = value => {
  if (!isValidNumber(value, US_COUNTRY_CODE)) return 'Invalid phone number';

  return false;
};

const onlySpaces = new RegExp(/^\s*$/);
export const isNotEmpty = (value, fieldLabel) => {
  if (onlySpaces.test(value)) {
    return `${fieldLabel} must not be an empty string`;
  }
  return false;
};

export const isDate = (value, fieldLabel) =>
  moment(value).isValid() || (isEmpty(value) && !Number.isNaN(value)) ? false : `${fieldLabel} must be a valid date`;

export const isDateInFuture = (value, fieldLabel) =>
  moment(value).isAfter(moment()) ? `${fieldLabel} must be a date in the past` : false;

export const isDateInPast = (value, fieldLabel) =>
  moment(value).isAfter(moment()) ? false : `${fieldLabel} must be a date in the future`;

export const isDateAfterDate = (value, fieldLabel, compareValue, compareLabel) =>
  moment(value).isBefore(moment(compareValue)) ? `${fieldLabel} must be a date before the ${compareLabel}` : false;

export const validHeight = (value, fieldLabel) => {
  if (value === '' || isNil(value)) return false;

  if (value <= MIN_HEIGHT_CM) return `${fieldLabel} should be greater than 0 ft.`;
  if (value > MAX_HEIGHT_CM) return `${fieldLabel} should be less than 10 ft.`;

  return false;
};

export const validWeight = (value, fieldLabel) => {
  const weight = parseFloat(value);

  return weight >= MIN_WEIGHT && weight <= MAX_WEIGHT
    ? false
    : `${fieldLabel} must be between ${MIN_WEIGHT} and ${MAX_WEIGHT}`;
};

export const maxStringLength = (value, fieldLabel, strLength) => {
  const lengthAsNum = parseInt(strLength, 10);

  return value.length > lengthAsNum ? `${fieldLabel} must be less than ${lengthAsNum + 1} characters` : false;
};

export const minStringLength = (value, fieldLabel, strLength) => {
  const lengthAsNum = parseInt(strLength, 10);

  return value.length < lengthAsNum ? `${fieldLabel} must be more than ${lengthAsNum - 1} characters` : false;
};

export const isNumberOrFloat = (value, fieldLabel) => {
  if (typeof value === 'number') return false;

  /* eslint-disable-next-line no-shadow */
  const isNumberOrFloat = ValidationRules.isNumber(value) || ValidationRules.isFloat(value);

  return isNumberOrFloat ? false : `${fieldLabel} must be a number`;
};

export const isPositive = (value, fieldLabel) => {
  return ValidationRules.isPositive(value) ? false : `${fieldLabel} must be a positive number`;
};

export const maxNumber = (value, fieldLabel, num) =>
  value > num ? `${fieldLabel} must be less than or equal to ${num}` : false;

export const minNumber = (value, fieldLabel, num) =>
  value < num ? `${fieldLabel} must be greater than or equal to ${num}` : false;

export const required = (value, fieldLabel) => {
  /*
    Check for the following:
    - If is a number
    - If is an object and is not an empty
    - If is a string and isn't just empty white space
  */
  return typeof value === 'number' ||
    (typeof value === 'object' && !isEmpty(value)) ||
    (typeof value === 'string' && !isEmpty(value.trim()))
    ? false
    : `${fieldLabel ? `${fieldLabel} is ` : ''}required`;
};

export const renderRequiredLabel = label => (
  <span>
    {label}
    &thinsp;*
  </span>
);

export const fieldValidators = {
  isDate,
  isDateInFuture,
  isDateInPast,
  isDateAfterDate,
  isNotEmpty,
  isNumberOrFloat,
  isPositive,
  isPhone,
  maxNumber,
  maxStringLength,
  minNumber,
  minStringLength,
  required,
  trim: required,
  validHeight,
  validWeight,
};
