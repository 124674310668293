import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListSubheader,
  Drawer as MUIDrawer,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { isEmpty, isNil } from 'lodash';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BrandLogo from 'assets/images/brandLogo.png';
import DrawerListItem from 'modules/layout/drawer/drawerListItem.component';
import {
  DRAWER_ITEMS_APP_MANAGEMENT,
  DRAWER_ITEMS_SPORTSCALE_MANAGEMENT,
  DRAWER_ITEMS_TEAM_MANAGEMENT,
  HELP,
  LOGOUT,
  ORGANIZATIONS,
} from 'modules/layout/drawer/drawerItems';
import { can } from 'common/permissions';
import { isNoRedirectRoute } from 'utilities/routes';
import { logout } from 'modules/login/login.actions';
import { setSelectedOrganizationId } from 'modules/organizations/organizations.actions';
import { toggleDrawer } from 'modules/layout/layout.actions';
import { useWidth } from 'utilities/theme';

export const DRAWER_WIDTH = 320;
export const DRAWER_WIDTH_CLOSED = 70;

const Drawer = (/* props */) => {
  const { open, organizations, selectedOrganizationId } = useSelector(state => ({
    open: state.layout.isDrawerOpen,
    organizations: state.organizations.organizations,
    selectedOrganizationId: state.organizations.selectedOrganizationId,
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const screenWidth = useWidth();

  const urlSegments = useMemo(() => location.pathname.split('/'), [location]);

  const checkIsActiveRoute = useCallback(
    route => {
      urlSegments.splice(3, urlSegments.length);

      if (isNoRedirectRoute(location.pathname)) return route === location.pathname;

      if (route === ORGANIZATIONS.route) return `${urlSegments.join('/')}${route}` === location.pathname;

      return !!matchPath(location.pathname, {
        path: `${urlSegments.join('/')}${route}`,
        exact: false,
        strict: false,
      });
    },
    [location, urlSegments]
  );

  const getIcon = useCallback(
    item => {
      const activeColor = theme.palette.primary.main;
      const isActiveRoute = checkIsActiveRoute(item.route);
      const iconStyle = {
        width: 'unset',
        margin: 'auto',
        color: isActiveRoute ? activeColor : theme.palette.common.white,
      };

      return <Icon style={iconStyle} className={item.iconClass} />;
    },
    [theme, checkIsActiveRoute]
  );

  const viewPage = useCallback(
    route => {
      const newRoute = route.isOrgRoute ? `/organizations/${selectedOrganizationId}${route.route}` : route.route;

      // Only navigate if we're going to a new page.
      if (newRoute !== location.pathname) {
        history.push(newRoute);
        // SPOR-116: Automatically close the drawer on mobile views.
        if (screenWidth === 'xs' && open) dispatch(toggleDrawer());
      }
    },
    [dispatch, history, location, open, screenWidth, selectedOrganizationId]
  );

  const handleHelp = () => {
    window.open(process.env.REACT_APP_SERVICE_DESK_URL, '_blank');
  };

  const handleLogout = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to logout?')) {
      dispatch(setSelectedOrganizationId(null));
      dispatch(logout());
    }
  }, [dispatch]);

  // next phase of refactoring would be to pull this into it's own component
  // which will be tricky since some of what's done here is needed for the logout and help links
  const renderList = (title, items) => {
    return (
      <List classes={{ root: classNames(classes.listRoot, classes.routesList) }}>
        <ListSubheader>{open ? title : ''}</ListSubheader>
        {!isEmpty(organizations) &&
          selectedOrganizationId &&
          items.map(item => {
            // Don't display anything if the user doesn't have permissions to view that area.
            if (item.permission && !can(item.permission)) return null;

            const isActiveRoute = checkIsActiveRoute(item.route);

            return (
              <DrawerListItem
                key={item.name}
                item={item}
                isActiveRoute={isActiveRoute}
                onClick={() => viewPage(item)}
                getIcon={getIcon}
                showTooltip={!open}
              />
            );
          })}
      </List>
    );
  };

  return (
    <MUIDrawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
      color="secondary"
    >
      <Box className={classes.toolbar} alignItems="center" display="flex" padding={theme.spacing(0, 1)}>
        <Box flex={1}>
          <img className={classes.brandLogo} src={BrandLogo} alt="Brand Logo" />
        </Box>
        <IconButton onClick={() => dispatch(toggleDrawer())}>
          <ChevronLeftIcon className={classes.drawerChevron} />
        </IconButton>
      </Box>
      {renderList('Team Management', DRAWER_ITEMS_TEAM_MANAGEMENT)}

      <Divider />

      <List classes={{ root: classes.listRoot }}>
        {renderList('App Management', DRAWER_ITEMS_APP_MANAGEMENT)}

        <Divider />

        {renderList('Sportscale Management', DRAWER_ITEMS_SPORTSCALE_MANAGEMENT)}
      </List>

      <Divider />

      <List classes={{ root: classes.listRoot }}>
        {!isNil(process.env.REACT_APP_SERVICE_DESK_URL) && process.env.REACT_APP_SERVICE_DESK_URL !== 'false' && (
          <DrawerListItem
            item={HELP}
            onClick={handleHelp}
            getIcon={getIcon}
            isActiveRoute={false}
            showTooltip={!open}
          />
        )}

        <DrawerListItem
          item={LOGOUT}
          onClick={handleLogout}
          getIcon={getIcon}
          isActiveRoute={false}
          showTooltip={!open}
        />
      </List>
    </MUIDrawer>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 36,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: 'none',
  },
  drawerClose: {
    overflowX: 'hidden',
    width: DRAWER_WIDTH_CLOSED,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRight: 'none',
  },
  drawerChevron: {
    color: theme.palette.common.white,
    fontSize: '2rem',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.grey[700],
    textAlign: 'center',
  },
  brandLogo: {
    height: '2rem',
    maxWidth: '14rem',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listRoot: {
    padding: 0,
  },
  routesList: {
    marginBottom: 'auto',
  },
  hide: {
    display: 'none',
  },
  username: {
    fontSize: '0.8rem',
    marginRight: 'auto',
    paddingLeft: '1rem',
  },
  activeItem: {
    color: theme.palette.secondary.main,
  },
  nonFAIcon: {
    margin: 'auto',
    color: theme.palette.common.white,
  },
}));

Drawer.propTypes = {};

export default Drawer;
