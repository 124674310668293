import { PERMISSIONS } from 'common/permissions';
/*
  These routes are subroutes of the specific organization which requires the orgId,
  in order to have that we do the template string in the connected drawer component
*/

export const PAGE_TITLES = {
  ATHLETE_ENROLLMENT: 'Athlete Enrollment',
  CURRENT_ACTIVITY: 'Current Activity',
  LOCATIONS: 'Locations',
  ORGANIZATION: 'Organization',
  PROFILE: 'My Profile',
  ANALYSIS: 'Data',
  SCALES: 'Scales',
  SPORTS_TEAMS: 'Sports & Teams',
  USERS: 'Users',
  LOGOUT: 'Logout',
  HELP: 'Help',
};

export const ORGANIZATIONS = {
  iconClass: 'fal fa-sitemap',
  isOrgRoute: true,
  name: PAGE_TITLES.ORGANIZATION,
  route: '',
};

export const LOCATIONS = {
  iconClass: 'far fa-map-marker-alt',
  isOrgRoute: true,
  name: PAGE_TITLES.LOCATIONS,
  route: '/locations',
  permission: PERMISSIONS.READ_LOCATIONS,
};

export const USERS = {
  iconClass: 'fal fa-users',
  isOrgRoute: true,
  route: '/users',
  name: PAGE_TITLES.USERS,
  permission: PERMISSIONS.READ_USERS,
};

export const SCALES = {
  iconClass: 'fal fa-weight',
  isOrgRoute: true,
  name: PAGE_TITLES.SCALES,
  route: '/scales',
  permission: PERMISSIONS.READ_SCALES,
};

export const TEAMS = {
  iconClass: 'fal fa-users-class',
  isOrgRoute: true,
  name: PAGE_TITLES.SPORTS_TEAMS,
  route: '/teams',
};

export const ATHLETES = {
  iconClass: 'fas fa-running',
  isOrgRoute: true,
  name: PAGE_TITLES.ATHLETE_ENROLLMENT,
  route: '/athletes',
  permission: PERMISSIONS.READ_ATHLETES,
};

export const PROFILE = {
  iconClass: 'fal fa-user',
  isOrgRoute: false,
  name: PAGE_TITLES.PROFILE,
  route: '/profile',
};

export const CURRENT_ACTIVITY = {
  iconClass: 'fal fa-eye',
  isOrgRoute: true,
  name: PAGE_TITLES.CURRENT_ACTIVITY,
  route: '/currentSports',
};

export const ANALYSIS = {
  iconClass: 'fal fa-file-chart-pie',
  isOrgRoute: true,
  name: PAGE_TITLES.ANALYSIS,
  route: '/reports',
};

export const LOGOUT = {
  iconClass: 'far fa-power-off',
  isOrgRoute: false,
  name: PAGE_TITLES.LOGOUT,
  route: '/logout', // route isn't used - but we need to make sure it doesn't show as active
};

export const HELP = {
  iconClass: 'far fa-question-circle',
  isOrgRoute: false,
  name: PAGE_TITLES.HELP,
  route: '/help', // route isn't used - but we need to make sure it doesn't show as active
};

export const DRAWER_ITEMS_TEAM_MANAGEMENT = [CURRENT_ACTIVITY, ATHLETES, TEAMS, ANALYSIS];

export const DRAWER_ITEMS_APP_MANAGEMENT = [ORGANIZATIONS, USERS, PROFILE];

export const DRAWER_ITEMS_SPORTSCALE_MANAGEMENT = [SCALES, LOCATIONS];
