import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Typography, makeStyles } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';

import AthletesUtils from 'utilities/athletes.utils';
import DateUtils from 'utilities/date.utils';
import HydrationLineChart from 'modules/athletes/hydrationLineChart.component';
import RosterUtils from 'utilities/roster.utils';
import WeightScatterChart from 'modules/athletes/weightScatterChart.component';
import WeightUtils, { SEVERE_MULTIPLIER } from 'utilities/weight';

const DATE_FORMAT = 'MM-DD';
const DEFAULT_AXIS_TICKS_Y = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];

/*
Determine the max and min values for the chart range
Create ticks for the chart on each integer along the range
*/
const getRange = (inToIn, inToOut, thresholdSevere, enableHydrationTracking, targetWeights) => {
  const HARD_MAX = 20;
  const HARD_MIN = -20;
  const RANGE_PADDING = 3;

  const maxValueOfInToIn = Math.max(...inToIn.map(o => o.y));
  const maxValueOfInToOut = Math.max(...inToOut.map(o => o.y));

  const minValueOfInToIn = Math.min(...inToIn.map(o => o.y));
  const minValueOfInToOut = Math.min(...inToOut.map(o => o.y));

  let finalMax;
  let finalMin;

  if (enableHydrationTracking) {
    finalMax = Math.max(maxValueOfInToIn, maxValueOfInToOut, thresholdSevere);
    finalMin = Math.min(minValueOfInToIn, minValueOfInToOut, thresholdSevere);
    if (finalMin < HARD_MIN) finalMin = HARD_MIN;
    else finalMin -= RANGE_PADDING;
    if (finalMax > HARD_MAX) finalMax = HARD_MAX;
    else finalMax += RANGE_PADDING;
  } else if (isEmpty(targetWeights)) {
    finalMax = maxValueOfInToIn + RANGE_PADDING;
    finalMin = minValueOfInToIn - RANGE_PADDING;
  } else {
    finalMax = Math.max(...targetWeights.map(o => o.targetWeight), maxValueOfInToIn) + RANGE_PADDING;
    finalMin = Math.min(...targetWeights.map(o => o.targetWeight), minValueOfInToIn) - RANGE_PADDING;
  }

  const axisTicks = [];

  for (let i = Math.floor(finalMin); i <= Math.floor(finalMax); i += 1) {
    axisTicks.push(i);
  }

  return axisTicks;
};

const getDomain = () =>
  AthletesUtils.getPastDays(null, AthletesUtils.TOTAL_CHART_ENTRIES)
    .reverse()
    .map(dateEntry => moment(dateEntry.date).format(DATE_FORMAT));

const WeightsChart = ({ athlete, enableHydrationTracking, targetWeights, team }) => {
  const classes = useStyles();

  const [dataThresholdWarning, setDataThresholdWarning] = useState([]);
  const [dataThresholdSevere, setDataThresholdSevere] = useState([]);
  const [dataIn2In, setDataIn2In] = useState([]);
  const [dataIn2Out, setDataIn2Out] = useState([]);
  const [dataTargets, setDataTargets] = useState([]);
  const [axisTicksY, setAxisTicksY] = useState(DEFAULT_AXIS_TICKS_Y);
  const [axisTicksX, setAxisTicksX] = useState([]);

  useEffect(() => {
    // the thresholds are defined as positive values, that represent a negative percentage
    const thresholdWarning = -1 * RosterUtils.getAthleteThreshold(athlete, team) * 100;
    const thresholdSevere = thresholdWarning * SEVERE_MULTIPLIER;

    // establish the horizontal lines for showing the thresholds
    const warning = [];
    const severe = [];
    for (let i = 0; i <= AthletesUtils.TOTAL_CHART_ENTRIES; i += 1) {
      warning.push({ x: i, y: thresholdWarning });
      severe.push({ x: i, y: thresholdSevere });
    }

    setDataThresholdWarning(warning);
    setDataThresholdSevere(severe);

    // iterate over the sorted athlete weights data, and establish the separate chart line data
    const inToIn = [];
    const inToOut = [];
    const sortedWeights = [...athlete.weights].sort((a, b) =>
      moment(new Date(a.date)).isSameOrBefore(new Date(b.date)) ? -1 : 1
    );

    sortedWeights.forEach(dayOfData => {
      const valueX = moment(dayOfData.date).format(DATE_FORMAT);

      /*
      If we are hydration tracking, use the inToInDelta provided, and calculate the inToOutDelta
      If not, calculate the inToIn, and always set the inToOut to be null, since there are no weigh outs in that context
      */
      if (enableHydrationTracking) {
        const valueYInToIn = !isNil(dayOfData.inToInDelta) ? dayOfData.inToInDelta * 100 : null;
        const inToOutDelta = WeightUtils.getIn2OutDelta(dayOfData.weighInWeight, dayOfData.weighOutWeight);
        const valueYInToOut = !isNil(inToOutDelta) ? inToOutDelta * 100 : null;
        inToIn.push({ x: valueX, y: valueYInToIn });
        inToOut.push({ x: valueX, y: valueYInToOut });
      } else if (dayOfData && dayOfData.simpleWeight) {
        inToIn.push({ x: valueX, y: dayOfData.simpleWeight });
      }
    });

    setDataIn2In(inToIn);
    setDataIn2Out(inToOut);

    // Find the min and max values needed to show the data
    const domain = getDomain();

    setAxisTicksX(domain);
    setAxisTicksY(getRange(inToIn, inToOut, thresholdSevere, enableHydrationTracking, targetWeights));

    /* Target Weight Steps */
    /*
      1) Find the next closest target weight in the future in order to always show this on the last of the chart
      2) Target weights need ot be filtered down to only ones that exist in the last two weeks and formatted into x & y
    */
    const dataTargetWeights = [...targetWeights]
      .filter(targetWeight => {
        const dt = DateUtils.convertToDateWithOffset(targetWeight.targetDate);

        const daysDiff = moment(dt).diff(moment(), 'days');
        const isSameAsToday = moment(dt).isSame(moment(), 'days');
        const isBeforeToday = moment(dt).isBefore(moment(), 'days');

        return daysDiff >= -14 && (isSameAsToday || isBeforeToday);
      })
      .map(targetWeight => {
        return {
          x: moment(targetWeight.targetDate).format(DATE_FORMAT),
          y: targetWeight.targetWeight,
          ...targetWeight,
        };
      });

    if (targetWeights.length && domain.length) {
      const closestTargetWeight = targetWeights.reduce((closestTarget, targetWeight) => {
        if (targetWeight.daysLeft <= 0) return closestTarget;

        if (isNil(closestTarget) || (closestTarget && targetWeight.daysLeft < closestTarget.daysLeft))
          return targetWeight;

        return closestTarget;
      }, null);

      if (closestTargetWeight)
        dataTargetWeights.push({
          x: domain[domain.length - 1],
          y: closestTargetWeight.targetWeight,
          ...closestTargetWeight,
        });
    }

    setDataTargets(dataTargetWeights);
  }, [athlete, enableHydrationTracking, targetWeights, team]);

  return (
    <div className={classes.container}>
      <Typography variant="h6" color="primary">
        Weight Change Over 15 Days
      </Typography>
      {enableHydrationTracking ? (
        <HydrationLineChart
          axisTicksX={axisTicksX}
          axisTicksY={axisTicksY}
          in2In={dataIn2In}
          in2Out={dataIn2Out}
          thresholdSevere={dataThresholdSevere}
          thresholdWarning={dataThresholdWarning}
        />
      ) : (
        <div className={classes.scatterChart}>
          <WeightScatterChart
            axisTicksX={axisTicksX}
            axisTicksY={axisTicksY}
            in2In={dataIn2In}
            targetWeights={dataTargets}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  scatterChart: {
    marginTop: theme.spacing(-4),
  },
}));

WeightsChart.defaultProps = {
  targetWeights: null,
};

WeightsChart.propTypes = {
  athlete: PropTypes.object.isRequired,
  enableHydrationTracking: PropTypes.bool.isRequired,
  targetWeights: PropTypes.array,
  team: PropTypes.object.isRequired,
};

export default WeightsChart;
