import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DialogContentText, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CreateLocationDialog from 'modules/scales/createLocationDialog.component';
import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import ScaleForm, { CREATE_LOCATION } from 'modules/scales/scaleForm.component';
import SportScaleBreadCrumbs from 'modules/layout/breadCrumbs/breadCrumbs.component';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import {
  DELETE_ORGANIZATION_SCALE_SUCCESS,
  UPDATE_ORGANIZATION_SCALE_SUCCESS,
  deleteOrganizationScale,
  getOrganizationScale,
  updateOrganizationScale,
} from 'modules/scales/scales.actions';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { ROUTES, SCALE_ID_MAP, generateBreadCrumbMap, generateRouteParamsMap } from 'utilities/breadCrumbs';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { getOrganizationLocations } from 'modules/locations/locations.actions';
import { getSubmitText } from 'utilities/form';
import { selectLocations } from 'modules/locations/locations.selectors';
import {
  selectOrganizationsIsLoadingOrganization,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';
import { selectScalesIsLoadingScale, selectSelectedScale } from 'modules/scales/scales.selectors';

const INITIAL_SCALE = {
  name: '',
  locationId: null,
};

const ScaleContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { organizationId, scaleId } = useParams();

  const isLoadingOrganization = useSelector(selectOrganizationsIsLoadingOrganization);
  const organizationName = useSelector(selectSelectedOrganizationName);

  const locations = useSelector(selectLocations);

  const isLoadingScale = useSelector(selectScalesIsLoadingScale);
  const selectedScale = useSelector(selectSelectedScale);

  const [scale, setScale] = useState(INITIAL_SCALE);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [createLocationOpen, setCreateLocationOpen] = useState(false);

  const isLoading = isLoadingOrganization;

  useEffect(() => {
    if (isNil(organizationName)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.SCALES}`));
    dispatch(getOrganizationLocations(organizationId));

    if (!isEmpty(location.state)) {
      const { scale: locationScale } = location.state;
      setScale(locationScale);
      return;
    }

    (async () => {
      const response = await dispatch(getOrganizationScale(organizationId, scaleId));

      setScale(response.response);
    })();
  }, [dispatch, location, organizationId, organizationName, scaleId]);

  const handleSubmitScale = useCallback(() => {
    (async () => {
      const response = await dispatch(updateOrganizationScale(organizationId, scaleId, scale));

      if (response.type !== UPDATE_ORGANIZATION_SCALE_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.UPDATE_SCALE_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.UPDATE_SCALE_SUCCESS));
      history.push(`/organizations/${organizationId}/scales`);
    })();
  }, [dispatch, history, organizationId, scale, scaleId]);

  const handleInputChange = useCallback(
    ({ target: { name, value } }) => {
      if (value === CREATE_LOCATION) {
        setCreateLocationOpen(true);
      }

      setScale({ ...scale, [name]: value });
    },
    [scale, setCreateLocationOpen, setScale]
  );

  const submitText = useMemo(() => getSubmitText(isLoadingScale), [isLoadingScale]);

  const breadCrumbs = useMemo(() => {
    const name = !isEmpty(location.state) ? location.state.scale.name : selectedScale.name;
    const identifier = !isEmpty(location.state) ? location.state.scale.identifier : selectedScale.identifier;

    return !isNil(selectedScale)
      ? generateBreadCrumbMap(generateRouteParamsMap(SCALE_ID_MAP, [organizationId, scaleId]), {
          [ROUTES.SCALES_ROUTE]: PAGE_TITLES.SCALES,
          [ROUTES.SCALE_ROUTE]: name || identifier,
        })
      : {};
  }, [location, organizationId, selectedScale, scaleId]);

  const handleToggleRemoveScale = useCallback(() => setRemoveDialogOpen(!removeDialogOpen), [
    removeDialogOpen,
    setRemoveDialogOpen,
  ]);

  const handleDeleteScale = useCallback(() => {
    (async () => {
      const response = await dispatch(deleteOrganizationScale(organizationId, scaleId));

      if (response.type !== DELETE_ORGANIZATION_SCALE_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_SCALE_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_SCALE_SUCCESS));
      history.replace(`/organizations/${organizationId}/scales`);
    })();
  }, [dispatch, history, organizationId, scaleId]);

  const handleLocationClose = useCallback(
    createdLocation => {
      if (createdLocation) {
        setScale({ ...scale, locationId: createdLocation.id });
      }

      setCreateLocationOpen(false);
    },
    [scale, setCreateLocationOpen, setScale]
  );

  if (isLoading || isNil(locations))
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  return (
    <>
      <PageWrapper className={classes.pageWrapper}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <SportScaleBreadCrumbs breadCrumbs={breadCrumbs} />
          {can(PERMISSIONS.REMOVE_SCALES) && (
            <SportScalePrimaryButton onClick={handleToggleRemoveScale}>unregister scale</SportScalePrimaryButton>
          )}
        </Box>
        <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitScale}>
          <ScaleForm
            disabled={isLoadingScale || !can(PERMISSIONS.EDIT_SCALES)}
            locations={locations}
            scale={scale}
            onChange={handleInputChange}
          />
          <Box alignItems="flex-end" display="flex" flex={1} justifyContent="space-between" width="100%">
            <SportScaleSecondaryButton
              disabled={isLoading}
              onClick={() => history.push(`/organizations/${organizationId}/scales`)}
            >
              cancel
            </SportScaleSecondaryButton>
            <SportScalePrimaryButton disabled={isLoading || !can(PERMISSIONS.EDIT_SCALES)} type="submit">
              {submitText}
            </SportScalePrimaryButton>
          </Box>
        </ValidatorForm>
      </PageWrapper>
      <SportScaleDialog
        actions={[
          {
            action: handleToggleRemoveScale,
            disabled: isLoadingScale,
            text: 'Cancel',
          },
          {
            action: handleDeleteScale,
            disabled: isLoadingScale,
            isPrimary: true,
            text: isLoadingScale ? 'unregistering...' : 'unregister',
          },
        ]}
        content={
          <DialogContentText>
            Are you sure you want to unregister {scale.name} from this organization?
          </DialogContentText>
        }
        open={removeDialogOpen}
        title="Unregister Scale?"
        onClose={handleToggleRemoveScale}
      />
      <CreateLocationDialog isOpen={createLocationOpen} onClose={handleLocationClose} />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
}));

ScaleContainer.propTypes = {};

export default ScaleContainer;
