import AthletesUtils from 'utilities/athletes.utils';
import PropTypes from 'prop-types';
import React from 'react';
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLegend, VictoryLine, VictoryTheme } from 'victory';
import { useTheme } from '@material-ui/core';

import {
  ANIMATION_SETTINGS,
  CHART_AXIS_STYLE,
  CHART_DOMAIN_PADDING,
  CHART_LEGEND_STYLE,
  CHART_LINE_PARENT_STYLE,
} from './charts.settings';

const ITEMS_PER_ROW = 4;
const LEGEND_POSITION = { x: 55, y: 15 };
const X_AXIS_LABEL_OFFSET = 315;

export default function HydrationLineChart(props) {
  const { axisTicksY, axisTicksX, in2In, in2Out, thresholdSevere, thresholdWarning } = props;
  const theme = useTheme();
  const legend = [
    { name: 'In2In', symbol: { fill: theme.palette.primary.main } },
    { name: 'In2Out', symbol: { fill: theme.palette.success.main } },
    { name: 'Alert', symbol: { fill: theme.palette.warning.main } },
    { name: 'Critical', symbol: { fill: theme.palette.error.main } },
  ];
  return (
    <VictoryChart
      style={{ parent: { marginTop: '-1rem' } }}
      theme={VictoryTheme.material}
      domain={{ y: [axisTicksY[0], axisTicksY[axisTicksY.length - 1]] }}
      domainPadding={CHART_DOMAIN_PADDING}
    >
      <VictoryLegend
        y={LEGEND_POSITION.y}
        x={LEGEND_POSITION.x}
        orientation="horizontal"
        gutter={theme.spacing(1.5)}
        style={CHART_LEGEND_STYLE}
        itemsPerRow={ITEMS_PER_ROW}
        data={legend}
      />
      <VictoryAxis
        name="X Axis"
        style={CHART_AXIS_STYLE}
        tickCount={Math.floor(AthletesUtils.TOTAL_CHART_ENTRIES / 2)}
        tickValues={axisTicksX}
        tickLabelComponent={<VictoryLabel y={X_AXIS_LABEL_OFFSET} />}
      />
      <VictoryAxis
        dependentAxis
        label="Weight Delta (%)"
        name="Y Axis"
        style={CHART_AXIS_STYLE}
        tickCount={Math.floor(axisTicksY.length / 2)}
        tickValues={axisTicksY}
        axisLabelComponent={<VictoryLabel dy={theme.spacing(-3)} />}
      />
      {/* Line Threshold - Warning  */}
      <VictoryLine
        style={{
          data: { stroke: theme.palette.warning.main },
          parent: CHART_LINE_PARENT_STYLE,
        }}
        data={thresholdWarning}
        animate={ANIMATION_SETTINGS}
      />
      {/* Line Threshold - Severe */}
      <VictoryLine
        style={{
          data: { stroke: theme.palette.error.main },
          parent: CHART_LINE_PARENT_STYLE,
        }}
        data={thresholdSevere}
        animate={ANIMATION_SETTINGS}
      />
      {/* Line 1 - In2In */}
      <VictoryLine
        style={{
          data: { stroke: theme.palette.primary.main },
          parent: CHART_LINE_PARENT_STYLE,
        }}
        data={in2In}
        animate={ANIMATION_SETTINGS}
      />
      {/* Line 2 - In2Out */}
      <VictoryLine
        style={{
          data: { stroke: theme.palette.success.main },
          parent: CHART_LINE_PARENT_STYLE,
        }}
        data={in2Out}
        animate={ANIMATION_SETTINGS}
      />
    </VictoryChart>
  );
}

HydrationLineChart.propTypes = {
  axisTicksX: PropTypes.array.isRequired,
  axisTicksY: PropTypes.array.isRequired,
  in2In: PropTypes.array.isRequired,
  in2Out: PropTypes.array.isRequired,
  thresholdSevere: PropTypes.array.isRequired,
  thresholdWarning: PropTypes.array.isRequired,
};
