import produce from 'immer';

import {
  CREATE_ORGANIZATION_USER_FAILURE,
  CREATE_ORGANIZATION_USER_REQUEST,
  CREATE_ORGANIZATION_USER_SUCCESS,
  DELETE_ORGANIZATION_USER_FAILURE,
  DELETE_ORGANIZATION_USER_REQUEST,
  DELETE_ORGANIZATION_USER_SUCCESS,
  GET_ORGANIZATION_USERS_FAILURE,
  GET_ORGANIZATION_USERS_REQUEST,
  GET_ORGANIZATION_USERS_SUCCESS,
  GET_ORGANIZATION_USER_FAILURE,
  GET_ORGANIZATION_USER_REQUEST,
  GET_ORGANIZATION_USER_SUCCESS,
  RESEND_USER_VERIFICATION_EMAIL_FAILURE,
  RESEND_USER_VERIFICATION_EMAIL_REQUEST,
  RESEND_USER_VERIFICATION_EMAIL_SUCCESS,
  RESET_ORGANIZATION_SELECTED_USER,
  RESET_ORGANIZATION_USERS,
  UPDATE_ORGANIZATION_USER_FAILURE,
  UPDATE_ORGANIZATION_USER_REQUEST,
  UPDATE_ORGANIZATION_USER_SUCCESS,
} from 'modules/users/users.actions';

const initialState = {
  isLoading: false,
  isLoadingUser: false,
  users: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_USERS_SUCCESS:
      draft.users = action.response;
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_USERS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_USERS_REQUEST:
      draft.isLoading = true;
      break;

    case RESET_ORGANIZATION_USERS:
      draft.users = null;
      break;

    case UPDATE_ORGANIZATION_USER_SUCCESS:
      draft.isLoadingUser = false;
      break;

    case UPDATE_ORGANIZATION_USER_FAILURE:
      draft.isLoadingUser = false;
      break;

    case UPDATE_ORGANIZATION_USER_REQUEST:
      draft.isLoadingUser = true;
      break;

    case GET_ORGANIZATION_USER_SUCCESS:
      draft.selectedUser = action.response;
      draft.isLoadingUser = false;
      break;

    case CREATE_ORGANIZATION_USER_REQUEST:
    case DELETE_ORGANIZATION_USER_REQUEST:
    case GET_ORGANIZATION_USER_REQUEST:
      draft.isLoadingUser = true;
      break;

    case CREATE_ORGANIZATION_USER_SUCCESS:
    case DELETE_ORGANIZATION_USER_SUCCESS:
      draft.isLoadingUser = false;
      break;

    case CREATE_ORGANIZATION_USER_FAILURE:
    case DELETE_ORGANIZATION_USER_FAILURE:
    case GET_ORGANIZATION_USER_FAILURE:
      draft.isLoadingUser = false;
      break;

    case RESET_ORGANIZATION_SELECTED_USER:
      draft.selectedUser = null;
      break;

    case RESEND_USER_VERIFICATION_EMAIL_REQUEST:
      draft.isLoadingEmail = true;
      break;

    case RESEND_USER_VERIFICATION_EMAIL_SUCCESS:
      draft.isLoadingEmail = false;
      break;

    case RESEND_USER_VERIFICATION_EMAIL_FAILURE:
      draft.isLoadingEmail = false;
      break;

    default:
      break;
  }
}, initialState);
