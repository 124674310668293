import React, { useEffect } from 'react';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { getReportsUrl } from 'modules/reports/reports.actions';
import { selectReportsIsLoading, selectReportsUrl } from 'modules/reports/reports.selectors';
import {
  selectSelectedOrganizationId,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';
import { setPageTitle } from 'modules/layout/layout.actions';

const IFRAME_STYLE = {
  border: 0,
  width: '100%',
  height: '100%',
};

const Reports = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();

  const organizationName = useSelector(selectSelectedOrganizationName);
  const organizationId = useSelector(selectSelectedOrganizationId);

  const reportsUrl = useSelector(selectReportsUrl);
  const isLoading = useSelector(selectReportsIsLoading);

  useEffect(() => {
    if (isNil(organizationName)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.ANALYSIS}`));
    dispatch(getReportsUrl(organizationId));
  }, [dispatch, organizationName, organizationId]);

  if (isLoading && isNil(reportsUrl))
    return (
      <PageWrapper>
        <Loading />
      </PageWrapper>
    );

  if (!isLoading && isNil(reportsUrl))
    return (
      <PageWrapper className={classes.emptyContainer}>
        <Typography variant="h4" className={classes.emptyText}>
          Reports URL not found.
        </Typography>
      </PageWrapper>
    );

  return (
    <PageWrapper>
      <Box bgcolor={theme.palette.common.white} width="100%" height="100%">
        <iframe title="Reports" src={reportsUrl} style={IFRAME_STYLE} />
      </Box>
    </PageWrapper>
  );
};

const useStyles = makeStyles(theme => ({
  emptyContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  emptyText: {
    color: theme.palette.common.white,
  },
}));

export default Reports;
