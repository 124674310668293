import produce from 'immer';

import {
  GET_REPORTS_URL_FAILURE,
  GET_REPORTS_URL_REQUEST,
  GET_REPORTS_URL_SUCCESS,
} from 'modules/reports/reports.actions';

const initialState = {
  isLoading: false,
  reportsUrl: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS_URL_SUCCESS:
      draft.reportsUrl = action.response.url;
      draft.isLoading = false;

      break;

    case GET_REPORTS_URL_FAILURE:
      draft.isLoading = false;
      break;

    case GET_REPORTS_URL_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
