import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import LocationForm, { INITIAL_LOCATION } from 'modules/locations/locationForm.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleFormDialog from 'common/components/sportScaleFormDialog.component';
import {
  CREATE_ORGANIZATION_LOCATION_SUCCESS,
  createOrganizationLocation,
  getOrganizationLocations,
} from 'modules/locations/locations.actions';
import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import { getSubmitText } from 'utilities/form';
import { selectLocationsIsLoadingLocation } from 'modules/locations/locations.selectors';

const CreateLocationDialog = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();

  const isLoading = useSelector(selectLocationsIsLoadingLocation);

  const [location, setLocation] = useState(INITIAL_LOCATION);

  const submitText = useMemo(() => getSubmitText(isLoading, true), [isLoading]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setLocation({ ...location, [name]: value }), [
    location,
    setLocation,
  ]);

  const handleSubmitLocation = useCallback(() => {
    (async () => {
      const updatedLocation = { ...location };

      const response = await dispatch(createOrganizationLocation(organizationId, updatedLocation));

      if (response.type !== CREATE_ORGANIZATION_LOCATION_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.CREATE_LOCATION_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.CREATE_LOCATION_SUCCESS));

      await dispatch(getOrganizationLocations(organizationId));
      onClose(response.response);
    })();
  }, [dispatch, organizationId, location, onClose]);

  return (
    <SportScaleFormDialog
      submitButtonText={submitText}
      content={<LocationForm location={location} onChange={handleInputChange} />}
      fullWidth
      isLoading={isLoading}
      maxWidth="md"
      open={isOpen}
      title="Create Location"
      onClose={onClose}
      onSubmit={handleSubmitLocation}
    />
  );
};

CreateLocationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
};

export default CreateLocationDialog;
