import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "reports" gets changed
const selectReportsState = createSelector(
  state => state.reports,
  reportsState => reportsState
);

export const selectReportsUrl = createSelector(selectReportsState, reportsState => reportsState.reportsUrl);

export const selectReportsIsLoading = createSelector(selectReportsState, reportsState => reportsState.isLoading);
