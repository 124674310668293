import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import { MONTH_DAY_YEAR_FORMAT } from 'common/formFields/sportScaleDatePicker.component';

const SportScaleDateColumn = value => {
  const formattedDate = moment(value).format(MONTH_DAY_YEAR_FORMAT);

  return <Typography variant="subtitle1">{formattedDate}</Typography>;
};

export default SportScaleDateColumn;
