import PropTypes from 'prop-types';
import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import FIELD_LABELS from 'types/fieldLabels';
import SportScaleMaskedTextField from 'common/formFields/sportScaleMaskedTextField.component';
import SportScaleSelectFieldValidator from 'common/formFields/sportScaleSelectFieldValidator.component';
import SportScaleTextField from 'common/formFields/sportScaleTextField.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

const LETTERS_AND_NUMBERS_REGEX = /[A-Za-z0-9]/;
export const CREATE_LOCATION = 'CREATE_LOCATION';

const ScaleForm = ({ disabled, locations, scale, onChange }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Container className={classes.gridSpacing} spacing={2}>
        <Item sm={6}>
          <SportScaleTextFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(scale.name, FIELD_LABELS.NAME, [VALIDATION_TYPES.REQUIRED])}
            fullWidth
            label={FIELD_LABELS.NAME}
            name="name"
            validators={[VALIDATION_TYPES.REQUIRED]}
            value={scale.name}
            onChange={onChange}
          />
        </Item>
        <Item sm={6}>
          <SportScaleSelectFieldValidator
            disabled={disabled}
            fullWidth
            label={FIELD_LABELS.LOCATION}
            name="locationId"
            options={[
              { label: 'Create New Location', value: CREATE_LOCATION },
              ...locations.map(location => ({ label: location.name, value: location.id })),
            ]}
            type="text"
            value={scale.locationId}
            onChange={onChange}
          />
        </Item>
      </Container>
      <Container spacing={2}>
        <Item sm={6}>
          <SportScaleMaskedTextField
            disabled
            fullWidth
            label={FIELD_LABELS.SCALE_REGISTRATION_CODE}
            mask={[
              LETTERS_AND_NUMBERS_REGEX,
              LETTERS_AND_NUMBERS_REGEX,
              LETTERS_AND_NUMBERS_REGEX,
              '-',
              LETTERS_AND_NUMBERS_REGEX,
              LETTERS_AND_NUMBERS_REGEX,
              LETTERS_AND_NUMBERS_REGEX,
              '-',
              LETTERS_AND_NUMBERS_REGEX,
              LETTERS_AND_NUMBERS_REGEX,
              LETTERS_AND_NUMBERS_REGEX,
              LETTERS_AND_NUMBERS_REGEX,
            ]}
            maskPlaceholder="a1b-2c3-d4e5"
            name="identifier"
            value={scale.identifier}
            onChange={onChange}
          />
        </Item>
        <Item sm={6}>
          <SportScaleTextField
            disabled
            fullWidth
            label="Model Number"
            name="model"
            value={scale.model}
            onChange={onChange}
          />
        </Item>
      </Container>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  gridSpacing: {
    marginBottom: theme.spacing(1),
  },
}));

ScaleForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  scale: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default ScaleForm;
