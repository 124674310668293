import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { AsYouType, formatIncompletePhoneNumber } from 'libphonenumber-js';

import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { US_COUNTRY_CODE, VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

const SportScalePhoneValidator = memo(({ errorMessages, label, validators, value, onChange, ...rest }) => {
  const handleChange = useCallback(
    ({ target: { name, value: phoneNumber } }) => {
      // issue: https://github.com/catamphetamine/libphonenumber-js/issues/225
      let newValue = phoneNumber ? new AsYouType(US_COUNTRY_CODE).input(phoneNumber) : '';
      const newValueFormatted = formatIncompletePhoneNumber(value, US_COUNTRY_CODE);

      if (newValue === newValueFormatted && newValueFormatted.indexOf(phoneNumber) === 0) {
        newValue = newValue.slice(0, -1);
      }

      onChange({ target: { name, value: newValue } });
    },
    [value, onChange]
  );

  return (
    <SportScaleTextFieldValidator
      errorMessages={[...errorMessages, getValidationErrorMessages(value, label, [VALIDATION_TYPES.IS_PHONE])]}
      label={label}
      validators={[...validators, VALIDATION_TYPES.IS_PHONE]}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
});

SportScalePhoneValidator.propTypes = {
  classes: PropTypes.object,

  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array.isRequired,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScalePhoneValidator.defaultProps = {
  classes: {},
  disabled: false,
  disableBlurValidation: false,
  value: null,
  onBlur: () => {},
};

export default SportScalePhoneValidator;
