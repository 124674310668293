import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SportScaleSecondaryOutlinedButton = ({ className, children, ...restProps }) => {
  const classes = useStyles();

  return (
    <Button variant="outlined" color="primary" className={classNames(classes.root, className)} {...restProps}>
      {children}
    </Button>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    borderRadius: '0.2rem',
    // color: theme.palette.text.primary,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.primary.main,
    },
  },
}));

SportScaleSecondaryOutlinedButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

SportScaleSecondaryOutlinedButton.defaultProps = {
  className: null,
};

export default SportScaleSecondaryOutlinedButton;
