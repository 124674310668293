import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

/*
  This hook should be used when you are making an api request that you do not need the response.
  If we want to use this for a "await async" scenario we'll need to create another version of this that returns a promise
*/
const useApiRequest = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (apiRequest, success, errorMessage) => {
      const response = await dispatch(apiRequest());

      if (response.type !== success.type) {
        dispatch(handleToastMessage(errorMessage, TOAST_TYPES.ERROR));
        return;
      }

      if (success.message) dispatch(handleToastMessage(success.message));
    },
    [dispatch]
  );
};

export default useApiRequest;
