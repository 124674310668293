export const GENDER_MALE_VALUE = 'male';
export const GENDER_FEMALE_VALUE = 'female';
export const GENDER_NON_BINARY_VALUE = 'nonbinary';

export const GENDER_MALE_LABEL = 'Male';
export const GENDER_FEMALE_LABEL = 'Female';
export const GENDER_NON_BINARY_LABEL = 'Non-Binary';

export const GENDER_TABLE_FILTERS = {
  [GENDER_MALE_VALUE]: GENDER_MALE_LABEL,
  [GENDER_FEMALE_VALUE]: GENDER_FEMALE_LABEL,
  [GENDER_NON_BINARY_VALUE]: GENDER_NON_BINARY_LABEL,
};
