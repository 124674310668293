import produce from 'immer';
import * as Sentry from '@sentry/react';

import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from 'modules/profile/profile.actions';

const initialState = {
  isLoading: false,
  isSuperAdmin: false,
  profile: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      draft.profile = action.response;
      draft.isSuperAdmin = action.response.isSuperadmin;
      draft.isLoading = false;

      Sentry.setUser({ email: action.response.email });
      break;

    case GET_PROFILE_FAILURE:
    case UPDATE_PROFILE_FAILURE:
      draft.isLoading = false;
      break;

    case GET_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
