import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PageCard from 'common/components/pageCard.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import { logout } from 'modules/login/login.actions';
import { setInitialLoad } from 'modules/layout/layout.actions';
import { setSelectedOrganizationId } from 'modules/organizations/organizations.actions';

const ErrorPage = ({ content, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  const goHome = useCallback(() => {
    dispatch(setSelectedOrganizationId(null));
    dispatch(setInitialLoad(false));

    history.replace('/');
  }, [dispatch, history]);

  const handleLogout = useCallback(() => {
    dispatch(setSelectedOrganizationId(null));
    dispatch(logout());
  }, [dispatch]);

  const cardActions = useMemo(() => {
    const actions = [];

    if (!state.showGoHome && !state.showLogout) return null;

    if (state.showGoHome)
      actions.push(
        <SportScalePrimaryButton key="go-home" onClick={goHome}>
          go home
        </SportScalePrimaryButton>
      );

    if (state.showLogout)
      actions.push(
        <SportScalePrimaryButton key="logout" onClick={handleLogout}>
          logout
        </SportScalePrimaryButton>
      );

    return actions;
  }, [state.showGoHome, state.showLogout, goHome, handleLogout]);

  return (
    <PageWrapper className={classes.pageWrapper}>
      <PageCard
        content={
          <>
            <ErrorOutlineIcon className={classes.icon} />
            <Typography variant="body1" component="p">
              {state.content || content}
            </Typography>
          </>
        }
        title={state.title || title}
        actions={cardActions}
      />
    </PageWrapper>
  );
};

const useStyles = makeStyles((/* theme */) => ({
  pageWrapper: {
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 0,
    width: '100%',
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '2rem',
  },
}));

ErrorPage.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
};

ErrorPage.defaultProps = {
  content: '',
  title: '',
};

export default ErrorPage;
