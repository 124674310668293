import PropTypes from 'prop-types';
import React, { memo } from 'react';

import SportScaleCustomValidator from 'common/formFields/sportScaleCustomValidator.component';
import SportScaleHeightField from 'common/formFields/sportScaleHeightField.component';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

const SportScaleHeightFieldValidator = memo(
  ({ disableBlurValidation, errorMessages, label, name, validators, value, onBlur, onChange, ...restProps }) => (
    <SportScaleCustomValidator
      disableBlurValidation={disableBlurValidation}
      errorMessages={[
        ...errorMessages,
        `${label} needs to be a positive value`,
        getValidationErrorMessages(value, label, [VALIDATION_TYPES.VALID_HEIGHT]),
      ]}
      label={label}
      name={name}
      validators={[...validators, VALIDATION_TYPES.IS_POSITIVE, VALIDATION_TYPES.VALID_HEIGHT]}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      <SportScaleHeightField {...restProps} />
    </SportScaleCustomValidator>
  )
);

SportScaleHeightFieldValidator.propTypes = {
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScaleHeightFieldValidator.defaultProps = {
  disableBlurValidation: false,
  disabled: false,
  errorMessages: [],
  onBlur: () => {},
  validators: [],
  value: '',
};

export default SportScaleHeightFieldValidator;
