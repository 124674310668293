import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DialogContentText, makeStyles } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import LocationForm, { INITIAL_LOCATION } from 'modules/locations/locationForm.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleBreadCrumbs from 'modules/layout/breadCrumbs/breadCrumbs.component';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import {
  CREATE_ORGANIZATION_LOCATION_SUCCESS,
  DELETE_ORGANIZATION_LOCATION_SUCCESS,
  UPDATE_ORGANIZATION_LOCATION_SUCCESS,
  createOrganizationLocation,
  deleteOrganizationLocation,
  getOrganizationLocation,
  resetOrganizationSelectedLocation,
  updateOrganizationLocation,
} from 'modules/locations/locations.actions';
import { LOCATION_ID_MAP, ROUTES, generateBreadCrumbMap, generateRouteParamsMap } from 'utilities/breadCrumbs';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getSubmitText } from 'utilities/form';

const LocationContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const browserLocation = useLocation();
  const { organizationId, locationId } = useParams();

  const { isLoading, isLoadingLocation, organization, selectedLocation } = useSelector(
    ({ organizations, locations }) => ({
      isLoading: organizations.isLoading || locations.isLoading,
      isLoadingLocation: locations.isLoadingLocation,
      organization: organizations.organization,
      selectedLocation: locations.selectedLocation,
    })
  );

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [location, setLocation] = useState(null);

  const isNewLocation = locationId === NEW_ITEM_ROUTE;

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationSelectedLocation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isNil(organization)) return;

    dispatch(setPageTitle(`${organization.name} - ${PAGE_TITLES.LOCATIONS}`));

    if (isNewLocation) {
      setLocation(INITIAL_LOCATION);
      return;
    }

    if (!isEmpty(browserLocation.state)) {
      const { location: browserLocationLocation } = browserLocation.state;

      setLocation({
        ...browserLocationLocation,
      });
      return;
    }

    (async () => {
      const { response } = await dispatch(getOrganizationLocation(organization.id, locationId));

      setLocation({
        ...response,
      });
    })();
  }, [dispatch, browserLocation, isNewLocation, organization, locationId]);

  const handleDeleteLocation = useCallback(() => {
    (async () => {
      const response = await dispatch(deleteOrganizationLocation(organization.id, locationId));

      setRemoveDialogOpen(false);

      if (response.type !== DELETE_ORGANIZATION_LOCATION_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_LOCATION_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_LOCATION_SUCCESS));
      history.replace(`/organizations/${organization.id}/locations`);
    })();
  }, [dispatch, history, locationId, organization]);

  const handleSubmitLocation = useCallback(() => {
    (async () => {
      let response;

      const updatedLocation = { ...location };

      if (isNewLocation) {
        response = await dispatch(createOrganizationLocation(organizationId, updatedLocation));
      } else {
        delete updatedLocation.tableData;
        response = await dispatch(updateOrganizationLocation(organizationId, updatedLocation));
      }

      if (
        response.type !== CREATE_ORGANIZATION_LOCATION_SUCCESS &&
        response.type !== UPDATE_ORGANIZATION_LOCATION_SUCCESS
      ) {
        const errorMessage = isNewLocation
          ? ERROR_MESSAGES.CREATE_LOCATION_FAILURE
          : ERROR_MESSAGES.UPDATE_LOCATION_FAILURE;

        dispatch(handleToastMessage(errorMessage, TOAST_TYPES.ERROR));
        return;
      }

      const successMessage = isNewLocation
        ? SUCCESS_MESSAGES.CREATE_LOCATION_SUCCESS
        : SUCCESS_MESSAGES.UPDATE_LOCATION_SUCCESS;

      dispatch(handleToastMessage(successMessage));
      history.push(`/organizations/${organizationId}/locations`);
    })();
  }, [dispatch, history, isNewLocation, organizationId, location]);

  useEffect(() => {
    if (!isEmpty(selectedLocation)) {
      setLocation({
        ...selectedLocation,
      });
    }
  }, [selectedLocation]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setLocation({ ...location, [name]: value }), [
    location,
    setLocation,
  ]);

  const submitText = useMemo(() => getSubmitText(isLoadingLocation, isNewLocation), [isLoadingLocation, isNewLocation]);

  const breadCrumbs = useMemo(() => {
    if (!isNil(location)) {
      let locationName = isNewLocation && 'create';

      if (!isNewLocation && !isEmpty(browserLocation.state)) locationName = browserLocation.state.location.name;
      if (!isNewLocation && !isNil(selectedLocation)) locationName = selectedLocation.name;

      return generateBreadCrumbMap(generateRouteParamsMap(LOCATION_ID_MAP, [organizationId, locationId]), {
        [ROUTES.LOCATIONS_ROUTE]: PAGE_TITLES.LOCATIONS,
        [isNewLocation ? ROUTES.NEW_LOCATION_ROUTE : ROUTES.LOCATION_ROUTE]: locationName,
      });
    }

    return {};
  }, [browserLocation, isNewLocation, organizationId, selectedLocation, location, locationId]);

  if (isLoading || isNil(location))
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  const DISABLE_CHANGE =
    isLoadingLocation || isNewLocation ? !can(PERMISSIONS.CREATE_LOCATIONS) : !can(PERMISSIONS.EDIT_LOCATIONS);

  return (
    <>
      <PageWrapper className={classes.pageWrapper}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <SportScaleBreadCrumbs breadCrumbs={breadCrumbs} />
          {!isNewLocation && can(PERMISSIONS.REMOVE_LOCATIONS) && (
            <SportScalePrimaryButton onClick={() => setRemoveDialogOpen(true)}>remove location</SportScalePrimaryButton>
          )}
        </Box>
        <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitLocation}>
          <LocationForm disabled={DISABLE_CHANGE} location={location} onChange={handleInputChange} />

          <Box alignItems="flex-end" display="flex" flex={1} justifyContent="space-between" width="100%">
            <SportScaleSecondaryButton
              disabled={isLoadingLocation}
              onClick={() => history.push(`/organizations/${organizationId}/locations`)}
            >
              cancel
            </SportScaleSecondaryButton>
            <SportScalePrimaryButton disabled={DISABLE_CHANGE} type="submit">
              {submitText}
            </SportScalePrimaryButton>
          </Box>
        </ValidatorForm>
      </PageWrapper>
      {!isNil(location) && (
        <SportScaleDialog
          actions={[
            {
              action: () => {
                setRemoveDialogOpen(false);
              },
              disabled: isLoadingLocation,
              text: 'Cancel',
            },
            {
              action: handleDeleteLocation,
              disabled: isLoadingLocation,
              isPrimary: true,
              text: isLoadingLocation ? 'Removing...' : 'Remove',
            },
          ]}
          content={
            <DialogContentText>
              Are you sure you want to remove {location.name} from this organization?
            </DialogContentText>
          }
          open={removeDialogOpen}
          title="Remove Location?"
          onClose={() => {
            setRemoveDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  title: {
    alignSelf: 'flex-start',
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
}));

LocationContainer.propTypes = {};

export default LocationContainer;
