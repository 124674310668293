import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Breadcrumbs, Button, Icon, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const SportScaleBreadCrumbs = ({ breadCrumbs }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToBreadCrumb = useCallback(to => history.push(to), [history]);

  const breadCrumbRoutes = useMemo(() => Object.keys(breadCrumbs), [breadCrumbs]);

  return (
    <Breadcrumbs>
      {breadCrumbRoutes.map((to, i) => {
        const { icon, text } = breadCrumbs[to];
        const isLastItem = i + 1 === breadCrumbRoutes.length;

        return (
          <Button
            classes={{ root: classNames({ [classes.lastButton]: isLastItem }), iconSizeSmall: classes.iconSmall }}
            disableRipple
            key={to}
            size="small"
            startIcon={<Icon classes={{ root: classes.iconRoot }} className={icon} />}
            onClick={() => goToBreadCrumb(to)}
          >
            {text}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
};

const useStyles = makeStyles(theme => ({
  lastButton: {
    color: theme.palette.secondary.light,
  },
  breadCrumbButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
    },
  },
  iconSmall: {
    fontSize: '1rem',
  },
  iconRoot: {
    paddingRight: theme.spacing(1) / 2,
  },
}));

SportScaleBreadCrumbs.propTypes = {
  breadCrumbs: PropTypes.object.isRequired,
};

export default SportScaleBreadCrumbs;
