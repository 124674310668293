import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATION_ATHLETES_REQUEST = 'GET_ORGANIZATION_ATHLETES_REQUEST';
export const GET_ORGANIZATION_ATHLETES_SUCCESS = 'GET_ORGANIZATION_ATHLETES_SUCCESS';
export const GET_ORGANIZATION_ATHLETES_FAILURE = 'GET_ORGANIZATION_ATHLETES_FAILURE';

export const UPDATE_ORGANIZATION_ATHLETE_REQUEST = 'UPDATE_ORGANIZATION_ATHLETE_REQUEST';
export const UPDATE_ORGANIZATION_ATHLETE_SUCCESS = 'UPDATE_ORGANIZATION_ATHLETE_SUCCESS';
export const UPDATE_ORGANIZATION_ATHLETE_FAILURE = 'UPDATE_ORGANIZATION_ATHLETE_FAILURE';

export const GET_ORGANIZATION_ATHLETE_REQUEST = 'GET_ORGANIZATION_ATHLETE_REQUEST';
export const GET_ORGANIZATION_ATHLETE_SUCCESS = 'GET_ORGANIZATION_ATHLETE_SUCCESS';
export const GET_ORGANIZATION_ATHLETE_FAILURE = 'GET_ORGANIZATION_ATHLETE_FAILURE';

export const CREATE_ORGANIZATION_ATHLETE_REQUEST = 'CREATE_ORGANIZATION_ATHLETE_REQUEST';
export const CREATE_ORGANIZATION_ATHLETE_SUCCESS = 'CREATE_ORGANIZATION_ATHLETE_SUCCESS';
export const CREATE_ORGANIZATION_ATHLETE_FAILURE = 'CREATE_ORGANIZATION_ATHLETE_FAILURE';

export const DELETE_ORGANIZATION_ATHLETE_REQUEST = 'DELETE_ORGANIZATION_ATHLETE_REQUEST';
export const DELETE_ORGANIZATION_ATHLETE_SUCCESS = 'DELETE_ORGANIZATION_ATHLETE_SUCCESS';
export const DELETE_ORGANIZATION_ATHLETE_FAILURE = 'DELETE_ORGANIZATION_ATHLETE_FAILURE';

export const DELETE_ORGANIZATION_ATHLETE_FACE_DATA_REQUEST = 'DELETE_ORGANIZATION_ATHLETE_FACE_DATA_REQUEST';
export const DELETE_ORGANIZATION_ATHLETE_FACE_DATA_SUCCESS = 'DELETE_ORGANIZATION_ATHLETE_FACE_DATA_SUCCESS';
export const DELETE_ORGANIZATION_ATHLETE_FACE_DATA_FAILURE = 'DELETE_ORGANIZATION_ATHLETE_FACE_DATA_FAILURE';

export const RESET_ORGANIZATION_ATHLETES = 'RESET_ORGANIZATION_ATHLETES';

export const SET_SELECTED_ATHLETE = 'SET_SELECTED_ATHLETE';

export const GET_TARGET_WEIGHTS_REQUEST = 'GET_TARGET_WEIGHTS_REQUEST';
export const GET_TARGET_WEIGHTS_SUCCESS = 'GET_TARGET_WEIGHTS_SUCCESS';
export const GET_TARGET_WEIGHTS_FAILURE = 'GET_TARGET_WEIGHTS_FAILURE';

export const ADD_TARGET_WEIGHT_REQUEST = 'ADD_TARGET_WEIGHT_REQUEST';
export const ADD_TARGET_WEIGHT_SUCCESS = 'ADD_TARGET_WEIGHT_SUCCESS';
export const ADD_TARGET_WEIGHT_FAILURE = 'ADD_TARGET_WEIGHT_FAILURE';

export const UPDATE_TARGET_WEIGHT_REQUEST = 'UPDATE_TARGET_WEIGHT_REQUEST';
export const UPDATE_TARGET_WEIGHT_SUCCESS = 'UPDATE_TARGET_WEIGHT_SUCCESS';
export const UPDATE_TARGET_WEIGHT_FAILURE = 'UPDATE_TARGET_WEIGHT_FAILURE';

export const DELETE_TARGET_WEIGHT_REQUEST = 'DELETE_TARGET_WEIGHT_REQUEST';
export const DELETE_TARGET_WEIGHT_SUCCESS = 'DELETE_TARGET_WEIGHT_SUCCESS';
export const DELETE_TARGET_WEIGHT_FAILURE = 'DELETE_TARGET_WEIGHT_FAILURE';

export const getOrganizationAthletes = organizationId => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_ATHLETES_REQUEST, GET_ORGANIZATION_ATHLETES_SUCCESS, GET_ORGANIZATION_ATHLETES_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes`,
      method: 'GET',
    },
  };
};

export const updateOrganizationAthlete = (organizationId, athlete) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_ORGANIZATION_ATHLETE_REQUEST,
        UPDATE_ORGANIZATION_ATHLETE_SUCCESS,
        UPDATE_ORGANIZATION_ATHLETE_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athlete.id}`,
      method: 'PUT',
      payload: athlete,
    },
  };
};

export const getOrganizationAthlete = (organizationId, athleteId) => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_ATHLETE_REQUEST, GET_ORGANIZATION_ATHLETE_SUCCESS, GET_ORGANIZATION_ATHLETE_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athleteId}`,
      method: 'GET',
    },
  };
};

export const createOrganizationAthlete = (organizationId, athlete) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_ORGANIZATION_ATHLETE_REQUEST,
        CREATE_ORGANIZATION_ATHLETE_SUCCESS,
        CREATE_ORGANIZATION_ATHLETE_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes`,
      method: 'POST',
      payload: athlete,
    },
  };
};

export const deleteOrganizationAthlete = (organizationId, athleteId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_ORGANIZATION_ATHLETE_REQUEST,
        DELETE_ORGANIZATION_ATHLETE_SUCCESS,
        DELETE_ORGANIZATION_ATHLETE_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athleteId}`,
      method: 'DELETE',
    },
  };
};

export const deleteOrganizationAthleteFaceData = (organizationId, athleteId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_ORGANIZATION_ATHLETE_FACE_DATA_REQUEST,
        DELETE_ORGANIZATION_ATHLETE_FACE_DATA_SUCCESS,
        DELETE_ORGANIZATION_ATHLETE_FACE_DATA_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athleteId}/face`,
      method: 'DELETE',
    },
  };
};

export const resetOrganizationAthletes = () => {
  return {
    type: RESET_ORGANIZATION_ATHLETES,
  };
};

export const setSelectedAthlete = (athlete = null) => {
  return {
    type: SET_SELECTED_ATHLETE,
    payload: athlete,
  };
};

export const getTargetWeights = (organizationId, athleteId) => {
  return {
    [CALL_API]: {
      types: [GET_TARGET_WEIGHTS_REQUEST, GET_TARGET_WEIGHTS_SUCCESS, GET_TARGET_WEIGHTS_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athleteId}/targetweights`,
      method: 'GET',
    },
  };
};

export const addTargetWeight = (organizationId, athleteId, target) => {
  return {
    [CALL_API]: {
      types: [ADD_TARGET_WEIGHT_REQUEST, ADD_TARGET_WEIGHT_SUCCESS, ADD_TARGET_WEIGHT_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athleteId}/targetweights`,
      method: 'POST',
      payload: target,
    },
  };
};

export const updateTargetWeight = (organizationId, athleteId, targetId, target) => {
  return {
    [CALL_API]: {
      types: [UPDATE_TARGET_WEIGHT_REQUEST, UPDATE_TARGET_WEIGHT_SUCCESS, UPDATE_TARGET_WEIGHT_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athleteId}/targetweights/${targetId}`,
      method: 'PUT',
      payload: target,
    },
  };
};

export const deleteTargetWeight = (organizationId, athleteId, targetId) => {
  return {
    [CALL_API]: {
      types: [DELETE_TARGET_WEIGHT_REQUEST, DELETE_TARGET_WEIGHT_SUCCESS, DELETE_TARGET_WEIGHT_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/athletes/${athleteId}/targetweights/${targetId}`,
      method: 'DELETE',
    },
  };
};
