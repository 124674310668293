import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Box, ClickAwayListener, Icon, MenuItem, Popover, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import SportScaleFixedAutoComplete from 'common/formFields/sportScaleFixedAutoComplete.component';
import { selectOrganizations } from 'modules/organizations/organizations.selectors';
import { selectProfileIsSuperAdmin } from 'modules/profile/profile.selectors';

const HeaderOrganizationsMenu = ({ anchorEl, onCreateOrganizationClick, onMenuClose, onOrganizationItemClick }) => {
  const classes = useStyles();

  const organizations = useSelector(selectOrganizations);
  const isSuperAdmin = useSelector(selectProfileIsSuperAdmin);

  const handleFilterOrganizations = useCallback(
    value => organizations.filter(organization => organization.name.toLowerCase().includes(value.toLowerCase())),
    [organizations]
  );

  return (
    <Popover open={Boolean(anchorEl)} anchorEl={anchorEl}>
      <ClickAwayListener onClickAway={onMenuClose}>
        <Box pl={1} pr={1}>
          <SportScaleFixedAutoComplete
            items={organizations}
            noResultsMessage="No Organizations Found"
            placeholder="Search Organizations"
            onFilter={handleFilterOrganizations}
            onItemClick={onOrganizationItemClick}
          />
          {isSuperAdmin && (
            <MenuItem key="create-org" onClick={onCreateOrganizationClick}>
              <Icon className={classNames(classes.iconStyle, 'fal fa-plus')} />
              Create Organization
            </MenuItem>
          )}
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};

const useStyles = makeStyles(theme => ({
  iconStyle: {
    fontSize: '1.25rem',
    marginRight: theme.spacing(1) / 2,
  },
}));

HeaderOrganizationsMenu.propTypes = {
  anchorEl: PropTypes.object,

  onCreateOrganizationClick: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onOrganizationItemClick: PropTypes.func.isRequired,
};

HeaderOrganizationsMenu.defaultProps = {
  anchorEl: null,
};

export default HeaderOrganizationsMenu;
