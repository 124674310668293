import produce from 'immer';

import {
  ADD_TARGET_WEIGHT_FAILURE,
  ADD_TARGET_WEIGHT_REQUEST,
  ADD_TARGET_WEIGHT_SUCCESS,
  CREATE_ORGANIZATION_ATHLETE_FAILURE,
  CREATE_ORGANIZATION_ATHLETE_REQUEST,
  CREATE_ORGANIZATION_ATHLETE_SUCCESS,
  DELETE_ORGANIZATION_ATHLETE_FAILURE,
  DELETE_ORGANIZATION_ATHLETE_REQUEST,
  DELETE_ORGANIZATION_ATHLETE_SUCCESS,
  DELETE_TARGET_WEIGHT_FAILURE,
  DELETE_TARGET_WEIGHT_REQUEST,
  DELETE_TARGET_WEIGHT_SUCCESS,
  GET_ORGANIZATION_ATHLETES_FAILURE,
  GET_ORGANIZATION_ATHLETES_REQUEST,
  GET_ORGANIZATION_ATHLETES_SUCCESS,
  GET_ORGANIZATION_ATHLETE_FAILURE,
  GET_ORGANIZATION_ATHLETE_REQUEST,
  GET_ORGANIZATION_ATHLETE_SUCCESS,
  GET_TARGET_WEIGHTS_FAILURE,
  GET_TARGET_WEIGHTS_REQUEST,
  GET_TARGET_WEIGHTS_SUCCESS,
  RESET_ORGANIZATION_ATHLETES,
  SET_SELECTED_ATHLETE,
  UPDATE_ORGANIZATION_ATHLETE_FAILURE,
  UPDATE_ORGANIZATION_ATHLETE_REQUEST,
  UPDATE_ORGANIZATION_ATHLETE_SUCCESS,
  UPDATE_TARGET_WEIGHT_FAILURE,
  UPDATE_TARGET_WEIGHT_REQUEST,
  UPDATE_TARGET_WEIGHT_SUCCESS,
} from 'modules/athletes/athletes.actions';

const initialState = {
  isLoadingAthletes: false,
  isLoadingAthlete: false,
  athletes: null,
  selectedAthlete: null,
  selectedAthleteTargetWeights: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_ATHLETES_SUCCESS:
      draft.athletes = action.response;
      draft.isLoadingAthletes = false;
      break;

    case GET_ORGANIZATION_ATHLETES_FAILURE:
      draft.isLoadingAthletes = false;
      break;

    case GET_ORGANIZATION_ATHLETE_SUCCESS:
      draft.selectedAthlete = action.response;
      draft.isLoadingAthlete = false;
      break;

    case GET_ORGANIZATION_ATHLETES_REQUEST:
      draft.isLoadingAthletes = true;
      break;

    case RESET_ORGANIZATION_ATHLETES:
      draft.athletes = null;
      break;

    case UPDATE_ORGANIZATION_ATHLETE_SUCCESS:
      draft.isLoadingAthlete = false;
      break;

    case DELETE_ORGANIZATION_ATHLETE_FAILURE:
    case ADD_TARGET_WEIGHT_FAILURE:
    case CREATE_ORGANIZATION_ATHLETE_FAILURE:
    case DELETE_TARGET_WEIGHT_FAILURE:
    case GET_ORGANIZATION_ATHLETE_FAILURE:
    case GET_TARGET_WEIGHTS_FAILURE:
    case UPDATE_ORGANIZATION_ATHLETE_FAILURE:
    case UPDATE_TARGET_WEIGHT_FAILURE:
      draft.isLoadingAthlete = false;
      break;

    case CREATE_ORGANIZATION_ATHLETE_REQUEST:
    case DELETE_ORGANIZATION_ATHLETE_REQUEST:
    case ADD_TARGET_WEIGHT_REQUEST:
    case DELETE_TARGET_WEIGHT_REQUEST:
    case GET_ORGANIZATION_ATHLETE_REQUEST:
    case GET_TARGET_WEIGHTS_REQUEST:
    case UPDATE_ORGANIZATION_ATHLETE_REQUEST:
    case UPDATE_TARGET_WEIGHT_REQUEST:
      draft.isLoadingAthlete = true;
      break;

    case ADD_TARGET_WEIGHT_SUCCESS:
    case CREATE_ORGANIZATION_ATHLETE_SUCCESS:
    case DELETE_ORGANIZATION_ATHLETE_SUCCESS:
    case DELETE_TARGET_WEIGHT_SUCCESS:
    case UPDATE_TARGET_WEIGHT_SUCCESS:
      draft.isLoadingAthlete = false;
      break;

    case GET_TARGET_WEIGHTS_SUCCESS:
      draft.selectedAthleteTargetWeights = action.response;
      draft.isLoadingAthlete = false;
      break;

    case SET_SELECTED_ATHLETE:
      draft.selectedAthlete = action.payload;
      break;

    default:
      break;
  }
}, initialState);
