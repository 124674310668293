import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { isNil } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import WatchlistCard from 'modules/watchlist/watchlistCard.component';
import WeightsDialog from 'modules/athletes/weights/weightsDialog.component';
import useApiRequest from 'hooks/apiRequest';
import { Container, Item } from 'common/components/grid.component';
import { GET_ORGANIZATION_ATHLETE_SUCCESS, getOrganizationAthlete } from 'modules/athletes/athletes.actions';
import { GET_ORGANIZATION_TEAM_SUCCESS, getOrganizationTeam } from 'modules/teams/teams.actions';
import { GET_ORGANIZATION_WATCHLIST_SUCCESS, getOrganizationWatchlist } from 'modules/watchlist/watchlist.actions';
import { GET_TEAM_SEASON_SUCCESS, getTeamSeason } from 'modules/teams/seasons/seasons.actions';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import {
  selectOrganizationsIsLoadingOrganization,
  selectSelectedOrganizationId,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';
import { selectWatchlist, selectWatchlistIsLoading } from 'modules/watchlist/watchlist.selectors';

const WatchListContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const makeRequest = useApiRequest();

  const watchlist = useSelector(selectWatchlist);
  const isLoadingWatchlist = useSelector(selectWatchlistIsLoading);
  const organizationId = useSelector(selectSelectedOrganizationId);

  const isLoadingOrganization = useSelector(selectOrganizationsIsLoadingOrganization);
  const organizationName = useSelector(selectSelectedOrganizationName);

  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [chartData, setChartData] = useState({});

  const onCloseDialog = () => {
    setChartData({});
    setSelectedAthlete(null);
  };

  useEffect(() => {
    if (isNil(organizationName)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.CURRENT_ACTIVITY}`));
    makeRequest(
      () => getOrganizationWatchlist(organizationId),
      { type: GET_ORGANIZATION_WATCHLIST_SUCCESS },
      ERROR_MESSAGES.GET_ORGANIZATION_TEAMS_FAILURE
    );
  }, [dispatch, makeRequest, organizationId, organizationName]);

  const onViewChart = useCallback(
    async (athlete, item) => {
      // Open the dialog immediately. Until the data is set later, the dialog will show a spinner.
      setSelectedAthlete(athlete);

      // Fetch the athlete data
      const athleteResult = await dispatch(getOrganizationAthlete(organizationId, athlete.id));
      if (athleteResult.type !== GET_ORGANIZATION_ATHLETE_SUCCESS) {
        dispatch(handleToastMessage('Error retrieving athlete chart data', TOAST_TYPES.ERROR));
        // Close the dialog by clearing the selected athlete
        dispatch(setSelectedAthlete(null));
        return;
      }
      const athleteWithWeights = { ...athlete, ...athleteResult.response };

      // Fetch the roster data
      const seasonResult = await dispatch(getTeamSeason(organizationId, item.teamId, item.rosterId));
      if (seasonResult.type !== GET_TEAM_SEASON_SUCCESS) {
        dispatch(handleToastMessage('Error fetching athlete chart data', TOAST_TYPES.ERROR));
        // Close the dialog by clearing the selected athlete
        dispatch(setSelectedAthlete(null));
        return;
      }
      const { enableHydrationTracking } = seasonResult.response;

      // Fetch the team data
      const teamResult = await dispatch(getOrganizationTeam(organizationId, item.teamId));
      if (teamResult.type !== GET_ORGANIZATION_TEAM_SUCCESS) {
        dispatch(handleToastMessage('Error getting athlete chart data', TOAST_TYPES.ERROR));
        // Close the dialog by clearing the selected athlete
        dispatch(setSelectedAthlete(null));
        return;
      }
      const team = {
        ...teamResult.response,
      };

      // Populate the chart.
      setChartData({
        athlete: athleteWithWeights,
        enableHydrationTracking,
        team,
      });
    },
    [dispatch, organizationId]
  );

  const navigateToTeams = () => {
    history.push(`/organizations/${organizationId}/teams`);
  };

  if (isLoadingOrganization || isLoadingWatchlist)
    return (
      <PageWrapper>
        <Loading />
      </PageWrapper>
    );

  return (
    <PageWrapper>
      <Box mb={2} width="100%">
        <Container>
          {watchlist.map(item => (
            <Item lg={4} md={6} xs={12} key={`${item.teamId}-${item.rosterId}`}>
              <WatchlistCard item={item} onClick={athlete => onViewChart(athlete, item)} />
            </Item>
          ))}
          {watchlist.length === 0 && (
            <Box className={classes.noInSeasonContainer}>
              <Typography className={classes.noInSeasonSportsText}>
                There are currently no sports with active seasons
              </Typography>
              <SportScalePrimaryButton onClick={navigateToTeams}>View Sports</SportScalePrimaryButton>
            </Box>
          )}
        </Container>
      </Box>
      <WeightsDialog
        athlete={chartData.athlete}
        enableHydrationTracking={chartData.enableHydrationTracking}
        team={chartData.team}
        open={!isNil(selectedAthlete)}
        onClose={onCloseDialog}
      />
    </PageWrapper>
  );
};

const useStyles = makeStyles(theme => ({
  noInSeasonContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  noInSeasonSportsText: {
    color: 'white',
    marginBottom: theme.spacing(3),
  },
}));

WatchListContainer.propTypes = {};

WatchListContainer.defaultProps = {
  organization: null,
};

export default WatchListContainer;
