import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';

const SportScaleDarkBGSecondaryButton = ({ children, className, onClick, ...restProps }) => {
  const classes = useStyles();

  return (
    <SportScaleSecondaryButton className={classNames(className, classes.button)} onClick={onClick} {...restProps}>
      {children}
    </SportScaleSecondaryButton>
  );
};

SportScaleDarkBGSecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,

  onClick: PropTypes.func,
};

SportScaleDarkBGSecondaryButton.defaultProps = {
  className: '',

  onClick: () => {},
};

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default SportScaleDarkBGSecondaryButton;
