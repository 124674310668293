import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Box, Button, IconButton, Snackbar, Typography, makeStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { TOAST_TYPES, hideToast } from 'modules/layout/layout.actions';

const MIN_TOAST_WIDTH = 300;

const Toast = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { detailMessage, isShowToast, toastMessage, toastType } = useSelector(({ layout }) => ({
    detailMessage: layout.detailMessage,
    isShowToast: layout.isShowToast,
    toastMessage: layout.toastMessage,
    toastType: layout.toastType,
  }));

  const [isShowDetailMessage, setIsShowDetailMessage] = useState(false);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isShowToast}
      onClose={hideToast}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: classNames(
          { [classes.error]: toastType === TOAST_TYPES.ERROR },
          { [classes.warning]: toastType === TOAST_TYPES.WARNING },
          { [classes.success]: toastType === TOAST_TYPES.SUCCESS }
        ),
      }}
      message={
        <Box alignItems="center" display="flex" minWidth={MIN_TOAST_WIDTH} id="message-id">
          <Box flex={1}>
            <Typography className={classes.message} variant="subtitle2">
              {toastMessage}
            </Typography>
            {isShowDetailMessage && (
              <Typography className={classes.messageDetail} variant="subtitle2">
                {detailMessage}
              </Typography>
            )}
          </Box>
          {!isEmpty(detailMessage) && (
            <Box alignItems="center" display="flex" justifyContent="flex-end">
              <Button
                className={classes.action}
                onClick={() => setIsShowDetailMessage(!isShowDetailMessage)}
                variant="outlined"
              >
                {isShowDetailMessage ? 'Less' : 'More'}
              </Button>
            </Box>
          )}
        </Box>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={() => dispatch(hideToast())}>
          <CloseIcon />
        </IconButton>,
      ]}
      ClickAwayListenerProps={{
        // this is set here to prevent users from being able to clickaway the toast
        // potentially should add a prop to allow modifying this
        onClickAway: () => {},
      }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  messageDetail: {
    marginTop: theme.spacing(1),
  },
  action: {
    color: 'white',
    marginLeft: '1rem',
  },
}));

Toast.propTypes = {};

export default Toast;
