import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Box, Icon, Typography, makeStyles, useTheme } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SportScaleDateColumn from 'common/table/sportScaleDateColumn.component';
import SportScaleTable from 'common/table/sportScaleTable.component';
import SportScaleTableActionButton from 'common/table/sportScaleTableActionButton.component';
import SportScaleTableToolbar from 'common/table/sportScaleTableToolbar.component';
import {
  CURRENT_EXPIRING_LABEL,
  CURRENT_EXPIRING_VALUE,
  CURRENT_LABEL,
  CURRENT_VALUE,
  EXPIRED_LABEL,
  EXPIRED_VALUE,
  STARTING_SOON_LABEL,
  STARTING_SOON_VALUE,
  SUBSCRIPTION_EXPIRED_MONTHS,
  SUBSCRIPTION_EXPIRING_SOON_MONTHS,
  TABLE_SUBSCRIPTIONS_FILTERS,
} from 'utilities/subscriptions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { selectProfileIsSuperAdmin } from 'modules/profile/profile.selectors';
import {
  selectSubscriptions,
  selectSubscriptionsIsLoadingSubscriptions,
} from 'modules/organizations/subscriptions/subscriptions.selectors';

const Subscriptions = memo(() => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const isLoadingSubscriptions = useSelector(selectSubscriptionsIsLoadingSubscriptions);
  const subscriptions = useSelector(selectSubscriptions);

  const isSuperAdmin = useSelector(selectProfileIsSuperAdmin);

  const columns = useMemo(
    () => [
      {
        customFilterAndSearch: (term, rowData) => {
          const keep = isEmpty(term);

          if (keep) return keep;

          const isCurrentSubscription = moment().isBetween(moment(rowData.startDate), moment(rowData.endDate));
          const isExpiring = moment().diff(moment(rowData.endDate), 'months', true) < SUBSCRIPTION_EXPIRING_SOON_MONTHS;
          const isInFuture = moment().isBefore(rowData.startDate);

          if (term.includes(STARTING_SOON_VALUE) && isInFuture) return true;
          if (term.includes(CURRENT_VALUE) && isCurrentSubscription && !isExpiring) return true;
          if (term.includes(CURRENT_EXPIRING_VALUE) && isCurrentSubscription && isExpiring) return true;

          return term.includes(EXPIRED_VALUE) && !isCurrentSubscription && !isInFuture;
        },
        lookup: TABLE_SUBSCRIPTIONS_FILTERS,
        render: rowData => {
          const isCurrentSubscription = moment().isBetween(moment(rowData.startDate), moment(rowData.endDate));
          const isExpiring = moment().diff(moment(rowData.endDate), 'months', true) > SUBSCRIPTION_EXPIRING_SOON_MONTHS;
          const isExpired = moment().diff(moment(rowData.endDate), 'months', true) > SUBSCRIPTION_EXPIRED_MONTHS;

          let columnText = CURRENT_LABEL;
          let iconColor = theme.palette.primary.main;

          if (isCurrentSubscription && isExpiring) {
            columnText = CURRENT_EXPIRING_LABEL;
            iconColor = theme.palette.warning.main;
          }

          if (isExpired) {
            columnText = EXPIRED_LABEL;
            iconColor = theme.palette.error.main;
          }

          if (!isExpired && !isCurrentSubscription) {
            columnText = STARTING_SOON_LABEL;
            iconColor = theme.palette.success.main;
          }

          return (
            <Box display="flex" alignItems="center">
              <Icon style={{ color: iconColor }} className={classNames('fal fa-history', classes.subStatusIcon)} />
              <Typography variant="subtitle1">{columnText}</Typography>
            </Box>
          );
        },
        title: 'Subscription Status',
      },
      {
        title: 'Start Date',
        field: 'startDate',
        render: rowData => SportScaleDateColumn(rowData.startDate),
      },
      {
        title: 'End Date',
        field: 'endDate',
        render: rowData => SportScaleDateColumn(rowData.endDate),
      },
    ],
    [classes, theme]
  );

  const goToCreateSubscription = useCallback(
    () => history.push(`${location.pathname}/subscriptions/${NEW_ITEM_ROUTE}`),
    [history, location]
  );

  return (
    <Box mb={2} width="100%">
      <SportScaleTable
        actions={
          isSuperAdmin
            ? [
                {
                  icon: '',
                  text: 'Edit',
                  onClick: rowData =>
                    history.push(`${location.pathname}/subscriptions/${rowData.id}`, { subscription: rowData }),
                },
              ]
            : []
        }
        columns={columns}
        components={{
          Action: SportScaleTableActionButton(classes.actionButton, false),
          Toolbar: SportScaleTableToolbar([{ text: 'add subscription', onClick: goToCreateSubscription }]),
        }}
        data={subscriptions || []}
        isLoading={isLoadingSubscriptions}
        options={{
          pageSize: 5,
          pageSizeOptions: [5, 10, 20],
          toolbar: isSuperAdmin,
        }}
      />
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  subStatusIcon: {
    marginRight: theme.spacing(2),
  },
}));

export default Subscriptions;
