import { find, isNil } from 'lodash';

const getRosterMemberWeightLossPercentage = member => {
  return member.weight.inToInDelta === null || member.weight.inToInDelta >= 0
    ? null
    : Math.abs(member.weight.inToInDelta);
};

const getAthleteThreshold = (athlete, team) => {
  return athlete.predisposed ? team.predispositionThreshold : team.dehydrationThreshold;
};

/*
This is used to assemble a roster list for the watch list, incomplete list, or the capture weight / roster
search screens. 

In any of these situations, we will have requested the list by making potentially several requests based 
on active seasonIDs, although in the vast majority of cases there will be only one active season.

Map the incoming list entries with the requested season ID on it
Determine if the source list is null, and if so, define it as an empty array
Ensure that the list doesn't have duplicates - this replaces any member already on the list with whatever comes in next
TODO: Test if this is valid and doesn't cause race conditions
*/
const prepareRoster = (currentRoster, incomingRoster, seasonId) => {
  /* TODO: Assess the implication of having an athlete on multiple active seasons. 
  The server doesn't attach the season ID to the athlete, so it's being done here.
  Possibly not a big deal since this will mostly just be setting the season ID in order to identify
  which athlete on the roster to update after taking a weight

  Below we are filtering out the same roster members, but need to confirm if this applies to athlete objects
  */

  const mappedRosterMembers = incomingRoster.map(member => {
    member.seasonId = seasonId;

    return member;
  });

  if (isNil(currentRoster)) return mappedRosterMembers;

  return [...currentRoster]
    .filter(member => {
      return find(mappedRosterMembers, { id: member.id }) === null;
    })
    .concat(mappedRosterMembers);
};

export default { getRosterMemberWeightLossPercentage, getAthleteThreshold, prepareRoster };
