import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import PageWrapper from 'modules/layout/pageWrapper.component';
import RegisterScaleForm from 'modules/scales/registerScaleForm.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import ScalesTable from 'modules/scales/scales.component';
import SportScaleFormDialog from 'common/components/sportScaleFormDialog.component';
import useApiRequest from 'hooks/apiRequest';
import {
  GET_ORGANIZATION_SCALES_SUCCESS,
  REGISTER_ORGANIZATION_SCALE_SUCCESS,
  getOrganizationScales,
  registerOrganizationScale,
  resetOrganizationScales,
} from 'modules/scales/scales.actions';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { selectScales, selectScalesIsRegisteringScale } from 'modules/scales/scales.selectors';
import {
  selectSelectedOrganizationId,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';

const INITIAL_CHAR_CODE = '';

const ScalesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const makeRequest = useApiRequest();

  const isRegisteringScale = useSelector(selectScalesIsRegisteringScale);
  const scales = useSelector(selectScales);

  const organizationId = useSelector(selectSelectedOrganizationId);
  const organizationName = useSelector(selectSelectedOrganizationName);

  const [isRegisterScaleOpen, setIsRegisterScaleOpen] = useState(false);
  const [registrationCode, setRegistrationCode] = useState(INITIAL_CHAR_CODE);

  useEffect(() => {
    if (isNil(organizationId) || !isNil(scales)) return;

    dispatch(setPageTitle(`${organizationName} - Scales`));

    makeRequest(
      () => getOrganizationScales(organizationId),
      { type: GET_ORGANIZATION_SCALES_SUCCESS },
      ERROR_MESSAGES.GET_ORGANIZATION_SCALES_FAILURE
    );
  }, [dispatch, makeRequest, organizationId, organizationName, scales]);

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationScales());
    };
  }, [dispatch]);

  const handleInputChange = useCallback(event => setRegistrationCode(event.target.value), [setRegistrationCode]);

  const handleToggleRegister = useCallback(() => {
    setIsRegisterScaleOpen(!isRegisterScaleOpen);
    setRegistrationCode(INITIAL_CHAR_CODE);
  }, [isRegisterScaleOpen, setIsRegisterScaleOpen, setRegistrationCode]);

  const handleRegisterScale = useCallback(() => {
    (async () => {
      const code = registrationCode.replace(/-/g, '');
      const response = await dispatch(registerOrganizationScale(organizationId, code));

      handleToggleRegister();

      if (response.type !== REGISTER_ORGANIZATION_SCALE_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.REGISTER_SCALE_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.REGISTER_SCALE_SUCCESS));
      history.push(`/organizations/${organizationId}/scales/${response.response.id}`);
    })();
  }, [dispatch, history, organizationId, registrationCode, handleToggleRegister]);

  return (
    <>
      <PageWrapper>
        <Box mb={2} width="100%">
          <ScalesTable onRegister={handleToggleRegister} />
        </Box>
      </PageWrapper>
      <SportScaleFormDialog
        submitButtonText={isRegisteringScale ? 'registering...' : 'register'}
        content={
          <RegisterScaleForm
            registrationCode={registrationCode}
            isLoading={isRegisteringScale}
            onChange={handleInputChange}
          />
        }
        fullWidth
        isLoading={isRegisteringScale}
        maxWidth="sm"
        open={isRegisterScaleOpen}
        title="Enter Registration Code"
        onClose={handleToggleRegister}
        onSubmit={handleRegisterScale}
      />
    </>
  );
};

ScalesContainer.propTypes = {};

export default ScalesContainer;
