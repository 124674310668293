import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "watchlist" gets changed
const selectWatchlistState = createSelector(
  state => state.watchlist,
  watchlistState => watchlistState
);

export const selectWatchlist = createSelector(selectWatchlistState, watchlistState => watchlistState.watchlist);

export const selectWatchlistIsLoading = createSelector(
  selectWatchlistState,
  watchlistState => watchlistState.isLoading
);
