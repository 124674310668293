/* 
  Adding the below offset fixes an issue where new Date() automatically assumes 00:00:00 for UTC time when it isn't provided on the date string
*/
const convertToDateWithOffset = date => {
  const dt = new Date(date);

  return new Date(new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000));
};

export default { convertToDateWithOffset };
