import produce from 'immer';

import {
  DELETE_ORGANIZATION_SCALE_FAILURE,
  DELETE_ORGANIZATION_SCALE_REQUEST,
  DELETE_ORGANIZATION_SCALE_SUCCESS,
  GET_ORGANIZATION_SCALES_FAILURE,
  GET_ORGANIZATION_SCALES_REQUEST,
  GET_ORGANIZATION_SCALES_SUCCESS,
  GET_ORGANIZATION_SCALE_FAILURE,
  GET_ORGANIZATION_SCALE_REQUEST,
  GET_ORGANIZATION_SCALE_SUCCESS,
  REGISTER_ORGANIZATION_SCALE_FAILURE,
  REGISTER_ORGANIZATION_SCALE_REQUEST,
  REGISTER_ORGANIZATION_SCALE_SUCCESS,
  RESET_ORGANIZATION_SCALES,
  UPDATE_ORGANIZATION_SCALE_FAILURE,
  UPDATE_ORGANIZATION_SCALE_REQUEST,
  UPDATE_ORGANIZATION_SCALE_SUCCESS,
} from 'modules/scales/scales.actions';

const initialState = {
  isLoadingScales: false,
  isLoadingScale: false,
  isRegisteringScale: false,
  scales: null,
  selectedScale: {},
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_SCALES_SUCCESS:
      draft.scales = action.response;
      draft.isLoadingScales = false;
      break;

    case GET_ORGANIZATION_SCALES_REQUEST:
      draft.isLoadingScales = true;
      break;

    case GET_ORGANIZATION_SCALES_FAILURE:
      draft.isLoadingScales = false;
      break;

    case RESET_ORGANIZATION_SCALES:
      draft.scales = null;
      break;

    case GET_ORGANIZATION_SCALE_SUCCESS:
      draft.selectedScale = action.response;
      draft.isLoadingScale = false;
      break;

    case DELETE_ORGANIZATION_SCALE_SUCCESS:
    case UPDATE_ORGANIZATION_SCALE_SUCCESS:
      draft.isLoadingScale = false;
      break;

    case DELETE_ORGANIZATION_SCALE_REQUEST:
    case GET_ORGANIZATION_SCALE_REQUEST:
    case UPDATE_ORGANIZATION_SCALE_REQUEST:
      draft.isLoadingScale = true;
      break;

    case DELETE_ORGANIZATION_SCALE_FAILURE:
    case GET_ORGANIZATION_SCALE_FAILURE:
    case UPDATE_ORGANIZATION_SCALE_FAILURE:
      draft.isLoadingScale = false;
      break;

    case REGISTER_ORGANIZATION_SCALE_FAILURE:
      draft.isRegisteringScale = false;
      break;

    case REGISTER_ORGANIZATION_SCALE_REQUEST:
      draft.isRegisteringScale = true;
      break;

    case REGISTER_ORGANIZATION_SCALE_SUCCESS:
      draft.isRegisteringScale = false;
      draft.selectedScale = action.response;
      break;

    default:
      break;
  }
}, initialState);
