import moment from 'moment';
import { MONTH_DAY_YEAR_FORMAT } from 'common/formFields/sportScaleDatePicker.component';

/* Return a function that will sort records by the correct field using the
specified moment function to compare the dates. */
export const filterByDateField = (field, sortingFunction) => (term, rowData) =>
  moment(rowData[field])[sortingFunction](moment(term).format(MONTH_DAY_YEAR_FORMAT));

export const filterFloatColumn = fieldName => (term, rowData) =>
  parseFloat(100 * rowData[fieldName])
    .toFixed(2)
    .toString()
    .includes(term);
