import produce from 'immer';
import {
  HIDE_TOAST_MESSAGE,
  SET_INITIAL_LOAD,
  SET_INITIAL_LOAD_FAILURE,
  SET_PAGE_TITLE,
  SET_TOAST_POSITION,
  SHOW_TOAST_MESSAGE,
  TOAST_TYPES,
  TOGGLE_DRAWER_MENU,
  TOGGLE_ORG_SELECTION_DIALOG,
} from 'modules/layout/layout.actions';

const initialState = {
  detailMessage: '',
  hasInitialLoad: false,
  hasInitialLoadFailure: false,
  isDrawerOpen: false,
  isOrgSelectionDialogOpen: false,
  isShowToast: false,
  pageTitle: 'Sportscale',
  toastMessage: '',
  toastPosition: '0',
  toastType: TOAST_TYPES.SUCCESS,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER_MENU:
      draft.isDrawerOpen = !draft.isDrawerOpen;
      break;

    case SET_INITIAL_LOAD:
      draft.hasInitialLoad = action.payload.hasInitialLoad;
      break;

    case SET_INITIAL_LOAD_FAILURE:
      draft.hasInitialLoadFailure = action.payload.hasInitialLoadFailure;
      break;

    case TOGGLE_ORG_SELECTION_DIALOG:
      draft.isOrgSelectionDialogOpen = !draft.isOrgSelectionDialogOpen;
      break;

    case SET_PAGE_TITLE:
      draft.pageTitle = action.payload;
      break;

    case SHOW_TOAST_MESSAGE:
      draft.isShowToast = true;
      draft.toastMessage = action.message;
      draft.toastType = action.toastType;
      draft.detailMessage = action.detailMessage;
      break;

    case HIDE_TOAST_MESSAGE:
      draft.isShowToast = false;
      break;

    case SET_TOAST_POSITION:
      draft.toastPosition = action.position;
      break;

    default:
      break;
  }
}, initialState);
