export default Object.freeze({
  ADD_ATHLETE_TO_ROSTER_SUCCESS: 'Athlete was added to the roster successfully.',
  ADD_ATHLETES_TO_ROSTER_SUCCESS: 'Athletes were added to the roster successfully.',
  ADD_TARGET_WEIGHT_SUCCESS: 'Target Weight was added to the athlete successfully.',
  COPY_SEASON_ROSTER_SUCCESS: 'Roster was copied to the season successfully.',
  CREATE_ATHLETE_SUCCESS: 'Athlete was created successfully.',
  CREATE_LOCATION_SUCCESS: 'Location was created successfully.',
  CREATE_ORGANIZATION_SUCCESS: 'Organization was created successfully.',
  CREATE_SCALE_SUCCESS: 'Scale was created successfully.',
  CREATE_SEASON_SUCCESS: 'Season was created successfully.',
  CREATE_SUBSCRIPTION_SUCCESS: 'Subscription was created successfully.',
  CREATE_TEAM_SUCCESS: 'Sport was created successfully.',
  CREATE_USER_SUCCESS: 'User was created successfully.',
  DELETE_ATHLETE_SUCCESS: 'Athlete was deleted successfully.',
  DELETE_ATHLETE_FACE_DATA_SUCCESS: 'Athlete face data was deleted successfully.',
  DELETE_LOCATION_SUCCESS: 'Location was deleted successfully.',
  DELETE_SEASON_SUCCESS: 'Season was deleted successfully.',
  DELETE_SCALE_SUCCESS: 'Scale was unregistered successfully.',
  DELETE_SUBSCRIPTION_SUCCESS: 'Subscription was deleted successfully.',
  DELETE_TARGET_WEIGHT_SUCCESS: 'Target Weight was deleted successfully.',
  DELETE_TEAM_SUCCESS: 'Sport was deleted successfully.',
  DELETE_USER_SUCCESS: 'User was deleted successfully.',
  REGISTER_SCALE_SUCCESS: 'The scale was registered successfully',
  REMOVE_ATHLETE_FROM_ROSTER_SUCCESS: 'Athlete was removed successfully.',
  RESEND_USER_VERIFICATION_EMAIL_SUCCESS: 'User verification email was sent successfully.',
  UPDATE_ATHLETE_SUCCESS: 'Athlete was updated sucessfully.',
  UPDATE_LOCATION_SUCCESS: 'Location was updated successfully.',
  UPDATE_ORGANIZATION_SUCCESS: 'Organization was updated successfully.',
  UPDATE_PROFILE_SUCCESS: 'Profile was updated sucessfully.',
  UPDATE_SCALE_SUCCESS: 'Scale was updated successfully.',
  UPDATE_SEASON_SUCCESS: 'Season was updated successfully.',
  UPDATE_SUBSCRIPTION_SUCCESS: 'Subscription was updated successfully.',
  UPDATE_TARGET_WEIGHT_SUCCESS: 'Target Weight was updated successfully.',
  UPDATE_TEAM_SUCCESS: 'Sport was updated successfully.',
  UPDATE_USER_SUCCESS: 'User was updated sucessfully.',
});
