import PropTypes from 'prop-types';
import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import SportScaleSelectFieldValidator from 'common/formFields/sportScaleSelectFieldValidator.component';
import SportScaleTextField from 'common/formFields/sportScaleTextField.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { LOCATION_TYPE_OPTIONS, US_STATE_OPTIONS } from 'utilities/inputs';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

export const INITIAL_LOCATION = {
  name: '',
  type: LOCATION_TYPE_OPTIONS[0].value,
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: US_STATE_OPTIONS[0].value,
  addressZip: '',
  addressCountry: 'USA', // always using this for now, not showing in form
};

const LocationForm = ({ disabled, location, onChange }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Container spacing={2}>
        <Item md={6} xs={12}>
          <SportScaleTextFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(location.name, 'Location Name', [VALIDATION_TYPES.REQUIRED])}
            fullWidth
            label="Location Name"
            name="name"
            validators={[VALIDATION_TYPES.REQUIRED]}
            value={location.name}
            onChange={onChange}
          />
        </Item>
        <Item md={6} xs={12}>
          <SportScaleSelectFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(location.type, 'Type', [VALIDATION_TYPES.REQUIRED])}
            fullWidth
            label="Sportscale Address"
            name="type"
            options={LOCATION_TYPE_OPTIONS}
            type="text"
            validators={[VALIDATION_TYPES.REQUIRED]}
            value={location.type}
            onChange={onChange}
          />
        </Item>
        <Item xs={12}>
          <SportScaleTextFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(location.addressLine1, 'Address Line 1', [
              VALIDATION_TYPES.REQUIRED,
            ])}
            fullWidth
            label="Address Line 1"
            name="addressLine1"
            validators={[VALIDATION_TYPES.REQUIRED]}
            value={location.addressLine1}
            onChange={onChange}
          />
        </Item>
        <Item xs={12}>
          <SportScaleTextField
            disabled={disabled}
            fullWidth
            label="Address Line 2"
            name="addressLine2"
            value={location.addressLine2}
            onChange={onChange}
          />
        </Item>
        <Item md={6} xs={12}>
          <SportScaleTextFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(location.addressCity, 'City', [VALIDATION_TYPES.REQUIRED])}
            fullWidth
            label="City"
            name="addressCity"
            validators={[VALIDATION_TYPES.REQUIRED]}
            value={location.addressCity}
            onChange={onChange}
          />
        </Item>
        <Item md={6} xs={12}>
          <SportScaleSelectFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(location.addressState, 'State', [VALIDATION_TYPES.REQUIRED])}
            fullWidth
            label="State"
            name="addressState"
            options={US_STATE_OPTIONS}
            type="text"
            validators={[VALIDATION_TYPES.REQUIRED]}
            value={location.addressState}
            onChange={onChange}
          />
        </Item>
        <Item xs={12}>
          <SportScaleTextFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(location.addressZip, 'Zip', [
              VALIDATION_TYPES.REQUIRED,
              `${VALIDATION_TYPES.MIN_STRING_LENGTH}:5`,
              `${VALIDATION_TYPES.MAX_STRING_LENGTH}:5`,
            ])}
            fullWidth
            label="Zip Code"
            name="addressZip"
            validators={[
              VALIDATION_TYPES.REQUIRED,
              `${VALIDATION_TYPES.MIN_STRING_LENGTH}:5`,
              `${VALIDATION_TYPES.MAX_STRING_LENGTH}:5`,
            ]}
            value={location.addressZip}
            onChange={onChange}
          />
        </Item>
      </Container>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

LocationForm.defaultProps = {
  disabled: false,
};

LocationForm.propTypes = {
  disabled: PropTypes.bool,
  location: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LocationForm;
