import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import { isNil } from 'lodash';

import { CM_PER_INCH, INCHES_PER_FOOT } from 'types/height';
import { GENDER_FEMALE_LABEL, GENDER_FEMALE_VALUE, GENDER_MALE_LABEL, GENDER_MALE_VALUE } from 'utilities/gender';

const WeightsAthleteDetails = ({ athlete }) => {
  const classes = useStyles();
  const theme = useTheme();

  const getHeightFeet = () => {
    if (isNil(athlete.height)) return null;

    const heightInInches = athlete.height / CM_PER_INCH;

    return Math.floor(heightInInches / INCHES_PER_FOOT);
  };

  const getHeightInches = () => {
    if (isNil(athlete.height)) return null;

    const heightInInches = athlete.height / CM_PER_INCH;

    return Math.ceil(heightInInches % INCHES_PER_FOOT);
  };

  const getGenderText = () => {
    switch (athlete.gender) {
      case GENDER_MALE_VALUE:
        return GENDER_MALE_LABEL;

      case GENDER_FEMALE_VALUE:
        return GENDER_FEMALE_LABEL;

      default:
        return '';
    }
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h5" className={classes.name}>
        {`${athlete.firstName} ${athlete.lastName}`}
      </Typography>
      <Box className={classes.details}>
        {!isNil(athlete.height) && (
          <Typography className={classes.detailsText}>
            {getHeightFeet()}&apos;{getHeightInches()}&apos;&apos;
          </Typography>
        )}

        {!isNil(athlete.graduationYear) && (
          <Typography className={classes.detailsText}>Graduation: {athlete.graduationYear}</Typography>
        )}

        <Typography className={classes.detailsText}>{getGenderText()}</Typography>

        {athlete.predisposed && (
          <Typography className={classes.detailsText} style={{ color: theme.palette.error.main }}>
            High Risk
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  name: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    alignItems: 'center',
  },
  detailsText: {
    fontSize: '0.85rem',
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(2),
  },
}));

WeightsAthleteDetails.propTypes = {
  athlete: PropTypes.object.isRequired,
};

export default WeightsAthleteDetails;
