import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATION_WATCHLIST_REQUEST = 'GET_ORGANIZATION_WATCHLIST_REQUEST';
export const GET_ORGANIZATION_WATCHLIST_SUCCESS = 'GET_ORGANIZATION_WATCHLIST_SUCCESS';
export const GET_ORGANIZATION_WATCHLIST_FAILURE = 'GET_ORGANIZATION_WATCHLIST_FAILURE';

export const getOrganizationWatchlist = organizationId => {
  return {
    [CALL_API]: {
      types: [
        GET_ORGANIZATION_WATCHLIST_REQUEST,
        GET_ORGANIZATION_WATCHLIST_SUCCESS,
        GET_ORGANIZATION_WATCHLIST_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/watchlist`,
      method: 'GET',
    },
  };
};
