import MenuIcon from '@material-ui/icons/Menu';
import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  useScrollTrigger,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { isNil } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CreateOrganizationDialog from 'modules/organizations/createOrganizationDialog.component';
import Drawer, { DRAWER_WIDTH } from 'modules/layout/drawer/drawer.component';
import HeaderOrganizationMenu from 'modules/layout/header/headerOrganizationsMenu.component';
import SportScaleSecondaryOutlinedButton from 'common/buttons/sportScaleSecondaryOutlinedButton.component';
import { DEFAULT_ORG_ROUTE } from 'utilities/routes';
import { getOrganization, setSelectedOrganizationId } from 'modules/organizations/organizations.actions';
import { selectLayoutIsDrawerOpen, selectLayoutPageTitle } from 'modules/layout/layout.selectors';
import {
  selectOrganizations,
  selectOrganizationsIsLoadingOrganization,
} from 'modules/organizations/organizations.selectors';
import { toggleDrawer } from 'modules/layout/layout.actions';
import { useWidth } from 'utilities/theme';

export const HEADER_HEIGHT = '4rem';

const ElevationScroll = props => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const Header = memo(props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const width = useWidth();

  const { isAuthenticated } = useAuth0();

  const isLoadingOrganization = useSelector(selectOrganizationsIsLoadingOrganization);
  const organizations = useSelector(selectOrganizations);

  const isDrawerOpen = useSelector(selectLayoutIsDrawerOpen);
  const pageTitle = useSelector(selectLayoutPageTitle);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(null);
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false);

  useEffect(() => {
    setIsSmallScreen(width === 'xs');
  }, [width, setIsSmallScreen]);

  const goToOrganization = useCallback(
    organization => {
      setAnchorEl(null);
      // SPOR-63: Get the new organization data.
      dispatch(getOrganization(organization.id));
      dispatch(setSelectedOrganizationId(organization.id));

      history.push(`/organizations/${organization.id}/${DEFAULT_ORG_ROUTE}`);
    },
    [dispatch, history, setAnchorEl]
  );

  const handleOrganizationMenuClick = useCallback(({ currentTarget }) => setAnchorEl(currentTarget), [setAnchorEl]);

  const handleOrganizationMenuClose = useCallback(
    event => {
      if (!isNil(anchorEl) && anchorEl.current && anchorEl.current.contains(event.target)) {
        return;
      }

      setAnchorEl(null);
    },
    [anchorEl, setAnchorEl]
  );

  const handleToggleCreateOrganizationDialog = useCallback(() => {
    handleOrganizationMenuClose();
    setIsCreateOrganizationOpen(!isCreateOrganizationOpen);
  }, [isCreateOrganizationOpen, handleOrganizationMenuClose, setIsCreateOrganizationOpen]);

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          ref={() => 'HeaderAppBar'}
          color="secondary"
          className={classNames(classes.appBar, isDrawerOpen && classes.appBarShift)}
        >
          <Container maxWidth="xl" classes={{ root: classes.containerRoot }}>
            <Toolbar className={classes.toolbar}>
              {isAuthenticated && (
                <IconButton
                  className={classNames(classes.menuButton, { [classes.hide]: isDrawerOpen })}
                  color="inherit"
                  edge="start"
                  onClick={() => dispatch(toggleDrawer())}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Box className={classes.pageTitle}>
                {!isLoadingOrganization && (
                  <Typography variant="h6" color="inherit" noWrap>
                    {pageTitle}
                  </Typography>
                )}
              </Box>
              {!isNil(isSmallScreen) && isAuthenticated && organizations.length > 1 && (
                <SportScaleSecondaryOutlinedButton endIcon={<ArrowDropDown />} onClick={handleOrganizationMenuClick}>
                  {isSmallScreen ? 'Orgs' : 'Organizations'}
                </SportScaleSecondaryOutlinedButton>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      {isAuthenticated && <Drawer />}
      {isAuthenticated && !isNil(isSmallScreen) && (
        <HeaderOrganizationMenu
          anchorEl={anchorEl}
          organizations={organizations}
          onCreateOrganizationClick={handleToggleCreateOrganizationDialog}
          onMenuClose={handleOrganizationMenuClose}
          onOrganizationItemClick={goToOrganization}
        />
      )}
      <CreateOrganizationDialog isOpen={isCreateOrganizationOpen} onClose={handleToggleCreateOrganizationDialog} />
    </>
  );
});

const useStyles = makeStyles(theme => ({
  containerRoot: {
    padding: 0,
  },
  logo: {
    height: 40,
    marginLeft: '1rem',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
  },
  pageTitle: {
    flex: 1,
    textAlign: 'center',
  },
}));

Header.propTypes = {};

export default Header;
