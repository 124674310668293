import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useState } from 'react';
import { Box, MenuItem, MenuList } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';

import SportScaleTextField from 'common/formFields/sportScaleTextField.component';

const GUTTER_SIZE = 5;
const ITEM_HEIGHT = 36;

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <MenuList
    ref={ref}
    style={{
      ...style,
      paddingLeft: GUTTER_SIZE,
      paddingTop: GUTTER_SIZE,
    }}
    {...rest}
  />
));

innerElementType.propTypes = {
  style: PropTypes.object.isRequired,
};

const SportScaleFixedAutoComplete = ({ items, noResultsMessage, placeholder, onFilter, onItemClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [listItems, setListItems] = useState(items);

  const handleInputChange = useCallback(
    ({ target: { value } }) => {
      setSearchTerm(value);

      const filteredItems = onFilter(value);

      setListItems(filteredItems);
    },
    [onFilter, setListItems]
  );

  return (
    <>
      <SportScaleTextField
        fullWidth
        margin="dense"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
      />
      {listItems.length ? (
        <List
          height={listItems.length > 5 ? ITEM_HEIGHT * 5 : ITEM_HEIGHT * listItems.length}
          innerElementType={innerElementType}
          itemCount={listItems.length}
          itemData={listItems}
          itemSize={ITEM_HEIGHT}
        >
          {({ data, index, style }) => (
            <MenuItem style={style} disableGutters onClick={() => onItemClick(data[index])}>
              {data[index].name}
            </MenuItem>
          )}
        </List>
      ) : (
        <Box display="flex" alignItems="center" height={ITEM_HEIGHT}>
          {noResultsMessage}
        </Box>
      )}
    </>
  );
};

SportScaleFixedAutoComplete.propTypes = {
  items: PropTypes.array.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  placeholder: PropTypes.string,

  onFilter: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

SportScaleFixedAutoComplete.defaultProps = {
  placeholder: '',
};

export default SportScaleFixedAutoComplete;
