import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const SportScaleSelectField = ({
  disabled,
  hasBlankOption,
  hasValidation,
  inputProps,
  multiple,
  options,
  placeholder,
  selectProps,
  validatorRef,
  value,
  onChange,
  ...restProps
}) => {
  const classes = useStyles();

  const sportScaleSelectProps = { classes: {}, ...selectProps };

  /* If the select is disabled, add a class to hide the dropdown icon. */
  if (disabled) {
    sportScaleSelectProps.classes = { icon: classes.hideSelectIcon, ...selectProps.classes };
  }

  // Multi-select must use `<Select>` instead of `<TextField select />`
  if (multiple) {
    return (
      <FormControl variant="outlined" className={classes.selectWidth}>
        <InputLabel>{restProps.label}</InputLabel>
        <Select
          ref={validatorRef}
          classes={{
            selectMenu: classes.multiselect,
          }}
          disabled={disabled}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          multiple
          renderValue={selectedValues => (
            <Box display="flex" flexWrap="wrap">
              {selectedValues.map(selectedValue => (
                <Chip
                  key={selectedValue}
                  className={classes.chip}
                  label={options.filter(o => o.value === selectedValue).map(o => o.label)}
                  deleteIcon={
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                      onMouseDown={e => {
                        if (e.button === 0) {
                          e.stopPropagation();

                          onChange({
                            target: { name: restProps.name, value: selectedValues.filter(s => s !== selectedValue) },
                          });
                        }
                      }}
                    >
                      <Close className="MuiChip-deleteIconSmall" />
                    </Box>
                  }
                  // Workaround disfunctional chips inside multiselect render value
                  // https://github.com/mui-org/material-ui/issues/18658
                  onDelete={() => {
                    /* Intentionally empty */
                  }}
                />
              ))}
            </Box>
          )}
          value={value || []}
          onChange={onChange}
          {...restProps}
        >
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {hasBlankOption && <MenuItem value="" className={classes.emptyOption} />}
          {options.map(option => (
            <MenuItem key={option.label} value={option.value} disabled={!!option.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <TextField
      ref={validatorRef}
      classes={{
        // if this component is wrapped in a validation component we dont want
        // a bottom margin added because the validation will add it so the
        // margin includes any error messages
        root: classNames(classes.select, {
          [classes.formControlNoMargin]: hasValidation,
        }),
      }}
      disabled={disabled}
      InputProps={inputProps}
      select
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
        ...sportScaleSelectProps,
      }}
      value={value || ''}
      variant="outlined"
      onChange={onChange}
      {...restProps}
    >
      {placeholder !== '' && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {hasBlankOption && <MenuItem value="" className={classes.emptyOption} />}
      {options.map(option => (
        <MenuItem key={option.label} value={option.value} disabled={!!option.disabled}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const useStyles = makeStyles(() => ({
  menu: {
    width: '100%',
  },
  emptyOption: {
    height: 36, // This is the height of items in the list, so need to match MUI's default for those
  },
  formControlNoMargin: {
    marginBottom: '0rem',
  },
  select: {
    borderRadius: '5%',
    flex: 1,
    minWidth: '10rem',
  },
  hideSelectIcon: {
    display: 'none',
  },
  selectWidth: {
    width: '100%',
  },
  chip: {
    margin: 1,
  },
  multiselect: {
    // Reduce default padding so inline chips don't cause select input to be
    // taller than other inputs
    padding: '0.6rem',
    // Match height of other inputs when no options selected
    minHeight: '36.4px',
  },
}));

SportScaleSelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasBlankOption: PropTypes.bool,
  hasValidation: PropTypes.bool,
  inputProps: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiple: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selectProps: PropTypes.any,
  validatorRef: PropTypes.object,
  value: PropTypes.any,

  onChange: PropTypes.func,
};

SportScaleSelectField.defaultProps = {
  className: '',
  disabled: false,
  hasValidation: false,
  inputProps: {},
  label: '',
  multiple: false,
  hasBlankOption: false,
  options: [],
  placeholder: '',
  selectProps: {},
  validatorRef: React.createRef(),
  value: null,
  onChange: () => {},
};

export default SportScaleSelectField;
