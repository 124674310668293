import React, { useCallback, useEffect } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

import PageWrapper from 'modules/layout/pageWrapper.component';
import STATUS_CODES from 'types/statusCodes';
import {
  GET_ORGANIZATIONS_FAILURE,
  GET_ORGANIZATION_FAILURE,
  getOrganization,
  getOrganizations,
} from 'modules/organizations/organizations.actions';
import { GET_PROFILE_FAILURE, getProfile } from 'modules/profile/profile.actions';
import { LOGIN_STATE } from 'utilities/login';
import { isEmpty } from 'lodash';
import { setInitialLoadFailure } from 'modules/layout/layout.actions';
import { setUser } from 'modules/login/login.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AuthLoadingContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthenticated, user: auth0User } = useAuth0();

  const { hasInitialLoad, hasInitialLoadFailure, loginState, selectedOrganizationId } = useSelector(
    ({ login, layout, organizations }) => ({
      hasInitialLoad: layout.hasInitialLoad,
      hasInitialLoadFailure: layout.hasInitialLoadFailure,
      loginState: login.loginState,
      selectedOrganizationId: organizations.selectedOrganizationId,
    })
  );

  const goToErrorPage = useCallback(
    (errorContent, showGoHome, showLogout = true) => {
      history.push('/unauthorized', {
        content: errorContent,
        showGoHome,
        showLogout,
        title: 'Unauthorized',
      });
    },
    [history]
  );

  /*
    User is authenticated, but hasn't loaded data yet
    Do all pre-loading of user and necessary information here 
  */
  useEffect(() => {
    if (!isAuthenticated || hasInitialLoad || loginState !== LOGIN_STATE.UNDETERMINED) return;

    dispatch(setUser(auth0User));

    (async () => {
      /*
        REQUESTING PROFILE
        - This error situation should never happen in the real world as we won't have an auth0 user without a system user
      */
      const profileResponse = await dispatch(getProfile());

      if (
        profileResponse.type === GET_PROFILE_FAILURE &&
        profileResponse.response.statusCode === STATUS_CODES.FORBIDDEN
      ) {
        dispatch(setInitialLoadFailure(true));

        goToErrorPage('You do not have an account for Sportscale. Please speak with your system administrator.', false);
        return;
      }

      /*
        REQUESTING ORGANZATIONS LIST 
        - If there is an error or if the response is empty, the user is missing the required permissions
      */
      const organizationsResponse = await dispatch(getOrganizations());

      // If the org request fails or has an empty set of orgs, the user doesn't have the proper settings
      if (organizationsResponse.type === GET_ORGANIZATIONS_FAILURE || isEmpty(organizationsResponse.response)) {
        dispatch(setInitialLoadFailure(true));

        goToErrorPage(
          'You do not have the required permissions to view organizations. Please speak with your system administrator.',
          false
        );
        return;
      }

      /*
        REQUESTING THE SELECTED ORG
        - If there is an error, the user is missing the required permissions or the org doesn't exist
      */
      if (selectedOrganizationId) {
        const organizationResponse = await dispatch(getOrganization(selectedOrganizationId));

        // If the org doesn't exist at all or org doesn't exist for this user, the user doesn't have the proper settings
        if (organizationResponse.type === GET_ORGANIZATION_FAILURE) {
          dispatch(setInitialLoadFailure(true));

          goToErrorPage(
            'You do not have the required permissions to view this organization. Please speak with your system administrator.',
            true
          );
        }
      }
    })();
  }, [
    dispatch,
    history,
    auth0User,
    hasInitialLoad,
    hasInitialLoadFailure,
    isAuthenticated,
    loginState,
    selectedOrganizationId,
    goToErrorPage,
  ]);

  return (
    <PageWrapper>
      <Box margin="auto" textAlign="center">
        <CircularProgress className={classes.progress} size={60} />
      </Box>
    </PageWrapper>
  );
};

const useStyles = makeStyles(() => ({
  progress: {
    marginBottom: '2rem',
  },
}));

export default AuthLoadingContainer;
