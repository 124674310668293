import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, DialogContentText, makeStyles } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import AthleteForm from 'modules/athletes/athleteForm.component';
import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleBreadCrumbs from 'modules/layout/breadCrumbs/breadCrumbs.component';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import { ATHLETE_ID_MAP, ROUTES, generateBreadCrumbMap, generateRouteParamsMap } from 'utilities/breadCrumbs';
import {
  CREATE_ORGANIZATION_ATHLETE_SUCCESS,
  DELETE_ORGANIZATION_ATHLETE_FACE_DATA_SUCCESS,
  DELETE_ORGANIZATION_ATHLETE_SUCCESS,
  UPDATE_ORGANIZATION_ATHLETE_SUCCESS,
  createOrganizationAthlete,
  deleteOrganizationAthlete,
  deleteOrganizationAthleteFaceData,
  getOrganizationAthlete,
  updateOrganizationAthlete,
} from 'modules/athletes/athletes.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getSubmitText } from 'utilities/form';
import { selectAthletesIsLoadingAthlete, selectSelectedAthlete } from 'modules/athletes/athletes.selectors';
import {
  selectOrganizationsIsLoadingOrganization,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';

const INITIAL_ATHLETE = {
  customAttributes: {},
  dob: null,
  ethnicity: '',
  firstName: '',
  gender: '',
  graduationYear: null,
  height: '',
  identifier: '',
  lastName: '',
  predisposed: false,
};

const AthleteContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { athleteId, organizationId } = useParams();

  const isLoadingOrganization = useSelector(selectOrganizationsIsLoadingOrganization);
  const organizationName = useSelector(selectSelectedOrganizationName);

  const isLoadingAthlete = useSelector(selectAthletesIsLoadingAthlete);
  const selectedAthlete = useSelector(selectSelectedAthlete);

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removeFaceDataDialogOpen, setRemoveFaceDataDialogOpen] = useState(false);
  const [athlete, setAthlete] = useState(null);

  const isNewAthlete = athleteId === NEW_ITEM_ROUTE;

  useEffect(() => {
    if (isNil(organizationName)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.ATHLETE_ENROLLMENT}`));

    if (isNewAthlete) {
      setAthlete(INITIAL_ATHLETE);
      return;
    }

    if (!isEmpty(location.state)) {
      const { athlete: locationAthlete } = location.state;
      setAthlete(locationAthlete);
      return;
    }

    dispatch(getOrganizationAthlete(organizationId, athleteId));
  }, [dispatch, location, athleteId, isNewAthlete, organizationId, organizationName]);

  useEffect(() => {
    if (!isEmpty(selectedAthlete)) setAthlete(selectedAthlete);
  }, [selectedAthlete]);

  const handleDeleteAthlete = useCallback(() => {
    (async () => {
      const response = await dispatch(deleteOrganizationAthlete(organizationId, athleteId));

      setRemoveDialogOpen(false);

      if (response.type !== DELETE_ORGANIZATION_ATHLETE_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_ATHLETE_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_ATHLETE_SUCCESS));
      history.replace(`/organizations/${organizationId}/athletes`);
    })();
  }, [dispatch, history, athleteId, organizationId]);

  const handleDeleteFaceData = useCallback(() => {
    (async () => {
      const response = await dispatch(deleteOrganizationAthleteFaceData(organizationId, athleteId));

      if (response.type !== DELETE_ORGANIZATION_ATHLETE_FACE_DATA_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_ATHLETE_FACE_DATA_FAILURE, TOAST_TYPES.ERROR));
        setRemoveFaceDataDialogOpen(false);
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_ATHLETE_FACE_DATA_SUCCESS));
      await dispatch(getOrganizationAthlete(organizationId, athleteId));
      setRemoveFaceDataDialogOpen(false);
    })();
  }, [dispatch, athleteId, organizationId]);

  const handleSubmitAthlete = useCallback(() => {
    (async () => {
      let response;

      const updatedAthlete = {
        ...athlete,
        dob: moment(athlete.dob),
        graduationYear: athlete.graduationYear ? moment(athlete.graduationYear).year().toString() : '',
      };

      if (!updatedAthlete.graduationYear) delete updatedAthlete.graduationYear;
      if (!updatedAthlete.height) delete updatedAthlete.height;

      if (isNewAthlete) {
        response = await dispatch(createOrganizationAthlete(organizationId, updatedAthlete));
      } else {
        delete updatedAthlete.tableData;
        response = await dispatch(updateOrganizationAthlete(organizationId, updatedAthlete));
      }

      if (
        response.type !== CREATE_ORGANIZATION_ATHLETE_SUCCESS &&
        response.type !== UPDATE_ORGANIZATION_ATHLETE_SUCCESS
      ) {
        const errorMessage = isNewAthlete
          ? ERROR_MESSAGES.CREATE_ATHLETE_FAILURE
          : ERROR_MESSAGES.UPDATE_ATHLETE_FAILURE;
        const isDuplicateError = response.response.message === 'Duplication entry';

        dispatch(
          handleToastMessage(
            errorMessage,
            TOAST_TYPES.ERROR,
            isDuplicateError ? ERROR_MESSAGES.CREATE_DUPLICATE_ATHLETE_ERROR : ''
          )
        );
        return;
      }

      const successMessage = isNewAthlete
        ? SUCCESS_MESSAGES.CREATE_ATHLETE_SUCCESS
        : SUCCESS_MESSAGES.UPDATE_ATHLETE_SUCCESS;

      dispatch(handleToastMessage(successMessage));
      history.push(`/organizations/${organizationId}/athletes`);
    })();
  }, [dispatch, history, athlete, isNewAthlete, organizationId]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setAthlete({ ...athlete, [name]: value }), [
    athlete,
    setAthlete,
  ]);

  const submitText = useMemo(() => getSubmitText(isLoadingAthlete, isNewAthlete), [isLoadingAthlete, isNewAthlete]);

  const breadCrumbs = useMemo(() => {
    if (!isNil(athlete)) {
      let athleteName = isNewAthlete && 'create';

      if (!isNewAthlete && !isEmpty(location.state)) athleteName = location.state.athlete.firstName;
      if (!isNewAthlete && !isNil(selectedAthlete)) athleteName = selectedAthlete.firstName;

      return generateBreadCrumbMap(generateRouteParamsMap(ATHLETE_ID_MAP, [organizationId, athleteId]), {
        [ROUTES.ATHLETES_ROUTE]: PAGE_TITLES.ATHLETE_ENROLLMENT,
        [isNewAthlete ? ROUTES.NEW_ATHLETE_ROUTE : ROUTES.ATHLETE_ROUTE]: athleteName,
      });
    }

    return {};
  }, [location, isNewAthlete, organizationId, selectedAthlete, athlete, athleteId]);

  if (isLoadingOrganization || isNil(athlete))
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  const DISABLE_CHANGE =
    isLoadingAthlete || isNewAthlete ? !can(PERMISSIONS.CREATE_ATHLETES) : !can(PERMISSIONS.EDIT_ATHLETES);

  return (
    <>
      <PageWrapper className={classes.pageWrapper}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <SportScaleBreadCrumbs breadCrumbs={breadCrumbs} />

          <Box>
            {!isNewAthlete && can(PERMISSIONS.REMOVE_ATHLETES) && (
              <SportScalePrimaryButton onClick={() => setRemoveDialogOpen(true)}>
                remove athlete
              </SportScalePrimaryButton>
            )}
            {!isNewAthlete && can(PERMISSIONS.REMOVE_ATHLETES) && athlete.hasFaceData && (
              <SportScalePrimaryButton
                onClick={() => setRemoveFaceDataDialogOpen(true)}
                className={classes.faceDataButton}
              >
                remove face data
              </SportScalePrimaryButton>
            )}
          </Box>
        </Box>
        <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitAthlete}>
          <AthleteForm disabled={DISABLE_CHANGE} athlete={athlete} onChange={handleInputChange} />
          <Box alignItems="flex-end" display="flex" flex={1} justifyContent="space-between" width="100%">
            <SportScaleSecondaryButton
              disabled={isLoadingAthlete}
              onClick={() => history.push(`/organizations/${organizationId}/athletes`)}
            >
              cancel
            </SportScaleSecondaryButton>
            <SportScalePrimaryButton disabled={DISABLE_CHANGE} type="submit">
              {submitText}
            </SportScalePrimaryButton>
          </Box>
        </ValidatorForm>
      </PageWrapper>
      {!isNil(athlete) && (
        <>
          <SportScaleDialog
            actions={[
              {
                action: () => {
                  setRemoveDialogOpen(false);
                },
                disabled: isLoadingAthlete,
                text: 'Cancel',
              },
              {
                action: handleDeleteAthlete,
                disabled: isLoadingAthlete || !can(PERMISSIONS.REMOVE_ATHLETES),
                isPrimary: true,
                text: isLoadingAthlete ? 'Removing...' : 'Remove',
              },
            ]}
            content={
              <DialogContentText>
                Are you sure you want to remove {athlete.firstName} {athlete.lastName} from this organization?
              </DialogContentText>
            }
            open={removeDialogOpen}
            title="Remove Athlete?"
            onClose={() => {
              setRemoveDialogOpen(false);
            }}
          />

          <SportScaleDialog
            actions={[
              {
                action: () => {
                  setRemoveFaceDataDialogOpen(false);
                },
                disabled: isLoadingAthlete,
                text: 'Cancel',
              },
              {
                action: handleDeleteFaceData,
                disabled: isLoadingAthlete || !can(PERMISSIONS.REMOVE_ATHLETES),
                isPrimary: true,
                text: isLoadingAthlete ? 'Removing...' : 'Remove',
              },
            ]}
            content={
              <DialogContentText>
                Are you sure you want to remove face data from {athlete.firstName} {athlete.lastName}?
              </DialogContentText>
            }
            open={removeFaceDataDialogOpen}
            title="Remove Athlete's Face Data?"
            onClose={() => {
              setRemoveFaceDataDialogOpen(false);
            }}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  faceDataButton: {
    marginLeft: theme.spacing(2),
  },
}));

AthleteContainer.propTypes = {};

export default AthleteContainer;
