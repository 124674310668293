import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import PageCard from 'common/components/pageCard.component';
import PageWrapper from 'modules/layout/pageWrapper.component';

const EmptyOrganizations = (/* props */) => {
  const classes = useStyles();

  return (
    <PageWrapper className={classes.pageWrapper}>
      <PageCard
        content={
          <>
            <ErrorOutlineIcon className={classes.icon} />
            <Typography variant="body1" component="p">
              You are not apart of an organization. Please speak with your system administrator..
            </Typography>
          </>
        }
      />
    </PageWrapper>
  );
};

const useStyles = makeStyles((/* theme */) => ({
  pageWrapper: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '2rem',
  },
}));

EmptyOrganizations.propTypes = {};

export default EmptyOrganizations;
