import React, { useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

const LoginForm = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSubmit = useCallback(async () => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return (
    <Box width="100%" textAlign="center">
      <Button color="primary" fullWidth type="submit" variant="contained" onClick={handleSubmit}>
        Login
      </Button>
    </Box>
  );
};

export default LoginForm;
