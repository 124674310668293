import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { InfoOutlined, Person } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import { selectSelectedOrganizationId } from 'modules/organizations/organizations.selectors';

const HYDRATION_TOOLTIP = 'Weigh-ins/weigh-outs and change calculations (</= 36 hours between weigh-ins)';
const SIMPLE_TOOLTIP = 'Recording weight assessments';

const WatchlistCard = ({ item, onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const organizationId = useSelector(selectSelectedOrganizationId);

  const onClickRoster = () =>
    history.push(`/organizations/${organizationId}/teams/${item.teamId}/seasons/${item.rosterId}`);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.clickable} variant="h6" color="primary" onClick={onClickRoster}>
          {item.teamName} - {item.rosterName}
        </Typography>
        <div className={classes.cardTypeContainer}>
          <Typography className={classes.cardTypeText} variant="subtitle1">
            {item.hydrationTracking ? 'Hydration Tracking' : 'Weight Tracking'}
          </Typography>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={item.hydrationTracking ? HYDRATION_TOOLTIP : SIMPLE_TOOLTIP}
            aria-label="roster"
          >
            <InfoOutlined className={classes.tooltipIcon} />
          </Tooltip>
        </div>
        <List className={classes.list}>
          {item.hydrationTracking &&
            item.members.length > 0 &&
            item.members.map(({ athlete, athleteId }) => (
              <ListItem key={athleteId} className={classNames(classes.listItem, { [classes.clickable]: onClick })}>
                <ListItemIcon className={classes.icon}>
                  <Person />
                </ListItemIcon>
                <ListItemText onClick={() => onClick(athlete)}>
                  {`${athlete.firstName} ${athlete.lastName}`}
                </ListItemText>
              </ListItem>
            ))}
          {item.hydrationTracking && !item.members.length && (
            <Typography>There are no athletes on this watchlist.</Typography>
          )}
          {!item.hydrationTracking && (
            <SportScalePrimaryButton className={classes.viewRosterButton} onClick={onClickRoster}>
              View Roster
            </SportScalePrimaryButton>
          )}
        </List>
      </CardContent>
    </Card>
  );
};
WatchlistCard.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1.5),
  },
  list: {
    height: '25vh',
    overflowY: 'auto',
  },
  cardTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
  },
  cardTypeText: {
    borderBottom: `1px solid ${theme.palette.common.white}`,
    fontSize: '1rem',
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    fontSize: '0.8rem',
  },
  tooltipIcon: {
    fontSize: '1rem',
    paddingLeft: theme.spacing(0.5),
  },
  viewRosterButton: {
    marginTop: theme.spacing(3),
  },
  icon: {
    minWidth: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
  },
  listItem: {
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    lineBreak: 'anywhere',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default WatchlistCard;
