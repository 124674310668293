import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import FIELD_LABELS from 'types/fieldLabels';
import SportScaleOverflowTooltip from 'common/components/sportScaleOverflowTooltip.component';
import SportScaleTable from 'common/table/sportScaleTable.component';
import SportScaleTableActionButton from 'common/table/sportScaleTableActionButton.component';
import SportScaleTableToolbar from 'common/table/sportScaleTableToolbar.component';
import { PERMISSIONS, can } from 'common/permissions';
import { selectScales, selectScalesIsLoading } from 'modules/scales/scales.selectors';

const SCALE_IDENTIFIER_REGEX = /([A-Za-z0-9]{3})([A-Za-z0-9]{3})([A-Za-z0-9]{4})/;

const COLUMNS = [
  {
    title: FIELD_LABELS.NAME,
    render: rowData => <SportScaleOverflowTooltip text={rowData.name} />,
    cellStyle: { maxWidth: 150 },
  },
  {
    title: FIELD_LABELS.SCALE_REGISTRATION_CODE,
    render: rowData => <Typography>{rowData.identifier.replace(SCALE_IDENTIFIER_REGEX, '$1-$2-$3')}</Typography>,
  },
  {
    customFilterAndSearch: (term, rowData) => isEmpty(rowData) || rowData.location.name.includes(term),
    render: rowData => {
      if (isNil(rowData.location)) return 'N/A';

      return rowData.location.name;
    },
    title: FIELD_LABELS.LOCATION,
  },
  { title: FIELD_LABELS.MODEL_NUMBER, field: 'model' },
];

const ScalesTable = memo(({ onRegister }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const isLoadingScales = useSelector(selectScalesIsLoading);
  const scales = useSelector(selectScales);

  return (
    <SportScaleTable
      actions={
        can(PERMISSIONS.EDIT_SCALES)
          ? [
              {
                icon: '',
                text: 'edit',
                onClick: rowData => history.push(`${location.pathname}/${rowData.id}`, { scale: rowData }),
              },
            ]
          : []
      }
      columns={COLUMNS}
      components={{
        Action: SportScaleTableActionButton(classes.actionButton, false),
        Toolbar: SportScaleTableToolbar(
          can(PERMISSIONS.CREATE_SCALES) ? [{ text: 'register scale', onClick: () => onRegister(true) }] : []
        ),
      }}
      data={scales || []}
      isLoading={isLoadingScales}
      options={{ toolbar: true }}
    />
  );
});

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
}));

ScalesTable.propTypes = {
  onRegister: PropTypes.func.isRequired,
};

export default ScalesTable;
