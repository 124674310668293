import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardActions, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import { isNil } from 'lodash';

const PageCard = ({ content, title, actions }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      {!isNil(title) && <CardHeader title={title} />}
      <CardContent className={classes.cardContent}>{content}</CardContent>
      {!isNil(actions) && <CardActions>{actions}</CardActions>}
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxHeight: '50%',
    padding: theme.spacing(),
    width: '50%',
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

PageCard.propTypes = {
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  title: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

PageCard.defaultProps = {
  actions: null,
  title: null,
};

export default PageCard;
