import PropTypes from 'prop-types';
import { Component } from 'react';

import packageJson from '../../../package.json';

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    /* eslint-disable-next-line no-restricted-globals */
    if (a !== b) return a !== b || isNaN(b);
  }

  return false;
};

class CacheBuster extends Component {
  state = {
    // set to true because we are always 'loading' on mount (fetching meta.json file)
    // we dont want a flicker on route loading then set loading=true
    // ie route loads then has to be refreshed - user sees flicker
    loading: true,
    shouldForceRefresh: false,
  };

  componentDidMount() {
    fetch(`/meta.json?dt=${Date.now()}`)
      .then(response => response.json())
      .then(meta => {
        // compare versions
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

        // we are done loading - set if we need to force refresh
        this.setState({ loading: false, shouldForceRefresh });
      })
      .catch((/* e */) => {
        // catch here if no meta.json file exists (development or a fallback to do nothing)
        this.setState({ loading: false, shouldForceRefresh: false });
      });
  }

  refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
  };

  render() {
    const { loading, shouldForceRefresh } = this.state;

    return this.props.children({
      loading,
      shouldForceRefresh,
      refreshCacheAndReload: this.refreshCacheAndReload,
    });
  }
}

CacheBuster.propTypes = {
  children: PropTypes.any,
};

CacheBuster.defaultProps = {
  children: () => {},
};

export default CacheBuster;
