import PropTypes from 'prop-types';
import React from 'react';
import { FormControlLabel, Radio, makeStyles } from '@material-ui/core';

const SportScaleRadio = ({ label, labelPlacement, value, ...rest }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      classes={{
        label: classes.controlLabel,
      }}
      control={
        <Radio
          color="primary"
          classes={{
            checked: classes.radioChecked,
            root: classes.radioRoot,
          }}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      {...rest}
    />
  );
};

const useStyles = makeStyles(theme => ({
  radioChecked: {
    color: theme.palette.success.main,
  },
  radioRoot: {
    '&:hover': {
      backgroundColor: theme.palette.success,
    },
  },
  controlLabel: {
    /* Needed to match question font size */
    fontSize: '0.875rem',
  },
}));

SportScaleRadio.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  labelPlacement: PropTypes.string,
  value: PropTypes.any.isRequired,
};

SportScaleRadio.defaultProps = {
  disabled: false,
  labelPlacement: 'end',
};

export default SportScaleRadio;
