import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DialogContentText, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isArray, isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleBreadCrumbs from 'modules/layout/breadCrumbs/breadCrumbs.component';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import UserForm, { ORGANIZATION_ROLES } from 'modules/users/userForm.component';
import {
  CREATE_ORGANIZATION_USER_SUCCESS,
  DELETE_ORGANIZATION_USER_SUCCESS,
  RESEND_USER_VERIFICATION_EMAIL_SUCCESS,
  UPDATE_ORGANIZATION_USER_SUCCESS,
  createOrganizationUser,
  deleteOrganizationUser,
  getOrganizationUser,
  resendVerificationEmail,
  resetOrganizationSelectedUser,
  updateOrganizationUser,
} from 'modules/users/users.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { ROUTES, USER_ID_MAP, generateBreadCrumbMap, generateRouteParamsMap } from 'utilities/breadCrumbs';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { getOrganization, getOrganizationRoles } from 'modules/organizations/organizations.actions';
import { getOrganizationTeams } from 'modules/teams/teams.actions';
import { getSubmitText } from 'utilities/form';

const INITIAL_USER = {
  email: '',
  roles: [ORGANIZATION_ROLES.ALL_TEAMS],
};

const UserContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { organizationId, userId } = useParams();

  const { isLoading, isLoadingEmail, isLoadingUser, organization, roles, selectedUser, teams } = useSelector(
    // eslint-disable-next-line no-shadow
    ({ organizations, teams, users }) => ({
      isLoading: organizations.isLoading || teams.isLoading,
      isLoadingEmail: users.isLoadingEmail,
      isLoadingUser: users.isLoadingUser,
      organization: organizations.organization,
      roles: organizations.roles,
      selectedUser: users.selectedUser,
      teams: teams.teams,
    })
  );

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [user, setUser] = useState(null);

  const isNewUser = useMemo(() => userId === NEW_ITEM_ROUTE, [userId]);

  useEffect(() => {
    if (isNewUser) setUser(INITIAL_USER);
  }, [isNewUser]);

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationSelectedUser());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(selectedUser)) {
      const selectedUserRoles = isEmpty(selectedUser.roles) ? INITIAL_USER.roles : selectedUser.roles;

      setUser({ ...selectedUser, roles: selectedUserRoles });
    }
  }, [dispatch, selectedUser]);

  useEffect(() => {
    if (isNil(organization)) {
      dispatch(getOrganization(organizationId));
    } else {
      dispatch(setPageTitle(`${organization.name} - ${PAGE_TITLES.USERS}`));
      dispatch(getOrganizationRoles(organization.id));
      dispatch(getOrganizationTeams(organization.id));
    }

    if (!isNil(organization) && !isNewUser) {
      dispatch(getOrganizationUser(organization.id, userId));
    }
  }, [dispatch, location, isNewUser, organization, organizationId, userId]);

  const hasLoggedIn = useMemo(() => !isNil(user) && user.lastLogin, [user]);

  const handleDeleteUser = useCallback(() => {
    (async () => {
      const response = await dispatch(deleteOrganizationUser(organization.id, userId));

      setRemoveDialogOpen(false);

      if (response.type !== DELETE_ORGANIZATION_USER_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_USER_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_USER_SUCCESS));
      history.replace(`/organizations/${organization.id}/users`);
    })();
  }, [dispatch, history, organization, userId]);

  const handleSubmitUser = useCallback(() => {
    (async () => {
      let type;
      let messages;

      if (isNewUser) {
        const response = await dispatch(createOrganizationUser(organizationId, user));
        type = response.type;
        messages = response.messages;
      } else {
        const response = await dispatch(updateOrganizationUser(organizationId, user));
        type = response.type;
      }

      if (type !== CREATE_ORGANIZATION_USER_SUCCESS && type !== UPDATE_ORGANIZATION_USER_SUCCESS) {
        const errorMessage = isNewUser ? ERROR_MESSAGES.CREATE_USER_FAILURE : ERROR_MESSAGES.UPDATE_USER_FAILURE;

        dispatch(handleToastMessage(errorMessage, TOAST_TYPES.ERROR, isArray(messages) ? messages[0] : null));
        return;
      }

      const successMessage = isNewUser ? SUCCESS_MESSAGES.CREATE_USER_SUCCESS : SUCCESS_MESSAGES.UPDATE_USER_SUCCESS;

      dispatch(handleToastMessage(successMessage));
      history.push(`/organizations/${organizationId}/users`);
    })();
  }, [dispatch, history, isNewUser, organizationId, user]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setUser({ ...user, [name]: value }), [
    user,
    setUser,
  ]);

  const submitText = useMemo(() => getSubmitText(isLoadingUser, isNewUser), [isLoadingUser, isNewUser]);

  const breadCrumbs = useMemo(() => {
    if (!isNil(user)) {
      let userName = isNewUser && 'create';

      if (!isNil(selectedUser)) userName = !isEmpty(selectedUser.name) ? selectedUser.name : 'user';

      return generateBreadCrumbMap(generateRouteParamsMap(USER_ID_MAP, [organizationId, userId]), {
        [ROUTES.USERS_ROUTE]: PAGE_TITLES.USERS,
        [isNewUser ? ROUTES.NEW_USER_ROUTE : ROUTES.USER_ROUTE]: userName,
      });
    }

    return {};
  }, [isNewUser, organizationId, selectedUser, user, userId]);

  const handleResendVerificationEmail = useCallback(() => {
    (async () => {
      const response = await dispatch(resendVerificationEmail(organizationId, userId));

      if (response.type !== RESEND_USER_VERIFICATION_EMAIL_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.RESEND_USER_VERIFICATION_EMAIL_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.RESEND_USER_VERIFICATION_EMAIL_SUCCESS));
    })();
  }, [dispatch, organizationId, userId]);

  if (isLoading || isNil(user) || isNil(roles) || isNil(teams))
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  const DISABLE_CHANGE = isLoadingUser || isNewUser ? !can(PERMISSIONS.CREATE_USERS) : !can(PERMISSIONS.EDIT_USERS);
  return (
    <>
      <PageWrapper className={classes.pageWrapper}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <SportScaleBreadCrumbs breadCrumbs={breadCrumbs} />
          <Box>
            {!hasLoggedIn && !isNewUser && (
              <SportScalePrimaryButton
                className={classes.actions}
                disabled={isLoadingEmail}
                onClick={handleResendVerificationEmail}
              >
                resend email
              </SportScalePrimaryButton>
            )}
            {!isNewUser && can(PERMISSIONS.REMOVE_USERS) && (
              <SportScalePrimaryButton className={classes.actions} onClick={() => setRemoveDialogOpen(true)}>
                remove user
              </SportScalePrimaryButton>
            )}
          </Box>
        </Box>
        <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitUser}>
          <UserForm
            disabled={DISABLE_CHANGE}
            isNewUser={isNewUser}
            roles={roles}
            teams={teams}
            user={user}
            onChange={handleInputChange}
          />
          <Box alignItems="flex-end" display="flex" flex={1} justifyContent="space-between" width="100%">
            <SportScaleSecondaryButton
              disabled={isLoadingUser}
              onClick={() => history.push(`/organizations/${organizationId}/users`)}
            >
              cancel
            </SportScaleSecondaryButton>
            <SportScalePrimaryButton disabled={DISABLE_CHANGE} type="submit">
              {submitText}
            </SportScalePrimaryButton>
          </Box>
        </ValidatorForm>
      </PageWrapper>
      {!isNil(user) && (
        <SportScaleDialog
          actions={[
            {
              action: () => {
                setRemoveDialogOpen(false);
              },
              disabled: isLoadingUser,
              text: 'Cancel',
            },
            {
              action: handleDeleteUser,
              disabled: isLoadingUser,
              isPrimary: true,
              text: isLoadingUser ? 'Removing...' : 'Remove',
            },
          ]}
          content={
            <DialogContentText>
              Are you sure you want to remove {!isNil(user.name) ? user.name : user.email} from this organization?
            </DialogContentText>
          }
          open={removeDialogOpen}
          title="Remove User?"
          onClose={() => {
            setRemoveDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  actions: {
    marginLeft: theme.spacing(2),
  },
}));

UserContainer.propTypes = {};

export default UserContainer;
