import produce from 'immer';

import {
  CREATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
  CREATE_ORGANIZATION_SUBSCRIPTION_REQUEST,
  CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  DELETE_ORGANIZATION_SUBSCRIPTION_FAILURE,
  DELETE_ORGANIZATION_SUBSCRIPTION_REQUEST,
  DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  GET_ORGANIZATION_SUBSCRIPTIONS_FAILURE,
  GET_ORGANIZATION_SUBSCRIPTIONS_REQUEST,
  GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS,
  GET_ORGANIZATION_SUBSCRIPTION_FAILURE,
  GET_ORGANIZATION_SUBSCRIPTION_REQUEST,
  GET_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  RESET_ORGANIZATION_SUBSCRIPTION,
  RESET_ORGANIZATION_SUBSCRIPTIONS,
  UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
  UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST,
  UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
} from 'modules/organizations/subscriptions/subscriptions.actions';

const initialState = {
  isLoadingSubscription: false,
  isLoadingSubscriptions: false,
  subscription: null,
  subscriptions: [],
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS:
      draft.subscriptions = action.response;
      draft.isLoadingSubscriptions = false;
      break;

    case GET_ORGANIZATION_SUBSCRIPTIONS_FAILURE:
      draft.isLoadingSubscriptions = false;
      break;

    case GET_ORGANIZATION_SUBSCRIPTIONS_REQUEST:
      draft.isLoadingSubscriptions = true;
      break;

    case GET_ORGANIZATION_SUBSCRIPTION_SUCCESS:
      draft.subscription = action.response;
      draft.isLoadingSubscription = false;
      break;

    case CREATE_ORGANIZATION_SUBSCRIPTION_FAILURE:
    case CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS:
    case GET_ORGANIZATION_SUBSCRIPTION_FAILURE:
    case UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE:
    case UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS:
    case DELETE_ORGANIZATION_SUBSCRIPTION_FAILURE:
    case DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS:
      draft.isLoadingSubscription = false;
      break;

    case CREATE_ORGANIZATION_SUBSCRIPTION_REQUEST:
    case GET_ORGANIZATION_SUBSCRIPTION_REQUEST:
    case UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST:
    case DELETE_ORGANIZATION_SUBSCRIPTION_REQUEST:
      draft.isLoadingSubscription = true;
      break;

    case RESET_ORGANIZATION_SUBSCRIPTIONS:
      draft.subscriptions = [];
      break;

    case RESET_ORGANIZATION_SUBSCRIPTION:
      draft.subscription = null;
      break;

    default:
      break;
  }
}, initialState);
