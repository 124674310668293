import { NEW_ITEM_ROUTE } from 'types/routes';

export const ATHLETE_ROUTE_PARAM = ':athleteId';
export const LOCATION_ROUTE_PARAM = ':locationId';
export const ORGANIZATION_ROUTE_PARAM = ':organizationId';
export const SCALE_ROUTE_PARAM = ':scaleId';
export const SEASON_ROUTE_PARAM = ':seasonId';
export const SUBSCRIPTION_ROUTE_PARAM = ':subscriptionId';
export const TEAM_ROUTE_PARAM = ':teamId';
export const USER_ROUTE_PARAM = ':userId';

export const ATHLETE_ID_MAP = [ORGANIZATION_ROUTE_PARAM, ATHLETE_ROUTE_PARAM];
export const SEASON_ID_MAP = [ORGANIZATION_ROUTE_PARAM, TEAM_ROUTE_PARAM, SEASON_ROUTE_PARAM];
export const TEAM_ID_MAP = [ORGANIZATION_ROUTE_PARAM, TEAM_ROUTE_PARAM];
export const USER_ID_MAP = [ORGANIZATION_ROUTE_PARAM, USER_ROUTE_PARAM];
export const LOCATION_ID_MAP = [ORGANIZATION_ROUTE_PARAM, LOCATION_ROUTE_PARAM];
export const SCALE_ID_MAP = [ORGANIZATION_ROUTE_PARAM, SCALE_ROUTE_PARAM];
export const SUBSCRIPTION_ID_MAP = [ORGANIZATION_ROUTE_PARAM, SUBSCRIPTION_ROUTE_PARAM];
export const ORGANIZATION_ID_MAP = [ORGANIZATION_ROUTE_PARAM];

export const ORGANIZATION_BASE_ROUTE = `/organizations/${ORGANIZATION_ROUTE_PARAM}`;

export const ROUTES = {
  ATHLETES_ROUTE: `${ORGANIZATION_BASE_ROUTE}/athletes`,
  ATHLETE_ROUTE: `${ORGANIZATION_BASE_ROUTE}/athletes/${ATHLETE_ROUTE_PARAM}`,
  NEW_ATHLETE_ROUTE: `${ORGANIZATION_BASE_ROUTE}/athletes/${NEW_ITEM_ROUTE}`,
  TEAMS_ROUTE: `${ORGANIZATION_BASE_ROUTE}/teams`,
  TEAM_ROUTE: `${ORGANIZATION_BASE_ROUTE}/teams/${TEAM_ROUTE_PARAM}`,
  NEW_TEAM_ROUTE: `${ORGANIZATION_BASE_ROUTE}/teams/${NEW_ITEM_ROUTE}`,
  SEASON_ROUTE: `${ORGANIZATION_BASE_ROUTE}/teams/${TEAM_ROUTE_PARAM}/seasons/${SEASON_ROUTE_PARAM}`,
  NEW_SEASON_ROUTE: `${ORGANIZATION_BASE_ROUTE}/teams/${TEAM_ROUTE_PARAM}/seasons/${NEW_ITEM_ROUTE}`,
  USERS_ROUTE: `${ORGANIZATION_BASE_ROUTE}/users`,
  USER_ROUTE: `${ORGANIZATION_BASE_ROUTE}/users/${USER_ROUTE_PARAM}`,
  NEW_USER_ROUTE: `${ORGANIZATION_BASE_ROUTE}/users/${NEW_ITEM_ROUTE}`,
  LOCATIONS_ROUTE: `${ORGANIZATION_BASE_ROUTE}/locations`,
  LOCATION_ROUTE: `${ORGANIZATION_BASE_ROUTE}/locations/${LOCATION_ROUTE_PARAM}`,
  NEW_LOCATION_ROUTE: `${ORGANIZATION_BASE_ROUTE}/locations/${NEW_ITEM_ROUTE}`,
  SCALES_ROUTE: `${ORGANIZATION_BASE_ROUTE}/scales`,
  SCALE_ROUTE: `${ORGANIZATION_BASE_ROUTE}/scales/${SCALE_ROUTE_PARAM}`,
  NEW_SCALE_ROUTE: `${ORGANIZATION_BASE_ROUTE}/scales/${NEW_ITEM_ROUTE}`,
  SUBSCRIPTION_ROUTE: `${ORGANIZATION_BASE_ROUTE}/subscriptions/${SUBSCRIPTION_ROUTE_PARAM}`,
  NEW_SUBSCRIPTION_ROUTE: `${ORGANIZATION_BASE_ROUTE}/subscriptions/${NEW_ITEM_ROUTE}`,
  WATCH_LIST_ROUTE: `${ORGANIZATION_BASE_ROUTE}/currentSports`,
  ANALYSIS_ROUTE: `${ORGANIZATION_BASE_ROUTE}/reports`,
  ORGANIZATION_ROUTE: `${ORGANIZATION_BASE_ROUTE}`,
};

export const BREAD_CRUMB_ICONS = {
  [ROUTES.ATHLETES_ROUTE]: 'fal fa-running',
  [ROUTES.ATHLETE_ROUTE]: 'fas fa-running',
  [ROUTES.NEW_ATHLETE_ROUTE]: 'fal fa-pencil',
  [ROUTES.TEAMS_ROUTE]: 'fal fa-users-class',
  [ROUTES.TEAM_ROUTE]: 'fal fa-trophy',
  [ROUTES.NEW_TEAM_ROUTE]: 'fal fa-pencil',
  [ROUTES.SEASON_ROUTE]: 'fal fa-calendar-alt',
  [ROUTES.NEW_SEASON_ROUTE]: 'fal fa-pencil',
  [ROUTES.USERS_ROUTE]: 'fal fa-users',
  [ROUTES.USER_ROUTE]: 'fal fa-user',
  [ROUTES.NEW_USER_ROUTE]: 'fal fa-pencil',
  [ROUTES.LOCATIONS_ROUTE]: 'far fa-map-marker-alt',
  [ROUTES.LOCATION_ROUTE]: 'fas fa-map-marker-alt',
  [ROUTES.NEW_LOCATION_ROUTE]: 'fal fa-pencil',
  [ROUTES.SCALES_ROUTE]: 'far fa-map-marker-alt',
  [ROUTES.SCALE_ROUTE]: 'fas fa-map-marker-alt',
  [ROUTES.NEW_SCALE_ROUTE]: 'fal fa-pencil',
  [ROUTES.SUBSCRIPTION_ROUTE]: 'fal fa-credit-card-front',
  [ROUTES.NEW_SUBSCRIPTION_ROUTE]: 'fal fa-credit-card-front',
  [ROUTES.WATCH_LIST_ROUTE]: 'fal fa-eye',
  [ROUTES.ANALYSIS_ROUTE]: 'fal fa-file-chart-pie',
  [ROUTES.ORGANIZATION_ROUTE]: 'fal fa-sitemap',
};

/*
  The below function creates a mapping of a full route with the replacement params as the keys and an object of
  text and icon as it's values to be used within the breadcrumbs. SportScaleBreadCrumb component uses the map keys in order to
  navigate to the proper place. Examples of inputs w/ output below:

  routesParamsMap - {
    ORGANIZATION_ROUTE_PARAM: 'org_xyz',
    TEAM_ROUTE_PARAM: 'team_xyz',
  }

  breadCrumbsMap - {
    [ROUTES.TEAMS_ROUTE]: 'Teams',
    [ROUTES.TEAM_ROUTE]: 'Football',
  }

  The output is a route w/ params replaced as keys for the "history.push" and the values are objects for the breadcrumbs to use as
  text and icons:

  output - {
    '/organizations/org_xyz/teams/': {
      icon: 'fal fa-users-class',
      text: 'Teams'
    },
    'organizations/org_xyz/teams/team_xyz: {
      icon: 'fal fa-football-ball',
      text: 'Football'
    }
  }
*/

export const generateBreadCrumbMap = (routeParamsMap, breadCrumbsTextMap) => {
  const clone = {};

  const breadCrumbsTextMapKeys = Object.keys(breadCrumbsTextMap);
  const routeParamsMapKeys = Object.keys(routeParamsMap);

  breadCrumbsTextMapKeys.forEach(k => {
    let newKey = k;

    routeParamsMapKeys.forEach(idKey => {
      newKey = newKey.replace(idKey, routeParamsMap[idKey]);
    });

    clone[newKey] = {
      text: breadCrumbsTextMap[k],
      icon: BREAD_CRUMB_ICONS[k],
    };
  });

  return clone;
};

export const generateRouteParamsMap = (idKeys, idValues) =>
  idKeys.reduce((idMap, key, i) => ({ ...idMap, [key]: idValues[i] }), {});
