import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE';

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';

export const SET_SELECTED_ORG_ID = 'SET_SELECTED_ORG_ID';

export const GET_ORGANIZATION_ROLES_REQUEST = 'GET_ORGANIZATION_ROLES_REQUEST';
export const GET_ORGANIZATION_ROLES_SUCCESS = 'GET_ORGANIZATION_ROLES_SUCCESS';
export const GET_ORGANIZATION_ROLES_FAILURE = 'GET_ORGANIZATION_ROLES_FAILURE';

export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';

export const getOrganizations = () => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATIONS_REQUEST, GET_ORGANIZATIONS_SUCCESS, GET_ORGANIZATIONS_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations`,
      method: 'GET',
    },
  };
};

export const getOrganization = organizationId => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_REQUEST, GET_ORGANIZATION_SUCCESS, GET_ORGANIZATION_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}`,
      method: 'GET',
    },
  };
};

export const setSelectedOrganizationId = organizationId => {
  return {
    type: SET_SELECTED_ORG_ID,
    organizationId,
  };
};

export const getOrganizationRoles = organizationId => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_ROLES_REQUEST, GET_ORGANIZATION_ROLES_SUCCESS, GET_ORGANIZATION_ROLES_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/roles`,
      method: 'GET',
    },
  };
};

export const createOrganization = organization => {
  return {
    [CALL_API]: {
      types: [CREATE_ORGANIZATION_REQUEST, CREATE_ORGANIZATION_SUCCESS, CREATE_ORGANIZATION_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations`,
      method: 'POST',
      payload: organization,
    },
  };
};

export const updateOrganization = (organizationId, payload) => {
  return {
    [CALL_API]: {
      types: [UPDATE_ORGANIZATION_REQUEST, UPDATE_ORGANIZATION_SUCCESS, UPDATE_ORGANIZATION_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}`,
      method: 'PUT',
      payload,
    },
  };
};
