import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

import SportScaleCustomComponentValidator from 'common/formFields/sportScaleCustomValidator.component';
import SportScaleDatePicker from 'common/formFields/sportScaleDatePicker.component';
import { VALIDATION_TYPES, fieldValidators, getValidationErrorMessages } from 'utilities/fieldValidation';
import { ValidatorForm } from 'react-material-ui-form-validator';

const SportScaleDatePickerValidator = memo(
  ({
    compareLabel,
    compareValue,
    disableBlurValidation,
    errorMessages,
    label,
    name,
    validators,
    value,
    onBlur,
    onChange,
    ...rest
  }) => {
    useEffect(() => {
      let ruleAdded = false;

      if (!ValidatorForm.hasValidationRule(VALIDATION_TYPES.IS_DATE)) {
        ruleAdded = true;

        ValidatorForm.addValidationRule(
          VALIDATION_TYPES.IS_DATE,
          dateValue =>
            !(fieldValidators[VALIDATION_TYPES.IS_DATE] && fieldValidators[VALIDATION_TYPES.IS_DATE](dateValue))
        );
      }

      return () => {
        if (ruleAdded && ValidatorForm.hasValidationRule(VALIDATION_TYPES.IS_DATE))
          ValidatorForm.removeValidationRule(VALIDATION_TYPES.IS_DATE);
      };
    }, []);

    return (
      <SportScaleCustomComponentValidator
        disableBlurValidation={disableBlurValidation}
        errorMessages={[getValidationErrorMessages(value, label, [VALIDATION_TYPES.IS_DATE]), ...errorMessages]}
        label={label}
        name={name}
        validators={[VALIDATION_TYPES.IS_DATE, ...validators]}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      >
        <SportScaleDatePicker {...rest} />
      </SportScaleCustomComponentValidator>
    );
  }
);

SportScaleDatePickerValidator.propTypes = {
  compareLabel: PropTypes.string,
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array.isRequired,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScaleDatePickerValidator.defaultProps = {
  compareLabel: null,
  compareValue: null,
  disabled: false,
  disableBlurValidation: false,
  value: null,
  onBlur: () => {},
};

export default SportScaleDatePickerValidator;
