import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormControl, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';

import FIELD_LABELS from 'types/fieldLabels';
import SportScaleDatePickerValidator from 'common/formFields/sportScaleDatePickerValidator.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { VALIDATION_TYPES, fieldValidators, getValidationErrorMessages } from 'utilities/fieldValidation';
import { selectAthletesIsLoadingAthlete } from 'modules/athletes/athletes.selectors';

const TARGET_WEIGHT_FORM_RULES = [
  VALIDATION_TYPES.IS_DATE_IN_PAST,
  VALIDATION_TYPES.IS_NUMBER_OR_FLOAT,
  VALIDATION_TYPES.VALID_WEIGHT,
];

const WeightsTargetWeightForm = ({ formRef, targetWeight, onChange }) => {
  const classes = useStyles();
  const isLoading = useSelector(selectAthletesIsLoadingAthlete);

  useEffect(() => {
    const rulesAdded = [];

    TARGET_WEIGHT_FORM_RULES.forEach(validator => {
      if (!ValidatorForm.hasValidationRule(validator)) {
        ValidatorForm.addValidationRule(validator, value => {
          rulesAdded.push(validator);
          let message = false;

          if (fieldValidators[validator]) {
            message = fieldValidators[validator](value);
          }

          return message === false;
        });
      }
    });

    return () => {
      rulesAdded.forEach(validator => ValidatorForm.removeValidationRule(validator));
    };
  }, []);

  return (
    <ValidatorForm
      ref={formRef}
      className={classes.form}
      onSubmit={() => {
        // This is purposefully blank as the submit is handled outside the form
      }}
    >
      <FormControl className={classes.formInput}>
        <SportScaleTextFieldValidator
          disabled={isLoading}
          errorMessages={getValidationErrorMessages(targetWeight.targetWeight, FIELD_LABELS.TARGET_WEIGHT, [
            VALIDATION_TYPES.REQUIRED,
            `${VALIDATION_TYPES.MAX_STRING_LENGTH}:5`,
            VALIDATION_TYPES.IS_NUMBER_OR_FLOAT,
            VALIDATION_TYPES.IS_POSITIVE,
            VALIDATION_TYPES.VALID_WEIGHT,
          ])}
          fullWidth
          label={FIELD_LABELS.TARGET_WEIGHT}
          name="targetWeight"
          type="number"
          validators={[
            VALIDATION_TYPES.REQUIRED,
            `${VALIDATION_TYPES.MAX_STRING_LENGTH}:5`,
            VALIDATION_TYPES.IS_NUMBER_OR_FLOAT,
            VALIDATION_TYPES.IS_POSITIVE,
            VALIDATION_TYPES.VALID_WEIGHT,
          ]}
          value={targetWeight.targetWeight}
          onChange={onChange}
        />
      </FormControl>
      <FormControl className={classes.formInput}>
        <SportScaleDatePickerValidator
          disabled={isLoading}
          errorMessages={getValidationErrorMessages(targetWeight.targetDate, FIELD_LABELS.TARGET_DATE, [
            VALIDATION_TYPES.REQUIRED,
            VALIDATION_TYPES.IS_DATE_IN_PAST,
          ])}
          fullWidth
          label="Target Date"
          name="targetDate"
          value={targetWeight.targetDate}
          validators={[VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_DATE_IN_PAST]}
          onChange={onChange}
        />
      </FormControl>
    </ValidatorForm>
  );
};

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formInput: {
    marginBottom: theme.spacing(2),
  },
}));

WeightsTargetWeightForm.defaultProps = {
  targetWeight: null,
};

WeightsTargetWeightForm.propTypes = {
  formRef: PropTypes.object.isRequired,
  targetWeight: PropTypes.object,

  onChange: PropTypes.func.isRequired,
};

export default WeightsTargetWeightForm;
