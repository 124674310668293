import PropTypes from 'prop-types';
import React from 'react';
import { isNil } from 'lodash';

import Loading from 'common/components/loading.component';
import WeightsTargetWeightForm from 'modules/athletes/weights/weightsTargetWeightForm.component';
import WeightsTargetWeightsTable from 'modules/athletes/weights/weightsTargetWeightsTable.component';

const WeightsTargetWeights = ({ athlete, formRef, targetWeight, targetWeights, onChange, onEdit }) => {
  if (!isNil(targetWeight))
    return <WeightsTargetWeightForm formRef={formRef} targetWeight={targetWeight} onChange={onChange} />;

  if (isNil(targetWeights)) return <Loading />;

  return <WeightsTargetWeightsTable athleteId={athlete.id} targetWeights={targetWeights} onEdit={onEdit} />;
};

WeightsTargetWeights.defaultProps = {
  targetWeight: null,
  targetWeights: null,
};

WeightsTargetWeights.propTypes = {
  athlete: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired,
  targetWeight: PropTypes.object,
  targetWeights: PropTypes.array,

  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default WeightsTargetWeights;
