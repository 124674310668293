import PropTypes from 'prop-types';
import React, { memo } from 'react';

import SportScaleCustomComponentValidator from 'common/formFields/sportScaleCustomValidator.component';
import SportScaleRadioGroup from 'common/formFields/sportScaleRadioGroup.component';

const SportScaleRadioGroupValidator = memo(
  ({ children, disableBlurValidation, errorMessages, label, name, onBlur, onChange, validators, value, ...rest }) => (
    <SportScaleCustomComponentValidator
      disableBlurValidation={disableBlurValidation}
      errorMessages={errorMessages}
      label={label}
      name={name}
      validators={validators}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      <SportScaleRadioGroup {...rest}>{children}</SportScaleRadioGroup>
    </SportScaleCustomComponentValidator>
  )
);

SportScaleRadioGroupValidator.propTypes = {
  classes: PropTypes.object,

  children: PropTypes.any.isRequired,
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array.isRequired,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScaleRadioGroupValidator.defaultProps = {
  classes: {},
  disabled: false,
  disableBlurValidation: false,
  value: null,
  onBlur: () => {},
};

export default SportScaleRadioGroupValidator;
