import Symbol from 'es6-symbol';
import auth0Service from 'auth0/config';
import * as Sentry from '@sentry/react';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';

export const STATUS_CODE_BAD_REQUEST = 400;

export const BASE_URL = `${SERVER_URL}/`;
console.log('base url', BASE_URL);

function callApi(token, endpoint, authenticated, method = 'GET', payload) {
  console.log('Requesting', endpoint);
  let config = { headers: {} };

  if (authenticated) {
    console.log('Auth required');
    if (token) {
      config = {
        headers: { Authorization: `Bearer ${token}` },
      };
    } else {
      console.log('No token available.');
      throw new Error('No token saved!');
    }
  }
  config.method = method;
  if (typeof payload !== 'undefined') {
    // Is this form data?
    if (Object.prototype.toString.call(payload) === '[object FormData]') {
      config.body = payload;
    } else {
      config.body = JSON.stringify(payload);
      config.headers['Content-Type'] = 'application/json';
    }
  }

  console.log('Fetching', BASE_URL + endpoint);
  return fetch(BASE_URL + endpoint, config)
    .then(response => {
      return response.json().then(json => {
        return { json, response };
      });
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        console.log('Bad Response', response);
        return Promise.reject(json);
      }
      const metadata = {};
      if (response.headers.get('X-Total-Count')) {
        metadata.totalCount = Number(response.headers.get('X-Total-Count'));
      }
      return { json, metadata };
    });
}

export const CALL_API = Symbol('Call API');

export default (/* { dispatch, getState } */) => next => async action => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { endpoint, types, authenticated, method, payload, options, actionMetadata } = callAPI;

  const [requestType, successType, errorType] = types;

  next({
    type: requestType,
    payload,
    actionMetadata,
  });

  let jwt;
  if (authenticated) {
    try {
      jwt = await auth0Service.getAccessTokenSilently()();

      if (!jwt) {
        console.log('SESSION EXPIRED');
        auth0Service.logout()({ returnTo: process.env.REACT_APP_AUTH_0_LOGOUT_REDIRECT_URL });
      }
      // console.log('USING JWT!', jwt);
    } catch (e) {
      console.log('ERROR GETTING TOKEN', e);
    }
  }

  // Passing the authenticated boolean back in our data will let us distinguish
  // between normal and secret quotes
  return callApi(jwt, endpoint, authenticated, method, payload).then(
    response => {
      return next({
        response: response.json,
        metadata: response.metadata,
        authenticated,
        payload,
        type: successType,
        options,
        actionMetadata,
      });
    },
    error => {
      const resp = {
        type: errorType,
        actionMetadata,
        response: error,
      };

      if (error.messages) {
        resp.messages = error.messages;
      } else if (error.message) {
        if (error.message === 'Unauthorized' && error.status === 401) {
          /* SPOR-419: Redirect to the login page. Return here and don't report
          the error to Sentry. */
          window.location.href = process.env.REACT_APP_AUTH_0_LOGOUT_REDIRECT_URL;
          return next(resp);
        }
        resp.messages = [error.message];
      } else {
        resp.messages = ['There was an error.'];
      }

      Sentry.captureException(new Error(`API Error: ${JSON.stringify(resp)}`));

      return next(resp);
      // TODO: We need a special way to handle 401s caused by expired tokens
      // That way the user will get kicked back to the login screen if needed
    }
  );
};
