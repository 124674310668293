import configureStoreDev from './configureStore.dev';
import configureStoreProd from './configureStore.prod';

// eslint-disable-next-line import/no-mutable-exports
let configureStore;

if (process.env.NODE_ENV === 'production') {
  configureStore = configureStoreProd;
} else {
  configureStore = configureStoreDev;
}

const store = configureStore();
export default store;
