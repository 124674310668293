import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import React from 'react';

const SportScaleMaskedInput = ({ inputRef, mask, maskPlaceholder, value, onChange, ...props }) => {
  return (
    <MaskedInput
      {...props}
      keepCharPositions={false}
      mask={mask}
      placeholder={maskPlaceholder}
      showMask={false}
      value={value}
      onChange={onChange}
    />
  );
};

SportScaleMaskedInput.propTypes = {
  inputRef: PropTypes.any,
  mask: PropTypes.array.isRequired,
  maskPlaceholder: PropTypes.string,
  value: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
};

SportScaleMaskedInput.defaultProps = {
  inputRef: null,
  maskPlaceholder: '',
};

export default SportScaleMaskedInput;
