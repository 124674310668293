import PropTypes from 'prop-types';
import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Tooltip, Typography, makeStyles } from '@material-ui/core';

const DrawerListItem = ({ item, isActiveRoute, getIcon, onClick, showTooltip }) => {
  const classes = useStyles();

  return (
    <Tooltip
      key={item.route}
      classes={{ tooltip: classes.tooltip }}
      placement="right"
      title={showTooltip ? item.name : ''}
    >
      <ListItem
        onClick={onClick}
        classes={{
          root: isActiveRoute ? classes.listItemRootActive : classes.listItemRoot,
        }}
        button
      >
        <ListItemIcon className={classes.icon}>{getIcon(item)}</ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          primary={<Typography variant="subtitle1">{item.name}</Typography>}
        />
      </ListItem>
    </Tooltip>
  );
};

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    borderRadius: 10,
    margin: '5px auto',
    paddingLeft: 4,
    width: '95%',
  },
  listItemRootActive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.lightGray,
    borderRadius: 10,
    margin: '5px auto',
    paddingLeft: 4,
    width: '95%',
  },
  tooltip: {
    fontSize: '.75rem',
  },
  icon: {
    '&> span': {
      overflow: 'visible',
    },
  },
  listItemText: {
    paddingLeft: theme.spacing(1),
  },
}));

DrawerListItem.propTypes = {
  item: PropTypes.object.isRequired,
  isActiveRoute: PropTypes.bool.isRequired,
  getIcon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool.isRequired,
};

export default DrawerListItem;
