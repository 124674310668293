import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Box, Icon, Paper, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import FIELD_LABELS from 'types/fieldLabels';
import SportScaleCheckbox from 'common/formFields/sportScaleCheckBox.component';
import SportScaleDatePickerValidator from 'common/formFields/sportScaleDatePickerValidator.component';
import SportScaleHeightFieldValidator from 'common/formFields/sportScaleHeightFieldValidator.component';
import SportScaleSelectField from 'common/formFields/sportScaleSelectField.component';
import SportScaleSelectFieldValidator from 'common/formFields/sportScaleSelectFieldValidator.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { GENDER_OPTIONS, RACE_OPTIONS } from 'utilities/inputs';
import { VALIDATION_TYPES, fieldValidators, getValidationErrorMessages } from 'utilities/fieldValidation';
import { YEAR_FORMAT } from 'common/formFields/sportScaleDatePicker.component';

const ATHLETE_FORM_RULES = [VALIDATION_TYPES.IS_DATE_IN_FUTURE, VALIDATION_TYPES.VALID_HEIGHT];

const AthleteForm = ({ disabled, athlete, onChange }) => {
  const classes = useStyles();

  useEffect(() => {
    const rulesAdded = [];

    ATHLETE_FORM_RULES.forEach(validator => {
      if (!ValidatorForm.hasValidationRule(validator)) {
        ValidatorForm.addValidationRule(validator, value => {
          rulesAdded.push(validator);
          let message = false;

          if (fieldValidators[validator]) {
            message = fieldValidators[validator](value);
          }

          return message === false;
        });
      }
    });

    return () => {
      rulesAdded.forEach(validator => ValidatorForm.removeValidationRule(validator));
    };
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <Container className={classes.gridSpacing} spacing={2}>
          <Item sm={6} xs={12}>
            <SportScaleTextFieldValidator
              disabled={disabled}
              errorMessages={getValidationErrorMessages(athlete.firstName, FIELD_LABELS.FIRST_NAME, [
                VALIDATION_TYPES.REQUIRED,
              ])}
              fullWidth
              label={FIELD_LABELS.FIRST_NAME}
              name="firstName"
              validators={[VALIDATION_TYPES.REQUIRED]}
              value={athlete.firstName}
              onChange={onChange}
            />
          </Item>
          <Item sm={6} xs={12}>
            <SportScaleTextFieldValidator
              disabled={disabled}
              errorMessages={getValidationErrorMessages(athlete.lastName, FIELD_LABELS.LAST_NAME, [
                VALIDATION_TYPES.REQUIRED,
              ])}
              fullWidth
              label={FIELD_LABELS.LAST_NAME}
              name="lastName"
              validators={[VALIDATION_TYPES.REQUIRED]}
              value={athlete.lastName}
              onChange={onChange}
            />
          </Item>
        </Container>
        <Container className={classes.gridSpacing} spacing={2}>
          <Item md={4} xs={12}>
            <SportScaleTextFieldValidator
              disabled={disabled}
              errorMessages={getValidationErrorMessages(athlete.identifier, FIELD_LABELS.ID_NUMBER, [
                VALIDATION_TYPES.REQUIRED,
                `${VALIDATION_TYPES.MIN_STRING_LENGTH}:10`,
                `${VALIDATION_TYPES.MAX_STRING_LENGTH}:10`,
              ])}
              fullWidth
              label={`${FIELD_LABELS.ID_NUMBER} (10 digits)`}
              name="identifier"
              validators={[
                VALIDATION_TYPES.REQUIRED,
                `${VALIDATION_TYPES.MIN_STRING_LENGTH}:10`,
                `${VALIDATION_TYPES.MAX_STRING_LENGTH}:10`,
              ]}
              value={athlete.identifier}
              onChange={onChange}
            />
          </Item>
          <Item md={4} sm={6} xs={12}>
            <SportScaleSelectFieldValidator
              disabled={disabled}
              errorMessages={getValidationErrorMessages(athlete.gender, FIELD_LABELS.GENDER, [
                VALIDATION_TYPES.REQUIRED,
              ])}
              fullWidth
              label={FIELD_LABELS.GENDER}
              name="gender"
              options={GENDER_OPTIONS}
              type="text"
              validators={[VALIDATION_TYPES.REQUIRED]}
              value={athlete.gender}
              onChange={onChange}
            />
          </Item>
          <Item md={4} sm={6} xs={12}>
            <SportScaleDatePickerValidator
              disabled={disabled}
              disableFuture
              errorMessages={getValidationErrorMessages(athlete.dob, FIELD_LABELS.DATE_OF_BIRTH, [
                VALIDATION_TYPES.REQUIRED,
                VALIDATION_TYPES.IS_DATE,
                VALIDATION_TYPES.IS_DATE_IN_FUTURE,
              ])}
              fullWidth
              label={FIELD_LABELS.DATE_OF_BIRTH}
              name="dob"
              value={athlete.dob}
              validators={[VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_DATE, VALIDATION_TYPES.IS_DATE_IN_FUTURE]}
              onChange={onChange}
            />
          </Item>
        </Container>
        <Container spacing={2}>
          <Item md={4} sm={6} xs={12}>
            <SportScaleDatePickerValidator
              disabled={disabled}
              errorMessages={getValidationErrorMessages(athlete.dob, FIELD_LABELS.GRADUATION_YEAR, [
                VALIDATION_TYPES.IS_DATE,
              ])}
              format={YEAR_FORMAT}
              fullWidth
              label={FIELD_LABELS.GRADUATION_YEAR}
              name="graduationYear"
              value={athlete.graduationYear}
              validators={[VALIDATION_TYPES.IS_DATE]}
              views={['year']}
              onChange={onChange}
            />
          </Item>
          <Item md={4} sm={6} xs={12}>
            <SportScaleSelectField
              disabled={disabled}
              fullWidth
              label={FIELD_LABELS.RACE}
              name="ethnicity"
              options={RACE_OPTIONS}
              type="text"
              value={athlete.ethnicity}
              onChange={onChange}
            />
          </Item>
          <Item md={4} xs={12}>
            <SportScaleHeightFieldValidator
              disabled={disabled}
              fullWidth
              label={FIELD_LABELS.HEIGHT}
              name="height"
              value={athlete.height}
              onChange={onChange}
            />
          </Item>
        </Container>
      </Paper>
      <Paper className={classes.paper}>
        <Container className={classes.gridSpacing}>
          <Item xs={12}>
            <SportScaleTextFieldValidator
              disabled={disabled}
              fullWidth
              label={FIELD_LABELS.ATHLETE_NOTE}
              name="note"
              value={athlete.note}
              onChange={onChange}
            />
          </Item>
        </Container>
      </Paper>
      <Paper className={classes.paper}>
        <SportScaleCheckbox
          disabled={disabled}
          isChecked={athlete.predisposed}
          label={
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">High Risk</Typography>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title="Identifier for persons who should be more carefully monitored for reasons such as medical history or medication use."
              >
                <Icon className={classNames(classes.infoIcon, 'fal fa-info-circle')} />
              </Tooltip>
            </Box>
          }
          name="predisposed"
          onChange={onChange}
        />
      </Paper>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  gridSpacing: {
    marginBottom: theme.spacing(1),
  },
  tooltip: {
    fontSize: '.75rem',
  },
  infoIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

AthleteForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  athlete: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AthleteForm;
