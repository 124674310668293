import { isNil } from 'lodash';

const getAthleteWeightData = athlete => {
  const past2Weeks = getPastDays(null, TOTAL_CHART_ENTRIES);
  // search for the occurrence of a weight entry among the athlete's weights
  // compare the date of the weight data to the dates in the past 2 weeks
  // if it's the same, add that weight data to the date entry
  return past2Weeks.map(dateEntry => {
    let idx = null;
    const dateDate = new Date(dateEntry.date);

    athlete.weights.forEach((weightData, i) => {
      const weightDate = new Date(weightData.date);
      if (weightDate.getDate() === dateDate.getDate()) {
        idx = i;
      }
    });

    // if index exists then we found an occurrence of the date in the athlete's weights
    if (!isNil(idx)) {
      return athlete.weights[idx];
    }

    return dateEntry;
  });
};

// Adapted from:
// https://stackoverflow.com/questions/13040053/storing-an-array-of-dates-for-the-previous-week-in-javascript-getdate
const getPastDays = (date, numDays) => {
  const now = date || new Date();
  // the plus 1 allows for this to be
  let i = numDays;
  const dates = [{ date: new Date(now).toISOString() }];
  // eslint-disable-next-line no-cond-assign
  while ((i -= 1)) {
    now.setDate(now.getDate() - 1);
    dates.push({ date: new Date(now).toISOString() });
  }
  return dates;
};

const TOTAL_CHART_ENTRIES = 15;

export default { getAthleteWeightData, getPastDays, TOTAL_CHART_ENTRIES };
