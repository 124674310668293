import PropTypes from 'prop-types';
import React, { cloneElement, useCallback, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

const SportScaleMenuButton = ({ buttonComponent, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = useCallback(({ currentTarget }) => setAnchorEl(currentTarget), [setAnchorEl]);

  const handleMenuClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  return (
    <>
      {cloneElement(buttonComponent, { onClick: handleMenuClick })}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {menuItems.map(({ disabled, text, onClick }) => (
          <MenuItem disabled={disabled} key={text} onClick={onClick}>
            {text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SportScaleMenuButton.propTypes = {
  buttonComponent: PropTypes.node.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.exact({
      disabled: PropTypes.bool,
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired
  ).isRequired,
};

export default SportScaleMenuButton;
