import PropTypes from 'prop-types';
import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import FIELD_LABELS from 'types/fieldLabels';
import SportScalePercentageValidator from 'common/formFields/sportScalePercentageValidator.component';
import SportScaleTextField from 'common/formFields/sportScaleTextField.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

const TeamForm = ({ disabled, team, onChange }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Container className={classes.gridSpacing} spacing={2}>
        <Item lg={6} xs={12}>
          <SportScaleTextFieldValidator
            disabled={disabled}
            errorMessages={getValidationErrorMessages(team.name, FIELD_LABELS.SPORT, [VALIDATION_TYPES.REQUIRED])}
            fullWidth
            label={FIELD_LABELS.SPORT}
            name="name"
            validators={[VALIDATION_TYPES.REQUIRED]}
            value={team.name}
            onChange={onChange}
          />
        </Item>
        <Item lg={6} xs={12}>
          <Container spacing={2}>
            <Item md={6} xs={12}>
              <SportScalePercentageValidator
                disabled={disabled}
                errorMessages={getValidationErrorMessages(team.dehydrationThreshold, FIELD_LABELS.IN_2_IN_THRESHOLD, [
                  VALIDATION_TYPES.REQUIRED,
                ])}
                fullWidth
                hasInfoTooltip
                label={FIELD_LABELS.IN_2_IN_THRESHOLD}
                name="dehydrationThreshold"
                tooltipText="Maximum permissible change (%) from one weigh-in to the next weigh-in (within 36 hours). Athletes exceeding this % are added to the watchlist."
                validators={[VALIDATION_TYPES.REQUIRED]}
                value={team.dehydrationThreshold}
                onChange={onChange}
              />
            </Item>
            <Item md={6} xs={12}>
              <SportScalePercentageValidator
                disabled={disabled}
                errorMessages={getValidationErrorMessages(
                  team.predispositionThreshold,
                  FIELD_LABELS.RISK_IN_2_IN_THRESHOLD,
                  [VALIDATION_TYPES.REQUIRED]
                )}
                fullWidth
                hasInfoTooltip
                label={FIELD_LABELS.RISK_IN_2_IN_THRESHOLD}
                name="predispositionThreshold"
                tooltipText="Maximum permissible change (%) from one weigh-in to the next weigh-in (within 36 hours) for those individuals flagged as high risk. Athletes exceeding this % are added to the watchlist."
                validators={[VALIDATION_TYPES.REQUIRED]}
                value={team.predispositionThreshold}
                onChange={onChange}
              />
            </Item>
          </Container>
        </Item>
      </Container>
      <Container spacing={2}>
        <Item xs>
          <SportScaleTextField
            disabled={disabled}
            fullWidth
            hasInfoTooltip
            label={FIELD_LABELS.HYDRATION_WARNING_MESSAGE}
            name="hydrationMessage"
            tooltipText="This message displays on the Sportscale for athletes on this team. When an athlete’s weigh-in exceeds the maximum permissible change % when compared to a previous weigh-in within 36 hours. Assuming the scale has good connectivity, the athlete will also immediately be added to the Watch List on Sportscale Mobile. If left blank, the organization's or system's default message will display."
            value={team.hydrationMessage}
            onChange={onChange}
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </Item>
      </Container>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  gridSpacing: {
    marginBottom: theme.spacing(1),
  },
}));

TeamForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  team: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default TeamForm;
