import PropTypes from 'prop-types';
import React from 'react';

import FIELD_LABELS from 'types/fieldLabels';
import SportScaleTextField from 'common/formFields/sportScaleTextField.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { PERMISSIONS, can } from 'common/permissions';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

const OrganizationForm = ({ isLoading, organization, onChange }) => {
  const disabled = isLoading || !can(PERMISSIONS.IS_SUPERADMIN);

  return (
    <Container spacing={2}>
      <Item xs={12}>
        <SportScaleTextFieldValidator
          disabled={disabled}
          errorMessages={getValidationErrorMessages(organization.name, FIELD_LABELS.ORGANIZATION_NAME, [
            VALIDATION_TYPES.REQUIRED,
          ])}
          fullWidth
          label={FIELD_LABELS.ORGANIZATION_NAME}
          name="name"
          validators={[VALIDATION_TYPES.REQUIRED]}
          value={organization.name}
          onChange={onChange}
        />
      </Item>
      <Item xs>
        <SportScaleTextField
          disabled={disabled}
          fullWidth
          hasInfoTooltip
          label={FIELD_LABELS.HYDRATION_WARNING_MESSAGE}
          name="hydrationMessage"
          tooltipText="This message displays on the Sportscale when an athlete’s weigh-in exceeds the maximum permissible change % when compared to a previous weigh-in within 36 hours.  Assuming the scale has good connectivity, the athlete will also immediately be added to the Watch List on Sportscale Mobile. If left blank, the system's default message will display."
          value={organization.hydrationMessage}
          onChange={onChange}
          inputProps={{
            autocomplete: 'off',
          }}
        />
      </Item>
    </Container>
  );
};

OrganizationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default OrganizationForm;
