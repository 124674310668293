export const SUBSCRIPTION_APP_ONLY_VALUE = 'APP_ONLY';
export const SUBSCRIPTION_SCALE_VALUE = 'SCALE';
export const SUBSCRIPTION_NONE_VALUE = 'NONE';

export const SUBSCRIPTION_APP_ONLY_LABEL = 'App Only';
export const SUBSCRIPTION_SCALE_LABEL = 'Scale';
export const SUBSCRIPTION_NONE_LABEL = 'None';

export const SUBSCRIPTION_TABLE_FILTERS = {
  [SUBSCRIPTION_APP_ONLY_VALUE]: SUBSCRIPTION_APP_ONLY_LABEL,
  [SUBSCRIPTION_SCALE_VALUE]: SUBSCRIPTION_SCALE_LABEL,
  [SUBSCRIPTION_NONE_VALUE]: SUBSCRIPTION_NONE_LABEL,
};

export const SUBSCRIPTION_EXPIRING_SOON_MONTHS = -3;
export const SUBSCRIPTION_EXPIRED_MONTHS = 0;

export const CURRENT_VALUE = 'CURRENT';
export const CURRENT_EXPIRING_VALUE = 'CURRENT_EXPIRING';
export const EXPIRED_VALUE = 'EXPIRED';
export const STARTING_SOON_VALUE = 'STARTING_SOON';

export const CURRENT_LABEL = 'Current';
export const CURRENT_EXPIRING_LABEL = 'Current & Expiring';
export const EXPIRED_LABEL = 'Expired';
export const STARTING_SOON_LABEL = 'Starting Soon';

export const TABLE_SUBSCRIPTIONS_FILTERS = {
  [CURRENT_VALUE]: CURRENT_LABEL,
  [CURRENT_EXPIRING_VALUE]: CURRENT_EXPIRING_LABEL,
  [EXPIRED_VALUE]: EXPIRED_LABEL,
  [STARTING_SOON_VALUE]: STARTING_SOON_LABEL,
};
