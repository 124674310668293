import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

export const MONTH_DAY_YEAR_FORMAT = 'MM/DD/YYYY';
export const YEAR_FORMAT = 'YYYY';

const SportScaleDatePicker = memo(
  ({ className, error, format, hasValidation, inputVariant, name, onChange, ...rest }) => {
    const classes = useStyles();
    // wrapper so we get unix time in ms
    const handleChange = useCallback(
      date => {
        const newValue = date ? date.unix() * 1000 : null;

        onChange({ target: { name, value: newValue } });
      },
      [name, onChange]
    );

    return (
      <KeyboardDatePicker
        autoOk
        // if this component is wrapped in a validation component we don't want
        // a bottom margin added because the validation will add it so the
        // margin includes any error messages
        className={classNames({ [classes.formControl]: hasValidation }, className)}
        error={error}
        format={format}
        helperText=""
        inputVariant={inputVariant}
        name={name}
        variant="inline"
        onChange={handleChange}
        {...rest}
      />
    );
  }
);

SportScaleDatePicker.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  format: PropTypes.string,
  hasValidation: PropTypes.bool,
  inputVariant: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  value: PropTypes.any,

  onChange: PropTypes.func,
};

SportScaleDatePicker.defaultProps = {
  className: '',
  error: false,
  format: MONTH_DAY_YEAR_FORMAT,
  hasValidation: false,
  inputVariant: 'outlined',
  label: '',
  name: '',
  value: null,

  onChange: () => {},
};

const useStyles = makeStyles(() => ({
  formControlNoMargin: {
    marginBottom: '0rem',
  },
}));

export default SportScaleDatePicker;
