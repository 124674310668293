export const TOGGLE_DRAWER_MENU = 'TOGGLE_DRAWER_MENU';

export const SET_INITIAL_LOAD = 'SET_INITIAL_LOAD';
export const SET_INITIAL_LOAD_FAILURE = 'SET_INITIAL_LOAD_FAILURE';

export const TOGGLE_ORG_SELECTION_DIALOG = 'TOGGLE_ORG_SELECTION_DIALOG';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';
export const SET_TOAST_POSITION = 'SET_TOAST_POSITION';

export const TOAST_TYPES = {
  ERROR: 'TOAST_ERROR',
  SUCCESS: 'TOAST_SUCCESS',
  WARNING: 'TOAST_WARNING',
};

export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER_MENU,
});

export const setInitialLoad = hasInitialLoad => {
  return {
    type: SET_INITIAL_LOAD,
    payload: { hasInitialLoad },
  };
};

export const setInitialLoadFailure = hasInitialLoadFailure => {
  return {
    type: SET_INITIAL_LOAD_FAILURE,
    payload: { hasInitialLoadFailure },
  };
};

export const toggleOrgSelectionDialog = () => ({
  type: TOGGLE_ORG_SELECTION_DIALOG,
});

export const setPageTitle = title => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
  };
};

export const setToastPosition = position => {
  return {
    type: SET_TOAST_POSITION,
    position,
  };
};

const showToast = (message, toastType, detailMessage) => {
  if (Array.isArray(message)) {
    message = message.join(', ');
  }

  return {
    type: SHOW_TOAST_MESSAGE,
    message,
    toastType,
    detailMessage,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

let toastTimer;
const TOAST_TIMEOUT_DURATION = 10000;

export const handleToastMessage = (
  message,
  toastType = TOAST_TYPES.SUCCESS,
  detailMessage,
  override = true,
  persistent = false
) => {
  return (dispatch, getState) => {
    /* Don't display the toast message if one is already being displayed and the override is false. */
    if (getState().layout.isShowToast && !override) return;

    /* Don't autoclose if the toast is persistent */
    if (persistent) {
      dispatch(showToast(message, toastType));
      return;
    }

    clearTimeout(toastTimer);
    dispatch(showToast(message, toastType, detailMessage));

    toastTimer = setTimeout(() => {
      dispatch(hideToast());
    }, TOAST_TIMEOUT_DURATION);
  };
};
