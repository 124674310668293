import produce from 'immer';

import {
  CREATE_ORGANIZATION_LOCATION_FAILURE,
  CREATE_ORGANIZATION_LOCATION_REQUEST,
  CREATE_ORGANIZATION_LOCATION_SUCCESS,
  DELETE_ORGANIZATION_LOCATION_FAILURE,
  DELETE_ORGANIZATION_LOCATION_REQUEST,
  DELETE_ORGANIZATION_LOCATION_SUCCESS,
  GET_ORGANIZATION_LOCATIONS_FAILURE,
  GET_ORGANIZATION_LOCATIONS_REQUEST,
  GET_ORGANIZATION_LOCATIONS_SUCCESS,
  GET_ORGANIZATION_LOCATION_FAILURE,
  GET_ORGANIZATION_LOCATION_REQUEST,
  GET_ORGANIZATION_LOCATION_SUCCESS,
  RESET_ORGANIZATION_LOCATIONS,
  RESET_ORGANIZATION_SELECTED_LOCATION,
  UPDATE_ORGANIZATION_LOCATION_FAILURE,
  UPDATE_ORGANIZATION_LOCATION_REQUEST,
  UPDATE_ORGANIZATION_LOCATION_SUCCESS,
} from 'modules/locations/locations.actions';

const initialState = {
  isLoading: false,
  isLoadingLocation: false,
  selectedLocation: null,
  locations: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_LOCATIONS_SUCCESS:
      draft.locations = action.response;
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_LOCATIONS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_LOCATIONS_REQUEST:
      draft.isLoading = true;
      break;

    case RESET_ORGANIZATION_LOCATIONS:
      draft.locations = null;
      break;

    case UPDATE_ORGANIZATION_LOCATION_SUCCESS:
      draft.selectedLocation = action.response;
      draft.isLoadingLocation = false;
      break;

    case UPDATE_ORGANIZATION_LOCATION_FAILURE:
      draft.isLoadingLocation = false;
      break;

    case UPDATE_ORGANIZATION_LOCATION_REQUEST:
      draft.isLoadingLocation = true;
      break;

    case GET_ORGANIZATION_LOCATION_SUCCESS:
      draft.selectedLocation = action.response;
      draft.isLoadingLocation = false;
      break;

    case CREATE_ORGANIZATION_LOCATION_REQUEST:
    case DELETE_ORGANIZATION_LOCATION_REQUEST:
    case GET_ORGANIZATION_LOCATION_REQUEST:
      draft.isLoadingLocation = true;
      break;

    case CREATE_ORGANIZATION_LOCATION_SUCCESS:
    case DELETE_ORGANIZATION_LOCATION_SUCCESS:
      draft.isLoadingLocation = false;
      break;

    case CREATE_ORGANIZATION_LOCATION_FAILURE:
    case DELETE_ORGANIZATION_LOCATION_FAILURE:
    case GET_ORGANIZATION_LOCATION_FAILURE:
      draft.isLoadingLocation = false;
      break;

    case RESET_ORGANIZATION_SELECTED_LOCATION:
      draft.selectedLocation = null;
      break;

    default:
      break;
  }
}, initialState);
