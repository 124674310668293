import PropTypes from 'prop-types';
import React from 'react';
import { Chip, Tooltip, makeStyles } from '@material-ui/core';

const RosterChip = ({ roster }) => {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={`${roster.team.name} - ${roster.name}`}>
      <Chip className={classes.rosterChip} color="primary" label={`${roster.team.name} - ${roster.name}`} />
    </Tooltip>
  );
};

const useStyles = makeStyles(theme => ({
  rosterChip: {
    marginRight: theme.spacing(1),
    maxWidth: '8rem', // Randomly selected, can be adjusted based off of expected sizes. Maybe add a tool tip to chips to show full tema name
  },
  tooltip: {
    fontSize: '.75rem',
  },
}));

RosterChip.propTypes = {
  roster: PropTypes.object.isRequired,
};

export default RosterChip;
