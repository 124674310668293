import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import SportScaleDarkBGPrimaryButton from 'common/buttons/sportScaleDarkBGPrimaryButton.component';
import SportScaleDarkBGSecondaryButton from 'common/buttons/sportScaleDarkBGSecondaryButton.component';

const SportScaleFormDialog = ({
  cancelButtonText,
  submitButtonText,
  content,
  isLoading,
  title,
  onClose,
  onSubmit,
  ...restProps
}) => (
  <Dialog onClose={onClose} {...restProps}>
    <ValidatorForm instantValidate={false} onSubmit={onSubmit}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <SportScaleDarkBGSecondaryButton disabled={isLoading} onClick={onClose}>
          {cancelButtonText}
        </SportScaleDarkBGSecondaryButton>
        <SportScaleDarkBGPrimaryButton disabled={isLoading} type="submit">
          {submitButtonText}
        </SportScaleDarkBGPrimaryButton>
      </DialogActions>
    </ValidatorForm>
  </Dialog>
);

SportScaleFormDialog.propTypes = {
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  content: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,

  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SportScaleFormDialog.defaultProps = {
  cancelButtonText: 'cancel',
  submitButtonText: 'confirm',
  isLoading: false,
  maxWidth: 'xs',
  title: '',
};

export default SportScaleFormDialog;
