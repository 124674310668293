import AthletesUtils from 'utilities/athletes.utils';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { TrackChanges } from '@material-ui/icons';
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryScatter, VictoryTheme } from 'victory';
import { useTheme } from '@material-ui/core';

import { CHART_AXIS_STYLE, CHART_DOMAIN_PADDING, CHART_LINE_PARENT_STYLE } from './charts.settings';

const TARGET_ICON_DIMENSION = 12;
const DATE_FORMAT = 'MM/DD';

const TargetPoint = ({ x, y }) => (
  <svg
    style={{ position: 'relative' }}
    height={TARGET_ICON_DIMENSION}
    width={TARGET_ICON_DIMENSION}
    x={x - TARGET_ICON_DIMENSION / 2}
    y={y - TARGET_ICON_DIMENSION / 2}
  >
    <TrackChanges />
  </svg>
);

TargetPoint.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

const TargetLabel = ({ datum, style, y, ...props }) => {
  const theme = useTheme();

  const isLoss = datum.initialWeight > datum.targetWeight;

  return (
    <VictoryLabel
      {...props}
      datum={datum}
      style={{ ...style, fill: theme.palette.primary.main }}
      verticalAnchor={isLoss ? 'start' : 'end'}
      y={isLoss ? y + TARGET_ICON_DIMENSION * 1.5 : y}
    />
  );
};

TargetLabel.propTypes = {
  datum: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  y: PropTypes.number.isRequired,
};

export default function WeightScatterChart({ axisTicksX, axisTicksY, in2In, targetWeights }) {
  const theme = useTheme();

  return (
    <VictoryChart
      domain={{ y: [axisTicksY[0], axisTicksY[axisTicksY.length - 1]] }}
      domainPadding={CHART_DOMAIN_PADDING}
      theme={VictoryTheme.material}
    >
      <VictoryAxis
        name="X Axis"
        style={CHART_AXIS_STYLE}
        tickCount={Math.floor(AthletesUtils.TOTAL_CHART_ENTRIES / 2)}
        tickLabelComponent={<VictoryLabel />}
        tickValues={axisTicksX}
      />

      <VictoryAxis
        axisLabelComponent={<VictoryLabel />}
        dependentAxis
        name="Y Axis"
        style={CHART_AXIS_STYLE}
        tickCount={Math.floor(axisTicksY.length / 2)}
        tickValues={axisTicksY}
      />
      <VictoryScatter
        data={in2In}
        size={5}
        style={{
          data: { fill: theme.palette.primary.main, stroke: theme.palette.primary.main },
          parent: CHART_LINE_PARENT_STYLE,
        }}
      />
      <VictoryScatter
        data={targetWeights}
        dataComponent={<TargetPoint />}
        labels={({ datum }) => moment(datum.targetDate).format(DATE_FORMAT)}
        labelComponent={<TargetLabel />}
        size={5}
        style={{
          data: { fill: theme.palette.primary.main, stroke: theme.palette.primary.main },
          parent: CHART_LINE_PARENT_STYLE,
        }}
      />
    </VictoryChart>
  );
}

WeightScatterChart.propTypes = {
  axisTicksX: PropTypes.array.isRequired,
  axisTicksY: PropTypes.array.isRequired,
  in2In: PropTypes.array.isRequired,
  targetWeights: PropTypes.array.isRequired,
};
