import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';

import Main from 'modules/layout/mainLayout.component';
import auth0Service from 'auth0/config';
import store from 'redux/store';
import { useAuth0 } from '@auth0/auth0-react';
import { useJiraReportBugWidget } from 'hooks/jiraWidget';

const Root = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  auth0Service.setAccessTokenSilently(getAccessTokenSilently);
  auth0Service.setLogout(logout);

  // Dynamically append Jira "Report A Bug" widget in non-prod and non-dev
  const preventWidgetUsage = process.env.REACT_APP_ENVIRONMENT_NAME !== 'test';
  useJiraReportBugWidget(preventWidgetUsage);

  return (
    <Provider store={store}>
      <Route path="/" component={Main} />
    </Provider>
  );
};

Root.propTypes = {};

export default Root;
