import PropTypes from 'prop-types';
import React, { memo } from 'react';
import SportScaleCustomValidator from 'common/formFields/sportScaleCustomValidator.component';
import SportScaleSelectField from 'common/formFields/sportScaleSelectField.component';

const SportScaleSelectFieldValidator = memo(
  ({ disableBlurValidation, errorMessages, label, name, validators, value, onBlur, onChange, ...restProps }) => (
    <SportScaleCustomValidator
      disableBlurValidation={disableBlurValidation}
      errorMessages={errorMessages}
      label={label}
      name={name}
      validators={validators}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      <SportScaleSelectField {...restProps} />
    </SportScaleCustomValidator>
  )
);

SportScaleSelectFieldValidator.propTypes = {
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  validators: PropTypes.array,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScaleSelectFieldValidator.defaultProps = {
  value: null,
  options: [],
  onBlur: () => {},
  disabled: false,
  disableBlurValidation: false,
  errorMessages: [],
  validators: [],
};

export default SportScaleSelectFieldValidator;
