import { createSelector } from 'reselect';
import { find } from 'lodash';

// The goal here is to make it so that the state tree gets recalculated only when something on "organizations" gets changed
const selectOrganizationsState = createSelector(
  state => state.organizations,
  organizationsState => organizationsState
);

export const selectOrganizations = createSelector(
  selectOrganizationsState,
  organizationsState => organizationsState.organizations
);

export const selectOrganizationsIsLoading = createSelector(
  selectOrganizationsState,
  organizationsState => organizationsState.isLoadingOrganization || organizationsState.isLoadingOrganizations
);

export const selectOrganizationsIsLoadingOrganization = createSelector(
  selectOrganizationsState,
  organizationsState => organizationsState.isLoadingOrganization
);
export const selectOrganizationsIsLoadingOrganizations = createSelector(
  selectOrganizationsState,
  organizationsState => organizationsState.isLoadingOrganizations
);

export const selectSelectedOrganization = createSelector(
  selectOrganizationsState,
  organizationsState => organizationsState.organization
);

export const selectSelectedOrganizationId = createSelector(
  selectOrganizationsState,
  organizationsState => organizationsState.selectedOrganizationId
);

export const selectSelectedOrganizationName = createSelector(
  selectOrganizations,
  selectSelectedOrganizationId,
  (organizations, selectedOrganizationId) => {
    const selectedOrganization = find(organizations, { id: selectedOrganizationId });
    if (!selectedOrganization) {
      return null;
    }

    return selectedOrganization.name;
  }
);
