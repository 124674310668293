import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@material-ui/core';
import { isNil } from 'lodash';

import AthletesUtils from 'utilities/athletes.utils';
import RosterUtils from 'utilities/roster.utils';
import WeightUtils, { WEIGHT_EMPTY_STRING } from 'utilities/weight';

const DATE_FORMAT = 'dd MM-DD';

const WeightsTable = ({ athlete, enableHydrationTracking, team }) => {
  const theme = useTheme();
  const [tableData, setTableData] = useState([]);
  const threshold = RosterUtils.getAthleteThreshold(athlete, team);

  // The color should be white if non-negative.
  // If negative, get the indicator color, which might still be white
  const getCellTextColor = useCallback(
    delta => {
      return isNil(delta) || delta >= 0
        ? theme.palette.common.white
        : WeightUtils.getIndicatorColor(Math.abs(delta), threshold);
    },
    [theme, threshold]
  );

  useEffect(() => {
    // iterate over the sorted athlete weights data, and establish the separate chart line data
    const sortedWeights = AthletesUtils.getAthleteWeightData(athlete).sort((a, b) =>
      moment(new Date(a.date)).isSameOrBefore(new Date(b.date)) ? 1 : -1
    );

    const computedTableData = sortedWeights.map(dayOfData => {
      const date = moment(dayOfData.date).format(DATE_FORMAT);
      const inToInColor = getCellTextColor(dayOfData.inToInDelta);
      const inToOutColor = getCellTextColor(dayOfData.inToOutDelta);

      /*
        If we are hydration tracking, use the inToInDelta provided, and calculate the inToOutDelta
        If not, calculate the inToIn, and always set the inToOut to be null, since there are no weigh outs in that context
      */
      if (enableHydrationTracking) {
        const inToIn = !isNil(dayOfData.inToInDelta) ? dayOfData.inToInDelta * 100 : null;
        const inToOutDelta = WeightUtils.getIn2OutDelta(dayOfData.weighInWeight, dayOfData.weighOutWeight);
        const inToOut = !isNil(inToOutDelta) ? inToOutDelta * 100 : null;
        return {
          date,
          in: dayOfData.weighInWeight,
          out: dayOfData.weighOutWeight,
          inToIn,
          inToOut,
          inToInColor,
          inToOutColor,
          wbgt: dayOfData.wbgt,
        };
      }

      return { date, in: dayOfData?.simpleWeight, wbgt: dayOfData.wbgt };
    });
    setTableData(computedTableData);
  }, [athlete, enableHydrationTracking, team, getCellTextColor]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="subtitle1" color="primary">
              Date
            </Typography>
          </TableCell>
          {enableHydrationTracking && (
            <>
              <TableCell>
                <Typography color="primary">In</Typography>
              </TableCell>
              <TableCell color="primary">
                <Typography color="primary">Out</Typography>
              </TableCell>
              <TableCell color="primary">
                <Typography color="primary">In2In</Typography>
              </TableCell>
              <TableCell color="primary">
                <Typography color="primary">In2Out</Typography>
              </TableCell>
            </>
          )}
          {!enableHydrationTracking && (
            <TableCell color="primary">
              <Typography color="primary">Weight</Typography>
            </TableCell>
          )}
          <TableCell color="primary">
            <Typography color="primary">WBGT</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map(entry => (
          <TableRow key={entry.date}>
            <TableCell>{entry.date}</TableCell>
            <TableCell>{entry.in || WEIGHT_EMPTY_STRING}</TableCell>
            {enableHydrationTracking && (
              <>
                <TableCell>{entry.out || WEIGHT_EMPTY_STRING}</TableCell>
                <TableCell style={{ color: entry.inToInColor }}>
                  {!isNil(entry.inToIn) ? `${entry.inToIn.toFixed(0)}%` : WEIGHT_EMPTY_STRING}
                </TableCell>
                <TableCell style={{ color: entry.inToOutColor }}>
                  {!isNil(entry.inToOut) ? `${entry.inToOut.toFixed(0)}%` : WEIGHT_EMPTY_STRING}
                </TableCell>
              </>
            )}
            <TableCell>{entry.wbgt || WEIGHT_EMPTY_STRING}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
WeightsTable.propTypes = {
  athlete: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  enableHydrationTracking: PropTypes.bool.isRequired,
};

export default WeightsTable;
