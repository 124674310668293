export default Object.freeze({
  DATE_OF_BIRTH: 'Date of Birth',
  RACE: 'Race',
  FIRST_NAME: 'First Name',
  GENDER: 'Gender',
  GRADUATION_YEAR: 'Graduation Year',
  HEIGHT: 'Height',
  HYDRATION_WARNING_MESSAGE: 'Warning Message on Sportscale',
  ID_NUMBER: 'ID Number for Sportscale Login',
  LAST_NAME: 'Last Name',
  LOCATION: 'Location',
  MODEL_NUMBER: 'Model Number',
  NAME: 'Name',
  ORGANIZATION_NAME: 'Organization Name',
  RISK_IN_2_IN_THRESHOLD: 'High Risk In2In Change',
  SCALE_REGISTRATION_CODE: 'Sportscale Registration Code',
  SPORT: 'Sport',
  IN_2_IN_THRESHOLD: 'In2In Change',
  TARGET_WEIGHT: 'Target Weight',
  TARGET_DATE: 'Target Date',
  ACTIVE_SEASON: 'Current Activity',
  ACTIVE_SEASON_NAME: 'Season Name',
  ACTIVE_SEASON_END_DATE: 'Season End Date',
  ATHLETE_NOTE: 'Additional Sorting / Note Field',
});
