import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATION_SUBSCRIPTIONS_REQUEST = 'GET_ORGANIZATION_SUBSCRIPTIONS_REQUEST';
export const GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS = 'GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS';
export const GET_ORGANIZATION_SUBSCRIPTIONS_FAILURE = 'GET_ORGANIZATION_SUBSCRIPTIONS_FAILURE';

export const GET_ORGANIZATION_SUBSCRIPTION_REQUEST = 'GET_ORGANIZATION_SUBSCRIPTION_REQUEST';
export const GET_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'GET_ORGANIZATION_SUBSCRIPTION_SUCCESS';
export const GET_ORGANIZATION_SUBSCRIPTION_FAILURE = 'GET_ORGANIZATION_SUBSCRIPTION_FAILURE';

export const RESET_ORGANIZATION_SUBSCRIPTIONS = 'RESET_ORGANIZATION_SUBSCRIPTIONS';

export const CREATE_ORGANIZATION_SUBSCRIPTION_REQUEST = 'CREATE_ORGANIZATION_SUBSCRIPTION_REQUEST';
export const CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS';
export const CREATE_ORGANIZATION_SUBSCRIPTION_FAILURE = 'CREATE_ORGANIZATION_SUBSCRIPTION_FAILURE';

export const RESET_ORGANIZATION_SUBSCRIPTION = 'RESET_ORGANIZATION_SUBSCRIPTION';

export const UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST = 'UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST';
export const UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS';
export const UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE = 'UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE';

export const DELETE_ORGANIZATION_SUBSCRIPTION_REQUEST = 'DELETE_ORGANIZATION_SUBSCRIPTION_REQUEST';
export const DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS';
export const DELETE_ORGANIZATION_SUBSCRIPTION_FAILURE = 'DELETE_ORGANIZATION_SUBSCRIPTION_FAILURE';

export const getOrganizationSubscriptions = organizationId => {
  return {
    [CALL_API]: {
      types: [
        GET_ORGANIZATION_SUBSCRIPTIONS_REQUEST,
        GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS,
        GET_ORGANIZATION_SUBSCRIPTIONS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/subscriptions`,
      method: 'GET',
    },
  };
};

export const getOrganizationSubscription = (organizationId, subscriptionId) => {
  return {
    [CALL_API]: {
      types: [
        GET_ORGANIZATION_SUBSCRIPTION_REQUEST,
        GET_ORGANIZATION_SUBSCRIPTION_SUCCESS,
        GET_ORGANIZATION_SUBSCRIPTION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/subscriptions/${subscriptionId}`,
      method: 'GET',
    },
  };
};

export const resetOrganizationSubscriptions = () => {
  return {
    type: RESET_ORGANIZATION_SUBSCRIPTIONS,
  };
};

export const createOrganizationSubscription = (organizationId, subscription) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_ORGANIZATION_SUBSCRIPTION_REQUEST,
        CREATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
        CREATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/subscriptions`,
      method: 'POST',
      payload: subscription,
    },
  };
};

export const resetSelectedSubscription = () => {
  return {
    type: RESET_ORGANIZATION_SUBSCRIPTION,
  };
};

export const updateOrganizationSubscription = (organizationId, subscription) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_ORGANIZATION_SUBSCRIPTION_REQUEST,
        UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
        UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/subscriptions/${subscription.id}`,
      method: 'PUT',
      payload: subscription,
    },
  };
};

export const removeOrganizationSubscription = (organizationId, subscriptionId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_ORGANIZATION_SUBSCRIPTION_REQUEST,
        DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
        DELETE_ORGANIZATION_SUBSCRIPTION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/subscriptions/${subscriptionId}`,
      method: 'DELETE',
    },
  };
};
