import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ component: Component, ...args }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      render={({ location, ...rest }) => {
        return isAuthenticated ? <Component location={location} {...rest} /> : <Redirect to={{ pathname: '/login' }} />;
      }}
      {...args}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any,
};

ProtectedRoute.defaultProps = {
  component: null,
};

export default ProtectedRoute;
