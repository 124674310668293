import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleCheckbox from 'common/formFields/sportScaleCheckBox.component';
import SportScalePhoneValidator from 'common/formFields/sportScalePhoneValidator.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import { UPDATE_PROFILE_FAILURE, updateProfile } from 'modules/profile/profile.actions';
import { VALIDATION_TYPES, getValidationErrorMessages, isPhone } from 'utilities/fieldValidation';

const ProfileForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, curProfile } = useSelector(state => ({
    isLoading: state.profile.isLoading,
    curProfile: state.profile.profile,
  }));

  const [profile, setProfile] = useState({
    ...curProfile,
    ...curProfile.preferences,
  });

  useEffect(() => {
    ValidatorForm.addValidationRule(VALIDATION_TYPES.IS_PHONE, value => {
      const message = isPhone(value);
      return message === false;
    });

    return () => {
      ValidatorForm.removeValidationRule(VALIDATION_TYPES.IS_PHONE);
    };
  }, []);

  const handleSubmitProfile = useCallback(() => {
    const profilePayload = cloneDeep(profile);

    // This is required in order to map the updated preferences back to the appropriate payload preferences
    Object.keys(profilePayload.preferences).forEach(preference => {
      profilePayload.preferences[preference] = profilePayload[preference];
      delete profilePayload[preference];
    });

    (async () => {
      const response = await dispatch(updateProfile(profilePayload));

      if (response.type === UPDATE_PROFILE_FAILURE) {
        dispatch(handleToastMessage(ERROR_MESSAGES.UPDATE_PROFILE_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.UPDATE_PROFILE_SUCCESS));
    })();
  }, [dispatch, profile]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setProfile({ ...profile, [name]: value }), [
    profile,
    setProfile,
  ]);

  const submitText = isLoading ? 'saving...' : 'save';

  return (
    <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitProfile}>
      <Box flex={1}>
        <Paper className={classes.paper}>
          <Typography variant="h6">Personal Info</Typography>
          <Typography variant="subtitle2">
            To change an Organization Administrator’s email address, please email
            <span
              role="button"
              tabIndex={0}
              onClick={() => {}}
              onKeyDown={e => {
                window.location = 'mailto:support@sportscalesystem.com';
                e.preventDefault();
              }}
            >
              &nbsp;support@sportscalesystem.com
            </span>
          </Typography>
          <Container spacing={2}>
            <Item sm={6} xs={12}>
              <SportScaleTextFieldValidator
                className={classes.textField}
                errorMessages={getValidationErrorMessages(profile.name, 'Name', [VALIDATION_TYPES.REQUIRED])}
                fullWidth
                label="Name"
                name="name"
                validators={[VALIDATION_TYPES.REQUIRED]}
                value={profile.name}
                onChange={handleInputChange}
              />
            </Item>
            <Item sm={6} xs={12}>
              <SportScalePhoneValidator
                className={classes.textField}
                errorMessages={getValidationErrorMessages(profile.mobileNumber, 'Mobile Number', [
                  VALIDATION_TYPES.REQUIRED,
                ])}
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                validators={[VALIDATION_TYPES.REQUIRED]}
                value={profile.mobileNumber || ''}
                onChange={handleInputChange}
              />
            </Item>
          </Container>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6">Notify Me When</Typography>
          <SportScaleCheckbox
            className={classes.checkbox}
            isChecked={profile.notifyOnAthleteEntersWatchlist}
            label="Athlete Enters Watchlist"
            labelPlacement="end"
            name="notifyOnAthleteEntersWatchlist"
            onChange={handleInputChange}
          />
        </Paper>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <SportScaleSecondaryButton
          disabled={isLoading}
          className={classes.cancelAction}
          onClick={() => history.goBack()}
        >
          cancel
        </SportScaleSecondaryButton>
        <SportScalePrimaryButton disabled={isLoading} type="submit">
          {submitText}
        </SportScalePrimaryButton>
      </Box>
    </ValidatorForm>
  );
};

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  cancelAction: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

ProfileForm.propTypes = {};

export default ProfileForm;
