import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Paper, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import OrganizationForm from 'modules/organizations/organizationForm.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import Subscriptions from 'modules/organizations/subscriptions/subscriptions.component';
import {
  GET_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_SUCCESS,
  getOrganization,
  getOrganizations,
  updateOrganization,
} from 'modules/organizations/organizations.actions';
import { PERMISSIONS, can } from 'common/permissions';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import {
  getOrganizationSubscriptions,
  resetOrganizationSubscriptions,
} from 'modules/organizations/subscriptions/subscriptions.actions';
import { getSubmitText } from 'utilities/form';
import {
  selectOrganizationsIsLoadingOrganization,
  selectSelectedOrganization,
  selectSelectedOrganizationId,
} from 'modules/organizations/organizations.selectors';

const Organization = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [organization, setOrganization] = useState({ name: '' });

  const isLoadingOrganization = useSelector(selectOrganizationsIsLoadingOrganization);
  const selectedOrganization = useSelector(selectSelectedOrganization);
  const selectedOrganizationId = useSelector(selectSelectedOrganizationId);

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationSubscriptions());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isNil(selectedOrganization) && selectedOrganizationId !== organization.id) {
      setOrganization(selectedOrganization);

      // Only superadmins can view subscriptions.
      if (can(PERMISSIONS.IS_SUPERADMIN)) dispatch(getOrganizationSubscriptions(selectedOrganization.id));
      dispatch(setPageTitle(selectedOrganization.name));
    }
  }, [dispatch, organization, selectedOrganization, selectedOrganizationId]);

  useEffect(() => {
    (async () => {
      if ((isNil(selectedOrganization) && selectedOrganizationId) || selectedOrganizationId !== organization.id) {
        const response = await dispatch(getOrganization(selectedOrganizationId));

        if (response.type === GET_ORGANIZATION_FAILURE) {
          history.push('/notFound', {
            content: "The organization you're looking for was not found. Please speak with your system administrator.",
            title: 'Organization Not Found',
            showActions: true,
          });
        }
      }
    })();
  }, [history, dispatch, organization, selectedOrganization, selectedOrganizationId]);

  const handleInputChange = useCallback(
    ({ target: { name, value } }) => {
      setOrganization({ ...organization, [name]: value });
    },
    [organization, setOrganization]
  );

  const submitText = useMemo(() => getSubmitText(isLoadingOrganization, false), [isLoadingOrganization]);

  const handleSubmitOrganization = useCallback(() => {
    (async () => {
      const response = await dispatch(updateOrganization(selectedOrganizationId, organization));

      if (response.type !== UPDATE_ORGANIZATION_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.UPDATE_ORGANIZATION_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.UPDATE_ORGANIZATION_SUCCESS));
      dispatch(getOrganizations());
    })();
  }, [dispatch, organization, selectedOrganizationId]);

  if (isNil(organization) || isLoadingOrganization)
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  return (
    <PageWrapper className={classes.pageWrapper}>
      <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitOrganization}>
        <Paper className={classes.paper}>
          <OrganizationForm
            isLoading={isLoadingOrganization}
            organization={organization}
            onChange={handleInputChange}
          />
        </Paper>
        {can(PERMISSIONS.IS_SUPERADMIN) && <Subscriptions />}
        <Box alignItems="flex-end" display="flex" flex={1} justifyContent="flex-end" width="100%">
          <SportScalePrimaryButton disabled={isLoadingOrganization || !can(PERMISSIONS.IS_SUPERADMIN)} type="submit">
            {submitText}
          </SportScalePrimaryButton>
        </Box>
      </ValidatorForm>
    </PageWrapper>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

Organization.propTypes = {};

export default Organization;
