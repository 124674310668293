import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { CM_PER_INCH, FEET_INPUT_NAME, INCHES_INPUT_NAME, INCHES_PER_FOOT } from 'types/height';
import { Container, Item } from 'common/components/grid.component';
import { TextField } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const SportScaleHeightField = memo(
  ({ disabled, hasValidation, label, name, validatorRef, value, onBlur, onChange, ...restProps }) => {
    const classes = useStyles();

    /*
      We are keeping local state of calculated feet and inches from the incoming centimeters value. This allows
      for us to deal with an empty state when a user backspaces to an empty text field.
    */
    const [heightFeet, setHeightFeet] = useState('');
    const [heightInches, setHeightInches] = useState('');

    useEffect(() => {
      if (value === '' || isNil(value)) {
        setHeightInches('');
        setHeightFeet('');
        return;
      }

      const heightInInches = value / CM_PER_INCH;

      setHeightFeet(Math.floor(heightInInches / INCHES_PER_FOOT));
      setHeightInches(Math.round(heightInInches % INCHES_PER_FOOT));
    }, [value]);

    const handleChange = useCallback(
      ({ target }) => {
        if (isEmpty(target.value)) {
          if (target.name === FEET_INPUT_NAME) setHeightFeet('');
          if (target.name === INCHES_INPUT_NAME) setHeightInches('');

          return;
        }

        const num = parseInt(target.value, 10);
        // SPOR-278: If the input isn't a number, don't do anything.
        if (Number.isNaN(num)) return;

        const heightCM =
          target.name === FEET_INPUT_NAME
            ? (num * INCHES_PER_FOOT + heightInches) * CM_PER_INCH
            : (num + heightFeet * INCHES_PER_FOOT) * CM_PER_INCH;

        onChange({ target: { name, value: heightCM } });
      },
      [heightFeet, heightInches, name, onChange]
    );

    /*
      This allows us to handle an empty state by specifically calculating height in centimeters when either
      feet or inches is empty and then calling onChange & onBlur for validation
    */
    const handleBlur = useCallback(() => {
      let heightCM;

      if (heightFeet !== '' && heightInches !== '') {
        onBlur();
        return;
      }

      if (heightFeet === '') {
        heightCM = heightInches * CM_PER_INCH;
      } else if (heightInches === '') {
        heightCM = heightFeet * INCHES_PER_FOOT * CM_PER_INCH;
      }

      onChange({ target: { name, value: heightCM } });
      onBlur();
    }, [heightFeet, heightInches, name, onBlur, onChange]);

    return (
      <Container spacing={2}>
        <Item sm={6} xs={12}>
          <TextField
            classes={{
              // if this component is wrapped in a validation component we dont want
              // a bottom margin added because the validation will add it so the
              // margin includes any error messages
              root: hasValidation ? classes.formControlNoMargin : classes.formControl,
            }}
            disabled={disabled}
            label="Height (ft)"
            name={FEET_INPUT_NAME}
            type="number"
            value={heightFeet}
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
            inputProps={{
              max: '12',
              min: '0',
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            {...restProps}
          />
        </Item>
        <Item sm={6} xs={12}>
          <TextField
            classes={{
              // if this component is wrapped in a validation component we dont want
              // a bottom margin added because the validation will add it so the
              // margin includes any error messages
              root: hasValidation ? classes.formControlNoMargin : classes.formControl,
            }}
            disabled={disabled}
            label="Height (in)"
            name={INCHES_INPUT_NAME}
            type="number"
            value={heightInches}
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
            inputProps={{
              max: '12',
              min: '0',
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            {...restProps}
          />
        </Item>
      </Container>
    );
  }
);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  input: {
    paddingRight: 0,
  },
  formControl: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5%',
    flex: 1,
    marginBottom: '0.5rem',
    marginRight: '0.5rem',
    minWidth: '10rem',
  },
  formControlNoMargin: {
    marginBottom: '0rem',
  },
}));

SportScaleHeightField.propTypes = {
  disabled: PropTypes.bool,
  hasValidation: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  validatorRef: PropTypes.object,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

SportScaleHeightField.defaultProps = {
  disabled: false,
  hasValidation: false,
  label: '',
  name: '',
  validatorRef: React.createRef(),
  value: '',
  onBlur: () => {},
  onChange: () => {},
};

export default SportScaleHeightField;
