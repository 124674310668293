import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { isEmpty } from 'lodash';

import SportScaleDarkBGPrimaryButton from 'common/buttons/sportScaleDarkBGPrimaryButton.component';
import SportScaleDarkBGSecondaryButton from 'common/buttons/sportScaleDarkBGSecondaryButton.component';

const SportScaleDialog = ({
  actions,
  classes = {},
  content,
  fullWidth = false,
  maxWidth,
  open,
  scroll = 'body',
  title,
  onClose,
}) => (
  <Dialog
    classes={classes.dialog || {}}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    scroll={scroll}
    onClose={onClose}
  >
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{content}</DialogContent>
    {!isEmpty(actions) && (
      <DialogActions>
        {actions.map(action =>
          action.isPrimary ? (
            <SportScaleDarkBGPrimaryButton key={action.text} disabled={action.disabled} onClick={action.action}>
              {action.text}
            </SportScaleDarkBGPrimaryButton>
          ) : (
            <SportScaleDarkBGSecondaryButton key={action.text} disabled={action.disabled} onClick={action.action}>
              {action.text}
            </SportScaleDarkBGSecondaryButton>
          )
        )}
      </DialogActions>
    )}
  </Dialog>
);

SportScaleDialog.defaultProps = {
  classes: {},
  scroll: 'body',
};

SportScaleDialog.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      isPrimary: PropTypes.bool,
      text: PropTypes.string.isRequired,
    }).isRequired
  ),
  classes: PropTypes.object,
  content: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  scroll: PropTypes.string,
  title: PropTypes.node,

  onClose: PropTypes.func.isRequired,
};

SportScaleDialog.defaultProps = {
  actions: [],
  fullWidth: false,
  maxWidth: 'xs',
  title: '',
};

export default SportScaleDialog;
