import React, { useCallback, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SportScaleTable from 'common/table/sportScaleTable.component';
import SportScaleTableActionButton from 'common/table/sportScaleTableActionButton.component';
import SportScaleTableToolbar from 'common/table/sportScaleTableToolbar.component';
import useApiRequest from 'hooks/apiRequest';
import {
  GET_ORGANIZATION_USERS_SUCCESS,
  getOrganizationUsers,
  resetOrganizationUsers,
} from 'modules/users/users.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PERMISSIONS, can } from 'common/permissions';
import {
  selectSelectedOrganizationId,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';
import { selectUsers, selectUsersIsLoading } from 'modules/users/users.selectors';
import { setPageTitle } from 'modules/layout/layout.actions';

const COLUMNS = [
  { title: 'Name', field: 'name' },
  { title: 'Email', field: 'email' },
  { title: 'Mobile Number', field: 'mobileNumber' },
];

const UsersContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const makeRequest = useApiRequest();

  const isLoading = useSelector(selectUsersIsLoading);
  const organizationId = useSelector(selectSelectedOrganizationId);
  const organizationName = useSelector(selectSelectedOrganizationName);
  const users = useSelector(selectUsers);

  useEffect(() => {
    if (isNil(organizationId) || !isNil(users)) return;

    dispatch(setPageTitle(`${organizationName} - Users`));

    makeRequest(
      () => getOrganizationUsers(organizationId),
      { type: GET_ORGANIZATION_USERS_SUCCESS },
      ERROR_MESSAGES.GET_ORGANIZATION_USERS_FAILURE
    );
  }, [dispatch, makeRequest, organizationId, organizationName, users]);

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationUsers());
    };
  }, [dispatch]);

  const goToCreateUser = useCallback(() => history.push(`${location.pathname}/${NEW_ITEM_ROUTE}`), [history, location]);

  return (
    <PageWrapper>
      <Box mb={2} width="100%">
        <SportScaleTable
          actions={[
            {
              icon: '',
              text: can(PERMISSIONS.EDIT_USERS) ? 'Edit' : 'View',
              onClick: rowData => history.push(`${location.pathname}/${rowData.id}`),
            },
          ]}
          columns={COLUMNS}
          components={{
            Action: SportScaleTableActionButton(classes.actionButton, false),
            Toolbar: SportScaleTableToolbar(
              can(PERMISSIONS.CREATE_USERS) ? [{ text: 'create user', onClick: goToCreateUser }] : []
            ),
          }}
          data={users || []}
          isLoading={isLoading}
          options={{ toolbar: true }}
        />
      </Box>
    </PageWrapper>
  );
};

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
}));

UsersContainer.propTypes = {};

export default UsersContainer;
