import React from 'react';

import SportScaleDarkBGPrimary from 'common/buttons/sportScaleDarkBGPrimaryButton.component';

const SportScaleTableActionButton = (className, disabled) => ({ action, data }) => {
  return (
    <SportScaleDarkBGPrimary
      className={className}
      data={data}
      disabled={disabled || action.disabled}
      text={action.text}
      onClick={() => action.onClick(data)}
    >
      {action.text}
    </SportScaleDarkBGPrimary>
  );
};

export default SportScaleTableActionButton;
