import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DialogContentText, makeStyles } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import Seasons from 'modules/teams/seasons/seasons.component';
import SportScaleBreadCrumbs from 'modules/layout/breadCrumbs/breadCrumbs.component';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScalePrimaryButton from 'common/buttons/sportScalePrimaryButton.component';
import SportScaleSecondaryButton from 'common/buttons/sportScaleSecondaryButton.component';
import TeamForm from 'modules/teams/teamForm.component';
import useApiRequest from 'hooks/apiRequest';
import {
  CREATE_ORGANIZATION_TEAM_SUCCESS,
  DELETE_ORGANIZATION_TEAM_SUCCESS,
  UPDATE_ORGANIZATION_TEAM_SUCCESS,
  createOrganizationTeam,
  deleteOrganizationTeam,
  getOrganizationTeam,
  resetOrganizationSelectedTeam,
  updateOrganizationTeam,
} from 'modules/teams/teams.actions';
import { GET_TEAM_SEASONS_SUCCESS, getTeamSeasons, resetTeamSeasons } from 'modules/teams/seasons/seasons.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { ROUTES, TEAM_ID_MAP, generateBreadCrumbMap, generateRouteParamsMap } from 'utilities/breadCrumbs';
import { TOAST_TYPES, handleToastMessage, setPageTitle } from 'modules/layout/layout.actions';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getSubmitText } from 'utilities/form';
import {
  selectOrganizationsIsLoadingOrganization,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';
import { selectSelectedTeam, selectTeamsIsLoadingTeam, selectTeamsIsLoadingTeams } from 'modules/teams/teams.selectors';

const INITIAL_TEAM = {
  dehydrationThreshold: 3,
  name: '',
  positions: [],
  predispositionThreshold: 2,
};

const TeamContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { organizationId, teamId } = useParams();
  const makeRequest = useApiRequest();

  const isLoadingOrganization = useSelector(selectOrganizationsIsLoadingOrganization);
  const isLoadingTeams = useSelector(selectTeamsIsLoadingTeams);
  const isLoadingTeam = useSelector(selectTeamsIsLoadingTeam);
  const organizationName = useSelector(selectSelectedOrganizationName);
  const selectedTeam = useSelector(selectSelectedTeam);

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [team, setTeam] = useState(null);

  const isNewTeam = teamId === NEW_ITEM_ROUTE;
  const isLoading = isLoadingOrganization || isLoadingTeams;

  useEffect(() => {
    return () => {
      dispatch(resetOrganizationSelectedTeam());
      dispatch(resetTeamSeasons());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isNil(organizationId)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.SPORTS_TEAMS}`));

    if (isNewTeam) {
      setTeam(INITIAL_TEAM);
      return;
    }

    makeRequest(
      () => getTeamSeasons(organizationId, teamId),
      { type: GET_TEAM_SEASONS_SUCCESS },
      ERROR_MESSAGES.GET_TEAM_SEASONS_FAILURE
    );

    if (!isEmpty(location.state)) {
      const { team: locationTeam } = location.state;

      setTeam({
        ...locationTeam,
        dehydrationThreshold: (locationTeam.dehydrationThreshold * 100).toFixed(2),
        predispositionThreshold: (locationTeam.predispositionThreshold * 100).toFixed(2),
      });
      return;
    }

    (async () => {
      const { response } = await dispatch(getOrganizationTeam(organizationId, teamId));

      setTeam({
        ...response,
        dehydrationThreshold: (response.dehydrationThreshold * 100).toFixed(2),
        predispositionThreshold: (response.predispositionThreshold * 100).toFixed(2),
      });
    })();
  }, [dispatch, location, makeRequest, isNewTeam, organizationId, organizationName, teamId]);

  const handleDeleteTeam = useCallback(() => {
    (async () => {
      const response = await dispatch(deleteOrganizationTeam(organizationId, teamId));

      setRemoveDialogOpen(false);

      if (response.type !== DELETE_ORGANIZATION_TEAM_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_TEAM_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_TEAM_SUCCESS));
      history.replace(`/organizations/${organizationId}/teams`);
    })();
  }, [dispatch, history, teamId, organizationId]);

  const handleSubmitTeam = useCallback(() => {
    (async () => {
      let response;

      const updatedTeam = { ...team };

      updatedTeam.dehydrationThreshold = Number((updatedTeam.dehydrationThreshold / 100).toFixed(4));
      updatedTeam.predispositionThreshold = Number((updatedTeam.predispositionThreshold / 100).toFixed(4));

      if (isNewTeam) {
        response = await dispatch(createOrganizationTeam(organizationId, updatedTeam));
      } else {
        delete updatedTeam.tableData;
        response = await dispatch(updateOrganizationTeam(organizationId, updatedTeam));
      }

      if (response.type !== CREATE_ORGANIZATION_TEAM_SUCCESS && response.type !== UPDATE_ORGANIZATION_TEAM_SUCCESS) {
        const errorMessage = isNewTeam ? ERROR_MESSAGES.CREATE_TEAM_FAILURE : ERROR_MESSAGES.UPDATE_TEAM_FAILURE;
        const isDuplicateError = response.response.message === 'Duplication entry';

        dispatch(
          handleToastMessage(
            errorMessage,
            TOAST_TYPES.ERROR,
            isDuplicateError ? ERROR_MESSAGES.CREATE_DUPLICATE_TEAM_ERROR : ''
          )
        );
        return;
      }

      const successMessage = isNewTeam ? SUCCESS_MESSAGES.CREATE_TEAM_SUCCESS : SUCCESS_MESSAGES.UPDATE_TEAM_SUCCESS;

      dispatch(handleToastMessage(successMessage));

      if (isNewTeam) history.push(`/organizations/${organizationId}/teams/${response.response.id}`);
    })();
  }, [dispatch, history, isNewTeam, organizationId, team]);

  useEffect(() => {
    if (!isEmpty(selectedTeam)) {
      setTeam({
        ...selectedTeam,
        dehydrationThreshold: (selectedTeam.dehydrationThreshold * 100).toFixed(2),
        predispositionThreshold: (selectedTeam.predispositionThreshold * 100).toFixed(2),
      });
    }
  }, [selectedTeam]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setTeam({ ...team, [name]: value }), [
    team,
    setTeam,
  ]);

  const submitText = useMemo(() => getSubmitText(isLoadingTeam, isNewTeam), [isLoadingTeam, isNewTeam]);

  const breadCrumbs = useMemo(() => {
    if (!isNil(team)) {
      let teamName = isNewTeam && 'create';

      if (!isNewTeam && !isEmpty(location.state)) teamName = location.state.team.name;
      if (!isNewTeam && !isNil(selectedTeam)) teamName = selectedTeam.name;

      return generateBreadCrumbMap(generateRouteParamsMap(TEAM_ID_MAP, [organizationId, teamId]), {
        [ROUTES.TEAMS_ROUTE]: PAGE_TITLES.SPORTS_TEAMS,
        [isNewTeam ? ROUTES.NEW_TEAM_ROUTE : ROUTES.TEAM_ROUTE]: teamName,
      });
    }

    return {};
  }, [location, isNewTeam, organizationId, selectedTeam, team, teamId]);

  if (isLoading || isNil(team))
    return (
      <PageWrapper className={classes.pageWrapper}>
        <Loading />
      </PageWrapper>
    );

  const DISABLE_CHANGE = isLoadingTeam || isNewTeam ? !can(PERMISSIONS.CREATE_TEAMS) : !can(PERMISSIONS.EDIT_TEAMS);

  return (
    <>
      <PageWrapper className={classes.pageWrapper}>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <SportScaleBreadCrumbs breadCrumbs={breadCrumbs} />
          {!isNewTeam && can(PERMISSIONS.REMOVE_TEAMS) && (
            <SportScalePrimaryButton onClick={() => setRemoveDialogOpen(true)}>remove sport</SportScalePrimaryButton>
          )}
        </Box>
        <ValidatorForm className={classes.form} instantValidate={false} onSubmit={handleSubmitTeam}>
          <TeamForm disabled={DISABLE_CHANGE} team={team} onChange={handleInputChange} />
          {!isNewTeam && (
            <Box width="100%">
              <Seasons />
            </Box>
          )}
          <Box alignItems="flex-end" display="flex" flex={1} justifyContent="space-between" width="100%">
            <SportScaleSecondaryButton
              disabled={isLoadingTeam}
              onClick={() => history.push(`/organizations/${organizationId}/teams`)}
            >
              cancel
            </SportScaleSecondaryButton>
            <SportScalePrimaryButton disabled={DISABLE_CHANGE} type="submit">
              {submitText}
            </SportScalePrimaryButton>
          </Box>
        </ValidatorForm>
      </PageWrapper>
      {!isNil(team) && (
        <SportScaleDialog
          actions={[
            {
              action: () => {
                setRemoveDialogOpen(false);
              },
              disabled: isLoadingTeam,
              text: 'cancel',
            },
            {
              action: handleDeleteTeam,
              disabled: isLoadingTeam,
              isPrimary: true,
              text: isLoadingTeam ? 'removing...' : 'remove',
            },
          ]}
          content={
            <DialogContentText>Are you sure you want to remove {team.name} from this organization?</DialogContentText>
          }
          open={removeDialogOpen}
          title="Remove Sport?"
          onClose={() => {
            setRemoveDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
}));

TeamContainer.propTypes = {};

export default TeamContainer;
