import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { TagFaces } from '@material-ui/icons';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ERROR_MESSAGES from 'types/errorMessages';
import FIELD_LABELS from 'types/fieldLabels';
import PageWrapper from 'modules/layout/pageWrapper.component';
import SportScaleTable, { ICON_COLUMN_WIDTH } from 'common/table/sportScaleTable.component';
import SportScaleTableActionButton from 'common/table/sportScaleTableActionButton.component';
import SportScaleTableToolbar from 'common/table/sportScaleTableToolbar.component';
import useApiRequest from 'hooks/apiRequest';
import {
  GET_ORGANIZATION_ATHLETES_SUCCESS,
  getOrganizationAthletes,
  resetOrganizationAthletes,
  setSelectedAthlete,
} from 'modules/athletes/athletes.actions';
import { NEW_ITEM_ROUTE } from 'types/routes';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { PERMISSIONS, can } from 'common/permissions';
import { selectAthletes, selectAthletesIsLoading } from 'modules/athletes/athletes.selectors';
import {
  selectSelectedOrganizationId,
  selectSelectedOrganizationName,
} from 'modules/organizations/organizations.selectors';
import { setPageTitle } from 'modules/layout/layout.actions';
import { sortByBoolean, sortByName } from 'utilities/sorting';

const TABLE_PREDISPOSED_FILTERS = {
  [true]: 'Yes',
  [false]: 'No',
};

const COLUMNS = [
  {
    cellStyle: { width: ICON_COLUMN_WIDTH, maxWidth: ICON_COLUMN_WIDTH, minWidth: ICON_COLUMN_WIDTH },
    render: rowData => (rowData.hasFaceData ? <TagFaces color="primary" /> : ''),
    sorting: false,
    title: '',
    width: ICON_COLUMN_WIDTH,
  },
  {
    customFilterAndSearch: (term, rowData) => {
      const name = `${rowData.firstName} ${rowData.lastName}`;
      return name.toLowerCase().includes(term.toLowerCase());
    },
    customSort: sortByName,
    render: rowData => `${rowData.firstName} ${rowData.lastName}`,
    title: FIELD_LABELS.NAME,
  },
  { title: FIELD_LABELS.ID_NUMBER, field: 'identifier' },
  {
    customFilterAndSearch: (term, rowData) => isEmpty(term) || term.includes(rowData.predisposed.toString()),
    customSort: sortByBoolean('predisposed'),
    lookup: TABLE_PREDISPOSED_FILTERS,
    render: rowData => (rowData.predisposed ? 'Yes' : 'No'),
    title: 'High Risk',
  },
];

const AthletesContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const makeRequest = useApiRequest();

  const isLoading = useSelector(selectAthletesIsLoading);
  const athletes = useSelector(selectAthletes);
  const organizationId = useSelector(selectSelectedOrganizationId);
  const organizationName = useSelector(selectSelectedOrganizationName);

  useEffect(() => {
    if (isNil(organizationId) || !isNil(athletes)) return;

    dispatch(setPageTitle(`${organizationName} - ${PAGE_TITLES.ATHLETE_ENROLLMENT}`));

    makeRequest(
      () => getOrganizationAthletes(organizationId),
      { type: GET_ORGANIZATION_ATHLETES_SUCCESS },
      ERROR_MESSAGES.GET_ORGANIZATION_ATHLETES_FAILURE
    );
  }, [dispatch, makeRequest, athletes, organizationId, organizationName]);

  useEffect(() => {
    // Clear out the selected athlete
    dispatch(setSelectedAthlete());
    return () => {
      dispatch(resetOrganizationAthletes());
    };
  }, [dispatch]);

  const tableActions = useMemo(
    () => [
      {
        icon: '',
        text: can(PERMISSIONS.EDIT_ATHLETES) ? 'Edit' : 'View',
        onClick: rowData => history.push(`${location.pathname}/${rowData.id}`, { athlete: rowData }),
      },
    ],
    [history, location]
  );

  const goToCreateAthlete = useCallback(() => history.push(`${location.pathname}/${NEW_ITEM_ROUTE}`), [
    history,
    location,
  ]);

  return (
    <PageWrapper>
      <Box mb={2} width="100%">
        <SportScaleTable
          actions={tableActions}
          columns={COLUMNS}
          components={{
            Action: SportScaleTableActionButton(classes.actionButton, false),
            Toolbar: SportScaleTableToolbar(
              can(PERMISSIONS.CREATE_ATHLETES) ? [{ text: 'Create Athlete', onClick: goToCreateAthlete }] : []
            ),
          }}
          data={athletes || []}
          isLoading={isLoading}
          options={{ toolbar: true }}
        />
      </Box>
    </PageWrapper>
  );
};

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
}));

AthletesContainer.propTypes = {};

AthletesContainer.defaultProps = {
  organization: null,
};

export default AthletesContainer;
