import PropTypes from 'prop-types';
import React from 'react';
import { Tab, Tabs } from '@material-ui/core';

export const WEIGHTS_TABS = {
  WEIGHTS: {
    INDEX: 0,
    LABEL: 'Weights',
  },
  TARGETS: {
    INDEX: 1,
    LABEL: 'Targets',
  },
};

const WeightsDialogTabs = ({ tabIndex, onChange }) => {
  return (
    <Tabs centered indicatorColor="primary" textColor="primary" value={tabIndex} onChange={onChange}>
      <Tab label={WEIGHTS_TABS.WEIGHTS.LABEL} />
      <Tab label={WEIGHTS_TABS.TARGETS.LABEL} />
    </Tabs>
  );
};

WeightsDialogTabs.propTypes = {
  tabIndex: PropTypes.number.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default WeightsDialogTabs;
