export default {
  WHITE: '#FFFFFF',
  TRANSPARENT: 'transparent',

  // Material UI theme definitions
  BRAND_GRAY_DARK: '#40403F',
  BRAND_GRAY_DARK_ALT: 'rgb(72, 72, 72)',
  BRAND_LIME_GREEN: '#BCD631',
  BRAND_LIGHT_BLUE: 'rgb(87, 171, 202)',
  ERROR: '#ED254E',
  CONTRAST: '#FFFFFF',
  WARNING: 'rgb(234, 151, 62)',

  GRAY1: '#9C9EA0',
  GRAY2: '#7B7E81',
};
