import PropTypes from 'prop-types';
import React, { forwardRef, memo, useMemo } from 'react';
import { TagFaces } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useTheme } from '@material-ui/core';

import FIELD_LABELS from 'types/fieldLabels';
import RosterChip from 'modules/teams/roster/rosterChip.component';
import SportScaleTable, { ICON_COLUMN_WIDTH } from 'common/table/sportScaleTable.component';
import { GENDER_OPTIONS } from 'utilities/inputs';
import { GENDER_TABLE_FILTERS } from 'utilities/gender';
import { sortByName } from 'utilities/sorting';

const AthleteDialogAthletes = memo(
  forwardRef(({ data, isLoading, onSelection }, ref) => {
    const theme = useTheme();

    const COLUMNS = useMemo(
      () => [
        {
          cellStyle: { width: ICON_COLUMN_WIDTH, maxWidth: ICON_COLUMN_WIDTH, minWidth: ICON_COLUMN_WIDTH },
          render: rowData => (rowData.hasFaceData ? <TagFaces color="primary" /> : ''),
          sorting: false,
          title: '',
          width: ICON_COLUMN_WIDTH,
        },
        {
          customFilterAndSearch: (term, rowData) => {
            const name = `${rowData.firstName} ${rowData.lastName}`;
            return name.toLowerCase().includes(term.toLowerCase());
          },
          customSort: sortByName,
          render: rowData => `${rowData.firstName} ${rowData.lastName}`,
          title: FIELD_LABELS.NAME,
        },
        { title: FIELD_LABELS.ID_NUMBER, field: 'identifier' },
        {
          customFilterAndSearch: (term, rowData) => isEmpty(term) || term.includes(rowData.gender),
          customSort: (a, b) => (a.gender < b.gender ? 1 : -1),
          lookup: GENDER_TABLE_FILTERS,
          render: rowData => GENDER_OPTIONS.find(({ value }) => value === rowData.gender).label,
          title: FIELD_LABELS.GENDER,
        },
        {
          cellStyle: (value, rowData) => {
            if (!isEmpty(rowData.rosters))
              return {
                display: 'flex',
                overflowX: 'scroll',
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
              };

            return {};
          },
          customFilterAndSearch: (term, rowData) => {
            if (isEmpty(term) || isEmpty(rowData.rosters)) return true;

            return (
              rowData.rosters
                .map(roster => `${rowData.id}-${roster.team.name}`)
                .filter(athleteTeam => athleteTeam.includes(term)).length > 0
            );
          },
          render: rowData => {
            if (isEmpty(rowData.rosters)) return 'No Rosters';

            return rowData.rosters.map(roster => (
              <RosterChip key={`${rowData.id}-${roster.team.name}-${roster.name}`} roster={roster} />
            ));
          },
          sorting: false,
          title: 'Rosters',
          width: 300,
        },
      ],
      [theme]
    );

    return (
      <SportScaleTable
        ref={ref}
        columns={COLUMNS}
        data={data}
        isLoading={isLoading}
        options={{
          pageSize: 5,
          selection: true,
        }}
        onSelectionChange={onSelection}
      />
    );
  })
);

AthleteDialogAthletes.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  onSelection: PropTypes.func.isRequired,
};

export default AthleteDialogAthletes;
