import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';

const SportScaleCheckbox = ({ description, isChecked, label, labelPlacement, name, onChange, ...rest }) => {
  const classes = useStyles();

  const handleCheck = useCallback(({ target: { checked } }) => onChange({ target: { name, value: checked } }), [
    name,
    onChange,
  ]);

  return (
    <FormControlLabel
      classes={{ labelPlacementStart: classes.labelPlacement }}
      control={<Checkbox checked={isChecked} onChange={handleCheck} name={name} color="primary" />}
      label={
        typeof label === 'string' ? (
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">{label}</Typography>
            {!isEmpty(description) && <Typography variant="caption">{description}</Typography>}
          </Box>
        ) : (
          label
        )
      }
      labelPlacement={labelPlacement}
      {...rest}
    />
  );
};

const useStyles = makeStyles(() => ({
  labelPlacement: {
    marginLeft: 0,
  },
}));

SportScaleCheckbox.propTypes = {
  description: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  labelPlacement: PropTypes.string,
  name: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
};

SportScaleCheckbox.defaultProps = {
  description: null,
  labelPlacement: 'end',
};

export default SportScaleCheckbox;
