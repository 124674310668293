import produce from 'immer';

import {
  CREATE_ORGANIZATION_TEAM_FAILURE,
  CREATE_ORGANIZATION_TEAM_REQUEST,
  CREATE_ORGANIZATION_TEAM_SUCCESS,
  DELETE_ORGANIZATION_TEAM_FAILURE,
  DELETE_ORGANIZATION_TEAM_REQUEST,
  DELETE_ORGANIZATION_TEAM_SUCCESS,
  GET_ORGANIZATION_TEAMS_FAILURE,
  GET_ORGANIZATION_TEAMS_REQUEST,
  GET_ORGANIZATION_TEAMS_SUCCESS,
  GET_ORGANIZATION_TEAM_FAILURE,
  GET_ORGANIZATION_TEAM_REQUEST,
  GET_ORGANIZATION_TEAM_SUCCESS,
  RESET_ORGANIZATION_SELECTED_TEAM,
  RESET_ORGANIZATION_TEAMS,
  UPDATE_ORGANIZATION_TEAM_FAILURE,
  UPDATE_ORGANIZATION_TEAM_REQUEST,
  UPDATE_ORGANIZATION_TEAM_SUCCESS,
} from 'modules/teams/teams.actions';

const initialState = {
  isLoading: false,
  isLoadingTeam: false,
  selectedTeam: null,
  teams: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_TEAMS_SUCCESS:
      draft.teams = action.response;
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_TEAMS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_TEAMS_REQUEST:
      draft.isLoading = true;
      break;

    case RESET_ORGANIZATION_TEAMS:
      draft.teams = null;
      break;

    case UPDATE_ORGANIZATION_TEAM_SUCCESS:
      draft.selectedTeam = action.response;
      draft.isLoadingTeam = false;
      break;

    case UPDATE_ORGANIZATION_TEAM_FAILURE:
      draft.isLoadingTeam = false;
      break;

    case UPDATE_ORGANIZATION_TEAM_REQUEST:
      draft.isLoadingTeam = true;
      break;

    case GET_ORGANIZATION_TEAM_SUCCESS:
      draft.selectedTeam = action.response;
      draft.isLoadingTeam = false;
      break;

    case CREATE_ORGANIZATION_TEAM_REQUEST:
    case DELETE_ORGANIZATION_TEAM_REQUEST:
    case GET_ORGANIZATION_TEAM_REQUEST:
      draft.isLoadingTeam = true;
      break;

    case CREATE_ORGANIZATION_TEAM_SUCCESS:
    case DELETE_ORGANIZATION_TEAM_SUCCESS:
      draft.isLoadingTeam = false;
      break;

    case CREATE_ORGANIZATION_TEAM_FAILURE:
    case DELETE_ORGANIZATION_TEAM_FAILURE:
    case GET_ORGANIZATION_TEAM_FAILURE:
      draft.isLoadingTeam = false;
      break;

    case RESET_ORGANIZATION_SELECTED_TEAM:
      draft.selectedTeam = null;
      break;

    default:
      break;
  }
}, initialState);
