import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PageWrapper from 'modules/layout/pageWrapper.component';
import ProfileForm from 'modules/profile/profileForm.component';
import { PAGE_TITLES } from 'modules/layout/drawer/drawerItems';
import { setPageTitle } from 'modules/layout/layout.actions';

const ProfileContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(PAGE_TITLES.PROFILE));
  }, [dispatch]);

  return (
    <PageWrapper>
      <ProfileForm />
    </PageWrapper>
  );
};

ProfileContainer.propTypes = {};

export default ProfileContainer;
