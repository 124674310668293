import produce from 'immer';
import * as Sentry from '@sentry/react';

import { LOGIN_STATE } from 'utilities/login';
import { LOGIN_USER, LOG_OUT } from 'modules/login/login.actions';

const initialState = {
  user: null,
  loginState: LOGIN_STATE.UNDETERMINED,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      const { user } = action;

      draft.loginState = LOGIN_STATE.LOGGED_IN;
      draft.user = user;

      Sentry.setUser({ id: user.sub, email: user.email });
      break;
    }

    case LOG_OUT:
      Sentry.setUser(null);
      draft.user = null;
      draft.loginState = LOGIN_STATE.NOT_LOGGED_IN;
      break;

    default:
      break;
  }
}, initialState);
