import produce from 'immer';

import {
  ADD_ATHLETES_TO_ROSTER_FAILURE,
  ADD_ATHLETES_TO_ROSTER_REQUEST,
  ADD_ATHLETES_TO_ROSTER_SUCCESS,
  ADD_ATHLETE_TO_ROSTER_FAILURE,
  ADD_ATHLETE_TO_ROSTER_REQUEST,
  ADD_ATHLETE_TO_ROSTER_SUCCESS,
  COPY_SEASON_ROSTER_FAILURE,
  COPY_SEASON_ROSTER_REQUEST,
  COPY_SEASON_ROSTER_SUCCESS,
  CREATE_TEAM_SEASON_FAILURE,
  CREATE_TEAM_SEASON_REQUEST,
  CREATE_TEAM_SEASON_SUCCESS,
  DELETE_TEAM_SEASON_FAILURE,
  DELETE_TEAM_SEASON_REQUEST,
  DELETE_TEAM_SEASON_SUCCESS,
  GET_SEASON_ROSTER_FAILURE,
  GET_SEASON_ROSTER_REQUEST,
  GET_SEASON_ROSTER_SUCCESS,
  GET_TEAM_SEASONS_FAILURE,
  GET_TEAM_SEASONS_REQUEST,
  GET_TEAM_SEASONS_SUCCESS,
  GET_TEAM_SEASON_FAILURE,
  GET_TEAM_SEASON_REQUEST,
  GET_TEAM_SEASON_SUCCESS,
  REMOVE_ATHLETE_FROM_ROSTER_FAILURE,
  REMOVE_ATHLETE_FROM_ROSTER_REQUEST,
  REMOVE_ATHLETE_FROM_ROSTER_SUCCESS,
  RESET_SEASON_ROSTER,
  RESET_SELECTED_SEASON,
  RESET_TEAM_SEASONS,
  UPDATE_TEAM_SEASON_FAILURE,
  UPDATE_TEAM_SEASON_REQUEST,
  UPDATE_TEAM_SEASON_SUCCESS,
} from 'modules/teams/seasons/seasons.actions';

const initialState = {
  isLoading: false,
  isLoadingRoster: false,
  isLoadingSeason: false,
  isRemovingAthlete: false,
  roster: null,
  seasons: null,
  selectedSeason: {},
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_TEAM_SEASONS_SUCCESS:
      draft.seasons = action.response;
      draft.isLoading = false;
      break;

    case GET_TEAM_SEASONS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_TEAM_SEASONS_REQUEST:
      draft.isLoading = true;
      break;

    case RESET_TEAM_SEASONS:
      draft.seasons = null;
      break;

    case GET_TEAM_SEASON_SUCCESS:
      draft.selectedSeason = action.response;
      draft.isLoadingSeason = false;
      break;

    case CREATE_TEAM_SEASON_REQUEST:
    case GET_TEAM_SEASON_REQUEST:
    case UPDATE_TEAM_SEASON_REQUEST:
    case DELETE_TEAM_SEASON_REQUEST:
      draft.isLoadingSeason = true;
      break;

    case CREATE_TEAM_SEASON_SUCCESS:
    case UPDATE_TEAM_SEASON_SUCCESS:
    case DELETE_TEAM_SEASON_SUCCESS:
      draft.isLoadingSeason = false;
      break;

    case GET_TEAM_SEASON_FAILURE:
    case UPDATE_TEAM_SEASON_FAILURE:
    case CREATE_TEAM_SEASON_FAILURE:
    case DELETE_TEAM_SEASON_FAILURE:
      draft.isLoadingSeason = false;
      break;

    case ADD_ATHLETE_TO_ROSTER_SUCCESS:
    case ADD_ATHLETES_TO_ROSTER_SUCCESS:
    case COPY_SEASON_ROSTER_SUCCESS:
      draft.isLoadingRoster = false;
      break;

    case GET_SEASON_ROSTER_SUCCESS:
      draft.roster = action.response.map(athlete => athlete.athlete);
      draft.isLoadingRoster = false;
      break;

    case ADD_ATHLETE_TO_ROSTER_FAILURE:
    case ADD_ATHLETES_TO_ROSTER_FAILURE:
    case COPY_SEASON_ROSTER_FAILURE:
    case GET_SEASON_ROSTER_FAILURE:
      draft.isLoadingRoster = false;
      break;

    case ADD_ATHLETE_TO_ROSTER_REQUEST:
    case ADD_ATHLETES_TO_ROSTER_REQUEST:
    case COPY_SEASON_ROSTER_REQUEST:
    case GET_SEASON_ROSTER_REQUEST:
      draft.isLoadingRoster = true;
      break;

    case RESET_SEASON_ROSTER:
      draft.roster = null;
      break;

    case REMOVE_ATHLETE_FROM_ROSTER_REQUEST:
      draft.isRemovingAthlete = true;
      break;

    case REMOVE_ATHLETE_FROM_ROSTER_FAILURE:
      draft.isRemovingAthlete = false;
      break;

    case REMOVE_ATHLETE_FROM_ROSTER_SUCCESS:
      draft.isRemovingAthlete = false;
      break;

    case RESET_SELECTED_SEASON:
      draft.selectedSeason = null;
      break;

    default:
      break;
  }
}, initialState);
