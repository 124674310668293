import PropTypes from 'prop-types';
import React from 'react';

import SportScaleDatePicker from 'common/formFields/sportScaleDatePicker.component';

const SportScaleDateFilter = ({ columnDef, onFilterChanged }) => (
  <SportScaleDatePicker
    {...columnDef}
    onChange={event => onFilterChanged(columnDef.tableData?.id, event.target.value)}
    inputVariant="standard"
  />
);

SportScaleDateFilter.propTypes = {
  columnDef: PropTypes.object.isRequired,

  onFilterChanged: PropTypes.func.isRequired,
};

export default SportScaleDateFilter;
