import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { findIndex, isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AthleteDialogAthletes from 'modules/teams/roster/athleteDialogAthletes.component';
import AthleteForm from 'modules/athletes/athleteForm.component';
import ERROR_MESSAGES from 'types/errorMessages';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleFormDialog from 'common/components/sportScaleFormDialog.component';
import {
  ADD_ATHLETES_TO_ROSTER_SUCCESS,
  ADD_ATHLETE_TO_ROSTER_SUCCESS,
  addAthleteToRoster,
  addAthletesToRoster,
  getSeasonRoster,
} from 'modules/teams/seasons/seasons.actions';
import {
  CREATE_ORGANIZATION_ATHLETE_SUCCESS,
  UPDATE_ORGANIZATION_ATHLETE_SUCCESS,
  createOrganizationAthlete,
  getOrganizationAthletes,
  resetOrganizationAthletes,
  updateOrganizationAthlete,
} from 'modules/athletes/athletes.actions';
import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import { getSubmitText } from 'utilities/form';

const INITIAL_ATHLETE = {
  customAttributes: {},
  dob: null,
  ethnicity: '',
  firstName: '',
  gender: '',
  graduationYear: null,
  height: '',
  identifier: '',
  lastName: '',
  predisposed: false,
};

const AthleteDialog = ({ isAddAthlete, isOpen, selectedAthlete, onClose }) => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const { organizationId, teamId, seasonId } = useParams();

  // eslint-disable-next-line no-shadow
  const { athletes, isLoading, roster } = useSelector(({ athletes, seasons }) => ({
    athletes: athletes.athletes,
    isLoading: athletes.isLoadingAthlete || seasons.isLoadingRoster,
    roster: seasons.roster,
  }));

  const [athlete, setAthlete] = useState(INITIAL_ATHLETE);
  const [selectedAthletes, setSelectedAthletes] = useState([]);

  const isNewAthlete = selectedAthlete === null;

  useEffect(() => {
    if (!isNil(selectedAthlete)) setAthlete(selectedAthlete);
  }, [selectedAthlete]);

  useEffect(() => {
    dispatch(getOrganizationAthletes(organizationId));

    return () => {
      dispatch(resetOrganizationAthletes());
    };
  }, [dispatch, organizationId]);

  const resetFilters = useCallback(() => {
    if (!isAddAthlete) return;

    /* 
      When closing the dialog doesn't unmount, so the table data isn't cleared, so with the columns
      staying the same the filter values stick around. Here we're using a tableRef in order to clear all column filters unless
      the table isn't currently filtered
    */
    const { state, dataManager } = tableRef.current;

    if (dataManager.filtered) {
      state.columns.forEach(column => {
        column.tableData.filterValue = undefined;
      });
    }
  }, [isAddAthlete, tableRef]);

  const handleInputChange = useCallback(({ target: { name, value } }) => setAthlete({ ...athlete, [name]: value }), [
    athlete,
    setAthlete,
  ]);

  const handleDialogClose = useCallback(() => {
    setAthlete(INITIAL_ATHLETE);
    resetFilters();
    onClose();
  }, [onClose, resetFilters, setAthlete]);

  const handleSubmitAthlete = useCallback(() => {
    (async () => {
      const updatedAthlete = {
        ...athlete,
        dob: moment(athlete.dob),
        graduationYear: athlete.graduationYear ? moment(athlete.graduationYear).year().toString() : '',
      };

      if (!updatedAthlete.graduationYear) delete updatedAthlete.graduationYear;
      if (!updatedAthlete.height) delete updatedAthlete.height;

      if (isNewAthlete) {
        const athleteResponse = await dispatch(createOrganizationAthlete(organizationId, updatedAthlete));

        if (athleteResponse.type !== CREATE_ORGANIZATION_ATHLETE_SUCCESS) {
          const isDuplicateError = athleteResponse.response.message === 'Duplication entry';

          dispatch(
            handleToastMessage(
              ERROR_MESSAGES.CREATE_ATHLETE_FAILURE,
              TOAST_TYPES.ERROR,
              isDuplicateError ? ERROR_MESSAGES.CREATE_DUPLICATE_ATHLETE_ERROR : ''
            )
          );
          return;
        }

        const rosterResponse = await dispatch(
          addAthleteToRoster(organizationId, teamId, seasonId, athleteResponse.response.id)
        );

        if (rosterResponse.type !== ADD_ATHLETE_TO_ROSTER_SUCCESS) {
          dispatch(handleToastMessage(ERROR_MESSAGES.ADD_ATHLETE_TO_ROSTER_FAILURE, TOAST_TYPES.ERROR));
          return;
        }

        dispatch(handleToastMessage(SUCCESS_MESSAGES.ADD_ATHLETE_TO_ROSTER_SUCCESS));
      } else {
        delete updatedAthlete.tableData;
        const response = await dispatch(updateOrganizationAthlete(organizationId, updatedAthlete));

        if (response.type !== UPDATE_ORGANIZATION_ATHLETE_SUCCESS) {
          dispatch(handleToastMessage(ERROR_MESSAGES.UPDATE_ATHLETE_FAILURE, TOAST_TYPES.ERROR));
          return;
        }

        dispatch(handleToastMessage(SUCCESS_MESSAGES.UPDATE_ATHLETE_SUCCESS));
      }

      handleDialogClose();
      dispatch(getSeasonRoster(organizationId, teamId, seasonId));
    })();
  }, [dispatch, athlete, isNewAthlete, organizationId, teamId, seasonId, handleDialogClose]);

  const submitText = useMemo(() => getSubmitText(isLoading, isNewAthlete), [isLoading, isNewAthlete]);

  const handleSelection = useCallback(selections => setSelectedAthletes(selections), [setSelectedAthletes]);

  const handleSubmitSelections = useCallback(() => {
    (async () => {
      const response = await dispatch(
        addAthletesToRoster(
          organizationId,
          teamId,
          seasonId,
          selectedAthletes.map(athleteToAdd => ({ athleteId: athleteToAdd.id }))
        )
      );

      resetFilters();
      onClose();

      if (response.type !== ADD_ATHLETES_TO_ROSTER_SUCCESS) {
        dispatch(handleToastMessage(ERROR_MESSAGES.ADD_ATHLETES_TO_ROSTER_FAILURE, TOAST_TYPES.ERROR));
        return;
      }

      dispatch(handleToastMessage(SUCCESS_MESSAGES.ADD_ATHLETES_TO_ROSTER_SUCCESS));
      dispatch(getSeasonRoster(organizationId, teamId, seasonId));
    })();
  }, [dispatch, organizationId, seasonId, teamId, selectedAthletes, onClose, resetFilters]);

  const filteredAthletes = useMemo(() => {
    if (isNil(athletes)) return [];

    return athletes.filter(
      orgAthlete => findIndex(roster, rosterAthlete => orgAthlete.id === rosterAthlete.athleteId) === -1
    );
  }, [athletes, roster]);

  if (isAddAthlete)
    return (
      <SportScaleFormDialog
        submitButtonText={isLoading ? 'selecting...' : 'select'}
        content={
          <AthleteDialogAthletes
            ref={tableRef}
            data={filteredAthletes || []}
            isLoading={isLoading}
            onSelection={handleSelection}
          />
        }
        fullWidth
        isLoading={isLoading}
        maxWidth="lg"
        open={isOpen}
        title="Select Athlete"
        onClose={handleDialogClose}
        onSubmit={handleSubmitSelections}
      />
    );

  return (
    <SportScaleFormDialog
      submitButtonText={submitText}
      content={<AthleteForm athlete={athlete} isLoading={isLoading} onChange={handleInputChange} />}
      fullWidth
      isLoading={isLoading}
      maxWidth="md"
      open={isOpen}
      title={isEmpty(selectedAthlete) ? 'Create Athlete' : 'Edit Athlete'}
      onClose={handleDialogClose}
      onSubmit={handleSubmitAthlete}
    />
  );
};

AthleteDialog.propTypes = {
  isAddAthlete: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedAthlete: PropTypes.object,

  onClose: PropTypes.func.isRequired,
};

AthleteDialog.defaultProps = {
  selectedAthlete: null,
};

export default AthleteDialog;
