import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import SportScaleCustomValidator from 'common/formFields/sportScaleCustomValidator.component';
import SportScaleTextField from 'common/formFields/sportScaleTextField.component';
import { InputAdornment } from '@material-ui/core';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

const PERCENTAGE_VALIDATION_TYPES = [`${VALIDATION_TYPES.MIN_NUMBER}:0`, `${VALIDATION_TYPES.MAX_NUMBER}:100`];

const SportScalePercentageValidator = memo(
  ({ disableBlurValidation, errorMessages, label, name, validators, value, onBlur, onChange, ...restProps }) => {
    const handleChange = useCallback(
      ({ target }) => {
        const decimal = target.value.split('.')[1];
        const newValue = decimal && decimal.length >= 2 ? Number(target.value).toFixed(2) : target.value;

        onChange({ target: { name: target.name, value: newValue } });
      },
      [onChange]
    );

    return (
      <SportScaleCustomValidator
        disableBlurValidation={disableBlurValidation}
        errorMessages={[...errorMessages, ...getValidationErrorMessages(value, label, PERCENTAGE_VALIDATION_TYPES)]}
        label={`${label} (%)`}
        name={name}
        validators={[...validators, ...PERCENTAGE_VALIDATION_TYPES]}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
      >
        <SportScaleTextField
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          inputProps={{
            max: '100',
            min: '0',
            step: '.01',
          }}
          type="number"
          {...restProps}
        />
      </SportScaleCustomValidator>
    );
  }
);

SportScalePercentageValidator.propTypes = {
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  hasInfoTooltip: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  validators: PropTypes.array,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScalePercentageValidator.defaultProps = {
  disableBlurValidation: false,
  disabled: false,
  errorMessages: [],
  hasInfoTooltip: false,
  tooltipText: '',
  validators: [],
  value: '',
  onBlur: () => {},
};

export default SportScalePercentageValidator;
