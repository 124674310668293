import { combineReducers } from 'redux';

import athletes from 'modules/athletes/athletes.reducer';
import layout from 'modules/layout/layout.reducer';
import locations from 'modules/locations/locations.reducer';
import login from 'modules/login/login.reducer';
import organizations from 'modules/organizations/organizations.reducer';
import profile from 'modules/profile/profile.reducer';
import reports from 'modules/reports/reports.reducer';
import scales from 'modules/scales/scales.reducer';
import seasons from 'modules/teams/seasons/seasons.reducer';
import subscriptions from 'modules/organizations/subscriptions/subscriptions.reducer';
import teams from 'modules/teams/teams.reducer';
import users from 'modules/users/users.reducer';
import watchlist from 'modules/watchlist/watchlist.reducer';

const appReducer = combineReducers({
  athletes,
  layout,
  locations,
  login,
  organizations,
  profile,
  reports,
  scales,
  seasons,
  subscriptions,
  teams,
  users,
  watchlist,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
