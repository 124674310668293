import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import {
  Box,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { MoreVert, TrackChanges } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import ERROR_MESSAGES from 'types/errorMessages';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import SportScaleMenuButton from 'common/buttons/sportScaleMenuButton.component';
import WeightUtils, { WEIGHT_EMPTY_STRING } from 'utilities/weight';
import { DELETE_TARGET_WEIGHT_SUCCESS, deleteTargetWeight, getTargetWeights } from 'modules/athletes/athletes.actions';
import { PERMISSIONS, can } from 'common/permissions';
import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import { selectAthletesIsLoadingAthlete } from 'modules/athletes/athletes.selectors';
import { selectSelectedOrganizationId } from 'modules/organizations/organizations.selectors';

const DATE_FORMAT = 'dd MM-DD';

const WeightsTargetWeightsTable = ({ athleteId, targetWeights, onEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const organizationId = useSelector(selectSelectedOrganizationId);
  const isLoading = useSelector(selectAthletesIsLoadingAthlete);

  const [targetWeightToDelete, setTargetWeightToDelete] = useState(null);

  const handleDeleteTargetWeight = useCallback(async () => {
    const response = await dispatch(deleteTargetWeight(organizationId, athleteId, targetWeightToDelete.id));

    if (response.type !== DELETE_TARGET_WEIGHT_SUCCESS) {
      dispatch(handleToastMessage(ERROR_MESSAGES.DELETE_TARGET_WEIGHT_FAILURE, TOAST_TYPES.ERROR));
      return;
    }
    dispatch(getTargetWeights(organizationId, athleteId));
    dispatch(handleToastMessage(SUCCESS_MESSAGES.DELETE_TARGET_WEIGHT_SUCCESS));
    setTargetWeightToDelete(null);
  }, [athleteId, organizationId, targetWeightToDelete, dispatch]);

  if (isEmpty(targetWeights))
    return (
      <Box marginBottom={theme.spacing()} marginTop={theme.spacing()} textAlign="center">
        <TrackChanges className={classes.targetIcon} color="primary" />
        <Typography className={classes.targetText} variant="h4">
          No Target Weights
        </Typography>
      </Box>
    );

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" color="primary">
                Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="primary">Length</Typography>
            </TableCell>
            <TableCell color="primary">
              <Typography color="primary">Target</Typography>
            </TableCell>
            <TableCell color="primary">
              <Typography color="primary">Change</Typography>
            </TableCell>
            <TableCell color="primary">
              <Typography color="primary">Achieved</Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {targetWeights.map(targetWeight => {
            const targetDate = moment(targetWeight.targetDate);
            const isInFuture = targetDate.isAfter(moment(), 'days');

            return (
              <TableRow key={targetWeight.id}>
                <TableCell>{targetDate.format(DATE_FORMAT)}</TableCell>
                <TableCell>{targetWeight.daysDuration || WEIGHT_EMPTY_STRING}</TableCell>
                <TableCell>{targetWeight.targetWeight || WEIGHT_EMPTY_STRING}</TableCell>
                <TableCell>
                  {WeightUtils.calcWeightChange(targetWeight.initialWeight, targetWeight.targetWeight)}
                </TableCell>
                <TableCell>{targetWeight.isAchieved ? 'Y' : 'N'}</TableCell>
                <TableCell className={classes.actionCell}>
                  {isInFuture && can(PERMISSIONS.EDIT_ATHLETES) && (
                    <SportScaleMenuButton
                      buttonComponent={
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      }
                      menuItems={[
                        {
                          text: 'Edit',
                          onClick: () => onEdit(targetWeight),
                        },
                        {
                          text: 'Delete',
                          onClick: () => setTargetWeightToDelete(targetWeight),
                        },
                      ]}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <SportScaleDialog
        actions={[
          {
            action: () => {
              setTargetWeightToDelete(null);
            },
            disabled: isLoading,
            text: 'Cancel',
          },
          {
            action: handleDeleteTargetWeight,
            disabled: isLoading,
            isPrimary: true,
            text: isLoading ? 'Deleting...' : 'Delete',
          },
        ]}
        content={<DialogContentText>Are you sure you want to delete this target weight?</DialogContentText>}
        open={Boolean(targetWeightToDelete)}
        title="Delete Target Weight?"
        onClose={() => setTargetWeightToDelete(null)}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  actionCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  targetIcon: {
    fontSize: '5rem',
    marginBottom: '1rem',
  },
  targetText: {
    color: theme.palette.common.white,
  },
}));

WeightsTargetWeightsTable.propTypes = {
  athleteId: PropTypes.string.isRequired,
  targetWeights: PropTypes.array.isRequired,

  onEdit: PropTypes.func.isRequired,
};

export default WeightsTargetWeightsTable;
