import store from 'redux/store';
import { memoize } from 'lodash';

const DEV_KEY = 'sportscale_permissions';
const TEAM_ID_REGEX = new RegExp('teams/([^/]+)');

function can(action) {
  const state = store.getState();
  const { profile } = state.profile;
  let selectedTeamId = state.teams.selectedTeam?.id;
  const { selectedOrganizationId } = state.organizations;
  // If the user is a super admin, they have permissions for all actions.
  if (profile?.isSuperadmin) return true;
  /* Permissions are in the form { org: [PERMISSION_1, PERMISSION-2] }. Check
  to see if the user has the necessary permissions for the selected organization. */
  const orgPermissions = profile?.permissions[selectedOrganizationId];
  if (!orgPermissions) return false;
  /* SPOR-457: See if the URL has a team selected if one hasn't been set in
  redux yet. If so, set that id as the one to use when checking for permission.
  */
  if (!selectedTeamId) {
    /* Grab the second match since the first match will be the whole string,
     not the team id. */
    [, selectedTeamId] = window.location.pathname.match(TEAM_ID_REGEX) || [];
  }
  /* If the user has permissions for this organization, see if they have
  permissions for this action or this action for the current team. */
  return orgPermissions.includes(action) || orgPermissions.includes(`${selectedTeamId}/${action}`);
}

function devCan(action) {
  const permissionsString = localStorage.getItem(DEV_KEY);
  if (!permissionsString) return false;
  try {
    const permissions = JSON.parse(permissionsString);
    return permissions.includes(action);
  } catch (e) {
    return false;
  }
}

let defaultFunc = can;
if (process.env.REACT_APP_ENVIRONMENT_NAME === 'development' && localStorage.getItem(DEV_KEY)) {
  defaultFunc = devCan;
}

export default memoize(defaultFunc);
