import React, { memo } from 'react';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import CacheBuster from 'modules/layout/cacheBuster.component';
import Header from 'modules/layout/header/header.component';
import OrganizationSelectionDialog from 'modules/organizations/organizationSelectionDialog.component';
import Routes from 'modules/layout/routes/routes.component';
import Toast from 'modules/layout/toast.component';

/* 
  IMPORTANT:
  React.memo is to replace a PureComponent
  https://reactjs.org/docs/hooks-faq.html - in the Hooks FAQ
*/
const MainContainer = memo(() => {
  const { hasInitialLoad } = useSelector(state => ({ hasInitialLoad: state.layout.hasInitialLoad }));

  const classes = useStyles();
  const theme = useTheme();
  const { isAuthenticated } = useAuth0();

  return (
    <CacheBuster>
      {({ loading, shouldForceRefresh, refreshCacheAndReload }) => {
        // if fetching meta.json dont do anything yet (show blank screen or loader?)
        if (loading) return null;

        // if not loading and we should force a refresh then do that
        // can do other things in `refreshCacheAndReload` like show notification instead
        if (!loading && shouldForceRefresh) {
          refreshCacheAndReload();
          return null;
        }

        return (
          <>
            <Box
              className={classes.container}
              bgcolor={theme.palette.background.default}
              display="flex"
              flex={1}
              flexDirection="column"
              height="100vh"
            >
              {isAuthenticated && hasInitialLoad && <Header />}
              <Routes />
            </Box>
            <OrganizationSelectionDialog />
            <Toast />
          </>
        );
      }}
    </CacheBuster>
  );
});

const useStyles = makeStyles((/* theme */) => ({
  container: {
    overflowY: 'auto',
  },
}));

MainContainer.propTypes = {};

export default MainContainer;
