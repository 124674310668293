import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SportScaleTable from 'common/table/sportScaleTable.component';
import SportScaleTableActionMenu from 'common/table/sportScaleTableActionMenu.component';
import SportScaleTableToolbar from 'common/table/sportScaleTableToolbar.component';
import WeightsDialog from 'modules/athletes/weights/weightsDialog.component';
import { GET_ORGANIZATION_ATHLETE_SUCCESS, getOrganizationAthlete } from 'modules/athletes/athletes.actions';
import { PERMISSIONS, can } from 'common/permissions';
import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import {
  selectSeasons,
  selectSeasonsIsLoadingRoster,
  selectSelectedSeasonRoster,
} from 'modules/teams/seasons/seasons.selectors';
import { selectSelectedOrganizationId } from 'modules/organizations/organizations.selectors';
import { selectSelectedTeam } from 'modules/teams/teams.selectors';

const TABLE_PREDISPOSED_FILTERS = {
  [true]: 'Yes',
  [false]: 'No',
};

const COLUMNS = [
  {
    customFilterAndSearch: (term, rowData) => {
      const name = `${rowData.firstName} ${rowData.lastName}`;
      return name.toLowerCase().includes(term.toLowerCase());
    },
    render: rowData => `${rowData.firstName} ${rowData.lastName}`,
    title: 'Name',
  },
  {
    customFilterAndSearch: (term, rowData) => !isNil(rowData.graduationYear) && rowData.graduationYear.includes(term),
    render: rowData => {
      if (isEmpty(rowData.graduationYear)) return '-';

      return rowData.graduationYear;
    },
    title: 'Graduation Year',
  },
  {
    customFilterAndSearch: (term, rowData) => isEmpty(term) || term.includes(rowData.predisposed.toString()),
    lookup: TABLE_PREDISPOSED_FILTERS,
    render: rowData => (rowData.predisposed ? 'Yes' : 'No'),
    title: 'High Risk',
  },
];

const Roster = ({ isLoadingSeason, onAddEditAthlete, onCopyRoster, onRemoveAthlete }) => {
  const classes = useStyles();
  const { seasonId } = useParams();
  const dispatch = useDispatch();
  const [selectedAthlete, setSelectedAthlete] = useState(null);

  const isLoading = useSelector(selectSeasonsIsLoadingRoster);
  const roster = useSelector(selectSelectedSeasonRoster);
  const seasons = useSelector(selectSeasons);
  const organizationId = useSelector(selectSelectedOrganizationId);
  const selectedSeason = seasons?.find(s => s.id === seasonId);
  const team = useSelector(selectSelectedTeam);

  const toolbarActions = useMemo(() => {
    const actions = [
      {
        disabled: isLoadingSeason || !can(PERMISSIONS.EDIT_TEAMS),
        isMenu: true,
        menuItems: [
          {
            disabled: !can(PERMISSIONS.CREATE_ATHLETES),
            text: 'Create Athlete',
            onClick: () => onAddEditAthlete(null, false),
          },
          { text: 'Add Existing Athlete', onClick: () => onAddEditAthlete(null, true) },
        ],
        text: 'add athlete',
      },
    ];

    const filteredSeasons = !isNil(seasons) && seasons.filter(season => season.id !== seasonId);

    if (filteredSeasons.length)
      actions.unshift({
        disabled: isLoadingSeason || !can(PERMISSIONS.EDIT_TEAMS),
        text: 'copy previous roster',
        isMenu: true,
        menuItems: filteredSeasons.map(season => ({ text: season.name, onClick: () => onCopyRoster(season) })),
      });

    return actions;
  }, [isLoadingSeason, seasonId, seasons, onAddEditAthlete, onCopyRoster]);

  const onViewChart = useCallback(
    async rowData => {
      const response = await dispatch(getOrganizationAthlete(organizationId, rowData.id));

      if (response.type !== GET_ORGANIZATION_ATHLETE_SUCCESS) {
        dispatch(handleToastMessage('Error getting athlete chart', TOAST_TYPES.ERROR));
        return;
      }
      setSelectedAthlete(response.response);
    },
    [dispatch, organizationId]
  );
  const getRowActions = useCallback(
    rowData => [
      {
        text: 'Edit',
        disabled: isLoadingSeason || !can(PERMISSIONS.EDIT_ATHLETES),
        onClick: () => onAddEditAthlete(rowData, false),
      },
      {
        text: 'Remove',
        disabled: isLoadingSeason || !can(PERMISSIONS.EDIT_ROSTERS),
        onClick: () => onRemoveAthlete(rowData),
      },
      {
        text: 'View Weights',
        disabled: isLoadingSeason,
        onClick: () => onViewChart(rowData),
      },
    ],
    [isLoadingSeason, onAddEditAthlete, onRemoveAthlete, onViewChart]
  );

  return (
    <>
      <Box mb={2} width="100%">
        <SportScaleTable
          actions={[
            {
              // This is the action used to create the actions menu.
              disabled: false,
            },
          ]}
          columns={COLUMNS}
          data={roster || []}
          components={{
            Action: SportScaleTableActionMenu(classes.actionButton, getRowActions),
            Toolbar: SportScaleTableToolbar(
              can(PERMISSIONS.EDIT_ROSTERS) ? toolbarActions : [],
              `Roster (${isNil(roster) ? 0 : roster.length})`
            ),
          }}
          isLoading={isLoading}
          options={{
            paging: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 20],
            toolbar: true,
          }}
        />
      </Box>
      <WeightsDialog
        athlete={selectedAthlete}
        enableHydrationTracking={selectedSeason?.enableHydrationTracking}
        team={team}
        open={!isNil(selectedAthlete)}
        onClose={() => setSelectedAthlete(null)}
      />
    </>
  );
};

Roster.propTypes = {
  isLoadingSeason: PropTypes.bool.isRequired,

  onAddEditAthlete: PropTypes.func.isRequired,
  onCopyRoster: PropTypes.func.isRequired,
  onRemoveAthlete: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(2),
  },
}));
/* memo is the equivalent to PureComponent and stops this from re-rendering when the parents season state is changing */
export default memo(Roster);
