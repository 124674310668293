import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import SportScaleCheckbox from 'common/formFields/sportScaleCheckBox.component';
import SportScaleDatePickerValidator from 'common/formFields/sportScaleDatePickerValidator.component';
import SportScaleSelectFieldValidator from 'common/formFields/sportScaleSelectFieldValidator.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { DAYS_TRACKED_OPTIONS } from 'utilities/inputs';
import { VALIDATION_TYPES, fieldValidators, getValidationErrorMessages } from 'utilities/fieldValidation';

const SeasonForm = ({ disabled, season, onChange }) => {
  const classes = useStyles();

  useEffect(() => {
    ValidatorForm.addValidationRule(VALIDATION_TYPES.IS_NOT_EMPTY, value => {
      const message = fieldValidators[VALIDATION_TYPES.IS_NOT_EMPTY](value, 'Season Name');
      return message === false;
    });
    ValidatorForm.addValidationRule(
      VALIDATION_TYPES.IS_DATE_AFTER_DATE,
      value =>
        !(
          fieldValidators[VALIDATION_TYPES.IS_DATE_AFTER_DATE] &&
          fieldValidators[VALIDATION_TYPES.IS_DATE_AFTER_DATE](value, 'End Date', season.startDate, 'Start Date')
        )
    );

    return () => {
      ValidatorForm.removeValidationRule(VALIDATION_TYPES.IS_DATE_AFTER_DATE);
      ValidatorForm.removeValidationRule(VALIDATION_TYPES.IS_NOT_EMPTY);
    };
  }, [season]);

  return (
    <Paper className={classes.paper}>
      <Container spacing={2}>
        <Container item spacing={2}>
          <Item md={6} xs={12}>
            <SportScaleTextFieldValidator
              disabled={disabled}
              errorMessages={getValidationErrorMessages(season.name, 'Season Name', [
                VALIDATION_TYPES.REQUIRED,
                VALIDATION_TYPES.IS_NOT_EMPTY,
              ])}
              fullWidth
              label="Season Name"
              name="name"
              validators={[VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_NOT_EMPTY]}
              value={season.name}
              onChange={onChange}
            />
          </Item>
          <Item md={6} xs={12}>
            <Container spacing={2}>
              <Item sm={6} xs={12}>
                <SportScaleDatePickerValidator
                  disabled={disabled}
                  errorMessages={getValidationErrorMessages(season.startDate, 'Start Date', [
                    VALIDATION_TYPES.REQUIRED,
                  ])}
                  fullWidth
                  label="Start Date"
                  name="startDate"
                  value={season.startDate}
                  validators={[VALIDATION_TYPES.REQUIRED]}
                  onChange={onChange}
                />
              </Item>
              <Item sm={6} xs={12}>
                <SportScaleDatePickerValidator
                  compareLabel="Start Date"
                  compareValue={season.startDate}
                  disabled={disabled}
                  errorMessages={getValidationErrorMessages(
                    season.endDate,
                    'End Date',
                    [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_DATE_AFTER_DATE],
                    season.startDate,
                    'Start Date'
                  )}
                  fullWidth
                  label="End Date"
                  name="endDate"
                  value={season.endDate}
                  validators={[VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_DATE_AFTER_DATE]}
                  onChange={onChange}
                />
              </Item>
            </Container>
          </Item>
        </Container>
        <Item xs={12}>
          <SportScaleCheckbox
            disabled={disabled}
            isChecked={season.showWeight}
            label="Display Weight on Sportscale"
            name="showWeight"
            onChange={onChange}
          />
        </Item>
        <Item xs={12}>
          <Container spacing={2}>
            <Item sm={4} xs={12}>
              <SportScaleCheckbox
                disabled={disabled}
                isChecked={season.enableHydrationTracking}
                label="Hydration Tracking"
                name="enableHydrationTracking"
                onChange={onChange}
              />
            </Item>
            {season.enableHydrationTracking && (
              <Item sm={8} xs={12}>
                <SportScaleSelectFieldValidator
                  className={classes.selectField}
                  disabled={disabled}
                  errorMessages={getValidationErrorMessages(season.dayTracked, 'Days Tracked', [
                    VALIDATION_TYPES.REQUIRED,
                  ])}
                  label="Days Tracked"
                  multiple
                  name="daysTracked"
                  options={DAYS_TRACKED_OPTIONS}
                  placeholder="Select Days"
                  selectProps={{
                    classes: {
                      select: classes.select,
                    },
                  }}
                  validators={[VALIDATION_TYPES.REQUIRED]}
                  value={season.daysTracked}
                  onChange={onChange}
                />
              </Item>
            )}
          </Container>
        </Item>
      </Container>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

SeasonForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  season: PropTypes.object,

  onChange: PropTypes.func.isRequired,
};

SeasonForm.defaultProps = {
  season: null,
};

export default SeasonForm;
