import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "locations" gets changed
const selectLocationsState = createSelector(
  state => state.locations,
  locationsState => locationsState
);

export const selectLocations = createSelector(selectLocationsState, locationsState => locationsState.locations);

export const selectLocationsIsLoading = createSelector(
  selectLocationsState,
  locationsState => locationsState.isLoading || locationsState.isLoadingLocation
);

export const selectLocationsIsLoadingLocations = createSelector(
  selectLocationsState,
  locationsState => locationsState.isLoading
);

export const selectLocationsIsLoadingLocation = createSelector(
  selectLocationsState,
  locationsState => locationsState.isLoadingLocation
);
