import { CALL_API } from '../../middleware/api';

export const GET_REPORTS_URL_REQUEST = 'GET_REPORTS_URL_REQUEST';
export const GET_REPORTS_URL_SUCCESS = 'GET_REPORTS_URL_SUCCESS';
export const GET_REPORTS_URL_FAILURE = 'GET_REPORTS_URL_FAILURE';

export const getReportsUrl = organizationId => {
  return {
    [CALL_API]: {
      types: [GET_REPORTS_URL_REQUEST, GET_REPORTS_URL_SUCCESS, GET_REPORTS_URL_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/reporturl`,
      method: 'GET',
    },
  };
};
