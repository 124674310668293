import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { cloneDeep, has, isNil } from 'lodash';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from 'utilities/theme';

export const ICON_COLUMN_WIDTH = 50;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const SportScaleTable = forwardRef(({ columns, data, options, ...props }, ref) => {
  const newTheme = cloneDeep(theme);
  newTheme.palette.secondary = theme.palette.primary;

  const widthAutoColumns = useMemo(() => {
    return columns.map(column => {
      if (!has(column, 'width')) {
        return {
          ...column,
          width: 'auto',
        };
      }

      return column;
    });
  }, [columns]);

  return (
    /*
      Wrapping this with a new theme provider allows us to have the primary colors show in the table,
      without this we get mostly secondary colors as we don't have in depth control of all components
    */
    <MuiThemeProvider theme={newTheme}>
      <MaterialTable
        tableRef={ref}
        columns={widthAutoColumns}
        data={isNil(data) ? [] : data.map(item => ({ ...item }))}
        icons={tableIcons}
        localization={{
          header: {
            actions: '',
          },
        }}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          filtering: true,
          pageSize: 10,
          paging: true,
          search: false,
          showTitle: false,
          toolbar: false,
          ...options,
        }}
        {...props}
      />
    </MuiThemeProvider>
  );
});

SportScaleTable.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array.isRequired,
  components: PropTypes.object,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.object,
  title: PropTypes.string,
};

SportScaleTable.defaultProps = {
  actions: [],
  components: {},
  options: {},
  title: '',
};

export default SportScaleTable;
