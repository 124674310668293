import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Typography, makeStyles } from '@material-ui/core';

const SportScaleOverflowTooltip = ({ text }) => {
  const element = useRef();

  const classes = useStyles();

  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    setIsOverflow(element.current.scrollWidth > element.current.clientWidth);
  }, [element]);

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={text} disableHoverListener={!isOverflow}>
      <Typography ref={element} className={classes.text}>
        {text}
      </Typography>
    </Tooltip>
  );
};

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '.75rem',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

SportScaleOverflowTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SportScaleOverflowTooltip;
