import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Box, Paper, Tooltip, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import SportScaleCheckbox from 'common/formFields/sportScaleCheckBox.component';
import SportScaleRadio from 'common/formFields/sportScaleRadio.component';
import SportScaleRadioGroupValidator from 'common/formFields/sportScaleRadioGroupValidator.component';
import SportScaleSelectFieldValidator from 'common/formFields/sportScaleSelectFieldValidator.component';
import SportScaleTextFieldValidator from 'common/formFields/sportScaleTextFieldValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

export const ORGANIZATION_ROLES = {
  ALL_TEAMS: '*/Team',
  ORGANIZATION_ADMIN: 'Organization Administrator',
};

const UserForm = ({ disabled, isNewUser, roles, teams, user, onChange }) => {
  const classes = useStyles();

  const hasAdminRole = useMemo(() => user.roles.indexOf(ORGANIZATION_ROLES.ORGANIZATION_ADMIN) !== -1, [user]);
  const hasAllTeamsRole = useMemo(() => user.roles.indexOf(ORGANIZATION_ROLES.ALL_TEAMS) !== -1, [user]);

  const generateUpdatedRoles = useCallback(
    (isAdd, roleName) => {
      let newRoles = [...user.roles];

      if (isAdd && roleName.includes('/')) {
        const role = roleName.split('/')[1];

        newRoles = newRoles.filter(newRole => !newRole.includes(role));
        newRoles.push(roleName);

        return newRoles;
      }

      if (isAdd) {
        newRoles.push(roleName);

        return newRoles;
      }

      const roleIndex = newRoles.findIndex(role => role === roleName);

      if (roleIndex !== -1) {
        newRoles.splice(roleIndex, 1);
      }

      return newRoles;
    },
    [user]
  );

  const handleOrgScopedRoleChange = useCallback(
    ({ target: { name, value } }) => {
      const newRoles = generateUpdatedRoles(value, name);

      onChange({ target: { name: 'roles', value: newRoles } });
    },
    [generateUpdatedRoles, onChange]
  );

  const handleTeamScopedRoleChange = useCallback(
    ({ target: { name, value } }) => {
      const newRoles = generateUpdatedRoles(value === 'true', `*/${name}`);

      onChange({ target: { name: 'roles', value: newRoles } });
    },
    [generateUpdatedRoles, onChange]
  );

  const handleTeamChange = useCallback(
    ({ target: { name, value } }) => {
      /*
        If the admin role exists, we don't want to overwrite it.
        So, we need to explicitly keep it
      */
      onChange({
        target: { name, value: hasAdminRole ? [ORGANIZATION_ROLES.ORGANIZATION_ADMIN, ...value] : [...value] },
      });
    },
    [hasAdminRole, onChange]
  );

  const isOrgRoleChecked = role => user.roles.indexOf(role.name) !== -1;

  const filterRoles = useCallback(role => user.roles.filter(userRole => userRole.includes(role.name)), [user]);

  /**
   * provide info bubble for certain roles in user form
   */
  const getRoleInfoBubble = roleName => {
    switch (roleName) {
      case ORGANIZATION_ROLES.ORGANIZATION_ADMIN:
        return 'This access permits users to enter and edit data. If not selected, users can only view data.';

      default:
        return null;
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Container>
          <Item sm={6} xs={12}>
            <SportScaleTextFieldValidator
              disabled={disabled || !isNewUser}
              errorMessages={getValidationErrorMessages(user.email, 'Email', [
                VALIDATION_TYPES.REQUIRED,
                'Email is not valid',
              ])}
              fullWidth
              label="Email"
              name="email"
              validators={[VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_EMAIL]}
              value={user.email}
              onChange={onChange}
            />
          </Item>
        </Container>
      </Paper>

      {roles.map(role => {
        const infoText = getRoleInfoBubble(role.name);

        if (!role.isTeamScoped) {
          return (
            <Paper key={role.name} className={classes.paper}>
              <Container>
                <Item sm={6} xs={12} className={classes.tooltipContainer}>
                  <SportScaleCheckbox
                    desciption={role.description}
                    disabled={disabled}
                    isChecked={isOrgRoleChecked(role)}
                    label={role.name}
                    name={role.name}
                    onChange={handleOrgScopedRoleChange}
                    className={classes.roleCheckbox}
                  />
                  {infoText && (
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={infoText} aria-label="tooltip">
                      <InfoOutlined className={classes.tooltipIcon} />
                    </Tooltip>
                  )}
                </Item>
              </Container>
            </Paper>
          );
        }

        return (
          <Paper key={role.name} className={classes.paper}>
            <Container>
              <Item sm={6} xs={12}>
                <SportScaleRadioGroupValidator
                  disabled={disabled}
                  errorMessages={getValidationErrorMessages(hasAllTeamsRole, 'Sports', [VALIDATION_TYPES.REQUIRED])}
                  label={role.name}
                  name={role.name}
                  row={false}
                  validators={[VALIDATION_TYPES.REQUIRED]}
                  value={hasAllTeamsRole.toString()}
                  onChange={handleTeamScopedRoleChange}
                >
                  <SportScaleRadio disabled={disabled} label="All Sports" value="true" />
                  <SportScaleRadio disabled={disabled} label="Pick Sports" value="false" />
                </SportScaleRadioGroupValidator>
                {!hasAllTeamsRole && (
                  <Box mt={2}>
                    <SportScaleSelectFieldValidator
                      disabled={disabled}
                      errorMessages={getValidationErrorMessages(filterRoles(role), role.name, [
                        VALIDATION_TYPES.REQUIRED,
                      ])}
                      fullWidth
                      label="Sports"
                      multiple
                      name="roles"
                      options={teams.map(team => ({ label: team.name, value: `${team.id}/${role.name}` }))}
                      placeholder="Select Sports"
                      selectProps={{
                        classes: {
                          select: classes.select,
                        },
                      }}
                      validators={[VALIDATION_TYPES.REQUIRED]}
                      value={filterRoles(role)}
                      onChange={handleTeamChange}
                    />
                  </Box>
                )}
              </Item>
            </Container>
          </Paper>
        );
      })}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  labelPlacement: {
    marginLeft: 0,
  },
  roleCheckbox: {
    marginRight: 4,
  },
  selectField: {
    width: '50%',
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    fontSize: '0.8rem',
  },
  tooltipIcon: {
    fontSize: '1rem',
    paddingLeft: theme.spacing(0.5),
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

UserForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default UserForm;
