import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATION_TEAMS_REQUEST = 'GET_ORGANIZATION_TEAMS_REQUEST';
export const GET_ORGANIZATION_TEAMS_SUCCESS = 'GET_ORGANIZATION_TEAMS_SUCCESS';
export const GET_ORGANIZATION_TEAMS_FAILURE = 'GET_ORGANIZATION_TEAMS_FAILURE';

export const UPDATE_ORGANIZATION_TEAM_REQUEST = 'UPDATE_ORGANIZATION_TEAM_REQUEST';
export const UPDATE_ORGANIZATION_TEAM_SUCCESS = 'UPDATE_ORGANIZATION_TEAM_SUCCESS';
export const UPDATE_ORGANIZATION_TEAM_FAILURE = 'UPDATE_ORGANIZATION_TEAM_FAILURE';

export const GET_ORGANIZATION_TEAM_REQUEST = 'GET_ORGANIZATION_TEAM_REQUEST';
export const GET_ORGANIZATION_TEAM_SUCCESS = 'GET_ORGANIZATION_TEAM_SUCCESS';
export const GET_ORGANIZATION_TEAM_FAILURE = 'GET_ORGANIZATION_TEAM_FAILURE';

export const CREATE_ORGANIZATION_TEAM_REQUEST = 'CREATE_ORGANIZATION_TEAM_REQUEST';
export const CREATE_ORGANIZATION_TEAM_SUCCESS = 'CREATE_ORGANIZATION_TEAM_SUCCESS';
export const CREATE_ORGANIZATION_TEAM_FAILURE = 'CREATE_ORGANIZATION_TEAM_FAILURE';

export const DELETE_ORGANIZATION_TEAM_REQUEST = 'DELETE_ORGANIZATION_TEAM_REQUEST';
export const DELETE_ORGANIZATION_TEAM_SUCCESS = 'DELETE_ORGANIZATION_TEAM_SUCCESS';
export const DELETE_ORGANIZATION_TEAM_FAILURE = 'DELETE_ORGANIZATION_TEAM_FAILURE';

export const RESET_ORGANIZATION_TEAMS = 'RESET_ORGANIZATION_TEAMS';

export const RESET_ORGANIZATION_SELECTED_TEAM = 'RESET_ORGANIZATION_SELECTED_TEAM';

export const getOrganizationTeams = organizationId => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_TEAMS_REQUEST, GET_ORGANIZATION_TEAMS_SUCCESS, GET_ORGANIZATION_TEAMS_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams`,
      method: 'GET',
    },
  };
};

export const updateOrganizationTeam = (organizationId, user) => {
  return {
    [CALL_API]: {
      types: [UPDATE_ORGANIZATION_TEAM_REQUEST, UPDATE_ORGANIZATION_TEAM_SUCCESS, UPDATE_ORGANIZATION_TEAM_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${user.id}`,
      method: 'PUT',
      payload: user,
    },
  };
};

export const getOrganizationTeam = (organizationId, userId) => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_TEAM_REQUEST, GET_ORGANIZATION_TEAM_SUCCESS, GET_ORGANIZATION_TEAM_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${userId}`,
      method: 'GET',
    },
  };
};

export const createOrganizationTeam = (organizationId, user) => {
  return {
    [CALL_API]: {
      types: [CREATE_ORGANIZATION_TEAM_REQUEST, CREATE_ORGANIZATION_TEAM_SUCCESS, CREATE_ORGANIZATION_TEAM_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams`,
      method: 'POST',
      payload: user,
    },
  };
};

export const deleteOrganizationTeam = (organizationId, userId) => {
  return {
    [CALL_API]: {
      types: [DELETE_ORGANIZATION_TEAM_REQUEST, DELETE_ORGANIZATION_TEAM_SUCCESS, DELETE_ORGANIZATION_TEAM_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${userId}`,
      method: 'DELETE',
    },
  };
};

export const resetOrganizationTeams = () => {
  return {
    type: RESET_ORGANIZATION_TEAMS,
  };
};

export const resetOrganizationSelectedTeam = () => {
  return {
    type: RESET_ORGANIZATION_SELECTED_TEAM,
  };
};
