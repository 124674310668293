import { CALL_API } from 'middleware/api';

export const GET_TEAM_SEASONS_REQUEST = 'GET_TEAM_SEASONS_REQUEST';
export const GET_TEAM_SEASONS_SUCCESS = 'GET_TEAM_SEASONS_SUCCESS';
export const GET_TEAM_SEASONS_FAILURE = 'GET_TEAM_SEASONS_FAILURE';

export const UPDATE_TEAM_SEASON_REQUEST = 'UPDATE_TEAM_SEASON_REQUEST';
export const UPDATE_TEAM_SEASON_SUCCESS = 'UPDATE_TEAM_SEASON_SUCCESS';
export const UPDATE_TEAM_SEASON_FAILURE = 'UPDATE_TEAM_SEASON_FAILURE';

export const GET_TEAM_SEASON_REQUEST = 'GET_TEAM_SEASON_REQUEST';
export const GET_TEAM_SEASON_SUCCESS = 'GET_TEAM_SEASON_SUCCESS';
export const GET_TEAM_SEASON_FAILURE = 'GET_TEAM_SEASON_FAILURE';

export const CREATE_TEAM_SEASON_REQUEST = 'CREATE_TEAM_SEASON_REQUEST';
export const CREATE_TEAM_SEASON_SUCCESS = 'CREATE_TEAM_SEASON_SUCCESS';
export const CREATE_TEAM_SEASON_FAILURE = 'CREATE_TEAM_SEASON_FAILURE';

export const DELETE_TEAM_SEASON_REQUEST = 'DELETE_TEAM_SEASON_REQUEST';
export const DELETE_TEAM_SEASON_SUCCESS = 'DELETE_TEAM_SEASON_SUCCESS';
export const DELETE_TEAM_SEASON_FAILURE = 'DELETE_TEAM_SEASON_FAILURE';

export const RESET_TEAM_SEASONS = 'RESET_TEAM_SEASONS';

export const GET_SEASON_ROSTER_REQUEST = 'GET_SEASON_ROSTER_REQUEST';
export const GET_SEASON_ROSTER_SUCCESS = 'GET_SEASON_ROSTER_SUCCESS';
export const GET_SEASON_ROSTER_FAILURE = 'GET_SEASON_ROSTER_FAILURE';

export const ADD_ATHLETE_TO_ROSTER_REQUEST = 'ADD_ATHLETE_TO_ROSTER_REQUEST';
export const ADD_ATHLETE_TO_ROSTER_SUCCESS = 'ADD_ATHLETE_TO_ROSTER_SUCCESS';
export const ADD_ATHLETE_TO_ROSTER_FAILURE = 'ADD_ATHLETE_TO_ROSTER_FAILURE';

export const ADD_ATHLETES_TO_ROSTER_REQUEST = 'ADD_ATHLETES_TO_ROSTER_REQUEST';
export const ADD_ATHLETES_TO_ROSTER_SUCCESS = 'ADD_ATHLETES_TO_ROSTER_SUCCESS';
export const ADD_ATHLETES_TO_ROSTER_FAILURE = 'ADD_ATHLETES_TO_ROSTER_FAILURE';

export const REMOVE_ATHLETE_FROM_ROSTER_REQUEST = 'REMOVE_ATHLETE_FROM_ROSTER_REQUEST';
export const REMOVE_ATHLETE_FROM_ROSTER_SUCCESS = 'REMOVE_ATHLETE_FROM_ROSTER_SUCCESS';
export const REMOVE_ATHLETE_FROM_ROSTER_FAILURE = 'REMOVE_ATHLETE_FROM_ROSTER_FAILURE';

export const RESET_SEASON_ROSTER = 'RESET_SEASON_ROSTER';

export const RESET_SELECTED_SEASON = 'RESET_SELECTED_SEASON';

export const COPY_SEASON_ROSTER_REQUEST = 'COPY_SEASON_ROSTER_REQUEST';
export const COPY_SEASON_ROSTER_SUCCESS = 'COPY_SEASON_ROSTER_SUCCESS';
export const COPY_SEASON_ROSTER_FAILURE = 'COPY_SEASON_ROSTER_FAILURE';

export const getTeamSeasons = (organizationId, teamId) => {
  return {
    [CALL_API]: {
      types: [GET_TEAM_SEASONS_REQUEST, GET_TEAM_SEASONS_SUCCESS, GET_TEAM_SEASONS_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters`,
      method: 'GET',
    },
  };
};

export const updateTeamSeason = (organizationId, teamId, season) => {
  return {
    [CALL_API]: {
      types: [UPDATE_TEAM_SEASON_REQUEST, UPDATE_TEAM_SEASON_SUCCESS, UPDATE_TEAM_SEASON_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${season.id}`,
      method: 'PUT',
      payload: season,
    },
  };
};

export const getTeamSeason = (organizationId, teamId, seasonId) => {
  return {
    [CALL_API]: {
      types: [GET_TEAM_SEASON_REQUEST, GET_TEAM_SEASON_SUCCESS, GET_TEAM_SEASON_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${seasonId}`,
      method: 'GET',
    },
  };
};

export const createTeamSeason = (organizationId, teamId, season) => {
  return {
    [CALL_API]: {
      types: [CREATE_TEAM_SEASON_REQUEST, CREATE_TEAM_SEASON_SUCCESS, CREATE_TEAM_SEASON_FAILURE],
      authenticated: true,
      endpoint: `/v1/organizations/${organizationId}/teams/${teamId}/rosters`,
      method: 'POST',
      payload: season,
    },
  };
};

export const deleteTeamSeason = (organizationId, teamId, seasonId) => {
  return {
    [CALL_API]: {
      types: [DELETE_TEAM_SEASON_REQUEST, DELETE_TEAM_SEASON_SUCCESS, DELETE_TEAM_SEASON_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${seasonId}`,
      method: 'DELETE',
    },
  };
};

export const resetTeamSeasons = () => {
  return {
    type: RESET_TEAM_SEASONS,
  };
};

export const getSeasonRoster = (organizationId, teamId, seasonId) => {
  return {
    [CALL_API]: {
      types: [GET_SEASON_ROSTER_REQUEST, GET_SEASON_ROSTER_SUCCESS, GET_SEASON_ROSTER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${seasonId}/members`,
      method: 'GET',
    },
  };
};

export const addAthleteToRoster = (organizationId, teamId, seasonId, athleteId) => {
  return {
    [CALL_API]: {
      types: [ADD_ATHLETE_TO_ROSTER_REQUEST, ADD_ATHLETE_TO_ROSTER_SUCCESS, ADD_ATHLETE_TO_ROSTER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${seasonId}/members`,
      method: 'POST',
      payload: [
        {
          athleteId,
        },
      ],
    },
  };
};

export const addAthletesToRoster = (organizationId, teamId, seasonId, athletes) => {
  return {
    [CALL_API]: {
      types: [ADD_ATHLETES_TO_ROSTER_REQUEST, ADD_ATHLETES_TO_ROSTER_SUCCESS, ADD_ATHLETES_TO_ROSTER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${seasonId}/members`,
      method: 'POST',
      payload: athletes,
    },
  };
};

export const removeAthleteFromRoster = (organizationId, teamId, seasonId, athleteId) => {
  return {
    [CALL_API]: {
      types: [
        REMOVE_ATHLETE_FROM_ROSTER_REQUEST,
        REMOVE_ATHLETE_FROM_ROSTER_SUCCESS,
        REMOVE_ATHLETE_FROM_ROSTER_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${seasonId}/members/${athleteId}`,
      method: 'DELETE',
    },
  };
};

export const resetSeasonRoster = () => {
  return {
    type: RESET_SEASON_ROSTER,
  };
};

export const resetSelectedSeason = () => {
  return {
    type: RESET_SELECTED_SEASON,
  };
};

export const copySeasonRoster = (organizationId, teamId, seasonId, copyFromRosterId) => {
  return {
    [CALL_API]: {
      types: [COPY_SEASON_ROSTER_REQUEST, COPY_SEASON_ROSTER_SUCCESS, COPY_SEASON_ROSTER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/teams/${teamId}/rosters/${seasonId}/copy`,
      method: 'POST',
      payload: {
        copyFromRosterId,
      },
    },
  };
};
