import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Box, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED } from 'modules/layout/drawer/drawer.component';
import { HEADER_HEIGHT } from 'modules/layout/header/header.component';

const PageWrapper = ({ children, className }) => {
  const { hasInitialLoad, isDrawerOpen } = useSelector(state => ({
    hasInitialLoad: state.layout.hasInitialLoad,
    isDrawerOpen: state.layout.isDrawerOpen,
  }));

  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  return (
    <Box
      className={classNames(classes.pageWrapper, className, {
        [classes.drawerOpen]: isDrawerOpen === true && isAuthenticated,
        [classes.drawerClosed]: isDrawerOpen !== true && isAuthenticated && hasInitialLoad,
        [classes.mobilePadding]: isMobile,
      })}
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="column"
      mt={isAuthenticated && hasInitialLoad ? HEADER_HEIGHT : 0}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    padding: theme.spacing(4),
  },
  drawerOpen: {
    marginLeft: DRAWER_WIDTH,
  },
  drawerClosed: {
    marginLeft: DRAWER_WIDTH_CLOSED,
  },
  mobilePadding: {
    paddingBottom: theme.spacing(10),
  },
}));

PageWrapper.propTypes = {
  className: PropTypes.string,

  children: PropTypes.any.isRequired,
};

PageWrapper.defaultProps = {
  className: '',
};

export default PageWrapper;
