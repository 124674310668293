import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Box, IconButton, makeStyles, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import ERROR_MESSAGES from 'types/errorMessages';
import Loading from 'common/components/loading.component';
import SUCCESS_MESSAGES from 'types/successMessages';
import SportScaleDialog from 'common/components/sportScaleDialog.component';
import WeightsAthleteDetails from 'modules/athletes/weights/weightsAthleteDetails.component';
import WeightsChart from 'modules/athletes/weights/weightsChart.component';
import WeightsDialogTabs, { WEIGHTS_TABS } from 'modules/athletes/weights/weightsDialogTabs.component';
import WeightsTable from 'modules/athletes/weights/weightsTable.component';
import WeightsTargetWeights from 'modules/athletes/weights/weightsTargetWeights.component';
import {
  ADD_TARGET_WEIGHT_SUCCESS,
  UPDATE_TARGET_WEIGHT_SUCCESS,
  addTargetWeight,
  getTargetWeights,
  updateTargetWeight,
} from 'modules/athletes/athletes.actions';
import { PERMISSIONS, can } from 'common/permissions';
import { STATUS_CODE_BAD_REQUEST } from 'middleware/api';
import { TOAST_TYPES, handleToastMessage } from 'modules/layout/layout.actions';
import { selectAthletesIsLoadingAthlete, selectedAthleteTargetWeights } from 'modules/athletes/athletes.selectors';
import { selectSelectedOrganizationId } from 'modules/organizations/organizations.selectors';

const TARGET_DATE_FORMAT = 'yyyy-MM-DD';
const INITIAL_TARGET_WEIGHT = {
  targetWeight: '',
  targetDate: null,
};

const WeightsDialog = ({ athlete, enableHydrationTracking, open, team, onClose }) => {
  const formRef = useRef();

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isLoading = useSelector(selectAthletesIsLoadingAthlete);
  const organizationId = useSelector(selectSelectedOrganizationId);
  const targetWeights = useSelector(selectedAthleteTargetWeights);

  const [isEditTargetWeight, setIsEditTargetWeight] = useState(false);
  const [targetWeight, setTargetWeight] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (athlete) dispatch(getTargetWeights(organizationId, athlete.id));
  }, [athlete, organizationId, dispatch]);

  const handleClose = useCallback(() => {
    setTabIndex(0);
    setTargetWeight(null);

    onClose();
  }, [onClose, setTabIndex, setTargetWeight]);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleAddTargetWeight = useCallback(async () => {
    const response = await dispatch(
      addTargetWeight(organizationId, athlete.id, {
        targetWeight: parseFloat(targetWeight.targetWeight),
        targetDate: moment(targetWeight.targetDate).format(TARGET_DATE_FORMAT),
      })
    );

    if (response.type !== ADD_TARGET_WEIGHT_SUCCESS) {
      const errorMessage =
        response.response.statusCode === STATUS_CODE_BAD_REQUEST
          ? response.response.message
          : ERROR_MESSAGES.ADD_TARGET_WEIGHT_FAILURE;

      dispatch(handleToastMessage(errorMessage, TOAST_TYPES.ERROR));
      return;
    }

    dispatch(getTargetWeights(organizationId, athlete.id));
    dispatch(handleToastMessage(SUCCESS_MESSAGES.ADD_TARGET_WEIGHT_SUCCESS));

    setTargetWeight(null);
  }, [athlete, organizationId, targetWeight, dispatch]);

  const handleEditTargetWeight = useCallback(async () => {
    const response = await dispatch(
      updateTargetWeight(organizationId, athlete.id, targetWeight.id, {
        targetWeight: parseFloat(targetWeight.targetWeight),
        targetDate: moment(targetWeight.targetDate).format(TARGET_DATE_FORMAT),
      })
    );

    if (response.type !== UPDATE_TARGET_WEIGHT_SUCCESS) {
      dispatch(handleToastMessage(ERROR_MESSAGES.UPDATE_TARGET_WEIGHT_FAILURE, TOAST_TYPES.ERROR));
      return;
    }
    dispatch(getTargetWeights(organizationId, athlete.id));
    dispatch(handleToastMessage(SUCCESS_MESSAGES.UPDATE_TARGET_WEIGHT_SUCCESS));
    setTargetWeight(null);
  }, [athlete, organizationId, targetWeight, dispatch]);

  const handleSubmitTarget = useCallback(async () => {
    if (formRef.current) {
      const isFormValid = await formRef.current.isFormValid(false);

      if (!isFormValid) return;

      if (!isEditTargetWeight) {
        handleAddTargetWeight();
        return;
      }

      handleEditTargetWeight();
    }
  }, [formRef, isEditTargetWeight, handleAddTargetWeight, handleEditTargetWeight]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setTargetWeight({
        ...targetWeight,
        [name]: value,
      });
    },
    [targetWeight]
  );

  const renderActions = useMemo(() => {
    const defaultActions = [
      {
        action: handleClose,
        text: 'Close',
      },
    ];

    if (tabIndex === WEIGHTS_TABS.WEIGHTS.INDEX || !can(PERMISSIONS.EDIT_ATHLETES)) return defaultActions;

    return !isNil(targetWeight)
      ? [
          {
            action: () => setTargetWeight(null),
            text: 'Cancel',
          },
          {
            action: handleSubmitTarget,
            disabled: isLoading,
            isPrimary: true,
            text: `${isEditTargetWeight ? 'Save' : 'Add'} Target`,
          },
        ]
      : [
          {
            action: handleClose,
            text: 'Close',
          },
          {
            action: () => setTargetWeight(INITIAL_TARGET_WEIGHT),
            isPrimary: true,
            text: 'Add Target',
          },
        ];
  }, [isEditTargetWeight, isLoading, tabIndex, targetWeight, handleClose, handleSubmitTarget]);

  const renderDialogContent = () => {
    if (isLoading || isNil(athlete) || isNil(targetWeights))
      return (
        <Box margin={theme.spacing()}>
          <Loading />
        </Box>
      );

    return (
      <>
        <Box hidden={tabIndex !== WEIGHTS_TABS.WEIGHTS.INDEX}>
          <WeightsChart
            athlete={athlete}
            enableHydrationTracking={enableHydrationTracking}
            targetWeights={targetWeights}
            team={team}
          />
          <WeightsTable athlete={athlete} enableHydrationTracking={enableHydrationTracking} team={team} />
        </Box>
        <Box marginBottom={theme.spacing() / 2} hidden={tabIndex !== WEIGHTS_TABS.TARGETS.INDEX}>
          <WeightsTargetWeights
            athlete={athlete}
            formRef={formRef}
            targetWeight={targetWeight}
            targetWeights={targetWeights}
            onChange={handleChange}
            onEdit={target => {
              setIsEditTargetWeight(true);
              setTargetWeight({ ...target, targetWeight: target.targetWeight.toString() });
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <SportScaleDialog
      actions={renderActions}
      classes={{
        dialog: {
          paper: classes.dialogPaper,
          scrollPaper: classes.scrollPaper,
        },
      }}
      content={renderDialogContent()}
      fullWidth
      maxWidth="sm"
      open={open}
      title={
        <>
          <IconButton className={classes.close} color="inherit" onClick={onClose}>
            <Close />
          </IconButton>
          <WeightsDialogTabs tabIndex={tabIndex} onChange={handleTabChange} />
          {!isNil(athlete) && (
            <WeightsAthleteDetails athlete={athlete} enableHydrationTracking={enableHydrationTracking} />
          )}
        </>
      }
      scroll="paper"
      onClose={handleClose}
    />
  );
};

const useStyles = makeStyles(() => ({
  dialogPaper: {
    position: 'relative',
    maxHeight: '50rem',
  },
  scrollPaper: {
    alignItems: 'baseline',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

WeightsDialog.defaultProps = {
  athlete: null,
  enableHydrationTracking: null,
  team: null,
};

WeightsDialog.propTypes = {
  athlete: PropTypes.object,
  enableHydrationTracking: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  team: PropTypes.object,

  onClose: PropTypes.func.isRequired,
};

export default WeightsDialog;
