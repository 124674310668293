import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "athletes" gets changed
const selectAthletesState = createSelector(
  state => state.athletes,
  athletesState => athletesState
);

export const selectAthletes = createSelector(selectAthletesState, athletesState => athletesState.athletes);

export const selectAthletesIsLoading = createSelector(
  selectAthletesState,
  athletesState => athletesState.isLoadingAthletes || athletesState.isLoadingAthlete
);

export const selectAthletesIsLoadingAthletes = createSelector(
  selectAthletesState,
  athletesState => athletesState.isLoadingAthletes
);

export const selectAthletesIsLoadingAthlete = createSelector(
  selectAthletesState,
  athletesState => athletesState.isLoadingAthlete
);

export const selectAthletesIsRemovingAthlete = createSelector(
  selectAthletesState,
  athletesState => athletesState.isRemovingAthlete
);

export const selectSelectedAthlete = createSelector(
  selectAthletesState,
  athletesState => athletesState.selectedAthlete
);

export const selectedAthleteTargetWeights = createSelector(
  selectAthletesState,
  athletesState => athletesState.selectedAthleteTargetWeights
);
