import { useEffect, useRef } from 'react';

// eslint-disable-next-line prefer-destructuring
const REACT_APP_JIRA_WIDGET_KEY = process.env.REACT_APP_JIRA_WIDGET_KEY;

// eslint-disable-next-line import/prefer-default-export
export const useJiraReportBugWidget = preventWidgetUsage => {
  const jiraScriptAppended = useRef(preventWidgetUsage);

  useEffect(() => {
    if (!jiraScriptAppended.current && REACT_APP_JIRA_WIDGET_KEY !== 'false') {
      jiraScriptAppended.current = true;
      const script = document.createElement('script');
      script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      script.setAttribute('data-jsd-embedded', '');
      script.setAttribute('data-key', REACT_APP_JIRA_WIDGET_KEY);
      script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        // Jira script listens for this event to launch widget
        document.dispatchEvent(
          new Event('DOMContentLoaded', {
            bubbles: true,
            cancelable: true,
          })
        );
        script.removeEventListener('load');

        // Position "Report A Bug" on left side of page
        const widget = document.getElementById('jsd-widget');

        widget.style.top = 0;
        widget.style.left = '50%';
        widget.style.marginLeft = '-75px';
      });
    }
  }, []);
};
