import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import MomentUtils from '@date-io/moment';
import React from 'react';
import * as Sentry from '@sentry/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { render } from 'react-dom';

import Root from 'redux/root.container';
import { theme } from 'utilities/theme';

import * as serviceWorker from './serviceWorker';

import './index.css';

const BASENAME = process.env.PUBLIC_URL || '/';

const history = createBrowserHistory();

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_KEY });
Sentry.setTag('environment', process.env.REACT_APP_ENVIRONMENT_NAME);

console.log(`APP VERSION: ${process.env.REACT_APP_VERSION}`);
console.log(`ENVIRONMENT: ${process.env.REACT_APP_ENVIRONMENT_NAME}`);
console.log(`SUPPORT: ${process.env.REACT_APP_SERVICE_DESK_URL}`);

const onRedirectCallback = appState => {
  history.replace(appState?.returnTo || window.location.pathname);
};

render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_0_CLIENT_ID}
    redirectUri={`${window.location.origin}${process.env.REACT_APP_AUTH_0_CALLBACK_URI}`}
    audience={process.env.REACT_APP_AUTH_0_AUDIENCE}
    scope={process.env.REACT_APP_AUTH_0_SCOPES}
    onRedirectCallback={onRedirectCallback}
  >
    <Router basename={BASENAME} history={history}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Root />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Router>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
