import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Athlete from 'modules/athletes/athlete.container';
import Athletes from 'modules/athletes/athletes.container';
import AuthWrapper from 'modules/auth/authWrapper.container';
import EmptyOrganizations from 'modules/organizations/emptyOrganizations.container';
import ErrorPage from 'modules/layout/errorPage.container';
import Location from 'modules/locations/location.container';
import Locations from 'modules/locations/locations.container';
import Login from 'modules/login/login.container';
import Organization from 'modules/organizations/organization.container';
import Profile from 'modules/profile/profile.container';
import ProtectedRoute from 'modules/layout/routes/protectedRoute.component';
import Reports from 'modules/reports/reports.container';
import Scale from 'modules/scales/scale.container';
import Scales from 'modules/scales/scales.container';
import Season from 'modules/teams/seasons/season.container';
import Subscription from 'modules/organizations/subscriptions/subscription.container';
import Team from 'modules/teams/team.container';
import Teams from 'modules/teams/teams.container';
import User from 'modules/users/user.container';
import Users from 'modules/users/users.container';
import WatchList from 'modules/watchlist/watchlist.container';
import { PERMISSIONS, hasPermission } from 'common/permissions';

import { ORGANIZATION_BASE_ROUTE, ROUTES } from 'utilities/breadCrumbs';

const Routes = memo(() => (
  <AuthWrapper>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/unauthorized" component={ErrorPage} />
      <ProtectedRoute exact path={ORGANIZATION_BASE_ROUTE} component={Organization} />
      <ProtectedRoute
        exact
        path={ROUTES.LOCATIONS_ROUTE}
        component={hasPermission(PERMISSIONS.READ_LOCATIONS)(Locations)}
      />
      <ProtectedRoute
        exact
        path={ROUTES.LOCATION_ROUTE}
        component={hasPermission(PERMISSIONS.READ_LOCATIONS)(Location)}
      />
      <ProtectedRoute exact path={ROUTES.USERS_ROUTE} component={hasPermission(PERMISSIONS.READ_USERS)(Users)} />
      <ProtectedRoute exact path={ROUTES.USER_ROUTE} component={hasPermission(PERMISSIONS.READ_USERS)(User)} />
      <ProtectedRoute exact path={ROUTES.SCALES_ROUTE} component={hasPermission(PERMISSIONS.READ_SCALES)(Scales)} />
      <ProtectedRoute exact path={ROUTES.SCALE_ROUTE} component={hasPermission(PERMISSIONS.READ_SCALES)(Scale)} />
      <ProtectedRoute exact path={ROUTES.SUBSCRIPTION_ROUTE} component={Subscription} />
      <ProtectedRoute exact path={ROUTES.TEAMS_ROUTE} component={Teams} />
      <ProtectedRoute exact path={ROUTES.TEAM_ROUTE} component={hasPermission(PERMISSIONS.READ_TEAMS)(Team)} />
      <ProtectedRoute exact path={ROUTES.SEASON_ROUTE} component={hasPermission(PERMISSIONS.READ_TEAMS)(Season)} />
      <ProtectedRoute
        exact
        path={ROUTES.ATHLETES_ROUTE}
        component={hasPermission(PERMISSIONS.READ_ATHLETES)(Athletes)}
      />
      <ProtectedRoute exact path={ROUTES.ATHLETE_ROUTE} component={hasPermission(PERMISSIONS.READ_ATHLETES)(Athlete)} />
      <ProtectedRoute exact path={ROUTES.WATCH_LIST_ROUTE} component={WatchList} />
      <ProtectedRoute exact path={ROUTES.ANALYSIS_ROUTE} component={Reports} />
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path="/notFound" component={ErrorPage} />
      <ProtectedRoute exact path="/empty" component={EmptyOrganizations} />
      <Redirect from="*" to="/login" />
    </Switch>
  </AuthWrapper>
));

export default Routes;
