import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SportScaleRadio from 'common/formFields/sportScaleRadio.component';
import { DEFAULT_ORG_ROUTE } from 'utilities/routes';
import { setSelectedOrganizationId } from 'modules/organizations/organizations.actions';
import { toggleOrgSelectionDialog } from 'modules/layout/layout.actions';

const OrganizationSelectionDialog = (/* props */) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isOpen, organizations } = useSelector(state => ({
    isOpen: state.layout.isOrgSelectionDialogOpen,
    organizations: state.organizations.organizations,
  }));

  const [org, setOrg] = useState(null);

  const handleOrganizationChange = useCallback(({ target: { value } }) => setOrg(value), [setOrg]);

  const handleOrganizationSelect = useCallback(() => {
    dispatch(toggleOrgSelectionDialog());
    dispatch(setSelectedOrganizationId(org));

    history.push(`/organizations/${org}/${DEFAULT_ORG_ROUTE}`);
  }, [dispatch, history, org]);

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={isOpen}>
        <DialogTitle disableTypography>
          <Typography align="center" variant="h6">
            Select Organization
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <RadioGroup className={classes.radioGroup} value={org} onChange={handleOrganizationChange}>
            {organizations.map(item => (
              <SportScaleRadio key={item.id} isSelected={item.id === org} label={item.name} value={item.id} />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOrganizationSelect}>select</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((/* theme */) => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  radioGroup: {
    width: '100%',
  },
}));

OrganizationSelectionDialog.propTypes = {};

export default OrganizationSelectionDialog;
