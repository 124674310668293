import produce from 'immer';
import { isNil } from 'lodash';

import LOCAL_STORAGE_KEYS from 'types/localStorage';
import {
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  GET_ORGANIZATIONS_FAILURE,
  GET_ORGANIZATIONS_REQUEST,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_ROLES_FAILURE,
  GET_ORGANIZATION_ROLES_REQUEST,
  GET_ORGANIZATION_ROLES_SUCCESS,
  GET_ORGANIZATION_SUCCESS,
  SET_SELECTED_ORG_ID,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
} from 'modules/organizations/organizations.actions';
import { getStorageItem, removeStorageItem, setStorageItem } from 'utilities/localStorage';

const initialState = {
  isLoadingOrganization: false,
  isLoadingOrganizations: false,
  organization: null,
  organizations: [],
  roles: [],
  selectedOrganizationId: getStorageItem(LOCAL_STORAGE_KEYS.SELECTED_ORG),
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATIONS_SUCCESS:
      draft.organizations = action.response;
      draft.isLoadingOrganizations = false;
      break;

    case GET_ORGANIZATIONS_FAILURE:
      draft.isLoadingOrganizations = false;
      break;

    case GET_ORGANIZATIONS_REQUEST:
      draft.isLoadingOrganizations = true;
      break;

    case GET_ORGANIZATION_SUCCESS:
    case CREATE_ORGANIZATION_SUCCESS:
    case UPDATE_ORGANIZATION_SUCCESS:
      draft.organization = action.response;
      draft.isLoadingOrganization = false;
      break;

    case GET_ORGANIZATION_FAILURE:
    case CREATE_ORGANIZATION_FAILURE:
    case UPDATE_ORGANIZATION_FAILURE:
      draft.isLoadingOrganization = false;
      break;

    case GET_ORGANIZATION_REQUEST:
    case CREATE_ORGANIZATION_REQUEST:
    case UPDATE_ORGANIZATION_REQUEST:
      draft.isLoadingOrganization = true;
      break;

    case SET_SELECTED_ORG_ID:
      draft.selectedOrganizationId = action.organizationId;

      if (isNil(action.organizationId)) {
        removeStorageItem(LOCAL_STORAGE_KEYS.SELECTED_ORG);
      } else {
        setStorageItem(LOCAL_STORAGE_KEYS.SELECTED_ORG, action.organizationId);
      }
      break;

    case GET_ORGANIZATION_ROLES_SUCCESS:
      draft.roles = action.response;
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_ROLES_FAILURE:
      draft.isLoading = false;
      break;

    case GET_ORGANIZATION_ROLES_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
