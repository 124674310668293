import PropTypes from 'prop-types';
import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import FIELD_LABELS from 'types/fieldLabels';
import SportScaleMaskedTextFieldValidator from 'common/formFields/sportScaleMaskedTextFieldValidator.component';
import { VALIDATION_TYPES, getValidationErrorMessages } from 'utilities/fieldValidation';

const LETTERS_AND_NUMBERS_REGEX = /[A-Za-z0-9]/;

const RegisterScaleForm = ({ registrationCode, isLoading, onChange }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <SportScaleMaskedTextFieldValidator
        disabled={isLoading}
        errorMessages={getValidationErrorMessages(registrationCode, FIELD_LABELS.SCALE_REGISTRATION_CODE, [
          VALIDATION_TYPES.REQUIRED,
        ])}
        fullWidth
        label={FIELD_LABELS.SCALE_REGISTRATION_CODE}
        mask={[
          LETTERS_AND_NUMBERS_REGEX,
          LETTERS_AND_NUMBERS_REGEX,
          LETTERS_AND_NUMBERS_REGEX,
          '-',
          LETTERS_AND_NUMBERS_REGEX,
          LETTERS_AND_NUMBERS_REGEX,
          LETTERS_AND_NUMBERS_REGEX,
          '-',
          LETTERS_AND_NUMBERS_REGEX,
          LETTERS_AND_NUMBERS_REGEX,
          LETTERS_AND_NUMBERS_REGEX,
          LETTERS_AND_NUMBERS_REGEX,
        ]}
        maskPlaceholder="a1b-2c3-d4e5"
        name="registrationCode"
        validators={[VALIDATION_TYPES.REQUIRED]}
        value={registrationCode}
        onChange={onChange}
      />
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

RegisterScaleForm.propTypes = {
  registrationCode: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default RegisterScaleForm;
