export const CHART_DOMAIN_PADDING = 0;

const ANIMATION_DURATION = 2000;
export const ANIMATION_SETTINGS = {
  duration: ANIMATION_DURATION,
  onLoad: { duration: ANIMATION_DURATION },
};

export const CHART_LINE_PARENT_STYLE = { border: `4px solid grey` };
export const CHART_AXIS_STYLE = { tickLabels: { fill: 'white' }, axisLabel: { fill: 'white' } };
export const CHART_LEGEND_STYLE = { border: { fill: 'transparent' }, labels: { fill: 'white' } };
