import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "teams" gets changed
const selectTeamsState = createSelector(
  state => state.teams,
  teamsState => teamsState
);

export const selectTeams = createSelector(selectTeamsState, teamsState => teamsState.teams);

export const selectTeamsIsLoading = createSelector(
  selectTeamsState,
  teamsState => teamsState.isLoading || teamsState.isLoadingTeam
);

export const selectTeamsIsLoadingTeams = createSelector(selectTeamsState, teamsState => teamsState.isLoading);

export const selectTeamsIsLoadingTeam = createSelector(selectTeamsState, teamsState => teamsState.isLoadingTeam);

export const selectSelectedTeam = createSelector(selectTeamsState, teamsState => teamsState.selectedTeam);
