import { isNil } from 'lodash';
import { theme } from 'utilities/theme';

import WEIGHT from 'types/weight.types';

export const WEIGHT_EMPTY_STRING = '- -';
export const SEVERE_MULTIPLIER = 2;

const getWeightType = member => {
  const { weight } = member;

  // if hydration tracking, only return weigh in or null
  if (weight.hydrationTracking === false) return WEIGHT.SIMPLE;

  if (isNil(weight.weighIn)) return WEIGHT.WEIGH_IN;

  if (!isNil(weight.weighIn) && isNil(weight.weighOut)) return WEIGHT.WEIGH_OUT;

  return null;
};

const getIn2OutDelta = (weighInAmount, weighOutAmount) => {
  if (isNil(weighInAmount) || isNil(weighOutAmount) || weighInAmount <= 0) return null;

  return (-1 * (weighInAmount - weighOutAmount)) / weighInAmount;
};

const getIndicatorColor = (percent, threshold) => {
  if (percent >= threshold * SEVERE_MULTIPLIER) {
    return theme.palette.error.main;
  }

  if (percent >= threshold) {
    return theme.palette.warning.main;
  }

  return theme.palette.common.white;
};

const calcWeightChange = (initialWeight, targetWeight) => {
  if (isNil(initialWeight) || isNil(targetWeight)) return WEIGHT_EMPTY_STRING;

  const isLoss = initialWeight > targetWeight;

  const directionSign = isLoss ? '-' : '+';
  const weightPercentage = isLoss ? 1 - targetWeight / initialWeight : targetWeight / initialWeight - 1;

  return `${directionSign}${Math.round(weightPercentage.toFixed(2) * 100)}%`;
};

export default { calcWeightChange, getWeightType, getIn2OutDelta, getIndicatorColor };
