import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import SportScaleDatePickerValidator from 'common/formFields/sportScaleDatePickerValidator.component';
import { Container, Item } from 'common/components/grid.component';
import { VALIDATION_TYPES, fieldValidators, getValidationErrorMessages } from 'utilities/fieldValidation';
import { ValidatorForm } from 'react-material-ui-form-validator';

const SubscriptionForm = ({ containerClass, isLoading, subscription, onChange }) => {
  useEffect(() => {
    ValidatorForm.addValidationRule(
      VALIDATION_TYPES.IS_DATE_AFTER_DATE,
      value =>
        !(
          fieldValidators[VALIDATION_TYPES.IS_DATE_AFTER_DATE] &&
          fieldValidators[VALIDATION_TYPES.IS_DATE_AFTER_DATE](
            value,
            'Subscription End Date',
            subscription.startDate,
            'Subscription Start Date'
          )
        )
    );

    return () => {
      ValidatorForm.removeValidationRule(VALIDATION_TYPES.IS_DATE_AFTER_DATE);
    };
  }, [subscription]);

  return (
    <Container className={containerClass} spacing={2}>
      <Item sm={6} xs={12}>
        <SportScaleDatePickerValidator
          disabled={isLoading}
          errorMessages={getValidationErrorMessages(subscription.startDate, 'Subscription Start Date', [
            VALIDATION_TYPES.REQUIRED,
            VALIDATION_TYPES.IS_DATE,
          ])}
          fullWidth
          label="Subscription Start Date"
          name="startDate"
          value={subscription.startDate}
          validators={[VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_DATE]}
          onChange={onChange}
        />
      </Item>
      <Item sm={6} xs={12}>
        <SportScaleDatePickerValidator
          compareLabel="Subscription Start Date"
          compareValue={subscription.startDate}
          disabled={isLoading}
          errorMessages={getValidationErrorMessages(
            subscription.endDate,
            'Subscription End Date',
            [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_DATE, VALIDATION_TYPES.IS_DATE_AFTER_DATE],
            subscription.startDate,
            'Subscription Start Date'
          )}
          fullWidth
          label="Subscription End Date"
          name="endDate"
          value={subscription.endDate}
          validators={[VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.IS_DATE, VALIDATION_TYPES.IS_DATE_AFTER_DATE]}
          onChange={onChange}
        />
      </Item>
    </Container>
  );
};

SubscriptionForm.propTypes = {
  containerClass: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  subscription: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

SubscriptionForm.defaultProps = {
  containerClass: '',
};

export default SubscriptionForm;
