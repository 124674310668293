import PropTypes from 'prop-types';
import React from 'react';
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';

const Loading = ({ disableShrink, text }) => {
  const classes = useStyles();

  return (
    <Box m="auto" textAlign="center">
      <CircularProgress size={60} className={classes.spinner} disableShrink={disableShrink} />
      {text && <Typography variant="h6">{text}</Typography>}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  spinner: {
    marginBottom: '2rem',
  },
}));

Loading.propTypes = {
  disableShrink: PropTypes.bool,
  text: PropTypes.string,
};

Loading.defaultProps = {
  disableShrink: false,
  text: '',
};

export default Loading;
