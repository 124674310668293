/* eslint-disable import/prefer-default-export */

import Colors from 'styles/colors';
import { createMuiTheme, useMediaQuery, useTheme } from '@material-ui/core';

/**
 * This comes from: https://material-ui.com/components/use-media-query/#migrating-from-withwidth
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();

  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

// eslint-disable-next-line import/prefer-default-export
export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: Colors.BRAND_LIME_GREEN,
    },
    secondary: {
      main: Colors.BRAND_GRAY_DARK_ALT,
    },
    success: {
      main: Colors.BRAND_LIGHT_BLUE,
    },
    background: {
      default: Colors.BRAND_GRAY_DARK,
      paper: Colors.BRAND_GRAY_DARK_ALT,
    },
    error: {
      main: Colors.ERROR,
    },
    warning: {
      main: Colors.WARNING,
    },
    common: {
      white: '#fff',
    },
  },
});
