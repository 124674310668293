import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATION_USERS_REQUEST = 'GET_ORGANIZATION_USERS_REQUEST';
export const GET_ORGANIZATION_USERS_SUCCESS = 'GET_ORGANIZATION_USERS_SUCCESS';
export const GET_ORGANIZATION_USERS_FAILURE = 'GET_ORGANIZATION_USERS_FAILURE';

export const UPDATE_ORGANIZATION_USER_REQUEST = 'UPDATE_ORGANIZATION_USER_REQUEST';
export const UPDATE_ORGANIZATION_USER_SUCCESS = 'UPDATE_ORGANIZATION_USER_SUCCESS';
export const UPDATE_ORGANIZATION_USER_FAILURE = 'UPDATE_ORGANIZATION_USER_FAILURE';

export const GET_ORGANIZATION_USER_REQUEST = 'GET_ORGANIZATION_USER_REQUEST';
export const GET_ORGANIZATION_USER_SUCCESS = 'GET_ORGANIZATION_USER_SUCCESS';
export const GET_ORGANIZATION_USER_FAILURE = 'GET_ORGANIZATION_USER_FAILURE';

export const CREATE_ORGANIZATION_USER_REQUEST = 'CREATE_ORGANIZATION_USER_REQUEST';
export const CREATE_ORGANIZATION_USER_SUCCESS = 'CREATE_ORGANIZATION_USER_SUCCESS';
export const CREATE_ORGANIZATION_USER_FAILURE = 'CREATE_ORGANIZATION_USER_FAILURE';

export const DELETE_ORGANIZATION_USER_REQUEST = 'DELETE_ORGANIZATION_USER_REQUEST';
export const DELETE_ORGANIZATION_USER_SUCCESS = 'DELETE_ORGANIZATION_USER_SUCCESS';
export const DELETE_ORGANIZATION_USER_FAILURE = 'DELETE_ORGANIZATION_USER_FAILURE';

export const RESET_ORGANIZATION_USERS = 'RESET_ORGANIZATION_USERS';

export const RESET_ORGANIZATION_SELECTED_USER = 'RESET_ORGANIZATION_SELECTED_USER';

export const RESEND_USER_VERIFICATION_EMAIL_REQUEST = 'RESEND_USER_VERIFICATION_EMAIL_REQUEST';
export const RESEND_USER_VERIFICATION_EMAIL_SUCCESS = 'RESEND_USER_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_USER_VERIFICATION_EMAIL_FAILURE = 'RESEND_USER_VERIFICATION_EMAIL_FAILURE';

export const getOrganizationUsers = organizationId => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_USERS_REQUEST, GET_ORGANIZATION_USERS_SUCCESS, GET_ORGANIZATION_USERS_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/users`,
      method: 'GET',
    },
  };
};

export const updateOrganizationUser = (organizationId, user) => {
  return {
    [CALL_API]: {
      types: [UPDATE_ORGANIZATION_USER_REQUEST, UPDATE_ORGANIZATION_USER_SUCCESS, UPDATE_ORGANIZATION_USER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/users/${user.id}`,
      method: 'PUT',
      payload: user,
    },
  };
};

export const getOrganizationUser = (organizationId, userId) => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_USER_REQUEST, GET_ORGANIZATION_USER_SUCCESS, GET_ORGANIZATION_USER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/users/${userId}`,
      method: 'GET',
    },
  };
};

export const createOrganizationUser = (organizationId, user) => {
  return {
    [CALL_API]: {
      types: [CREATE_ORGANIZATION_USER_REQUEST, CREATE_ORGANIZATION_USER_SUCCESS, CREATE_ORGANIZATION_USER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/users`,
      method: 'POST',
      payload: user,
    },
  };
};

export const deleteOrganizationUser = (organizationId, userId) => {
  return {
    [CALL_API]: {
      types: [DELETE_ORGANIZATION_USER_REQUEST, DELETE_ORGANIZATION_USER_SUCCESS, DELETE_ORGANIZATION_USER_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/users/${userId}`,
      method: 'DELETE',
    },
  };
};

export const resetOrganizationUsers = () => {
  return {
    type: RESET_ORGANIZATION_USERS,
  };
};

export const resetOrganizationSelectedUser = () => {
  return {
    type: RESET_ORGANIZATION_SELECTED_USER,
  };
};

export const resendVerificationEmail = (organizationId, userId) => {
  return {
    [CALL_API]: {
      types: [
        RESEND_USER_VERIFICATION_EMAIL_REQUEST,
        RESEND_USER_VERIFICATION_EMAIL_SUCCESS,
        RESEND_USER_VERIFICATION_EMAIL_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/users/${userId}/reinvite`,
      method: 'POST',
    },
  };
};
