import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "users" gets changed
const selectUsersState = createSelector(
  state => state.users,
  usersState => usersState
);

export const selectUsers = createSelector(selectUsersState, usersState => usersState.users);

export const selectUsersIsLoading = createSelector(
  selectUsersState,
  usersState => usersState.isLoading || usersState.isLoadingUser
);

export const selectUsersIsLoadingUsers = createSelector(selectUsersState, usersState => usersState.isLoading);

export const selectUsersIsLoadingUser = createSelector(selectUsersState, usersState => usersState.isLoadingUser);
