export default Object.freeze({
  ADD_ATHLETE_TO_ROSTER_FAILURE:
    'There was an error adding the athlete to the roster. Please try again or contact your administrator.',
  ADD_ATHLETES_TO_ROSTER_FAILURE:
    'There was an error adding the athletes to the roster. Please try again or contact your administrator.',
  ADD_TARGET_WEIGHT_FAILURE:
    'There was an error adding the target weight. Please try again or contact your administrator',
  COPY_SEASON_ROSTER_FAILURE: 'There was an error copying the roster. Please try again or contact your administrator.',
  CREATE_ATHLETE_FAILURE: 'There was an error creating the athlete. Please try again or contact your administrator.',
  CREATE_DUPLICATE_ATHLETE_ERROR: 'The ID number you provided already exists. Please try again.',
  CREATE_DUPLICATE_TEAM_ERROR: 'The sport name you provided already exists. Please try again.',
  CREATE_LOCATION_FAILURE: 'There was an error creating the location. Please try again or contact your administrator.',
  CREATE_ORGANIZATION_FAILURE:
    'There was an error creating the organization. Please try again or contact your administrator.',
  CREATE_SCALE_FAILURE: 'There was an error creating the scale. Please try again or contact your administrator.',
  CREATE_SEASON_FAILURE: 'There was an error creating the season. Please try again or contact your administrator.',
  CREATE_SUBSCRIPTION_FAILURE:
    'There was an error creating the subscription. Please try again or contact your administrator.',
  CREATE_TEAM_FAILURE: 'There was an error creating the sport. Please try again or contact your administrator.',
  CREATE_USER_FAILURE: 'There was an error creating the user. Please try again or contact your administrator.',
  DELETE_ATHLETE_FAILURE: 'There was an error deleting the athlete. Please try again or contact your administrator',
  DELETE_ATHLETE_FACE_DATA_FAILURE:
    'There was an error deleting the athlete face data. Please try again or contact your administrator',
  DELETE_LOCATION_FAILURE: 'There was an error deleting the location. Please try again or contact your administrator',
  DELETE_SCALE_FAILURE: 'There was an error unregistering the scale. Please try again or contact your administrator',
  DELETE_SEASON_FAILURE: 'There was an error deleting the season. Please try again or contact your administrator',
  DELETE_SUBSCRIPTION_FAILURE:
    'There was an error deleting the subscription. Please try again or contact your administrator',
  DELETE_TARGET_WEIGHT_FAILURE:
    'There was an error deleting the target weight. Please try again or contact your administrator',
  DELETE_TEAM_FAILURE: 'There was an error deleting the sport. Please try again or contact your administrator',
  DELETE_USER_FAILURE: 'There was an error deleting the user. Please try again or contact your administrator',
  GET_ORGANIZATION_ATHLETES_FAILURE:
    'There was an error requesting athletes. Please try again or contact your administrator.',
  GET_ORGANIZATION_LOCATIONS_FAILURE:
    'There was an error requesting locations. Please try again or contact your administrator.',
  GET_ORGANIZATION_SCALES_FAILURE:
    'There was an error requesting scales. Please try again or contact your administrator.',
  GET_ORGANIZATION_TEAMS_FAILURE:
    'There was an error requesting sports. Please try again or contact your administrator.',
  GET_ORGANIZATION_USERS_FAILURE:
    'There was an error requesting users. Please try again or contact your administrator.',
  GET_SEASON_ROSTER_FAILURE:
    'There was an error requesting the roster. Please try again or contact your administrator.',
  GET_TEAM_SEASONS_FAILURE: 'There was an error requesting seasons. Please try again or contact your administrator.',
  REGISTER_SCALE_FAILURE: 'There was an error registering your scale. Please try again or contact your administrator.',
  REMOVE_ATHLETE_FROM_ROSTER_FAILURE:
    'There was an error removing the athlete. Please try again or contact your administrator.',
  RESEND_USER_VERIFICATION_EMAIL_FAILURE:
    'There was an error resending the user verification email. Please try again or contact your administrator.',
  UPDATE_ATHLETE_FAILURE: 'There was an error updating your athlete. Please try again or contact your administrator.',
  UPDATE_LOCATION_FAILURE: 'There was an error updating the location. Please try again or contact your administrator.',
  UPDATE_ORGANIZATION_FAILURE:
    'There was an error updating your organization. Please try again or contact your administrator.',
  UPDATE_PROFILE_FAILURE: 'There was an error updating your profile. Please try again or contact your administrator.',
  UPDATE_SCALE_FAILURE: 'There was an error updating the scale. Please try again or contact your administrator.',
  UPDATE_SEASON_FAILURE: 'There was an error updating the season. Please try again or contact your administrator.',
  UPDATE_TARGET_WEIGHT_FAILURE:
    'There was an error updating the target weight. Please try again or contact your administrator.',
  UPDATE_TEAM_FAILURE: 'There was an error updating the sport. Please try again or contact your administrator.',
  UPDATE_SUBSCRIPTION_FAILURE:
    'There was an error updating the subscription. Please try again or contact your administrator.',
  UPDATE_USER_FAILURE: 'There was an error updating the user. Please try again or contact your administrator.',
});
