const PERMISSIONS = {
  // Athletes
  CREATE_ATHLETES: 'create:athlete',
  EDIT_ATHLETES: 'update:athlete',
  READ_ATHLETES: 'read:athlete',
  REMOVE_ATHLETES: 'delete:athlete',
  // Locations
  CREATE_LOCATIONS: 'create:location',
  READ_LOCATIONS: 'read:location',
  EDIT_LOCATIONS: 'update:location',
  REMOVE_LOCATIONS: 'delete:location',
  // Roster
  CREATE_ROSTERS: 'create:roster',
  EDIT_ROSTERS: 'update:roster',
  READ_ROSTERS: 'read:roster',
  REMOVE_ROSTERS: 'delete:roster',
  // Scales
  CREATE_SCALES: 'create:scale',
  EDIT_SCALES: 'update:scale',
  READ_SCALES: 'read:scale',
  REMOVE_SCALES: 'delete:scale',
  // Teams
  CREATE_TEAMS: 'create:team',
  EDIT_TEAMS: 'update:team',
  READ_TEAMS: 'read:team',
  REMOVE_TEAMS: 'delete:team',
  // Users
  READ_USERS: 'read:user',
  CREATE_USERS: 'create:user',
  EDIT_USERS: 'update:user',
  REMOVE_USERS: 'delete:user',
  /* IS_SUPERADMIN doesn't actually exist but 'can' will always return true for
  superadmins so this provides a way to check that and keep permission checking
  consistent throughout the app */
  IS_SUPERADMIN: 'is:superadmin',
};
export default PERMISSIONS;
