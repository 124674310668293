import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "organizations" gets changed
const selectLayoutState = createSelector(
  state => state.layout,
  layoutState => layoutState
);

export const selectLayoutIsDrawerOpen = createSelector(selectLayoutState, layoutState => layoutState.isDrawerOpen);

export const selectLayoutPageTitle = createSelector(selectLayoutState, layoutState => layoutState.pageTitle);
