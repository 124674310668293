import { CALL_API } from 'middleware/api';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const getProfile = () => {
  return {
    [CALL_API]: {
      types: [GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE],
      authenticated: true,
      endpoint: 'v1/profile',
      method: 'GET',
    },
  };
};

export const updateProfile = profile => {
  return {
    [CALL_API]: {
      types: [UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE],
      authenticated: true,
      endpoint: 'v1/profile',
      method: 'PUT',
      payload: profile,
    },
  };
};
