import PropTypes from 'prop-types';
import React, { memo } from 'react';

import SportScaleCustomValidator from 'common/formFields/sportScaleCustomValidator.component';
import SportScaleMaskedTextField from 'common/formFields/sportScaleMaskedTextField.component';

const SportScaleMaskedTextFieldValidator = memo(
  ({
    disabled,
    disableBlurValidation,
    errorMessages,
    label,
    name,
    value,
    validators,
    onBlur,
    onChange,
    ...restProps
  }) => (
    <SportScaleCustomValidator
      disableBlurValidation={disableBlurValidation}
      errorMessages={errorMessages}
      label={label}
      name={name}
      validators={validators}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      <SportScaleMaskedTextField {...restProps} />
    </SportScaleCustomValidator>
  )
);

SportScaleMaskedTextFieldValidator.propTypes = {
  disabled: PropTypes.bool,
  disableBlurValidation: PropTypes.bool,
  errorMessages: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  validators: PropTypes.array.isRequired,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScaleMaskedTextFieldValidator.defaultProps = {
  disabled: false,
  disableBlurValidation: false,
  label: '',
  name: '',
  readOnly: false,
  value: '',
  onBlur: () => {},
};

export default SportScaleMaskedTextFieldValidator;
