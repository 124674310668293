import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import can from 'common/permissions/can';

export default function HasPermission(permission) {
  return WrappedComponent =>
    function Wrapper(props) {
      const classes = useStyles();
      if (can(permission)) {
        return <WrappedComponent {...props} />;
      }
      return (
        <div className={classes.noPermission}>
          <Typography>You don&apos;t have permission to view this page.</Typography>
        </div>
      );
    };
}
const useStyles = makeStyles({
  noPermission: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '80vh',
  },
});
