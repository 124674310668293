import PropTypes from 'prop-types';
import React, { memo } from 'react';

import SportScaleCustomValidator from 'common/formFields/sportScaleCustomValidator.component';
import SportScaleTextField from 'common/formFields/sportScaleTextField.component';

const SportScaleTextFieldValidator = memo(
  ({ disableBlurValidation, errorMessages, label, name, validators, value, onBlur, onChange, ...restProps }) => (
    <SportScaleCustomValidator
      disableBlurValidation={disableBlurValidation}
      errorMessages={errorMessages}
      label={label}
      name={name}
      validators={validators}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      <SportScaleTextField {...restProps} />
    </SportScaleCustomValidator>
  )
);

SportScaleTextFieldValidator.propTypes = {
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SportScaleTextFieldValidator.defaultProps = {
  disableBlurValidation: false,
  disabled: false,
  errorMessages: [],
  validators: [],
  value: '',
  onBlur: () => {},
};

export default SportScaleTextFieldValidator;
