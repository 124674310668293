import { createSelector } from 'reselect';

// The goal here is to make it so that the state tree gets recalculated only when something on "scales" gets changed
const selectScalesState = createSelector(
  state => state.scales,
  scalesState => scalesState
);

export const selectScales = createSelector(selectScalesState, scalesState => scalesState.scales);

export const selectScalesIsLoading = createSelector(
  selectScalesState,
  scalesState => scalesState.isLoadingScales || scalesState.isLoadingScale
);

export const selectScalesIsLoadingScale = createSelector(selectScalesState, scalesState => scalesState.isLoadingScale);

export const selectScalesIsLoadingScales = createSelector(
  selectScalesState,
  scalesState => scalesState.isLoadingScales
);

export const selectSelectedScale = createSelector(selectScalesState, scalesState => scalesState.selectedScale);

export const selectScalesIsRegisteringScale = createSelector(
  selectScalesState,
  scalesState => scalesState.isRegisteringScale
);
