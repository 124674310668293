import { CALL_API } from 'middleware/api';

export const GET_ORGANIZATION_SCALES_REQUEST = 'GET_ORGANIZATION_SCALES_REQUEST';
export const GET_ORGANIZATION_SCALES_SUCCESS = 'GET_ORGANIZATION_SCALES_SUCCESS';
export const GET_ORGANIZATION_SCALES_FAILURE = 'GET_ORGANIZATION_SCALES_FAILURE';

export const GET_ORGANIZATION_SCALE_REQUEST = 'GET_ORGANIZATION_SCALE_REQUEST';
export const GET_ORGANIZATION_SCALE_SUCCESS = 'GET_ORGANIZATION_SCALE_SUCCESS';
export const GET_ORGANIZATION_SCALE_FAILURE = 'GET_ORGANIZATION_SCALE_FAILURE';

export const UPDATE_ORGANIZATION_SCALE_REQUEST = 'UPDATE_ORGANIZATION_SCALE_REQUEST';
export const UPDATE_ORGANIZATION_SCALE_SUCCESS = 'UPDATE_ORGANIZATION_SCALE_SUCCESS';
export const UPDATE_ORGANIZATION_SCALE_FAILURE = 'UPDATE_ORGANIZATION_SCALE_FAILURE';

export const DELETE_ORGANIZATION_SCALE_REQUEST = 'DELETE_ORGANIZATION_SCALE_REQUEST';
export const DELETE_ORGANIZATION_SCALE_SUCCESS = 'DELETE_ORGANIZATION_SCALE_SUCCESS';
export const DELETE_ORGANIZATION_SCALE_FAILURE = 'DELETE_ORGANIZATION_SCALE_FAILURE';

export const RESET_ORGANIZATION_SCALES = 'RESET_ORGANIZATION_SCALES';

export const REGISTER_ORGANIZATION_SCALE_REQUEST = 'REGISTER_ORGANIZATION_SCALE_REQUEST';
export const REGISTER_ORGANIZATION_SCALE_SUCCESS = 'REGISTER_ORGANIZATION_SCALE_SUCCESS';
export const REGISTER_ORGANIZATION_SCALE_FAILURE = 'REGISTER_ORGANIZATION_SCALE_FAILURE';

export const getOrganizationScales = organizationId => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_SCALES_REQUEST, GET_ORGANIZATION_SCALES_SUCCESS, GET_ORGANIZATION_SCALES_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/scales`,
      method: 'GET',
    },
  };
};

export const getOrganizationScale = (organizationId, scaleId) => {
  return {
    [CALL_API]: {
      types: [GET_ORGANIZATION_SCALE_REQUEST, GET_ORGANIZATION_SCALE_SUCCESS, GET_ORGANIZATION_SCALE_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/scales/${scaleId}`,
      method: 'GET',
    },
  };
};

export const updateOrganizationScale = (organizationId, scaleId, scale) => {
  return {
    [CALL_API]: {
      types: [UPDATE_ORGANIZATION_SCALE_REQUEST, UPDATE_ORGANIZATION_SCALE_SUCCESS, UPDATE_ORGANIZATION_SCALE_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/scales/${scaleId}`,
      method: 'PUT',
      payload: scale,
    },
  };
};

export const deleteOrganizationScale = (organizationId, scaleId) => {
  return {
    [CALL_API]: {
      types: [DELETE_ORGANIZATION_SCALE_REQUEST, DELETE_ORGANIZATION_SCALE_SUCCESS, DELETE_ORGANIZATION_SCALE_FAILURE],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/scales/${scaleId}`,
      method: 'DELETE',
    },
  };
};

export const resetOrganizationScales = () => {
  return {
    type: RESET_ORGANIZATION_SCALES,
  };
};

export const registerOrganizationScale = (organizationId, registrationCode) => {
  return {
    [CALL_API]: {
      types: [
        REGISTER_ORGANIZATION_SCALE_REQUEST,
        REGISTER_ORGANIZATION_SCALE_SUCCESS,
        REGISTER_ORGANIZATION_SCALE_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/organizations/${organizationId}/linkscale`,
      method: 'POST',
      payload: {
        identifier: registrationCode,
      },
    },
  };
};
